<template>
  <span>
    <v-btn
      @click="exportExcel"
      :width="$vuetify.breakpoint.xs ? 100 : 150"
      color="primary"
      class="white--text text-capitalize"
      :small="$vuetify.breakpoint.xs"
      :loading="loading"
    >
      <v-icon
        :small="$vuetify.breakpoint.xs"
        :size="$vuetify.breakpoint.smAndUp ? 17 : ''"
        class="me-1"
        >fa-file-export</v-icon
      >Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="printQuickView"
        hide-default-footer
        :headers="headers"
        :items="itemsWithSno"
        :items-per-page="-1"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.order_id="{ item }">
          #{{ padLeft(item.order_id, 4) }}
        </template>
        <template v-slot:item.customerName="{ item }">
          <span v-if="item.customer">
            {{ item.customer.user.firstnameEn }}
            {{ item.customer.user.lastnameEn }}
          </span>
        </template>
        <template v-slot:item.customerId="{ item }">
          #{{ padLeft(item.customer.customerId, 4) }}
        </template>
        <template v-slot:item.tags="{ item }">
          <span v-if="item.customer.tags">
            <v-row v-for="tag in item.customer.tags" :key="tag._id">
              {{ tag.name }}
            </v-row>
          </span>
        </template>
        <template v-slot:item.area="{ item }">
          <CustomerAddressColumn :addresses="item.address" type="area" />
        </template>

        <template v-slot:item.block="{ item }">
          <CustomerAddressColumn :addresses="item.address" type="block" />
        </template>

        <template v-slot:item.building="{ item }">
          <CustomerAddressColumn :addresses="item.address" type="building" />
        </template>
        <template v-slot:item.deliveryDate="{ item }">
          {{ getDate(new Date(parseFloat(item.date))) | moment("MMM DD YYYY") }}
        </template>
        <template v-slot:item.startDate="{ item }">
          {{
            getDate(new Date(item.subscribedPackage.start_date))
              | moment("MMM DD YYYY")
          }}
        </template>
        <template v-slot:item.endDate="{ item }">
          {{
            getDate(new Date(item.subscribedPackage.end_date))
              | moment("MMM DD YYYY")
          }}
        </template>
        <template v-slot:item.shift="{ item }">
          {{ item.shift }}
        </template>
        <template v-slot:item.agent="{ item }">
          <span v-if="item.driver">
            {{ item.driver.user.firstnameEn }} {{ item.driver.user.lastnameEn }}
          </span>
        </template>
        <template v-slot:item.status="{ item }">
          {{ getDeliveryStatus(item.delivery_status) }}
        </template>
      </v-data-table>
    </div>
  </span>
</template>

<script>
import { Utils } from "../../utils/utils";
import CustomerAddressColumn from "../components/AddressColumn.vue";
import moment from "moment";
import { tz } from "moment-timezone";
import { TIME_ZONE } from "../../utils/utils";
export default {
  data() {
    return {
      tz: tz,
      reports: [],
      loading: false
    };
  },
  components: { CustomerAddressColumn },
  props: {
    date: {
      required: true
    },
    headers: {
      required: true
    }
  },

  methods: {
    exportExcel() {
      this.getInstantViewDeliveryDetailsByDate();
    },
    getDeliveryStatus(deliveryStatus) {
      if (deliveryStatus == "PENDING") {
        deliveryStatus = "Pending";
      }
      if (deliveryStatus == "DELIVERED") {
        deliveryStatus = "Delivered";
      }
      if (deliveryStatus == "NOT_DELIVERED") {
        deliveryStatus = "Not Delivered";
      }
      return deliveryStatus;
    },
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    getInstantViewDeliveryDetailsByDate() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getInstantViewDeliveryDetailsByDate.graphql"),
          variables: {
            date: this.date,
            skip: 0,
            limit: 10000
          }
        })
        .refetch({
          variables: {
            date: this.date,
            skip: 0,
            limit: 10000
          }
        })
        .then(data => {
          this.loading = false;
          this.reports =
            data.data.getInstantViewDeliveryDetailsByDate.orderDelivery;
          setTimeout(() => {
            Utils.htmlToExcel("printQuickView", "QuickView", false);
          }, 1000);
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  computed: {
    itemsWithSno() {
      if (this.reports == null) return [];
      return this.reports.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  }
};
</script>
