 <template>
  <v-container>
    <a href="javascriptt:void(0)" @click="showDialogBox">{{
      subscribedPackage.net_price.toFixed(3)
    }}</a>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="pa-3 pa-md-12">
        <v-row justify="end">
          <v-btn color="primary" text @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-card-title class="headline primary--text"
            >Price Splitup</v-card-title
          >
        </v-row>

        <v-row justify="space-between" class="mx-2">
          <h5 class="primary--text">
            Package Price :
            {{ numberWithCommas(subscribedPackage.package_price) }} KD
          </h5>
        </v-row>
        <v-row justify="space-between" class="mx-2">
          <h5 class="primary--text">
            Discount : {{ numberWithCommas(subscribedPackage.discount) }} KD
          </h5>
        </v-row>
        <v-row justify="space-between" class="mx-2">
          <h5 class="primary--text">
            Wallet : {{ numberWithCommas(subscribedPackage.walletApplied) }} KD
          </h5>
        </v-row>

        <v-row
          justify="space-between"
          class="mx-2"
          v-if="subscribedPackage.isPromoCodeApplied"
        >
          <h5 class="primary--text">
            Promo Code :
            {{ subscribedPackage.promoCodeInfo.promoCodeDetails.promoCode }},

            {{ subscribedPackage.promoCodeInfo.promoCodeDetails.discount }}
            {{
              subscribedPackage.promoCodeInfo.promoCodeDetails.inPercentage
                ? "%"
                : "KD"
            }}
          </h5>
        </v-row>

        <v-row
          justify="space-between"
          class="mx-2"
          v-if="subscribedPackage.isPromoCodeApplied"
        >
          <h5 class="primary--text">
            Promo Code Discount :
            {{ numberWithCommas(subscribedPackage.promoCodeDiscount) }} KD
          </h5>
        </v-row>

        <v-row justify="space-between" class="mx-2">
          <h5 class="primary--text">
            Net Price :
            {{ numberWithCommas(subscribedPackage.net_price) }} KD
          </h5>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
export default {
  components: {},
  props: {
    subscribedPackage: {
      require: true,
    },
  },
  methods: {
    numberWithCommas(x) {
      if (!x) {
        return 0;
      }
      return parseFloat(x)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showDialogBox() {
      this.showDialog = true;
    },
  },
  created() {},
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>
