<template>
  <v-container>
    <v-btn
      class="text-capitalize mt-n3 white--text"
      dark
      small
      color="primary"
      @click.stop="showDialogBox()"
      >Pending Payments
      <v-icon>mdi mdi-exclamation</v-icon>
    </v-btn>

    <v-dialog v-model="showDialog" max-width="1000">
      <v-card class="pa-6">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Payment Pending</v-card-title
          >
          <v-spacer></v-spacer>

          <v-btn color="black" text @click="showDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>

        <div v-for="item in unPaidPackages" :key="item._id">
          <v-layout wrap>
            <v-flex xs12 md2>
              <h4 class="text-body-2">
                Price (KD) : <strong> {{ item.package_price }}</strong>
              </h4>
            </v-flex>

            <v-flex xs12 md3>
              <h4 class="text-body-2">
                Additional Price(KD) :
                <strong> {{ item.additionalPrice }}</strong>
              </h4>
            </v-flex>
            <v-flex xs12 md2 class="ms-n8">
              <h4 class="font-weight-bolder"></h4>
            </v-flex>
            <v-flex xs12 md2>
              <h4 class="text-body-2">
                Discount (KD) :
                <strong>{{ item.discount ? item.discount : 0 }}</strong>
              </h4>
            </v-flex>

            <v-flex sm2 md4 lg2 class="ms-md-16">
              <h4 class="text-body-2">
                Total(KD): <strong> {{ item.net_price }}</strong>
              </h4>
            </v-flex>
            <v-flex xs8 md1 class="ms-md-0"> </v-flex>
          </v-layout>
          <v-spacer class="mt-5"></v-spacer>
          <v-layout>
            <v-flex xs12 md2>
              Additional Days:
              <strong> {{ item.additionalNumberOfDays }}</strong>
            </v-flex>
            <v-flex xs12 md10 v-if="item.invoiceUrl">
              Invoice Url:
              <strong class="ml-3">
                <a :href="item.invoiceUrl"> {{ item.invoiceUrl }} </a></strong
              >
              <v-btn
                class="ma-2"
                text
                icon
                color="red lighten-2"
                @click="copyUrl(item)"
              >
                <v-icon class="gray1--text">mdi-content-copy</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-spacer class="mt-5"></v-spacer>
          <v-layout v-if="item.dayChoices">
            <v-flex xs12 md2>
              <h4 class="font-weight-bolder">Meals Count</h4>
            </v-flex>
            <v-flex>
              <v-layout v-for="day in item.dayChoices" :key="day.day">
                <v-flex xs12 md2>
                  <strong>
                    {{ day.day }}
                  </strong>
                  <v-flex
                    xs12
                    md12
                    v-for="mealCount in day.mealCount"
                    :key="mealCount.category"
                  >
                    <h4 class="text-body-2">
                      {{ mealCount.count }}
                      {{ getCategoryName(mealCount.category) }}
                    </h4>
                  </v-flex>
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout justify-end class="mt-8">
            <v-btn
              color="secondary"
              class="mr-5 black--text"
              @click="checkPaymentStatus(item)"
              :loading="btnPaymentStatusLoading"
            >
              <v-icon>mdi-refresh</v-icon> Payment Status
            </v-btn>
            <v-btn
              color="error"
              class="mr-5"
              @click="regenerateInvoice(item)"
              :loading="btnRegenerateLoading"
              >Regenerate Invoice</v-btn
            >
            <CustomerPackagePaidButton :unpaidPackage="item" />
          </v-layout>
          <v-divider class="mt-2"></v-divider>
          <v-row class="mt-10"></v-row>
        </div>

        <v-row class="mt-4"> </v-row>

        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackbarColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import CustomerPackagePaidButton from "./CustomerPackagePaidButton.vue";
export default Vue.extend({
  components: { CustomerPackagePaidButton },
  props: {
    unPaidPackages: {
      require: true
    }
  },
  apollo: {
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: { limit: 0, skip: 0 },
        result({ data, loading }) {
          this.loading = loading;
          let allCategories = data.getAllCategories.category.filter(
            x => x.active == true
          );
          this.allCategories = JSON.parse(JSON.stringify(allCategories));
        }
      };
    }
  },

  mounted() {
    this.$root.$on("confirmModifiedPackagePayment", () => {
      this.showDialog = false;
    });
  },
  methods: {
    getCategoryName(categoryId) {
      let category = this.allCategories.find(x => x._id == categoryId);
      if (category) {
        return category.nameEn;
      }
      return "";
    },
    copyUrl(unPaidPackage) {
      navigator.clipboard.writeText(unPaidPackage.invoiceUrl);
    },
    showDialogBox() {
      this.showDialog = true;
    },
    regenerateInvoice(item) {
      this.btnRegenerateLoading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/regenerateInvoiceUrl.graphql"),
          variables: {
            tempPackageId: item._id
          }
        })
        .then(data => {
          this.btnRegenerateLoading = false;
          this.text = "Payment url successfully regenerated.";
          this.snackbarColor = "success";
          this.snackbar = true;
          item.invoiceUrl = data.data.regenerateInvoiceUrl.invoiceUrl;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnRegenerateLoading = false;
        });
    },
    checkPaymentStatus(item) {
      this.btnPaymentStatusLoading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/recheckSalesOrderEditPaymentStatus.graphql"),
          variables: {
            customerId: this.$route.query.customerId,
            tempPackageId: item._id
          }
        })
        .then(data => {
          this.btnPaymentStatusLoading = false;
          if (data.data.recheckSalesOrderEditPaymentStatus) {
            this.$root.$emit("updateGetCustomerById");
            this.button_loading = false;
            this.dialog = false;
            this.text = "Sales order updated as Expired";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.text = "No payment received";
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
            this.button_loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.btnPaymentStatusLoading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    }
  },
  data() {
    return {
      loading: true,
      allCategories: [],
      snackbar: false,
      text: "",
      snackbarColor: "",
      btnPaymentStatusLoading: false,
      btnRegenerateLoading: false,
      showDialog: false,
      btnLoading: false
    };
  }
});
</script>
