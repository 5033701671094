<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success"
      >Tag successfully updated.</v-snackbar
    >

    <v-form v-model="valid" ref="form">
      <v-layout justify-end class="mt-3 mt-sm-0">
        <h3 class="black--text text-h5 font-weight-bold">Customer Tags</h3>
        <v-spacer></v-spacer>
        <div v-show="$vuetify.breakpoint.smAndUp">
          <v-btn
            v-if="hasRole(AdminUserPermissions.CUSTOMER_TAG_ADD)"
            class="white--text text-capitalize font-weight-bold"
            color="primary"
            @click="add"
            >Create New</v-btn
          >
        </div>
      </v-layout>
      <v-layout
        class="mt-4 mt-sm-0 mb-n4 mb-sm-0"
        v-show="$vuetify.breakpoint.xs"
        justify-end
      >
        <v-btn class="white--text" color="primary" @click="add"
          >Create New</v-btn
        >
      </v-layout>
      <v-layout justify-space-between wrap class="mt-16 me-md-16">
        <v-flex v-for="(textField, i) in textFields" :key="i" xs10 sm8 md5>
          <v-text-field
            :disabled="textDisabled"
            :placeholder="textField.placeholder"
            v-model="textField.value"
            @click="onClick"
            :rules="[requiredValidator('tag')]"
            solo
            dense
          >
            <template v-slot:prepend-inner>
              <v-btn
                small
                depressed
                tile
                color="white"
                height="40"
                class="ms-n3"
              >
                <v-icon color="black">mdi-tag</v-icon>
              </v-btn>
            </template>
            <template v-slot:append>
              <v-icon
                color="red"
                v-if="hasRole(AdminUserPermissions.CUSTOMER_TAG_DELETE)"
                @click="remove(i)"
                >mdi-close</v-icon
              >
            </template>
          </v-text-field>
        </v-flex>
      </v-layout>
      <v-layout justify-center class="mt-4">
        <v-btn
          v-if="save"
          color="secondary"
          :loading="btnloading"
          :disabled="!valid"
          @click="createTag()"
          class="ms-4 black--text px-16 text-capitalize font-weight-bold"
          >Save</v-btn
        >
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      save: false,
      textFields: [],
      btnloading: false,
      snackbar: false,
      valid: false,
      requiredValidator: requiredValidator,
      textDisabled: true
    };
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.CUSTOMER_TAG_EDIT)) {
      this.textDisabled = false;
    }
    if (!this.hasRole(this.AdminUserPermissions.CUSTOMER_TAG_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllCUstomerTags();
  },
  methods: {
    createTag() {
      let allTags = [];
      this.textFields.forEach(element => {
        let tag = {
          id: element._id,
          name: element.value
        };

        allTags.push(tag);
      });

      let customerTag = { data: allTags };
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/updateCustomerTag.graphql"),
          variables: {
            input: customerTag
          }
        })
        .then(() => {
          this.btnloading = false;
          this.snackbar = true;
          this.save = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    getAllCUstomerTags() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/menu-packages/api/getAllCUstomerTags.graphql")
        })
        .then(data => {
          data.data.customerTags.forEach(element => {
            this.textFields.push({ value: element.name, _id: element._id });
          });
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    add() {
      this.textFields.push({
        placeholder: "Add Tag",
        value: "",
        _id: ""
      });
      this.save = true;
    },
    onClick() {
      this.save = true;
    },
    remove(index) {
      this.textFields.splice(index, 1);
      this.save = true;
    }
  }
};
</script>
