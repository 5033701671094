<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="secondary"
          class="black--text text-capitalize font-weight-bold"
        >
          Notification Config
        </v-btn>
      </template>
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text"> Notification Configuration</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          v-if="pushNotification"
          class="pa-sm-8 mt-n6"
          @submit="updatePushNotification()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <!-- {{ pushNotification }} -->
              <v-layout wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Dietitian booking approved notification
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.bookingApprovedNotification.titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.bookingApprovedNotification.titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.bookingApprovedNotification.messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.bookingApprovedNotification.messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Dietitian booking completed notification
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.bookingCompletedNotification.titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.bookingCompletedNotification.titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.bookingCompletedNotification.messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.bookingCompletedNotification.messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <!-- :rules="[requiredValidator('message ')]" -->
              <!-- :rules="[requiredValidator('message in arabic')]" -->
              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Subscription going to expire notification
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="pushNotification.goingToExpireNotification.titleEn"
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="pushNotification.goingToExpireNotification.titleAr"
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.goingToExpireNotification.messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.goingToExpireNotification.messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Subscription expired notification
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="pushNotification.expiredNotification.titleEn"
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="pushNotification.expiredNotification.titleAr"
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="pushNotification.expiredNotification.messageEn"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="pushNotification.expiredNotification.messageAr"
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">Delivery notification</h4>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="pushNotification.deliveredNotification.titleEn"
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="pushNotification.deliveredNotification.titleAr"
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="pushNotification.deliveredNotification.messageEn"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="pushNotification.deliveredNotification.messageAr"
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Disliked ingredients chosen notification
                  </h4>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.dislikedIngredientsChosenNotification
                        .titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.dislikedIngredientsChosenNotification
                        .titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.dislikedIngredientsChosenNotification
                        .messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.dislikedIngredientsChosenNotification
                        .messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Meal selection reminder notification
                  </h4>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.mealSelectionReminderNotification.titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.mealSelectionReminderNotification.titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.mealSelectionReminderNotification
                        .messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.mealSelectionReminderNotification
                        .messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <!-- <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">Delivery notification</h4>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="pushNotification.deliveredNotification.titleEn"
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="pushNotification.deliveredNotification.titleAr"
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="pushNotification.deliveredNotification.messageEn"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="pushNotification.deliveredNotification.messageAr"
                  ></v-textarea>
                </v-flex>
              </v-layout> -->

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Subscription confirmed notification
                  </h4>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.subscriptionConfirmedNotification.titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.subscriptionConfirmedNotification.titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.subscriptionConfirmedNotification
                        .messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.subscriptionConfirmedNotification
                        .messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Dietitian payment link expire notification
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.dietitianPaymentLinkExpireNotification
                        .titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.dietitianPaymentLinkExpireNotification
                        .titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.dietitianPaymentLinkExpireNotification
                        .messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.dietitianPaymentLinkExpireNotification
                        .messageAr
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Birthday Auto Notificaion
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="pushNotification.birthDayNotification.titleEn"
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="pushNotification.birthDayNotification.titleAr"
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="pushNotification.birthDayNotification.messageEn"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="pushNotification.birthDayNotification.messageAr"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Plan switch notification
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="pushNotification.planSwitchNotification.titleEn"
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="pushNotification.planSwitchNotification.titleAr"
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="pushNotification.planSwitchNotification.messageEn"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="pushNotification.planSwitchNotification.messageAr"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <!-- <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    Universal pause notification
                  </h4>
                </v-flex>
              </v-layout> -->
              <!-- <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title"
                    v-model="
                      pushNotification.universalPauseNotification
                        .titleEn
                    "
                    :rules="[requiredValidator('title')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Title Arabic"
                    v-model="
                      pushNotification.universalPauseNotification
                        .titleAr
                    "
                    :rules="[requiredValidator('title in arabic')]"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md5 class="mt-5 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message"
                    v-model="
                      pushNotification.universalPauseNotification
                        .messageEn
                    "
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16 mt-md-n1">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Message Arabic"
                    v-model="
                      pushNotification.universalPauseNotification
                        .messageAr 
                    "
                  ></v-textarea>
                </v-flex>
              </v-layout> -->
              <!-- <v-layout wrap class="mt-4">
                    <v-flex xs12 sm8 md9 class="mt-n1">
                      <v-checkbox
                        label="Enable SMS"
                        v-model="enableSms"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout> -->
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n6">
            <!-- <v-spacer></v-spacer> -->
            <v-layout justify-center>
              <v-btn
                color="primary"
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                :loading="btnloading"
                @click="updatePushNotification"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="color">{{
      text
    }}</v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      timeout="3000"
      right
      top
      color="error"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      btnloading: false,
      addedArea: [],
      areaList: [],
      block: "",
      area: "",
      color: "success",
      snackbar: false,
      text: "",
      valid: true,
      pushNotification: "",
      enableSms: false
    };
  },
  apollo: {
    getPushNotification() {
      return {
        query: require("../api/getPushNotification.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.pushNotification = data.getPushNotification;
          this.enableSms = data.getPushNotification.enableSms;
        }
      };
    }
  },
  methods: {
    updatePushNotification() {
      // console.log(this.area, this.block);
      delete this.pushNotification.deliveredNotification.__typename;
      delete this.pushNotification.dislikedIngredientsChosenNotification
        .__typename;
      delete this.pushNotification.goingToExpireNotification.__typename;
      delete this.pushNotification.subscriptionConfirmedNotification.__typename;
      delete this.pushNotification.bookingApprovedNotification.__typename;
      delete this.pushNotification.bookingCompletedNotification.__typename;
      delete this.pushNotification.mealSelectionReminderNotification.__typename;
      delete this.pushNotification.expiredNotification.__typename;
      delete this.pushNotification.dietitianPaymentLinkExpireNotification
        .__typename;
      delete this.pushNotification.planSwitchNotification.__typename;
      delete this.pushNotification.universalPauseNotification.__typename;
      delete this.pushNotification.birthDayNotification.__typename;
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updatePushNotification.graphql"),
          variables: {
            enableSms: this.enableSms,
            deliveredNotification: this.pushNotification.deliveredNotification,
            goingToExpireNotification: this.pushNotification
              .goingToExpireNotification,
            bookingApprovedNotification: this.pushNotification
              .bookingApprovedNotification,
            bookingCompletedNotification: this.pushNotification
              .bookingCompletedNotification,
            expiredNotification: this.pushNotification.expiredNotification,
            dislikedIngredientsChosenNotification: this.pushNotification
              .dislikedIngredientsChosenNotification,
            mealSelectionReminderNotification: this.pushNotification
              .mealSelectionReminderNotification,
            subscriptionConfirmedNotification: this.pushNotification
              .subscriptionConfirmedNotification,
            dietitianPaymentLinkExpireNotification: this.pushNotification
              .dietitianPaymentLinkExpireNotification,
            planSwitchNotification: this.pushNotification
              .planSwitchNotification,
            universalPauseNotification: this.pushNotification
              .universalPauseNotification,
            birthDayNotification: this.pushNotification.birthDayNotification
          },
          update: () => {
            try {
              // this.$root.$emit("updateBlock");
              this.color = "success";
              this.text = "Push Notification Updated";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = error;
          this.color = "error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
