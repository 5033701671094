<template>
  <v-data-table
    :headers="headers"
    :items="itemsWithSno"
    class="elevation-1"
    :items-per-page="10"
    :options.sync="options"
    :loading="loading"
    :mobile-breakpoint="0"
    :server-items-length="totalItemsCount"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100, 500] }"
  >
    <template v-slot:item.name="{ item }">
      <span v-if="item.customer">
        <span v-if="item.customer.user">
          <router-link
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item.customer._id },
              query: { customerId: item.customer._id }
            }"
            class="mx-auto"
          >
            {{
              item.customer.user.firstnameEn[0].toUpperCase() +
                item.customer.user.firstnameEn.substring(1)
            }}
            {{
              item.customer.user.lastnameEn[0].toUpperCase() +
                item.customer.user.lastnameEn.substring(1)
            }}
          </router-link>
        </span>
      </span>
    </template>
    <template v-slot:item.createdDate="{ item }">
      {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
    </template>
    <template v-slot:item.appointmentDate="{ item }">
      <span v-if="item.appointmentDate">
        {{ new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY") }}
      </span>
    </template>
    <template v-slot:item.appointmentTime="{ item }">
      <span v-if="item.appointmentTime"> {{ item.appointmentTime }} </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <div v-if="type == 'PENDING'">
        <div v-if="item.payment_status == 'SUCCESS'">
          <UpdateDietitianAppointment :dietitianappointment="item" />
        </div>
        <div v-if="item.payment_status == 'PENDING'">
          <PaymentStatusCheck :appointment="item" />
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import UpdateDietitianAppointment from "../../customer/components/dietitian/updateDietitianAppointment.vue";
import PaymentStatusCheck from "./PaymentStatusCheck.vue";

export default {
  props: {
    dietitian_id: {
      require: true
    },
    type: {
      require: true
    }
  },
  components: {
    PaymentStatusCheck,
    UpdateDietitianAppointment
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.$apollo.queries.getAllAppoinmentsByDietitian)
          this.$apollo.queries.getAllAppoinmentsByDietitian.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 500,
            skip:
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage,
            type: this.type
          });
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  mounted() {
    this.$root.$on("updateAppointment", () => {
      this.loading = true;
      this.$apollo.queries.getAllAppoinmentsByDietitian.refetch({
        variables: this.variables
      });
    });
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
          width: 50
        },
        { text: "Customer Name", value: "name", width: 160, sortable: false },
        {
          text: "Contact Number",
          value: "customer.user.phone",
          width: 100,
          sortable: false
        },
        { text: "Date", value: "createdDate", width: 120, sortable: false },
        {
          text: "Appointment Date",
          value: "appointmentDate",
          width: 120,
          sortable: false
        },
        {
          text: "Time",
          value: "appointmentTime",
          sortable: false,
          width: 120
        },
        {
          text: "Payment Status",
          value: "payment_status",
          width: 120,
          sortable: false
        },
        { text: "Action", value: "actions", width: 70, sortable: false }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 10,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      variables: {
        id: this.dietitian_id,
        type: this.type,
        limit: 0,
        skip: 0
      },
      loading: true
    };
  },
  apollo: {
    getAllAppoinmentsByDietitian() {
      return {
        query: require("../api/getAllAppoinmentsByDietitian.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.customerList = [];
          this.totalItemsCount = 0;
          if (data.getAllAppoinmentsByDietitian) {
            this.customerList = data.getAllAppoinmentsByDietitian.appointments;
            this.totalItemsCount = data.getAllAppoinmentsByDietitian.totalCount;
          }
        }
      };
    }
  },
  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  },
  methods: {}
};
</script>

<style scoped></style>
