<template>
  <span>
    <v-btn
      :disabled="disabled"
      @click="printA4"
      :loading="btnloading"
      color="primary"
      :small="$vuetify.breakpoint.xs"
      :width="$vuetify.breakpoint.xs ? 100 : 150"
      class="white--text ms-n5 ms-sm-0 text-capitalize"
    >
      <v-icon
        :small="$vuetify.breakpoint.xs"
        :size="$vuetify.breakpoint.smAndUp ? 20 : ''"
        class="me-1"
        >mdi-file-document</v-icon
      >Download
    </v-btn>
  </span>
</template>
<script>
import { tz } from "moment-timezone";
import { sortBy } from "lodash";
import { print_a4_report } from "../methods/print_a4_report";
export default {
  props: {
    pendingList: { require: true },
    date: { require: true },
    disabled: { require: true }
  },
  data() {
    return {
      btnloading: false,
      tz: tz
    };
  },
  methods: {
    printA4() {
      let sortedList = sortBy(this.pendingList, x => x.order_id);
      print_a4_report(sortedList, this.date);
    }
  }
};
</script>
<style scoped></style>
