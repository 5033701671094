<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="secondary" class="black--text text-capitalize">
          <v-icon small class="me-1">mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <v-card class="" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Offers</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          @submit="createOffer()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md4 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Promo Code</h4>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-autocomplete
                    item-text="promoCode"
                    v-model="promoCode"
                    @change="onChange"
                    item-value="_id"
                    :items="promoCodeList"
                    :rules="[requiredValidator('promo Code ')]"
                    dense
                    attach
                    solo
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for promoCode...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md4>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Offer Title</h4>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-text-field
                    label="Title (English)"
                    v-model="titleEn"
                    :disabled="true"
                    :rules="[requiredValidator('Offer title')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Offer Title (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md8 class="mt-sm-5 mt-md-0">
                  <v-text-field
                    label="Title (Arabic)"
                    v-model="titleAr"
                    :disabled="true"
                    :rules="[requiredValidator('Offer title Arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-4 mt-sm-0">
                <v-flex xs12 sm4 md4>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Choose Image</h4>
                </v-flex>

                <v-flex xs12 sm8 md8 style="">
                  <croppa
                    class="mt-2"
                    v-model="myCroppa"
                    :width="315"
                    :height="123"
                    canvas-color="black"
                    :placeholder="'Choose an image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="2"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="20"
                  ></croppa>

                  <v-layout class="mb-3">
                    <span style="color: red">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Start Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        dense
                        :value="formatStartDate"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        :rules="[requiredValidator('start date')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="startDate"
                      :min="promoStartDate"
                      :max="promoEndDate"
                      @input="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">End Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        dense
                        :value="formatEndDate"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        :rules="[requiredValidator('end date')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="endDate"
                      :min="promoStartDate"
                      :max="promoEndDate"
                      @input="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Active</h4>
                </v-flex>
                <v-flex xs12 sm8 md8 align-self-center>
                  <v-radio-group v-model="active" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pe-8">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createOffer"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      timeout="3000"
      right
      top
      color="error"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import { DATE_FORMAT } from "../../utils/utils";
import moment from "moment";
import { format, fromUnixTime } from "date-fns";

export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      btnloading: false,
      addedOffer: [],
      offerList: [],
      titleEn: "",
      titleAr: "",
      myCroppa: null,
      image: null,
      startDate: "",
      endDate: "",
      active: true,
      snackbar: false,
      text: "",
      valid: true,
      menuStartDate: false,
      menuEndDate: false,
      promoCodeList: [],
      promoCode: "",
      promoStartDate: "",
      promoEndDate: ""
    };
  },
  apollo: {
    getAllPromoCodes() {
      return {
        query: require("@/promotion/api/getAllPromoCodes.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.promoCodeList = data.getAllPromoCodes;
        }
      };
    }
  },
  methods: {
    onChange() {
      const promoExist = this.promoCodeList.find(
        promo => promo._id === this.promoCode
      );
      this.titleEn = promoExist.titleEn;
      this.titleAr = promoExist.titleAr;
      const dateEnd = fromUnixTime(promoExist.endDate / 1000);
      this.promoEndDate = format(dateEnd, "yyyy-MM-dd");
      const dateStart = fromUnixTime(promoExist.startDate / 1000);
      this.promoStartDate = format(dateStart, "yyyy-MM-dd");
    },

    createOffer() {
      console.log(this.Offer, this.Offer);
      this.btnloading = true;
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob(blob => {
          this.$apollo
            .mutate({
              mutation: require("../api/createOffer.graphql"),
              variables: {
                image: blob,
                startDate: this.startDate,
                endDate: this.endDate,
                active: this.active,
                promoCode: this.promoCode
              },
              update: () => {
                try {
                  this.$root.$emit("updateOffer");
                  this.text = "Offer Added";
                  this.snackbar = true;
                } catch (e) {
                  console.log(e);
                  console.log("Something bad happend");
                }
                this.$refs.form.reset();
                this.btnloading = false;
                this.dialog = false;
              }
            })
            .catch(error => {
              this.btnloading = false;
              this.text = "Offer Already exist.";
              this.snackbarError = true;
              console.log(error);
            });
        });
      } else {
        this.text = "Please select offer image";
        this.snackbarError = true;
        this.btnloading = false;
      }
    },

    close() {
      this.dialog = false;
    }
  },
  computed: {
    formatStartDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    },
    formatEndDate() {
      return this.endDate ? moment(this.endDate).format(DATE_FORMAT) : "";
    }
  }
};
</script>
