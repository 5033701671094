var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"px-0",attrs:{"col-12":"","sm6":"","md4":"","lg2":""}},[_c('v-card',{staticClass:"px-2 py-6 mx-auto  rounded-lg  ",attrs:{"max-width":"250","height":"200"}},[_c('v-row',{staticClass:"\n        text-center\n        black--text\n        text-h6\n        font-weight-light\n        px-4 px-lg-6\n        mb-xl-3\n      ",class:_vm.index == 3 ? 'px-lg-9' : 'px-lg-9',attrs:{"justify":"center"}},[_vm._v(_vm._s(_vm.cardItem.title))]),_c('v-row',{class:_vm.index == 4 ? 'mt-lg-11 mt-xl-2' : '',attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mt-3",attrs:{"color":_vm.index == 0
            ? '#22F55E'
            : _vm.index == 1
            ? 'primary'
            : _vm.index == 2
            ? 'black'
            : _vm.index == 3
            ? '#839E87'  
            : '#FBD159',"size":_vm.$vuetify.breakpoint.xl ? 54 : 46}},[_vm._v(_vm._s(_vm.cardItem.image))])],1),_c('v-card-actions',{staticClass:"mt-2"},[_c('v-list-item-title',{staticClass:"text-center text-h6 font-weight-black"},[_vm._v(_vm._s(_vm.cardItem.count))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }