<template>
  <v-container v-if="!loading">
    <v-row>
      <v-btn
        class="black--text text-capitalize ms-sm-4 mb-4 mt-3"
        color="secondary"
        @click.stop="onAddClick()"
        :disabled="isFreezButtonEnabled"
      >
        <v-icon class="me-1" small>mdi-plus</v-icon>Add
      </v-btn>

      <h5 v-if="!minDate" class="red--text ml-2">No active package !!!</h5>
    </v-row>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="pa-8" color="pop_bg">
        <v-form @submit="saveDate()" v-model="valid" ref="form">
          <v-row>
            <v-card-title class="text-h6 black--text mt-n4"
              >Freeze from
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-4">
            <v-col cols="12" md="10" class="mx-auto">
              <v-row justify="center">
                <vc-date-picker
                  :key="number"
                  :min-date="minDate"
                  :max-date="maxDate"
                  v-model="pausedDate"
                  :disabled-dates="disabledDates"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-6"></v-divider>
          <v-row justify="center" class="mt-6">
            <v-flex xs12 sm7 md8>
              <v-text-field
                solo
                dense
                name="input-7-4"
                label="Remark"
                v-model="remark"
                :rules="[requiredValidator('remark')]"
              ></v-text-field>
            </v-flex>
          </v-row>

          <v-row justify="end" class="mt-6">
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              class="black--text me-3 text-capitalize"
              color="secondary"
              @click="saveDate()"
              :loading="btnloading"
              :disabled="!valid"
            >
              <v-icon left>mdi-plus</v-icon>
              Freeze
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import VCalendar from "v-calendar";
import { tz } from "moment-timezone";
import moment from "moment";
import { TIME_ZONE, Utils } from "../../../utils/utils";
import { requiredValidator } from "@/core/methods/validations";

Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
export default Vue.extend({
  components: {},
  props: {
    freezeDays: {
      required: true,
    },
  },

  watch: {
    "$route.query.customerId": {
      handler() {
        this.getCustomerDetails();
      },
    },
  },
  apollo: {
    getMealSelectionDate() {
      return {
        query: require("@/customer/components/salesOrder/api/getMealSelectionDate.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
debugger
          let date = data.getMealSelectionDate.packageSelectionDate;
          this.packageSelectionDate = moment(new Date(parseInt(date)));
          this.freezeUpToDays = data.getAppConfigurations.freezeUpToDays;
          this.getCustomerDetails();
        },
      };
    },
  },
  created() {},
  methods: {
    getCustomerDetails() {
      debugger
      this.loading = true;
      debugger
      this.$apollo
        .watchQuery({
          query: require("../../api/getCustomerById.graphql"),
          variables: {
            id: this.$route.query.customerId,
          },
        })
        .refetch({
          variables: {
            id: this.$route.query.customerId,
          },
        })
        .then((data) => {
          this.loading = false;

          if (data.data.getCustomerById) {
            this.customer = data.data.getCustomerById;
            debugger
            this.getCustomerPlans();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getCustomerPlans() {
      debugger;
      let packages = Utils.getActiveAndPendingCustomerPackages(
        this.customer.subscribed_packages
      );
      let startDate = null;
      let endDate = null;
      // let endDate = null;
      if (packages.activePackage) {
        startDate = packages.activePackage.start_date;
        endDate = packages.activePackage.end_date;
        //  endDate = packages.activePackage.end_date;
      }
      if (packages.upcomingPackage) {
        //   endDate = packages.upcomingPackage.end_date;
        if (!packages.activePackage) {
          startDate = packages.upcomingPackage.start_date;
          endDate = packages.upcomingPackage.end_date;
        }
      }

      this.minDate = new Date(parseInt(startDate));
      if (moment().startOf("day") > moment(this.minDate).startOf("day")) {
        this.minDate = moment().toDate();
      }

      if (this.packageSelectionDate.isAfter(this.minDate)) {
        this.minDate = moment(this.packageSelectionDate)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }

      this.maxDate = moment(this.minDate)
        .add(this.freezeUpToDays, "days")
        .toDate();

      debugger;
      if (moment(new Date(parseInt(endDate))).isBefore(moment(this.maxDate))) {
        this.maxDate = moment(new Date(parseInt(endDate))).format("YYYY-MM-DD");
      }

      let activeFreeze = this.freezeDays.find((x) => x.isActive == true);

      this.freezeDays.forEach((element) => {
        if (
          moment(new Date(parseInt(element.endDate))).isAfter(
            moment(this.minDate)
          )
        ) {
          this.minDate = moment(new Date(parseInt(element.endDate)))
            .add(1, "days")
            .format("YYYY-MM-DD");
        }
      });
      if (activeFreeze) {
        if (packages.activePackage || packages.upcomingPackage) {
          this.isFreezButtonEnabled = true;
        }
      }
      if (!packages.activePackage && !packages.upcomingPackage) {
        this.isFreezButtonEnabled = true;
      }
    },
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },

    checkActivePackage() {
      if (!this.subscribedPackage) {
        return true;
      } else if (this.subscribedPackage.payment_status === "PENDING") {
        return true;
      }
      const today = new Date();
      const currentDate = moment(today);
      const endDate = moment(
        new Date(parseInt(this.subscribedPackage.end_date))
      ).tz(TIME_ZONE);
      let dateDiff = endDate.diff(currentDate, "days");
      if (dateDiff < 0) {
        return true;
      }
      return false;
    },
    onAddClick() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },

    saveDate() {
      if (!this.pausedDate) {
        return false;
      }
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("./api/freezePackages.graphql"),
          variables: {
            input: {
              fromDate: moment(this.pausedDate).format("MM-DD-YYYY"),
              customer: this.$route.query.customerId,
              remark: this.remark,
            },
          },
          update: () => {
            this.btnloading = false;
            this.showDialog = false;
          },
        })
        .then((data) => {
          console.log(data);
          this.pausedDate = "";
          this.$root.$emit("updateGetCustomerById");
          this.$root.$emit("getCustomerFreezeDays");
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
  },

  data() {
    return {
      tab: null,
      loading: true,
      requiredValidator,
      valid: false,
      tz: tz,
      btnloading: false,
      pausedDate: null,
      disabledDates: [],
      days: [],
      number: 0,
      showDialog: false,
      minDate: null,
      maxDate: null,
      weekDays: "",
      packageSelectionDate: null,
      freezeUpToDays: 10,
      isFreezButtonEnabled: false,
      remark: "",
    };
  },
});
</script>
