<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text text-capitalize font-weight-bold"
          color="secondary"
          >+ Add</v-btn
        >
      </template>
      <v-card class="pa-5" color="pop_bg">
        <v-row class="py-2 py-sm-3">
          <v-card-title class="black--text text-h6 mt-n2 ms-2"
            >Add Category</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="px-0">
          <v-container class="mt-2">
            <h3 class="mb-0 mb-sm-8 black--text text-body-1 font-weight-bold">
              Enter Meal Category Details
            </h3>
            <v-form
              class="mb-3"
              @submit="createCategory()"
              v-model="valid"
              ref="form"
            >
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text mt-4 mt-sm-0 mb-2 mb-sm-0">
                    Category Name
                  </h5>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    label="Category Name"
                    v-model="nameEn"
                    :rules="[requiredValidator('category name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs10 sm6 md4 class="ml-5 mt-n3">
                  <v-checkbox
                    v-model="pcValueNotRequired"
                    label="Hide P/C Value"
                  >
                  </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text  mb-2 mb-sm-0">
                    Category Name (Arabic)
                  </h5>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    v-model="nameAr"
                    label="Category Name Arabic"
                    :rules="[requiredValidator('category name in arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mb-5" align-center>
                <v-flex xs6 sm3 md2 class="me-md-3">
                  <h5 class="text-body-2 black--text mb-3 mb-sm-0">
                    Currently Active
                  </h5>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group
                    hide-details
                    class="mt-0"
                    v-model="active"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Show Order</h5>
                </v-flex>
                <v-flex xs12 sm6 md4 class="mt-3 mt-md-0">
                  <v-text-field
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                    label="Show Order"
                    type="number"
                    v-model="showOrder"
                    @keypress="isNumber"
                    min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Calorie Order</h5>
                </v-flex>
                <v-flex xs12 sm6 md4 class="mt-3 mt-md-0">
                  <v-text-field
                    :rules="[requiredValidator('calorie calculation order')]"
                    solo
                    dense
                    label="Show Order"
                    type="number"
                    v-model="calorieOrder"
                    @keypress="isNumber"
                    min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Map To</h5>
                </v-flex>
                <v-flex xs10 sm6 md4 class="mt-3 mt-md-0">
                  <v-autocomplete :items="categoriesList" v-model="migrateTo" item-text="nameEn" item-value="_id" label="Map To Category" dense :loading="loading" solo></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row justify-end class="mt-5 mb-sm-n10 ">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="black--text text-capitalize"
                  @click="createCategory()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,
      nameEn: "",
      nameAr: "",
      active: true,
      showOrder: "",
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      pcValueNotRequired: false,
      calorieOrder: "",
      categoriesList:[],
      migrateTo:""
    };
  },
  apollo: {
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: {
          limit: 0,
          skip: 0,
        },
        result({ data, loading }) {
          this.loading = loading;
          this.categoriesList = data.getAllCategories.category;
        },
      };
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createCategory() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/createCategory.graphql"),
          variables: {
            nameEn: this.nameEn,
            nameAr: this.nameAr,
            showOrder: this.showOrder,
            active: this.active,
            pcValueNotRequired: this.pcValueNotRequired,
            calorieOrder: parseInt(this.calorieOrder),
            migrateTo:this.migrateTo
          },
          update: (store, { data: { createCategory } }) => {
            try {
              const query = {
                query: require("@/menu-packages/api/getAllCategories.graphql"),
                variables: {
                  limit: 25,
                  skip: 0
                }
              };

              let allCategories = store.readQuery(query);
              allCategories.getAllCategories.category.unshift(createCategory); //push to top
              store.writeQuery({
                ...query,
                data: allCategories
              });
              this.text = "Category Added";
              this.snackbar = true;
              this.show_dialog_box = false;
            } catch (e) {
              // console.log(e);
              console.log("Something bad happend- CategoryForm.vue");
            }
            this.$refs.form.reset();
            this.active = true;
            this.btnloading = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
    }
  }
};
</script>
