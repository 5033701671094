<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-5" color="pop_bg">
        <v-row class="py-2 py-sm-3">
          <v-card-title class="black--text text-h6 mt-n2 ms-2"
            >Edit Category</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <v-container class="mt-2">
            <h3 class="mb-0 mb-sm-8 black--text text-body-1 font-weight-bold">
              Enter Meal Category Details
            </h3>
            <v-form
              class="mb-5"
              @submit="updateCategory()"
              v-model="valid"
              ref="form"
            >
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="mb-3 mb-sm-0 text-body-2 black--text mt-4 mt-sm-0">
                    Category Name
                  </h5>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    label="Category Name"
                    v-model="category.nameEn"
                    :rules="[requiredValidator('category name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs10 sm6 md4 class="ml-5 mt-n3">
                  <v-checkbox
                    v-model="category.pcValueNotRequired"
                    label="Hide P/C Value"
                  >
                  </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2 class="pe-md-2">
                  <h5 class="text-body-2 black--text mb-3 mb-sm-0">
                    Category Name (Arabic)
                  </h5>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    v-model="category.nameAr"
                    label="Category Name Arabic"
                    :rules="[requiredValidator('category name in arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mb-5" align-center>
                <v-flex xs6 sm3 md2 class="me-md-3">
                  <h5 class="text-body-2 black--text mb-3 mb-sm-0">
                    Currently Active
                  </h5>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group
                    class="mt-0"
                    v-model="category.active"
                    :mandatory="false"
                    row
                    hide-details
                  >
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Show Order</h5>
                </v-flex>
                <v-flex xs12 sm6 md4 class="mt-3 mt-md-0">
                  <v-text-field
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                    type="number"
                    v-model="category.showOrder"
                    min="0"
                    label="Show Order"
                    @keypress="isNumber"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Calorie Order</h5>
                </v-flex>
                <v-flex xs12 sm6 md4 class="mt-3 mt-md-0">
                  <v-text-field
                    :rules="[requiredValidator('calorie ccalculation order')]"
                    solo
                    dense
                    type="number"
                    v-model="category.calorieOrder"
                    min="0"
                    label="Show Order"
                    @keypress="isNumber"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Map To</h5>
                </v-flex>
                <v-flex xs10 sm6 md4 class="mt-3 mt-md-0">
                  <v-autocomplete
                    :items="categoriesList"
                    v-model="category.migrateTo"
                    item-text="nameEn"
                    item-value="_id"
                    label="Map To Category"
                    dense
                    :loading="loading"
                    solo
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row justify-end class="mt-4 mb-n6 mb-sm-n12">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="black--text text-capitalize"
                  @click="updateCategory()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    categoryInfo: {
      required: true
    }
  },
  created() {
    this.category = JSON.parse(JSON.stringify(this.categoryInfo));
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      category: [],
      show_dialog_box: false,
      categoriesList: []
    };
  },
  apollo: {
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: {
          limit: 0,
          skip: 0
        },
        result({ data, loading }) {
          this.loading = loading;
          this.categoriesList = data.getAllCategories.category;
        }
      };
    }
  },
  methods: {
    onShowDialog() {
      this.category = JSON.parse(JSON.stringify(this.categoryInfo));
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateCategory() {
      //   console.log(this.category);
      this.categoryInfo.nameEn = this.category.nameEn;
      this.categoryInfo.nameAr = this.category.nameAr;
      this.categoryInfo.active = this.category.active;
      this.categoryInfo.showOrder = this.category.showOrder;
      this.categoryInfo.pcValueNotRequired = this.category.pcValueNotRequired;
      this.categoryInfo.calorieOrder = this.category.calorieOrder;

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/updateCategory.graphql"),
          variables: {
            id: this.category._id,
            nameEn: this.category.nameEn,
            nameAr: this.category.nameAr,
            showOrder: this.category.showOrder,
            active: this.category.active,
            pcValueNotRequired: this.category.pcValueNotRequired,
            calorieOrder: parseInt(this.category.calorieOrder),
            migrateTo: this.category.migrateTo
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.show_dialog_box = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
      // this.$refs.form.reset();
    }
  }
};
</script>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
