import moment from "moment";
import tz from "moment-timezone";
import { sortBy } from "lodash";
let DATE_FORMAT = "DD-MM-YYYY";
let TIME_ZONE = "Asia/Bahrain";
export { DATE_FORMAT, TIME_ZONE, tz };
export class Utils {
  static htmlToExcelOld(id, name, isClass) {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    let table = "";
    if (isClass) {
      table = document.getElementsByClassName(id)[0];
    } else {
      table = document.getElementById(id);
    }
    var ctx = {
      worksheet: name || "Worksheet",
      table: table.innerHTML
    };
    window.location.href = uri + base64(format(template, ctx));
  }
  static htmlToExcel(id, name, isClass) {
    let table = "";
    if (isClass) {
      table = document.getElementsByClassName(id)[0];
    } else {
      table = document.getElementById(id);
    }

    var myBlob = new Blob([table.innerHTML], { type: 'application/vnd.ms-excel' });
    var url = window.URL.createObjectURL(myBlob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = `${name}.xls`;
    a.click();
    //adding some delay in removing the dynamically created link solved the problem in FireFox
    setTimeout(function () { window.URL.revokeObjectURL(url); }, 0);

  }
  static getAvailabelWeekDaysInNumbers(availabelWeekDays) {
    let availabelWeekDaysInNumbers = new Array(0);
    if (availabelWeekDays == null) return [];
    availabelWeekDays.forEach(element => {
      switch (element) {
        case "Sunday":
          availabelWeekDaysInNumbers.push(0);
          break;
        case "Monday":
          availabelWeekDaysInNumbers.push(1);
          break;
        case "Tuesday":
          availabelWeekDaysInNumbers.push(2);
          break;
        case "Wednesday":
          availabelWeekDaysInNumbers.push(3);
          break;
        case "Thursday":
          availabelWeekDaysInNumbers.push(4);
          break;
        case "Friday":
          availabelWeekDaysInNumbers.push(5);
          break;
        case "Saturday":
          availabelWeekDaysInNumbers.push(6);
          break;
        default:
      }
    });
    return availabelWeekDaysInNumbers;
  }

  padLeft(number, length) {
    var stringValue = "" + number;
    while (stringValue.length < length) {
      stringValue = "0" + stringValue;
    }
    return stringValue;
  }
  static checkIsActiveOrUpcomingPackage(subscribedPackage) {
    if (!subscribedPackage) {
      return false;
    } else if (subscribedPackage.payment_status === "PENDING") {
      return false;
    }
    const today = new Date();
    const currentDate = moment(today);
    const endDate = moment(new Date(parseInt(subscribedPackage.end_date))).tz(
      TIME_ZONE
    );
    let dateDiff = endDate.diff(currentDate, "days");
    if (dateDiff < 0) {
      return false;
    }
    return true;
  }
  static getActiveAndPendingCustomerPackages(subscribedPackages) {
    let activePackage = null;
    let upcomingPackage = null;
    let expiredPackage = null;
    if (subscribedPackages) {
      let today = moment().tz(TIME_ZONE);
      if (subscribedPackages.length > 0) {
        activePackage = subscribedPackages.filter(
          x =>
            moment(new Date(parseInt(x.end_date)))
              .tz(TIME_ZONE)
              .endOf("day") >= moment(today) &&
            moment(new Date(parseInt(x.start_date)))
              .tz(TIME_ZONE)
              .startOf("day") <= moment(today) &&
            x.payment_status == "SUCCESS" &&
            x.is_expired != true
        );

        if (activePackage.length == 1) {
          activePackage = activePackage[0];
        }
        if (activePackage.length > 1) {
          activePackage = sortBy(
            activePackage,
            x => x.order_number
          ).reverse()[0];
        }
        if (activePackage.length == 0) {
          activePackage = null;
        }

        if (activePackage) activePackage.packageName = "Active Package";

        upcomingPackage = subscribedPackages.filter(
          x =>
            moment(new Date(parseInt(x.end_date)))
              .tz(TIME_ZONE)
              .endOf("day") > moment(today) &&
            moment(new Date(parseInt(x.start_date)))
              .tz(TIME_ZONE)
              .startOf("day") > moment(today) &&
            x.payment_status == "SUCCESS" &&
            x.is_expired != true
        );

        if (upcomingPackage.length == 1) {
          upcomingPackage = upcomingPackage[0];
        }
        if (upcomingPackage.length > 1) {
          upcomingPackage = sortBy(
            upcomingPackage,
            x => x.order_number
          ).reverse()[0];
        }
        if (upcomingPackage.length == 0) {
          upcomingPackage = null;
        }
        if (upcomingPackage) upcomingPackage.packageName = "Upcoming Package";

        expiredPackage = subscribedPackages.find(
          x =>
            moment(new Date(parseInt(x.end_date)))
              .tz(TIME_ZONE)
              .endOf("day") < moment(today) &&
            moment(new Date(parseInt(x.start_date)))
              .tz(TIME_ZONE)
              .startOf("day") < moment(today) &&
            x.payment_status == "SUCCESS"
        );
      }
    }

    return {
      activePackage: activePackage,
      upcomingPackage: upcomingPackage,
      expiredPackage: expiredPackage
    };
  }
}
