<template>
  <div>
    <v-layout class="bg">
      <AddDeliverySchedule v-if="hasRole(AdminUserPermissions.CUSTOMER_DELIVERY_SCHEDULE_ADD)" /> 
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="deliverySchedules"
      :items-per-page="10"
      :mobile-breakpoint="0"
    >
      <template v-slot:item.updated_At="{ item }">
        {{ new Date(parseInt(item.updated_At)) | moment("MMM DD YYYY") }}
      </template>
      <template v-if="hasRole(AdminUserPermissions.CUSTOMER_DELIVERY_SCHEDULE_ADD)" v-slot:item.actions="{ item }">
        <v-row>
          <DeleteDeliverySchedule :deliveryShift="item" />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AddDeliverySchedule from "../deliverySchedule/addDeliverySchedule.vue";
import DeleteDeliverySchedule from "../deliverySchedule/DeleteDeliverySchedule.vue";
export default {
  components: { AddDeliverySchedule, DeleteDeliverySchedule },

  data() {
    return {
      deliverySchedules: [],
      headers: [
        {
          text: "Week Day",
          value: "week",
        },
        { text: "Area", value: "address.area.nameEn" },
        { text: "Block", value: "address.block.block" },
        { text: "Street", value: "address.street", width: 120 },
        { text: "Jedha", value: "address.jedha" },
        { text: "House", value: "address.house_number" },
        { text: "Floor", value: "address.apartment_number" },
        { text: "Flat", value: "address.building_number" },
        { text: "Updated At", value: "updated_At", width: 120 },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  apollo: {
    getDeliveryScheduleByCustomerId() {
      return {
        query: require("../../api/getDeliveryScheduleByCustomerId.graphql"),
        variables: {
          id: this.$route.query.customerId,
        },
        result({ data, loading }) {
          this.loading = loading;
          this.deliverySchedules = data.getDeliveryScheduleByCustomerId;
          console.log(this.deliverySchedules);
        },
      };
    },
  },
};
</script>
