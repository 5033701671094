<template>
  <v-container class="ma-0 pa-0 " >
    <v-icon color="black" @click="showIngrediantDialog()"
      >mdi-square-edit-outline</v-icon
    >

    <v-dialog v-model="show_dialog_box" max-width="900px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Stock Category</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="updateCategory()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Name En</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    solo
                    dense
                    label="Name En"
                    :rules="[requiredValidator('Name En')]"
                    v-model="stockCategory.nameEn"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h4 class="black--text text-body-2">Name Ar</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="stockCategory.nameAr"
                    :rules="[requiredValidator('Name Ar')]"
                    solo
                    dense
                    label="Name Ar"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n16">
          <v-spacer></v-spacer>
          <v-btn
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            color="secondary "
            @click="updateCategory()"
            :loading="btnloading"
            :disabled="!valid"
            class="mt-8 black--text text-capitalize"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </v-container> 
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  props: {
    stockCategory: {
      required: true
    }
  },
  data() {
    return {
      btnloading: false,
      isIngredient: true,
      isCommonDislikable: true,
      loading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      requiredValidator: requiredValidator,
      snackbarColor: ""
    };
  },

  methods: {
    updateCategory() {
      console.log(this.stockCategory.nameEn + this.stockCategory.nameAr);
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/updateStockCategory.graphql"),
          variables: {
            id: this.stockCategory._id,
            nameEn: this.stockCategory.nameEn,
            nameAr: this.stockCategory.nameAr
          }
        })
        .then(data => {
          console.log(data);
          this.$emit("updatedStockCategory");
          this.show_dialog_box = false;
          this.text = "Stock category successfully updated";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.$emit("updatedStockCategory");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            this.text = error.graphQLErrors[0].message;
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        });
    },
    showIngrediantDialog() {
      this.show_dialog_box = true;
    }
  }
};
</script>

