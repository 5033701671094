<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onEditClick"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="ma-0 pa-3" color="pop_bg">
        <v-card-text>
          <v-row>
            <h3 class="primary--text text-h6 mb-4">Edit Ingredient Details</h3>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider class="mt-2"></v-divider>
          <v-form class="pa-sm-8" v-model="valid" ref="form">
            <v-layout v-if="ingredientInfo.ingredient.ingredientCategory" wrap class="mt-6 mt-sm-0"> 
              <v-flex xs12 sm3 md3>
                <h4 class="black--text text-body-2">Category Name</h4> 
              </v-flex>
              <v-flex
                xs12
                sm8
                md5
                class="mt-2 mt-sm-0 black--text text-body-2 font-weight-bold"
              >
                {{ ingredientInfo.ingredient.ingredientCategory.nameEn }} 
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-6 mt-sm-3">
              <v-flex xs12 sm3 md3>
                <h4 class="black--text text-body-2">Ingredient Name</h4>
              </v-flex>
              <v-flex
                xs12
                sm8
                md5
                class="mt-2 mt-sm-0 black--text text-body-2 font-weight-bold"
              >
                {{ ingredientInfo.ingredient.nameEn }}
              </v-flex>
            </v-layout>

            <v-layout wrap class="mt-4">
              <v-flex xs12 sm3 md3>
                <h4 class="black--text text-body-2">Quantity </h4>
              </v-flex>
              <v-flex xs6 sm4 md5>
                <v-text-field
                  v-model="ingredientInfo.quantity"
                  solo
                  dense
                  label="Quantity"
                  type="number"
                  min="0"
                  @keypress="isFloatNumber"
                  :rules="[requiredValidator('quantity')]"
                >
                  <template v-slot:append>
                    <h5 class="mt-1 grey--text">
                      {{ getUnitName(ingredientInfo.ingredient.quantityUnit) }} 
                    </h5>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
            <!-- <v-layout wrap class="mt-4">
              <v-flex xs12 sm3 md3>
                <h4 class="black--text text-body-2">Quantity After Preparation</h4>
              </v-flex>
              <v-flex xs6 sm4 md5>
                <v-text-field
                  v-model="ingredientInfo.quantityAfterPreperation"
                  solo
                  dense
                  label="Quantity"
                  type="number"
                  min="0"
                  @keypress="isFloatNumber"
                  :rules="[requiredValidator('quantity')]"
                >
                  <template v-slot:append>
                    <h5 class="mt-1 grey--text">
                      {{ ingredientInfo.ingredient.quantityUnit }}
                    </h5>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout> -->

            <v-layout wrap>
              <v-flex xs12 sm3 md3>
                <h4 class="black--text text-body-2">Dislikable</h4>
              </v-flex>
              <v-flex xs12 sm6>
                <v-radio-group
                  v-model="ingredientInfo.isDislikable"
                  :mandatory="false"
                  row
                >
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout row justify-end class="mt-8 mb-n5">
              <v-btn
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                color="secondary"
                class="primary--text text-capitalize"
                @click="updateMenuItem()"
                :loading="btnloading"
                :disabled="!valid"
                >Update</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  props: {
    ingredient: {
      required: true,
    },
      unitList: {
      type: Array,
      required: true
    }
    
  },
  emits:['update'],
  data() {
    return {
      snack: false,
      requiredValidator: requiredValidator,
      ingredientInfo: [],
      btnloading: false,
      show_dialog_box: false,
      valid: false,
    };
  },
  watch: {},

  created() {
    this.ingredientInfo = JSON.parse(JSON.stringify(this.ingredient));
   
  },
  methods: {
     getUnitName(id) {
      if (id) {
        let data = this.unitList.find(x => x._id == id);
        if (data) {
          return data.name;
        }
      } 
    },
    onEditClick() {
      this.ingredientInfo = JSON.parse(JSON.stringify(this.ingredient));
      
    
    },
    isFloatNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateMenuItem() {
      
      this.ingredient.quantity = this.ingredientInfo.quantity;
      this.ingredient.quantityAfterPreperation = this.ingredientInfo.quantity;
      this.ingredient.isDislikable = this.ingredientInfo.isDislikable;
      this.show_dialog_box = false;
      this.$emit('update')
    },
    close() {
      this.show_dialog_box = false;
    },
  },
};
</script>














