import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#43C2D3",
        secondary: "#FBE8D4",
        background: "#FFFFFF",
        black: "#000000",
        bg: "#FFFFFF",
        secondary_one: "#839E87"
      },
      light: {
        primary: "#43C2D3",
        secondary: "#FBE8D4",
        background: "#FFFFFF",
        black: "#000000",
        bg: "#FFFFFF",
        secondary_one: "#839E87"
      }
    }
  }
});