<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="green" class="ms-2">mdi-pencil</v-icon>
    </template>
    <v-card class="pa-6" color="pop_bg">
      <v-card-text
        class="subtitle font-weight-bold mb-0 mt-6 text-center black--text"
        >Update Meal Portion</v-card-text
      >

      <v-card-text v-if="error" class="error--text font-weight-bold">{{
        error
      }}</v-card-text>
      <v-form
        class="pa-sm-8"
        @submit="createMealPortion()"
        ref="form"
        v-model="valid"
      >
        <v-layout wrap class="mt-6 mt-sm-0" justify-start>
          <v-flex xs12 sm3 md3>
            <h4 class="text-body-2 black--text mt-2 ">Name</h4>
          </v-flex>
          <v-flex xs12 sm8 md9>
            <v-text-field
              placeholder="meal portion name"
              :rules="[requiredValidator('name')]"
              solo
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap class="mt-6 mt-sm-0" justify-start>
          <v-flex xs12 sm3 md3>
            <h4 class="text-body-2 black--text mt-2 ">Calorie Factor</h4>
          </v-flex>
          <v-flex xs12 sm8 md9>
            <v-select
              placeholder="select the calorie factor"
              :rules="[requiredValidator('calorie factor')]"
              solo
              dense
              :items="calorieFactors"
              v-model="calorieFactor"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-row justify="center">
          <v-btn
            class="mr-4 black--text"
            color="secondary"
            depressed
            @click="close"
            >Cancel</v-btn
          >
          <v-btn
            class="ml-4 "
            color=" primary"
            depressed
            :loading="button_loading"
            @click="update"
            :disabled="!valid"
            >Update</v-btn
          >
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    mealPortion: {
      required: true
    }
  },
  name: "UpdateMealPortion",
  data() {
    return {
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      requiredValidator,
      calorieFactors: [0.25, 0.5, 0.75, 1],
      name: "",
      calorieFactor: ""
    };
  },
  created() {
    this.name = this.mealPortion.name;
    this.calorieFactor = this.mealPortion.calorieFactor;
  },
  methods: {
    close() {
      this.error = null;
      this.dialog = false;
    },
    update() {
      this.dialog = false;
      this.mealPortion.name = this.name;
      this.mealPortion.calorieFactor = this.calorieFactor;
      this.$emit("updateMealPortions");
    }
  }
};
</script>
