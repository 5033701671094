var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('v-layout',[(_vm.hasRole(_vm.AdminUserPermissions.CUSTOMER_DIETITIAN_ADD))?_c('AddCustomerDietitianAppoinment',{attrs:{"customer_id":_vm.customer_id}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.appointmentList,"items-per-page":25,"options":_vm.options,"mobile-breakpoint":0,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"item-class":function (item) { return item.isCancelled ? ' darken-1 red--text' : ' darken-1 black--text'; },"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.user.firstnameEn)+" "+_vm._s(item.customer.user.lastnameEn)+" ")]}},{key:"item.createdDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"MMM DD YYYY"))+" ")]}},{key:"item.height",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.height).toFixed(3))+" ")]}},{key:"item.weight",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.weight).toFixed(3))+" ")]}},{key:"item.fat",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.fat).toFixed(3))+" ")]}},{key:"item.muscle",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.muscle).toFixed(3))+" ")]}},{key:"item.targetCalories",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.targetCalories).toFixed(3))+" ")]}},{key:"item.dietitian",fn:function(ref){
        var item = ref.item;
return [(item.dietitian && item.dietitian.user)?_c('span',[_vm._v(" "+_vm._s(item.dietitian.user.firstnameEn[0].toUpperCase() + item.dietitian.user.firstnameEn.substring(1))+" "+_vm._s(item.dietitian.user.lastnameEn[0].toUpperCase() + item.dietitian.user.lastnameEn.substring(1))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.appointmentTime",fn:function(ref){
        var item = ref.item;
return [(item.appointmentDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"MMM DD YYYY"))+", "+_vm._s(item.appointmentTime)+" ")]):_vm._e()]}},{key:"item.workoutTime",fn:function(ref){
        var item = ref.item;
return [(item.workoutTime)?_c('span',[_vm._v(" "+_vm._s(item.workoutTime)+" ")]):_vm._e()]}},{key:"item.reason",fn:function(ref){
        var item = ref.item;
return [(item.isCancelled)?_c('span',[_vm._v(" "+_vm._s(item.reasonToCancel)+" ")]):_vm._e()]}},{key:"item.sleepingTime",fn:function(ref){
        var item = ref.item;
return [(item.sleepingTime)?_c('span',[_vm._v(" "+_vm._s(item.sleepingTime)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.fee",fn:function(ref){
        var item = ref.item;
return [(item.fee)?_c('span',[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.fee))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!item.isCancelled)?_c('v-row',[(_vm.isPaymentPending(item))?_c('div',[(_vm.hasRole(_vm.AdminUserPermissions.CUSTOMER_SALES_ORDER_UPDATE))?_c('RegenerateApptPayment',{attrs:{"appointment":item}}):_vm._e()],1):_vm._e(),(!item.isAppointmentClosed && !_vm.isPaymentPending(item))?_c('div',[(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_APPOINTMENT_EDIT))?_c('UpdateCustomerDietitianAppoinment',{attrs:{"appointment":item}}):_vm._e()],1):_vm._e(),(!item.isAppointmentClosed && !_vm.isPaymentPending(item))?_c('div',[(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_APPOINTMENT_DELETE))?_c('DeleteCustomerDietitianAppt',{attrs:{"dietitianappointment":item}}):_vm._e()],1):_vm._e()]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }