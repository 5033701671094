var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.dietitianList,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'DietitianDetails',
          params: { dietitianId: item._id },
          query: { dietitianId: item._id }
        }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")])]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isActive),expression:"item.isActive"}],attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.isActive),expression:"!item.isActive"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.userRole",fn:function(ref){
        var item = ref.item;
return [(item.user.userRole)?_c('div',[(item.user.userRole.role)?_c('span',[_vm._v(" "+_vm._s(item.user.userRole.role.toUpperCase())+" ")]):_vm._e()]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_EDIT))?_c('DietitianUpdateForm',{staticClass:"me-3",attrs:{"dietitian":item}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_DELETE))?_c('DietitianDelete',{attrs:{"dietitian":item}}):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }