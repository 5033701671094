var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 mt-10 ms-2",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.itemsWithSno,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.nameEn",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameEn))]}},{key:"item.nameAr",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameAr))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.itemId))]}},{key:"item.brandName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.brand))]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUnitName(item.quantityUnit))+" ")]}},{key:"item.conversionUnit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getConversionUnit(item.quantityUnit))+" ")]}},{key:"item.conversionValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getConversionValue(item.quantityUnit))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.MASTER_DATA_EDIT))?_c('app-master-update-form',{attrs:{"ingredientInfo":item,"unitList":_vm.unitList}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.MASTER_DATA_DELETE))?_c('StockMasterDelete',{attrs:{"ingredient":item}}):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }