<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success"
      >Meal portion successfully updated.</v-snackbar
    >

    <v-form v-model="valid" ref="form">
      <v-layout justify-end class="mt-3 mt-sm-0">
        <h3 class="black--text text-h5 font-weight-bold">Meal Portions</h3>
        <v-spacer></v-spacer>
        <div v-if="hasRole(AdminUserPermissions.MEAL_PORTION_ADD)">
          <CreateMealPortion @updateMealPortion="updateMealPortion" />
        </div>
      </v-layout>

      <v-layout justify-space-between wrap class="mt-16 me-md-16">
        <v-flex
          class="mb-5"
          v-for="(textField, i) in textFields"
          :key="i"
          xs10
          sm8
          md5
        >
          <v-card class="d-flex justify-space-between pt-2" height="40px">
            <span class="text-body-1  black--text ml-5">
              {{ i + 1 }}.
              <span class="font-weight-bold text-uppercase">
                {{ textField.name }}</span
              >
              (<span class=""> {{ textField.calorieFactor }}</span
              >)
            </span>
            <span class="mr-3">
              <span v-if="hasRole(AdminUserPermissions.MEAL_PORTION_EDIT)">
                <UpdateMealPortion
                  @updateMealPortions="updateMealPortions"
                  :mealPortion="textField"
                />
              </span>

              <v-icon
                v-if="hasRole(AdminUserPermissions.MEAL_PORTION_DELETE)"
                color="red"
                @click="remove(i)"
                >mdi-close</v-icon
              >
            </span>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-btn
          v-if="save"
          color="secondary"
          :loading="btnloading"
          :disabled="!valid"
          @click="createTag()"
          class="ms-4 black--text px-16 font-weight-bold text-capitalize"
          >Save</v-btn
        >
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import CreateMealPortion from "../components/CreateMealPortion.vue";
import UpdateMealPortion from "../components/UpdateMealPortion.vue";
export default {
  components: {
    CreateMealPortion,
    UpdateMealPortion
  },
  data() {
    return {
      save: false,
      textDisabled: true,
      textFields: [],
      btnloading: false,
      snackbar: false,
      valid: false,
      requiredValidator: requiredValidator
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MEAL_PORTION_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  mounted() {
    this.getAllMealPortions();
  },
  methods: {
    updateMealPortion(data) {
      this.textFields.push({
        name: data.name,
        calorieFactor: data.calorieFactor,
        _id: ""
      });
      this.save = true;
    },
    updateMealPortions() {
      this.save = true;
    },
    createTag() {
      let allTags = [];
      this.textFields.forEach(element => {
        let tag = {
          id: element._id,
          name: element.name,
          calorieFactor: element.calorieFactor
        };

        allTags.push(tag);
      });

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/updateMealPortions.graphql"),
          variables: {
            input: allTags
          }
        })
        .then(() => {
          this.btnloading = false;
          this.snackbar = true;
          this.save = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    getAllMealPortions() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/menu-packages/api/getAllMealPortions.graphql")
        })
        .refetch({})
        .then(data => {
          console.log(data.data);
          data.data.getAllMealPortions.mealPortions.forEach(element => {
            this.textFields.push({
              name: element.name,
              _id: element._id,
              calorieFactor: element.calorieFactor
            });
          });
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    remove(index) {
      this.textFields.splice(index, 1);
      this.save = true;
    },
    onClick() {
      this.save = true;
    }
  }
};
</script>
