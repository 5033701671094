<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="stockAdjustList"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.availableQuantity="{ item }">
        {{ getAvailableQuantity(item, true) }}
        <!-- {{item}}  -->
        <!-- <div v-if="item.ingredient">
          <span v-if="item.ingredient.quantityUnit === 'GRAMS'">
            {{ parseFloat(item.availableQuantity / 1000).toFixed(3) }} KG
          </span>
          <span v-if="item.ingredient.quantityUnit === 'ML'">
            {{ parseFloat(item.availableQuantity / 1000).toFixed(3) }} L
          </span>
          <span v-else-if="item.ingredient.quantityUnit === 'PIECE'">
            {{ parseFloat(item.availableQuantity).toFixed(0) }} PCS
          </span>
        </div>
        <span v-else>
          {{ parseFloat(item.availableQuantity / 1000).toFixed(3) }}
        </span> -->
      </template>
      <template v-slot:item.updatedQuantity="{ item }">
        {{ getAvailableQuantity(item, false) }}
        <!-- <div v-if="item.ingredient">
          <span v-if="item.ingredient.quantityUnit === 'GRAMS'">
            {{ parseFloat(item.updatedQuantity / 1000).toFixed(3) }} KG
          </span>

          <span v-else-if="item.ingredient.quantityUnit === 'ML'">
            {{ parseFloat(item.updatedQuantity / 1000).toFixed(3) }} L
          </span>
          <span v-else-if="item.ingredient.quantityUnit === 'PIECE'">
            {{ parseFloat(item.updatedQuantity).toFixed(0) }} PCS
          </span>
        </div>
        <span v-else>
          {{ parseFloat(item.updatedQuantity / 1000).toFixed(3) }}
        </span> -->
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    unitList: {
      required: true
    }
  },
  apollo: {
    getAllStockAdjust() {
      return {
        query: require("../api/getAllStockAdjust.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.stockAdjustList = data.getAllStockAdjust.StockAdjust;
          this.totalItemsCount = data.getAllStockAdjust.totalCount;
        }
      };
    }
  },
  created() {
    // this.getAllUnits();
  },
  watch: {
    options: {
      handler() {
        this.variables.limit = this.options.itemsPerPage;
        this.variables.skip =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
        this.$apollo.queries.getAllStockAdjust.refetch({
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
          skip: this.variables.skip
        });
      },
      deep: true
    }
  },
  data: () => ({
    dialog: false,
    stockAdjustList: [],
    totalItemsCount: 25,
    options: {
      itemsPerPage: 25,
      page: 1
    },
    variables: {
      limit: 25,
      skip: 0
    },

    loading: true,
    headers: [
      {
        text: "Ref.No",
        value: "refNo",
        width: 120,
        sortable: false
      },
      {
        text: "Item ID",
        value: "itemId",
        sortable: false,
        width: 120
      },
      {
        text: "Item Name",
        value: "nameEn",
        sortable: false,
        width: 120
      },
      {
        text: "Available Quantity",
        value: "availableQuantity",
        sortable: false,
        width: 120
      },
      {
        text: "New Quantity",
        value: "updatedQuantity",
        sortable: false,
        width: 120
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        width: 120
      }
    ]
    // unitList: []
  }),

  methods: {
    // getAllUnits() {
    //   this.$apollo
    //     .watchQuery({
    //       query: require("../api/getAllUnits.graphql"),
    //       variables: this.variables
    //     })
    //     .refetch({})
    //     .then(data => {
    //       if (data.data) {
    //         this.unitList = data.data.getAllUnits.units;
    //       }
    //     });
    // },
    getAvailableQuantity(item, availableQuantity) {
      let id = null;
      let conversionUnit = null;
      let quantity = null;
      let unit = null;
      let dynamicQuantity = item.updatedQuantity;
      if (availableQuantity) {
        dynamicQuantity = item.availableQuantity;
      }
      if (item.ingredient) {
        id = item.ingredient.quantityUnit;
      }
      if (id) {
        let data = this.unitList.find(x => x._id == id);
        if (data) {
          conversionUnit = data.conversionUnit;
        }
      }
      if (conversionUnit == "GRAMS") {
        quantity = parseFloat(dynamicQuantity / 1000).toFixed(3);
        unit = "KG";
      } else if (conversionUnit == "PIECE") {
        quantity = parseFloat(dynamicQuantity).toFixed(0);
        unit = "PCS";
      } else if (conversionUnit == "ML") {
        quantity = parseFloat(dynamicQuantity / 1000).toFixed(3);
        unit = "ML";
      }
      return `${quantity} ${unit}`;
    }
  }
};
</script>
