<template>
  <v-container class="bg px-0" fluid>
    <v-layout class="mt-8" wrap>
      <!-- <v-flex xs3 sm2 md1 class="ms-sm-2 ms-md-0">
        <h4>From</h4>
      </v-flex> -->
      <v-flex xs8 sm4 md3 lg3 class="d-flex">
        <span class="black--text me-3">From</span>
        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fromDate"
              readonly
              label="From"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="fDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="fDate"
            @change="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <!-- <v-flex xs3 sm2 md1 class="ms-sm-10 ms-md-16">
        <h4>To</h4>
      </v-flex> -->
      <v-flex xs8 sm4 md3 lg3 class="d-flex ms-sm-8 pe-sm-5">
        <span class="black--text me-3">To</span>
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="ps-6 ps-sm-0"
              :value="toDate"
              readonly
              label="To"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="tDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="tDate"
            @change="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <OutwardStockTable :fDate="fDate" :tDate="tDate"></OutwardStockTable>
  </v-container>
</template>
<script>
import { format, parseISO } from "date-fns";
import OutwardStockTable from "../components/OutwardStockTable.vue";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {
    OutwardStockTable: OutwardStockTable,
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format(DATE_FORMAT) : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format(DATE_FORMAT) : "";
    },
  },
  data() {
    return {
      loading: true,
      fDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      stockList: [],
      menu1: false,
      menu2: false,
      totalItemsCount: 0,
    };
  },
};
</script>