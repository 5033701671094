<template>
  <v-container>
    <h2 class="black--text text-h5 font-weight-bold mb-4 mt-4">
      Plan Switch
    </h2>
    <v-layout justify-end>
      <SwitchCustomerPlans v-if="hasRole(AdminUserPermissions.SWITCH_PLAN)" />
      <AddPlanSwitch
        @updateplanSwitch="updateplanSwitch"
        v-if="hasRole(AdminUserPermissions.PLAN_SWITCH_ADD)"
      />

      <v-btn
        v-if="hasRole(AdminUserPermissions.PLAN_SWITCH_ADD)"
        @click="groupSwapPackage"
        class="black--text text-capitalize mt-1 mb-5 ml-4 "
        color="secondary"
        :loading="groupSwapBtn"
        >Group Swap</v-btn
      >
    </v-layout>
    <div class="dv-switch">
      <v-data-table
        hide-default-footer
        class="myTable"
        :mobile-breakpoint="0"
        :headers="mainHeaders"
        :items="item"
        :items-per-page="25"
        :options.sync="options"
      >
        <template v-slot:[`item.from`]>
          <v-data-table
            hide-default-footer
            :mobile-breakpoint="0"
            :headers="headers"
            :items="planSwitchList"
            :items-per-page="options.itemsPerPage"
          >
            <template v-slot:[`item.package`]="item">
              <div class=" font-weight-bold">
                {{ item.item.from.package.titleEn }}
              </div>
            </template>
            <template v-slot:[`item.choice`]="item">
              <div>
                {{
                  getDetail(
                    item.item.from.package.availableChoices,
                    item.item.from.choice
                  ).choiceNameEn
                }}
              </div>
            </template>
            <template v-slot:[`item.variant`]="item">
              <div>
                {{
                  getDetail(
                    getDetail(
                      item.item.from.package.availableChoices,
                      item.item.from.choice
                    ).mealChoice,
                    item.item.from.variant
                  ).variantNameEn
                }}
              </div>
            </template>
          </v-data-table>
        </template>
        <template v-slot:[`item.to`]>
          <v-data-table
            class="myTableTwo"
            hide-default-footer
            :mobile-breakpoint="0"
            :headers="headers"
            :items="planSwitchList"
            :items-per-page="options.itemsPerPage"
          >
            <template v-slot:[`item.package`]="item">
              <div class=" font-weight-bold">
                {{ item.item.to.package.titleEn }}
              </div>
            </template>
            <template v-slot:[`item.choice`]="item">
              <div>
                {{
                  getDetail(
                    item.item.to.package.availableChoices,
                    item.item.to.choice
                  ).choiceNameEn
                }}
              </div>
            </template>
            <template v-slot:[`item.variant`]="item">
              <div>
                {{
                  getDetail(
                    getDetail(
                      item.item.to.package.availableChoices,
                      item.item.to.choice
                    ).mealChoice,
                    item.item.to.variant
                  ).variantNameEn
                }}
              </div>
            </template>
          </v-data-table>
        </template>
        <template v-slot:[`item.action`]>
          <v-data-table
            class="myTableTwo"
            hide-default-footer
            :mobile-breakpoint="0"
            :headers="headersAction"
            :items="planSwitchList"
            :items-per-page="options.itemsPerPage"
          >
            <template v-slot:[`item.swap`]="item">
              <v-btn
                v-if="hasRole(AdminUserPermissions.PLAN_SWITCH_ADD)"
                color="transparent"
                @click="swapPlanSwitch(item.item)"
                elevation="false "
                :loading="swapLoading && loadingId == item.item._id"
              >
                <v-icon class="ms-2">mdi-swap-horizontal-circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.groupSwap`]="item">
              <div class="pt--2 ml-7 ">
                <v-checkbox
                  v-model="groupSwap"
                  color="black "
                  :value="item.item"
                  hide-details
                  class="align-center justify-center pa-0 ma-0 "
                ></v-checkbox>
              </div>
            </template>
            <template v-slot:[`item.delete`]="item">
              <DeletePlanSwitch
                :planSwitchId="item.item._id"
                @updateplanSwitch="updateplanSwitch"
                v-if="hasRole(AdminUserPermissions.PLAN_SWITCH_DELETE)"
              />
            </template>
          </v-data-table>
        </template>
      </v-data-table>
    </div>

    <v-snackbar v-model="snack" right top :timeout="3000" :color="snackColor">
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>
<script>
import AddPlanSwitch from "../components/AddPlanSwitch.vue";
import SwitchCustomerPlans from "../components/SwitchCustomerPlans.vue";
import DeletePlanSwitch from "../components/DeletePlanSwitch.vue";
export default {
  props: {},
  components: {
    AddPlanSwitch,
    DeletePlanSwitch,
    SwitchCustomerPlans
  },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      groupSwapBtn: false,
      pagination: {},
      mainHeaders: [
        { text: "From", value: "from", align: "center", sortable: false },
        { text: "To", value: "to", align: "center", sortable: false },
        {
          text: "Actions",
          value: "action",
          align: "center",
          width: "240px",
          sortable: false
        }
      ],
      headers: [
        { text: "Package", value: "package", align: "center", sortable: false },
        { text: "Choice", value: "choice", align: "center", sortable: false },
        { text: "Variant", value: "variant", align: "center", sortable: false }
      ],
      headersAction: [
        {
          text: "Swap",
          value: "swap",
          align: "center",
          width: "60px",
          sortable: false
        },
        {
          text: "Group Swap",
          value: "groupSwap",
          align: "center",
          width: "60px",
          sortable: false
        },
        {
          text: "Delete",
          value: "delete",
          align: "center",
          width: "60px",
          sortable: false
        }
      ],
      item: [{ from: "from", to: "to", action: "action" }],
      planSwitchList: [],
      options: {
        itemsPerPage: 10000,
        page: 1,
        sortDesc: [false],
        sortBy: []
      },
      swapLoading: false,
      loadingId: "",
      groupSwap: []
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PLAN_SWITCH_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllPlanSwitches();
  },

  methods: {
    groupSwapPackage() {
      this.groupSwapBtn = true;
      if (this.groupSwap == null) {
        this.groupSwap = [];
      }
      let groupSwap = new Array();
      if (this.groupSwap.length) {
        for (let swapPackage of this.groupSwap) {
          let from = new Object();
          let to = new Object();
          let result = new Object();
          to.package = swapPackage.to.package._id;
          to.choice = swapPackage.to.choice;
          to.variant = swapPackage.to.variant;
          from.package = swapPackage.from.package._id;
          from.choice = swapPackage.from.choice;
          from.variant = swapPackage.from.variant;
          result.to = to;
          result.from = from;
          result.id = swapPackage._id;
          groupSwap.push(result);
        }
        this.$apollo
          .mutate({
            mutation: require("../api/groupPlanSwitch.graphql"),
            variables: {
              input: groupSwap
            }
          })
          .then(() => {
            this.groupSwapBtn = false;
            this.error = null;
            this.snackColor = "green";
            this.snack = true;
            this.snackText = "group swaped plan switch";
            this.swapLoading = false;

            this.getAllPlanSwitches();
            this.groupSwap = new Array();
          })
          .catch(error => {
            this.groupSwapBtn = false;
            if (error.graphQLErrors != null) {
              this.button_loading = false;
              this.error = null;
              this.dialog = false;
              this.snackText = error.graphQLErrors[0].message;
              this.snackColor = "error";
              this.snack = true;
            }
            this.swapLoading = false;
          });
      } else {
        this.snackColor = "red";
        this.snack = true;
        this.snackText = "please select any package";
      }
      this.swapLoading = false;
    },
    swapPlanSwitch(item) {
      this.loadingId = item._id;
      this.swapLoading = true;
      let from = new Object();
      let to = new Object();
      to.package = item.to.package._id;
      to.choice = item.to.choice;
      to.variant = item.to.variant;
      from.package = item.from.package._id;
      from.choice = item.from.choice;
      from.variant = item.from.variant;

      this.$apollo
        .mutate({
          mutation: require("../api/swapPlanSwitch.graphql"),
          variables: {
            id: item._id,
            to,
            from
          }
        })
        .then(() => {
          this.button_loading = false;
          this.error = null;
          this.snackColor = "green";
          this.snack = true;
          this.snackText = "plan switch swaped";
          this.swapLoading = false;
          this.getAllPlanSwitches();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            this.button_loading = false;
            this.error = null;
            this.dialog = false;
            this.snackText = error.graphQLErrors[0].message;
            this.snackColor = "error";
            this.snack = true;
          }
          this.swapLoading = false;
        });
    },
    updateplanSwitch() {
      this.getAllPlanSwitches();
    },
    getAllPlanSwitches() {
      this.loading = true;

      this.$apollo
        .watchQuery({
          query: require("../api/getAllPlanSwitches.graphql")
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          if (data.data) {
            this.planSwitchList = data.data.getAllPlanSwitch.planSwitches;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getDetail(list, Id) {
      if (list && Id) {
        let selected = list.find(x => x._id == Id);
        if (selected) return selected;
      }
      return { choiceNameEn: "--", variantNameEn: "--" };
    }
  }
};
</script>
<style>
.dv-switch .myTable th {
  border: 1px solid rgb(252, 239, 57);
}
.dv-switch .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.dv-switch .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.dv-switch .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.dv-switch .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.dv-switch .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.dv-switch .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0;
}
*/ .v-input__slot {
  align-items: center;
  justify-content: center;
}
</style>
