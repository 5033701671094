<template>
  <v-container class="pa-0 ma-0 ">
    <v-icon color="black" @click="showIngrediantDialog()"
      >mdi-square-edit-outline</v-icon
    >

    <v-dialog v-model="show_dialog_box" max-width="600px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Update Unit</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="updateUnit()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <h4 class="black--text text-body-2 mt-2">Unit Name</h4>
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    solo
                    dense
                    label="Name of unit"
                    :rules="[requiredValidator('unit name')]"
                    v-model="unit.name"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <!-- <h4 class="black--text text-body-2">Unit Name</h4> -->
                </v-flex>

                <v-flex xs12 sm8>
                  <v-radio-group
                    v-model="unit.conversionUnit"
                    :mandatory="false"
                    row
                    class="mt-n1"
                  >
                    <v-radio label="Grams" value="GRAMS"></v-radio>
                    <v-radio label="Nos" value="PIECE"></v-radio>
                    <v-radio label="mL" value="ML"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <h4 class="black--text text-body-2 mt-2">Conversion Value</h4>
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    solo
                    dense
                    label="conversion value"
                    :rules="[positive('conversion value')]"
                    type="number"
                    min="0"
                    v-model="unit.conversionValue"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
            <p
              class="text-center red--text "
              v-if="text == 'UNIT_ADDED_IN_MEAL'"
            >
              {{unit.name}} is already added in meal, so cant change the conversion unit
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n16">
          <v-spacer></v-spacer>
          <v-btn
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            color="secondary "
            @click="updateUnit()"
            :loading="btnloading"
            :disabled="!valid"
            class="mt-8 black--text text-capitalize"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator, positive } from "../../core/methods/validations";

export default {
  props: {
    unit: {
      required: true
    }
  },
  data() {
    return {
      btnloading: false,
      isIngredient: true,
      isCommonDislikable: true,
      loading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      requiredValidator: requiredValidator,
      positive,
      snackbarColor: ""
    };
  },

  methods: {
    updateUnit() {
      this.text = "";
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/updateUnit.graphql"),
          variables: {
            id: this.unit._id,
            name: this.unit.name,
            conversionValue: parseFloat(this.unit.conversionValue),
            conversionUnit: this.unit.conversionUnit
          }
        })
        .then(data => {
          console.log(data);
          this.$emit("updatedUnit");
          this.btnloading = false;
          this.show_dialog_box = false;
          this.text = "unit successfully updated";
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            this.btnloading = false;
            this.text = error.graphQLErrors[0].message;
            if (this.text !== "UNIT_ADDED_IN_MEAL") {
              this.snackbarColor = "error";
              this.snackbar = true;
            } else {
              setTimeout(() => {
                this.text = "";
              }, 3400);
            }
          }
          this.btnloading = false;
        });
    },
    showIngrediantDialog() {
      this.show_dialog_box = true;
    }
  }
};
</script>
