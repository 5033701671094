<template>
  <v-container class="px-0" fluid>
    <h2 class="black--text text-h5 font-weight-bold ms-2 mb-3">Area</h2>

    <v-row align="center" justify="space-between" class="mt-n4 mt-md-0">
      <v-col cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="Area.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" class="d-flex justify-start justify-md-end">
        <span class="me-5">

        <AreaCreateForm
          v-if="hasRole(AdminUserPermissions.LOCATION_ADD)"
          :area="area"
        />
        </span>
        <AuditTrialLog
          class=" mt-n6 mt-md-n6"
          auditType="AREA_LOG"
        />
      </v-col>
    </v-row>
    <!-- </v-layout> -->
    <app-area-table
      :areaList="areaList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
    ></app-area-table>
  </v-container>
</template>

<script>
import AreasTable from "../components/AreasTable.vue";
import AreaCreateForm from "../components/AreaCreateForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  components: {
    "app-area-table": AreasTable,
    AreaCreateForm,
    AuditTrialLog,
  },
  data() {
    return {
      textFields: [],
      tags: [],
      loading: true,
      areaList: [],
      blockList: [],
      totalItemsCount: 0,
      area: {
        NameEn: "",
        NameAr: "",
      },
      shift: {
        NameEn: "",
        NameAr: "",
      },
      block: "",
      zone: "",
      search: "",
    };
  },
  created() {},
  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
          this.totalItemsCount = data.getAllAreas.totalCount;
        },
      };
    },
  },
  methods: {
    runSearch() {
      this.$apollo.queries.getAllAreas.refetch({
        searchKey: this.search,
      });
    },
    clearSearch() {
      this.search = "";
      this.$apollo.queries.getAllAreas.refetch({
        searchKey: "",
      });
    },
  },
};
</script>