<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="dietitianList"
      class="elevation-1 mt-10"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.user="{ item }">
        <router-link
          :to="{
            name: 'DietitianDetails',
            params: { dietitianId: item._id },
            query: { dietitianId: item._id }
          }"
          class="mx-auto"
        >
          {{
            item.user.firstnameEn[0].toUpperCase() +
              item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
              item.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <v-icon v-show="item.isActive" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.isActive" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:[`item.userRole`]="{ item }">
        <div v-if="item.user.userRole">
          <span v-if="item.user.userRole.role">
            {{ item.user.userRole.role.toUpperCase() }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <DietitianUpdateForm
            class="me-3"
            v-if="hasRole(AdminUserPermissions.DIETITIAN_EDIT)"
            :dietitian="item"
          ></DietitianUpdateForm>
          <DietitianDelete
            v-if="hasRole(AdminUserPermissions.DIETITIAN_DELETE)"
            :dietitian="item"
          />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import DietitianDelete from "../components/DietitianDeleteForm.vue";
import DietitianUpdateForm from "../components/DietitianUpdateForm.vue";
export default {
  components: {
    DietitianDelete,
    DietitianUpdateForm
  },
  props: {
    dietitianList: {
      require: true
    },
    loading: { require: true }
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        width: 70
      },
      { text: "Name", value: "user", sortable: false, width: 200 },
      { text: "Phone", value: "user.phone", sortable: false, width: 200 },
      { text: "Is Active", value: "isActive", sortable: false, width: 120 },
      { text: "Type of User", value: "userRole", sortable: false, width: 100 },
      { text: "Action", value: "actions", sortable: false, width: 10 }
    ]
  }),

  methods: {}
};
</script>
