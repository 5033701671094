<template>

  <v-container>
    <v-icon @click="show_dialog_box = !show_dialog_box" small color="red">mdi-pencil</v-icon>
    <v-dialog v-model="show_dialog_box" max-width="800px">

      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Edit Variant</span>
        </v-card-title>
        <v-card-title>
          <v-form class="pa-3" v-model="frmvarient" ref="frmvarient">
            <v-layout wrap>
              <v-flex xs12 sm5 class="text-body-2 black--text">
                Name English
                <v-text-field label="Variant Name English" v-model="choiceInfo.variantNameEn"
                  :rules="[requiredValidator('variant name english')]" solo dense></v-text-field>
              </v-flex>
              <v-flex xs12 sm5 class="text-body-2 black--text ml-sm-5">
                Name Arabic
                <v-text-field label="Variant Name Arabic" v-model="choiceInfo.variantNameAr"
                  :rules="[requiredValidator('variant name arabic')]" solo dense></v-text-field>
              </v-flex>
            </v-layout>
            <v-tabs class="mt-4 mt-sm-12 ms-n13 ms-md-0" v-model="tab" center-active slider-color="black">
              <v-tab v-for="day in choiceInfo.dayChoices" :key="day.day" :value="day.day"><label class="
                          text-caption text-sm-body-2
                          black--text
                          text-capitalize
                        ">{{ day.day }}</label></v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(day, index) in choiceInfo.dayChoices" :key="index">
                <v-card color="pop_bg pt-4">

                  <v-flex xs8 sm5 class="text-body-2 black--text ms-5" v-for="(item, index) in day.mealCount"
                    :key="item._id">
                    {{ getCategoryName(item) }}
                    <span v-show="false">{{ index }}</span>
                    <v-row class="mt-3">
                      <v-text-field :label="`${getCategoryName(item)}`" solo type="number" @keypress="isNumber"
                        v-model="item.count" min="0" dense></v-text-field>

                      <v-btn class="
                                white--text
                                text-capitalize
                                ms-sm-5
                                mb-4
                              " color="primary" :width="$vuetify.breakpoint.smAndUp ? 130 : 130"
                        @click="applyToAll(item)">Apply to All</v-btn>
                    </v-row>
                  </v-flex>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-divider></v-divider>
            <v-layout row class="mt-8">
              <v-flex xs12 sm5 class="text-body-2 black--text mt-3">
                Price
                <v-text-field label="Price(amt)" v-model="choiceInfo.price" @keypress="isFloatNumber"
                  :rules="[requiredValidator('price')]" solo type="number" min="0" dense></v-text-field>
              </v-flex>

            </v-layout>
            <v-divider class="mt-5"></v-divider>
            <v-flex xs12 sm5 class="text-body-2 black--text mt-3">
              Strikethrough Price
              <v-text-field label="Strikethrough Price(amt)" v-model="choiceInfo.strikeThroughPrice"
                @keypress="isFloatNumber" solo :error-messages="strikeThroughPriceError" type="number" min="0" dense>
              </v-text-field>
            </v-flex>
            <v-spacer></v-spacer>


            <v-layout row class="mt-8">
              <v-spacer></v-spacer>
              <v-btn class="black--text text-capitalize" color="secondary" width="120" @click="addMealChoice"
                :disabled="!frmvarient">Update</v-btn>
            </v-layout>
          </v-form>
        </v-card-title>
      </v-card>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
          snackText
      }}</v-snackbar>
    </v-dialog>

  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  props: {
    choice: {
      required: true
    },

  },
  apollo: {

  },
  created() {
    this.choiceInfo = JSON.parse(JSON.stringify(this.choice));
  },
  methods: {
    applyToAll(category) {

      this.choiceInfo.dayChoices.forEach(day => {
        day.mealCount.forEach(element => {
          if (element.category) {
            if (element.category._id == category.category._id) {
              element.count = category.count;
            }
          }
          else {
            if (element._id == category._id) {
              element.count = category.count;
            }
          }
        });
      });
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Meal count applied to all days.";
    },
    getCategoryName(item) {
      if (item.category) {
        return item.category.nameEn;
      }
      else {
        return item.nameEn;
      }
    },
    addMealChoice() {
      this.$emit("updateVariant", this.choiceInfo);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloatNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  data: () => ({
    loading: true,
    snackText: "",
    snackColor: "",
    snack: "",
    tab: "",
    valid: false,
    requiredValidator,
    frmvarient: false,
    show_dialog_box: false,
    price: "",
    choiceInfo: [],
  }),

};
</script>
