<template>
  <div>
    <v-data-table
      :headers="headers"
      :mobile-breakpoint="0"
      :server-items-length="totalItemsCount"
      :options.sync="options"
      :items-per-page="25"
      :items="managerList"
      class="elevation-1 mt-10"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          managerList
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:item.user="{ item }">
        <!-- <v-row> -->
        <router-link
          v-if="item.user"
          :to="{
            name: 'StaffInfo',
            params: { managerId: item._id },
            query: { managerId: item._id },
          }"
          class="mx-auto"
        >
          {{
            item.user.firstnameEn[0].toUpperCase() +
            item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
            item.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>

      <template v-slot:item.isActive="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.isActive" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.isActive" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <ReferralTransations :user="item.user" isUserTable="true" />
          <ManagerUpdateForm
            v-if="hasRole(AdminUserPermissions.MANAGER_EDIT)"
            :managerInfo="item"
            class="ms-2"
          />
          <ManagerDelete
            v-if="hasRole(AdminUserPermissions.MANAGER_DELETE)"
            :manager="item"
          />
        </div>
      </template>
      <template v-slot:item.userRole="{ item }">
        <div v-if="item.user.userRole">
          <span v-if="item.user.userRole.role">
            {{ item.user.userRole.role.toUpperCase() }}
          </span>
        </div>
      </template>
      <template v-slot:item.referralCode="{ item }">
        <div v-if="item.user.userId">
          {{ parseInt(item.user.userId).toString(16).toUpperCase() }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ManagerUpdateForm from "../components/ManagerUpdateForm.vue";
import ManagerDelete from "../components/ManagerDeleteForm.vue";
import ReferralTransations from "@/referral-transactions/components/ReferralTransations.vue";

export default {
  components: {
    ManagerUpdateForm,
    ManagerDelete,
    ReferralTransations,
  },
  props: {
    totalItemsCount:{
      type:Number
    },
    managerList: {
      require: true,
    },
    loading: {
      require: true,
    },
    options:{
      type:Object
    } 
  },
  watch:{
   options:{
     handler(val){
       this.$emit('optionsEmit',val)
     }
   }
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        width: 80,
        sortable: false,
      },
      { text: "Name", value: "user", width: 200, sortable: false },
      { text: "Phone", value: "user.phone", width: 150, sortable: false },
      { text: "Is Active", value: "isActive", width: 100, sortable: false },
      {
        text: "Referral Code",
        value: "referralCode",
        sortable: false,
        width: 140,
      },
      { text: "Type of User", value: "userRole", sortable: false, width: 100 },
      { text: "Action", value: "actions", sortable: false, width: 100 },
    ],
  }),

  methods: {},
};
</script>
