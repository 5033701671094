<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row>
      <v-col>
        <h3 class="black--text text-h5 font-weight-bold">Activities</h3>
      </v-col>
      <v-col>
        <ActivityForm
          @updateActivity="updateActivity"
          :content="{ name: 'ACTIVITY' }"
          :isUpdated="false"
        />
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success"
      >Activity successfully updated.</v-snackbar
    >

    <v-form v-model="valid" ref="form">
      <v-layout
        class="mt-4 mt-sm-0 mb-n4 mb-sm-0"
        v-show="$vuetify.breakpoint.xs"
        justify-end
      >
      </v-layout>

      <v-layout wrap class="mt-10">
        <v-flex v-for="(textField, i) in allActivities" :key="i" xs12 sm6 md4>
          <v-card class="ma-4" color="primary ">
            <div class="pa-2 capitalize">
              <ul>
                <li>{{ textField.name }}</li>
                <li>{{ textField.nameAr ? textField.nameAr : "--" }}</li>
              </ul>
            </div>
            <v-card-actions>
              <ActivityForm
                @updateActivity="updateActivity"
                :content="{ name: 'ACTIVITY', data: textField }"
                :isUpdated="true"
              />

              <v-divider></v-divider>

              <deleteActivity
                :activityId="textField._id"
                @updateActivity="updateActivity"
              />
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-btn
          v-if="save"
          color="secondary"
          :loading="btnloading"
          :disabled="!valid"
          @click="createTag()"
          class="ms-4 black--text px-16 font-weight-bold text-capitalize"
          >Save</v-btn
        >
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import ActivityForm from "../components/ActivityForm.vue";
import deleteActivity from "../components/DeleteActivityOrHealthIssue.vue";
export default {
  components: {
    ActivityForm,
    deleteActivity
  },
  data() {
    return {
      save: false,
      textDisabled: true,
      textFields: [],
      allActivities: [],
      btnloading: false,
      snackbar: false,
      valid: false,
      requiredValidator: requiredValidator,
      name: ""
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.ACTIVITY_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllActivities();
  },
  methods: {
    updateActivity() {
      this.getAllActivities();
    },
    // createTag() {
    //   let allTags = [];
    //   this.textFields.forEach(element => {
    //     let tag = {
    //       id: element._id,
    //       name: element.value
    //     };

    //     allTags.push(tag);
    //   });
    //   let activityInput = { activityInput: allTags };
    //   console.log(activityInput);
    //   console.log(allTags);
    //   // let mealsTag = { data: allTags };
    //   this.btnloading = true;
    //   this.$apollo
    //     .mutate({
    //       mutation: require("@/e-clinic/api/updateActivity.graphql"),
    //       variables: {
    //         input: allTags
    //       }
    //     })
    //     .then(() => {
    //       this.btnloading = false;
    //       this.snackbar = true;
    //       this.save = false;
    //     })
    //     .catch(error => {
    //       if (error.graphQLErrors != null) {
    //         console.error(error.graphQLErrors);
    //       }
    //       this.btnloading = false;
    //     });
    // },
    getAllActivities() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/e-clinic/api/getAllActivity.graphql")
        })
        .refetch({})
        .then(data => {
          console.log(data.data);
          this.allActivities = data.data.getAllActivities.activities;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    // add() {
    //   this.textFields.push({
    //     placeholder: "Add activity",
    //     value: "",
    //     _id: ""
    //   });
    //   this.textDisabled = false;
    //   this.save = true;
    // },
    remove(index) {
      this.textFields.splice(index, 1);
      this.save = true;
    },
    onClick() {
      // this.save = true;
      // this.textDisabled=false
    },
    onUpdate() {
      this.save = true;
      this.textDisabled = false;
    }
  }
};
</script>
<style>
.capitalize {
  text-transform: capitalize;
}
</style>
