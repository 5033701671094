<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="px-0 px-sm-3 pt-3" color="pop_bg">
        <v-card-title class="px-0">
          <span class="text-h6 black--text ms-4">Update Staff</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
          
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          @submit="updateManager()"
          v-model="valid"
          ref="form"
          class=" mt-n6"
        >
          <v-card-text class="px-3  py-5 py-sm-8 ">
            <v-container>
              <v-layout class="mt-4 mt-sm-0" wrap>
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">First Name</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="First Name"
                    v-model="manager.user.firstnameEn"
                    :rules="[requiredValidator('First Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Last Name</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Last Name"
                    v-model="manager.user.lastnameEn"
                    :rules="[requiredValidator('Last Name ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md3>
                  <h4 class=" mb-2 mb-sm-0 text-body-2 black--text">Phone</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Phone"
                    @keypress="isNumber"
                    v-model="manager.user.phone"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 8),
                      maxLengthValidator('phone', 8),
                      this.doesPhoneExist != true || 'phone already exists.'
                    ]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Password</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Password"
                    v-model="password"
                    :append-icon="
                      show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                    "
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap>
              

                <v-flex sm3 v-for="per in permissionsList" :key="per.key">
                  <v-checkbox
                    :label="per.value"
                    v-model="manager.permission"
                    :value="per.value"
                    :class="per.value == 'Operation' ? 'ms-md-n7' : ''"
                  ></v-checkbox>
                </v-flex>
              </v-layout> -->
              <v-layout wrap>
                <v-flex xs8 md9 offset-md-3>
                  <v-checkbox
                    label="Is Active"
                    v-model="manager.isActive"
                  ></v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 black--text text-body-2">Referral Percent I</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage_one"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 black--text text-body-2">Referral Percent II</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage_two"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 black--text text-body-2">Referral Percent III</h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage_three"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 black--text text-body-2">
                    Max. Referral Amount(KD)
                  </h4>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    placeholder="Max. Referral Amount"
                    v-model="maxReferralAmount"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('max. referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- new permission -->
              <v-layout wrap>
                <v-flex xs12 sm3 md3>
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Role</h4>
                </v-flex>
                <v-flex sm9 md9>
                  <v-select
                    :items="userTypes"
                    v-model="userRoleId"
                    item-text="role"
                    item-value="_id"
                    label="User Role"
                    dense
                    attach
                    solo
                    @change="userRoleDisabled = false"
                  ></v-select>
                </v-flex>
              </v-layout>
              <!-- end of new permission -->
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n4 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>

            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="updateManager"
              :disabled="!valid"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  requiredValidator,
  maxLengthValidator,
  minLengthValidator
} from "../../core/methods/validations";
export default {
  apollo: {
    getAllUserRole() {
      return {
        query: require("../../permission/api/getAllUserRole.graphql"),
        result({ data }) {
          this.userTypes = data.getAllUserRole;
        }
      };
    }
  },
  data() {
    return {
      permissionsList: [
        { key: "1", value: "Sales" },
        { key: " 2", value: "Operation" },
        { key: " 3", value: "Reports" }
      ],
      maxLengthValidator: maxLengthValidator,
      requiredValidator: requiredValidator,
      minLengthValidator: minLengthValidator,
      btnloading: false,
      doesPhoneExist: false,
      dialog: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      percentage: 0,
      maxReferralAmount: 0,
      percentage_one: 0,
      percentage_two: 0,
      percentage_three: 0,
      phone: "",
      password: "",
      show1: false,
      snackbar: false,
      text: "",
      valid: true,
      manager: [],
      userTypes: [],
      userRoleId: "",
      role: "",
      label: ""
    };
  },
  props: {
    managerInfo: {
      required: true
    }
  },
  created() {
    this.manager = JSON.parse(JSON.stringify(this.managerInfo));
    if (this.manager.referralPercent) {
      this.maxReferralAmount = parseFloat(
        this.manager.referralPercent.maxReferralAmount
      );
      this.percentage_one = parseFloat(
        this.manager.referralPercent.percentage_one
      );
      this.percentage_two = parseFloat(
        this.manager.referralPercent.percentage_two
      );
      this.percentage_three = parseFloat(
        this.manager.referralPercent.percentage_three
      );
    }
  },
  methods: {
    onShowDialog() {
      this.manager = JSON.parse(JSON.stringify(this.managerInfo));
      if (this.manager.user.userRole) {
        this.userRoleId = this.manager.user.userRole._id;
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateManager() {
      this.btnloading = true;
      const user = {
        firstnameEn: this.manager.user.firstnameEn,
        lastnameEn: this.manager.user.lastnameEn,
        password: this.password,
        phone: this.manager.user.phone
      };

      this.$apollo
        .mutate({
          mutation: require("../api/updateManager.graphql"),
          variables: {
            managerId: this.manager._id,
            userId: this.manager.user._id,
            isActive: this.manager.isActive,
            userInput: user,
            userTypeId: this.userRoleId,
            percentage_one: parseFloat(this.percentage_one),
            percentage_two: parseFloat(this.percentage_two),
            percentage_three: parseFloat(this.percentage_three),
            maxReferralAmount: parseFloat(this.maxReferralAmount)
          }
        })
        .then(() => {
          this.$root.$emit("updateManager");
          this.text = "manager details updated";
          this.snackbar = true;
          this.btnloading = false;
          this.dialog = false;
        })
        .catch(error => {
          if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
            this.doesPhoneExist = true;
          }
          this.btnloading = false;
          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
