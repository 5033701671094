<template>
  <v-container class="bg px-0" fluid>
    <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout class="mt-2 ms-sm-n4 mb-3 mb-sm-0" wrap>
      <v-flex xs7 sm4 md3 class="ms-sm-5 me-5">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              label="Date"
              readonly
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            color="secondary"
            v-model="date"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs7 sm3 md2>
        <v-select
          :items="reportFilters"
          label="Report Type"
          v-model="reportFilter"
          item-text="text"
          item-value="value"
          dense
          filled
          solo
          attach
        ></v-select>
      </v-flex>

      <v-flex xs12 sm3 md2 class="ms-sm-5 ms-md-0">
        <v-btn
          class="ms-sm-5 black--text text-capitalize"
          color="secondary"
          width="150"
          @click="searchDelivery"
          >Search
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout justify-end  wrap>
      <!-- </v-layout>
    <v-layout> -->
      <v-flex
        xs12
        sm12
        lg5
        xl5
        class="ms-n3 ms-sm-0 mt-12 mt-sm-4 mt-lg-0 text-end d-flex"
      >
        <v-row justify="end">
          <PrintMeals
            :date="date"
            :disabled="allCategoryResponse.length == 0"
            :reportList="allCategoryResponse"
            :headers="headers"
            :selectedCategory="selectedCategory"
            :meanInfos="meanInfos"
          />
          <v-flex xs7 sm5>
            <v-select
              :items="allCategories"
              label="Meal Category"
              v-model="selectedCategory"
              @change="filterReportList"
              item-text="categoryName"
              item-value="categoryName"
              dense
              filled
              solo
            ></v-select>
          </v-flex>
        </v-row>
      </v-flex>
    </v-layout>
    <v-data-table
      class="mt-8"
      v-if="filteredReportList"
      :mobile-breakpoint="0"
      :headers="headers"
      :items="filteredReportList"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:body="props">
        <tbody>
          <tr v-for="item in props.items" :key="item.id">
            <td v-for="(header, index) in headers" :key="index">
              <div v-if="index == 1">
                <span v-if="item.isCategory" style="padding: 4px 2px 0px 4px">
                </span>
                <span v-else-if="item.isParent">
                  {{ item.itemName }}
                </span>
              </div>
              <div v-else-if="index == 0">
                <span v-if="item.isCategory">
                  <strong> {{ item.itemName }}</strong>
                </span>
                <span v-else-if="item.isParent">
                  {{ item.count }}
                </span>
              </div>

              <div v-else-if="header.value == 'total' && !item.isCategory">
                {{ getTotalValue(item) }}
              </div>
              <div v-else>
                <span v-if="!item.isCategory">
                  {{ getCalrieMealCount(header, item) }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-row v-else>
      <h3>No data found</h3>
    </v-row>

    <v-dialog v-model="showErrorDialog" max-width="500">
      <v-card class="pa-3">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n4"
            >Meals Log</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showErrorDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <ul class="mt-5" v-if="errorLog.length > 0">
          <li v-for="item in errorLog" :key="item">{{ item }}</li>
        </ul>
        <div v-else>All meals are generated successfully!</div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="error">{{
      text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import VueLodash from "vue-lodash";
import Vue from "vue";
import lodash from "lodash";
import PrintMeals from "./PrintMeals.vue";

import { DATE_FORMAT } from "../../utils/utils";

Vue.use(VueLodash, { name: "custom", lodash: lodash });
export default Vue.extend({
  components: { PrintMeals },
  data() {
    return {
      menu: false,
      snackbar: false,
      text: "",
      loading: false,
      lodash: lodash,
      errorLog: [],
      showErrorDialog: false,
      btnloading: false,
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      nowDate: new Date().toISOString().slice(0, 10),
      headers: [],
      reportList: [],
      filteredReportList: [],
      reportGeneratedDate: "",
      meanInfos: [],
      reportFilter: "ALL",
      allCategoryResponse: [],
      allCategories: ["All Meals"],
      selectedCategory: null, //for category dropdown filter
      reportFilters: [
        { text: "All", value: "ALL" },
        { text: "With Dislike", value: "WITH_DISLIKE" },
        { text: "With out Dislike", value: "WITHOUT_DISLIKE" }
      ]
    };
  },

  created() {
    this.getOrderMealDetailsByDate();
  },
  methods: {
    filterReportList() {
      if (this.selectedCategory === this.allCategories[0]) {
        //checking if selection is "All meals"
        this.filteredReportList = this.reportList;
      } else {
        //filtering based on selected category
        this.filteredReportList = this.reportList.filter(item => {
          return item.menuCategory.nameEn === this.selectedCategory;
        });
      }
    },
    getOrderMealDetailsByDate() {
      this.reportList = [];
      this.loading = true;
      const dateTime = moment(`${this.date}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.$apollo
        .watchQuery({
          query: require("../api/getOrderMealDetailsByDate.graphql"),
          variables: { date: modifiedDate }
        })
        .refetch({ variables: { date: modifiedDate } })
        .then(data => {
          this.loading = false;
          this.reportGeneratedDate = "";
          if (data.data.getOrderMealDetailsByDate) {
            let resultList = new Array();
            //    let resultListOrderByCategory = new Array();

            this.meanInfos = data.data.getOrderMealDetailsByDate.meanInfos;
            let count = 1;
            if (data.data.getOrderMealDetailsByDate.orderMeals)
              data.data.getOrderMealDetailsByDate.orderMeals.forEach(order => {
                if (this.reportFilter == "ALL") {
                  let parentMeanInfo = order.varieties.map(x => x.meanInfo);
                  let item = {
                    isParent: true,
                    itemName: order.nameEn,
                    dislikedIngredients: [],
                    meanInfo: parentMeanInfo,
                    count: count,
                    menuCategory: order.menuCategory,
                    portions: order.portions
                  };

                  resultList.push(item);
                  count = count + 1;
                }
              });

            let groupByCategory = this.lodash.groupBy(
              data.data.getOrderMealDetailsByDate.orderMeals,
              x => x.menuCategory.menuCategoryId
            );

            let allCategories = data.data.getOrderMealDetailsByDate.orderMeals.map(
              x => x.menuCategory.menuCategoryId
            );
            count = 0;
            allCategories = this.lodash.uniq(allCategories);

            let allCategoryResponse = new Array();
            allCategories.forEach(mealWithCategory => {
              let allOrdes = groupByCategory[mealWithCategory];
              let allOrderList = new Array();

              if (this.reportFilter == "ALL") {
                allOrdes.forEach(order => {
                  let parentMeanInfo = order.varieties.map(x => x.meanInfo);
                  let orderVerities = new Array();
                  order.varieties.forEach(element => {
                    let subIitem = {
                      isParent: false,
                      itemName: order.nameEn,
                      dislikedIngredients: element.dislikedIngredients,
                      meanInfo: element.meanInfo,
                      orderNumber: element.orderNumber,
                      menuCategory: order.menuCategory
                    };
                    //
                    orderVerities.push(subIitem);
                  });
                  count = count + 1;

                  let item = {
                    isParent: true,
                    itemName: order.nameEn,
                    dislikedIngredients: [],
                    orderVerities: orderVerities,
                    meanInfo: parentMeanInfo,
                    count: count
                  };
                  allOrderList.push(item);
                });

                let itemCategory = {
                  categoryName: allOrdes[0].menuCategory.nameEn,
                  orders: allOrderList
                };
                allCategoryResponse.push(itemCategory);
              }
            });

            this.allCategoryResponse = allCategoryResponse;
            this.allCategoryResponse.map(item => {
              this.allCategories.push(item.categoryName);
            });

            this.reportList = resultList;
            this.filteredReportList = this.reportList;
            //   this.reportList = resultList;
            this.updateHeaders();

            if (data.data.getOrderMealDetailsByDate.generatedDate) {
              this.reportGeneratedDate =
                data.data.getOrderMealDetailsByDate.generatedDate;
            }
          }
        })
        .catch(error => {
          this.reportGeneratedDate = "";
          this.loading = false;
          console.log(error);
        });
    },

    updateHeaders() {
      let header = [
        { text: "Sl.No", value: "slNo", width: 80 },
        { text: "Item Name ", value: "itemName", width: 500 }
      ];
      let allPortions = this.reportList.map(x => x.portions);

      allPortions = this.lodash.flatten(allPortions, true);
      allPortions = this.lodash.uniqBy(allPortions, x => x.name);

      if (allPortions)
        allPortions.forEach(porion => {
          let item = {
            text: porion.name,
            value: porion.name
          };
          header.push(item);
        });
      let total = { text: "Total", value: "total" };
      header.push(total);
      this.headers = header;
    },

    getTotalValue(item) {
      let total = 0;
      item.portions.forEach(element => {
        total = total + element.count;
      });
      return total;
    },
    convertToFactorOfHundered(item, count) {
      const bigVal = item.carbs > item.protein ? item.carbs : item.protein;
      switch (bigVal) {
        case 200:
          return count * 2;
        case 150:
          return count * 1.5;
        default:
          return count;
      }
    },
    getCalrieMealCount(header, item) {
      let portions = item.portions.find(x => x.name == header.value);
      if (portions) return portions.count;
      return "0";
    },
    getMeanInfo(type, meanInfoList) {
      meanInfoList = this.lodash.flatten(meanInfoList, true);
      meanInfoList = meanInfoList.filter(
        x => x.protein == type.protein && x.carbs == type.carbs
      );
      if (meanInfoList.length > 0) {
        if (meanInfoList.length > 1) {
          let count = 0;
          meanInfoList.forEach(element => {
            count = count + element.count;
          });
          return count;
        }
        return meanInfoList[0].count;
      }
      return 0;
    },

    generateReport() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../../delivery/api/generateDeliveryReport.graphql"),
          variables: {
            date: this.date,
            isInstantView: false
          }
        })
        .then(data => {
          this.btnloading = false;
          if (data.data.generateDeliveryReport) {
            if (data.data.generateDeliveryReport.errors) {
              this.errorLog = data.data.generateDeliveryReport.errors;
              this.showErrorDialog = true;
            }
          }
          this.searchDelivery();
        })
        .catch(error => {
          this.searchDelivery();
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    isAllowToGenerateReport() {
      if (this.btnloading == false) {
        this.btnloading = true;
        this.$apollo
          .query({
            query: require("../../delivery/api/isAllowToGenerateReport.graphql"),
            variables: {
              date: this.date
            }
          })
          .then(data => {
            this.btnloading = false;
            if (data.data.isAllowToGenerateReport) {
              this.generateReport();
            } else {
              this.text = `Please generate report upto ${this.date}`;
              this.snackbar = true;
            }
          })
          .catch(error => {
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },
    getDislikes(dislikes) {
      let dislikeNames = new Array();
      dislikes.forEach(element => {
        dislikeNames.push(`No ${element.nameEn}`);
      });
      let joined = dislikeNames.join(",");
      let response = "";
      if (dislikes.length > 0) {
        response = `( ${joined} )`;
      }
      return response;
    },
    searchDelivery() {
      this.getOrderMealDetailsByDate();
    }
  },

  computed: {
    printMealData() {
      if (this.selectedCategory === null) {
        return this.allCategoryResponse;
      } else {
        return this.allCategoryResponse.find(
          item => item.categoryName === this.selectedCategory
        );
      }
    },
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  }
});
</script>

<style scoped>
table.blueTable {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
  padding: 3px 2px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #d0e4f5;
}
table.blueTable thead {
  background: #1c6ea4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: -webkit-linear-gradient(
    top,
    #5592bb 0%,
    #327cad 66%,
    #1c6ea4 100%
  );
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}
table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border-left: 2px solid #d0e4f5;
}
table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(
    top,
    #dcebf7 0%,
    #d4e6f6 66%,
    #d0e4f5 100%
  );
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}
table.blueTable tfoot td {
  font-size: 14px;
}
table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
</style>
