var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-row',[_c('span',{staticClass:"black--text text-h5 font-weight-bold ms-3 mb-6 mt-3"},[_vm._v(" Permission ")]),_c('v-spacer'),_c('span',[_c('router-link',{staticClass:"mx-auto",staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'AddPermission'
          }}},[_c('v-btn',{staticClass:"mt-3 text-capitalize black--text me-3",attrs:{"color":"secondary"}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Add ")],1)],1)],1),_c('AuditTrialLog',{staticClass:"me-3  mt-3",attrs:{"auditType":"ROLES_LOG"}})],1),[_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.usersRole},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.usersRole.indexOf(item) + 1)+" ")]}},{key:"item.userType",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.role.toUpperCase())+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[(_vm.hasRole(_vm.AdminUserPermissions.PERMISSION_DELETE))?_c('DeletePermission',{attrs:{"permission":item},on:{"updatePermission":_vm.updatePermission}}):_vm._e(),_c('router-link',{staticClass:"mx-5",staticStyle:{"text-decoration":"none"},attrs:{"to":{
                name: 'UpdatePermission',
                params: { id: item._id },
                query: { id: item._id }
              }}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-square-edit-outline")])],1)],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }