var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"mt-sm-12",attrs:{"justify-end":"","wrap":""}},[_c('v-flex',{staticClass:"mt-8 mt-sm-0",attrs:{"xs12":"","sm6":"","lg2":""}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Customer Log")])]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":25,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.customer != null)?_c('span',[_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id },
          }}},[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")])],1):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"MMM DD YYYY"))+" ")]}},{key:"item.height",fn:function(ref){
          var item = ref.item;
return [(item.height)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.height).toFixed(3))+" ")]):_vm._e()]}},{key:"item.weight",fn:function(ref){
          var item = ref.item;
return [(item.weight)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.weight).toFixed(3))+" ")]):_vm._e()]}},{key:"item.fat",fn:function(ref){
          var item = ref.item;
return [(item.fat)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.fat).toFixed(3))+" ")]):_vm._e()]}},{key:"item.muscle",fn:function(ref){
          var item = ref.item;
return [(item.muscle)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.muscle).toFixed(3))+" ")]):_vm._e()]}},{key:"item.targetCalories",fn:function(ref){
          var item = ref.item;
return [(item.targetCalories)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.targetCalories).toFixed(3))+" ")]):_vm._e()]}},{key:"item.appointmentDate",fn:function(ref){
          var item = ref.item;
return [(item.appointmentDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"MMM DD YYYY hh:mm:A"))+" ")]):_vm._e()]}},{key:"item.workoutTime",fn:function(ref){
          var item = ref.item;
return [(item.workoutTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.workoutTime)),"hh:mm:A"))+" ")]):_vm._e()]}},{key:"item.sleepingTime",fn:function(ref){
          var item = ref.item;
return [(item.sleepingTime)?_c('span',[_vm._v(" "+_vm._s(_vm.sleepingTime)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-row',[_c('UpdateAppointment',{attrs:{"appointment":item}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }