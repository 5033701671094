<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn
          small
          class="mx-2 my-1 white--text text-capitalize rounded-lg"
          v-on="on"
          tile
          @click="showDialog()"
          width="70"
          color="secondary_one"
          >Duplicate</v-btn
        >
      </template>
      <v-card class="ma-0 pa-4" color="pop_bg">
        <v-card-text>
          <v-row class="mt-6 mb-2">
            <span class="black--text text-h6 ms-2 ">
              Duplicate Package</span
            >
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider class="mt-5"></v-divider>
          <v-form v-model="valid" ref="form">
            <v-layout wrap class="mb-5">
              <h4 class="mt-4 font-weight-bold text-body-1 black--text">
                Change Name
              </h4>
              <v-spacer></v-spacer>
            </v-layout>
            <v-layout wrap class="mt-6 mt-sm-0">
              <v-flex xs12 sm3 md3>
                <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Plan Name</h4>
              </v-flex>
              <v-flex xs12 sm9 md9>
                <v-text-field
                  v-model="titleEn"
                  placeholder="Plan Name"
                  :rules="[requiredValidator('plan name')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm3 md3>
                <h4 class=" mb-2 mb-sm-0 text-body-2 black--text">Plan Name Arabic</h4>
              </v-flex>
              <v-flex xs12 sm9 md9>
                <v-text-field
                  v-model="titleAr"
                  placeholder="Plan Name Arabic"
                  :rules="[requiredValidator('plan name in arabic')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout justify-end class="mt-8">
              <v-btn
                color="secondary"
                class="primary--text text-capitalize"
                :width="$vuetify.breakpoint.xs ? 200 : 300"
                @click="duplicatePackage()"
                :loading="btnloading"
                :disabled="!valid"
                >Duplicate Package</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" timeout="3000" right top color="success">{{
      snackText
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    package_id: {
      type: String
    }
  },
  components: {},

  data() {
    return {
      snack: false,
      snackText: "",
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      package: null,
      // form data
      titleEn: null,
      titleAr: null
    };
  },
  created() {},

  methods: {
    showDialog() {
      this.getPackageById();
    },
    getPackageById() {
      this.$apollo
        .watchQuery({
          query: require("../api/getPackageById.graphql"),
          variables: {
            packageId: this.package_id
          }
        })
        .refetch({
          variables: {
            packageId: this.package_id
          }
        })
        .then(data => {
          this.package = data.data.getPackageById;
        });
      //
    },
    //utility methods
    showSnack(text) {
      this.snack = true;
      this.snackText = text;
    },
    close() {
      this.show_dialog_box = false;
    },
    //api methods
    duplicatePackage() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/duplicatePackages.graphql"),
          variables: {
            id: this.package._id,
            titleEn: this.titleEn,
            titleAr: this.titleAr
          }
        })
        .then(() => {
          this.btnloading = false;
          this.$root.$emit("updatePackage");
          this.close();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.showSnack("something went wrong", true, error);
        });
    }
  }
};
</script>
