<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="success">mdi-cash-100</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text"
          >Do you really receive the payment?</v-card-text
        >
        <v-form @submit="expireSalesOrder()" v-model="valid" ref="form">
          <v-layout>
            <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
              <h4 class="text-body-2 black--text">Note</h4>
            </v-flex>
            <v-flex xs12 sm8 md9 lg8>
              <v-text-field
                solo
                dense
                v-model="note"
                :rules="[requiredValidator('note')]"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-card-text v-if="error" class="error--text font-weight-bold">{{
            error
          }}</v-card-text>
          <v-card-actions justify="center">
            <v-row justify="center">
              <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
              <v-btn
                color=" mx-2 success"
                depressed
                :loading="button_loading"
                @click="
                  button_loading = true;
                  confrimOfflinePayment(salesOrder);
                "
                :disabled="!valid"
                >Yes</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { DATE_FORMAT } from "../../../utils/utils";
import { requiredValidator } from "../../../core/methods/validations";
import moment from "moment";
export default {
  props: {
    salesOrder: {
      required: true,
    },
  },
  data() {
    return {
      menu2: false,
      noOfDays: "",
      expiredFrom: "NOW",
      expDate: "",
      refundAmount: "",
      refundTax: "",
      total: "",
      requiredValidator,
      valid: true,
      isPermanentlyDelete: false,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      snackbar: false,
      snackbarColor: "",
      text: "",
      minDate: moment().format("YYYY-MM-DD"),
      endDateLoading: false,
      reason: "",
    };
  },

  methods: {
    confrimOfflinePayment() {
      let refundAmount = parseFloat(this.refundAmount);

      this.$apollo
        .mutate({
          mutation: require("./api/updateSalesOrderToExpire.graphql"),
          variables: {
            input: {
              customerId: this.$route.query.customerId,
              subscribedPackageId: this.salesOrder._id,
              isPermanentlyDelete: this.isPermanentlyDelete,
              net_price: refundAmount,
              basePrice: refundAmount,
              endDate: moment(this.expDate).format("MM-DD-YYYY"),
              noOfDays: parseInt(this.noOfDays),
              is_expire: this.expiredFrom == "NOW" ? true : false,
              reason: this.reason,
            },
          },
        })
        .then(() => {
          this.$root.$emit("updateGetCustomerById");
          this.button_loading = false;
          this.dialog = false;
          this.text = "Sales order updated as Expired";
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
  computed: {
    formatDate() {
      return this.expDate ? moment(this.expDate).format(DATE_FORMAT) : "";
    },
  },
};
</script>
