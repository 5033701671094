var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":10,"options":_vm.options,"loading":_vm.loading,"mobile-breakpoint":0,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [10, 25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('span',[(item.customer.user)?_c('span',[_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id }
          }}},[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")])],1):_vm._e()]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"MMM DD YYYY"))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
          var item = ref.item;
return [(item.appointmentDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"MMM DD YYYY"))+" ")]):_vm._e()]}},{key:"item.appointmentTime",fn:function(ref){
          var item = ref.item;
return [(item.appointmentTime)?_c('span',[_vm._v(" "+_vm._s(item.appointmentTime)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.type == 'PENDING')?_c('div',[(item.payment_status == 'SUCCESS')?_c('div',[_c('UpdateDietitianAppointment',{attrs:{"dietitianappointment":item}})],1):_vm._e(),(item.payment_status == 'PENDING')?_c('div',[_c('PaymentStatusCheck',{attrs:{"appointment":item}})],1):_vm._e()]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }