<template>
  <v-container fluid class="pa-4 pa-sm-8">
    <v-progress-linear v-if="loading" color="primary"></v-progress-linear>
    <div v-if="manager.user">
      <v-row justify="space-between" class="ps-4 pe-2 py-4" align="center">
            <h2 class="black--text mb-n2">
              {{ manager.user.firstnameEn }}
              {{ manager.user.lastnameEn }}
            </h2>
        <ManagerPayment :manager="manager" />
      </v-row>

      

      <v-layout wrap class="mt-8 mb-6">
        <v-tabs
          v-model="tabs"
          :mobile-breakpoint="0"
          background-color="bg"
          slider-size="3"
          slider-color="black"
        >
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
          >
            Payments
          </v-tab>

          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
          >
            Plans Taken
          </v-tab>
        </v-tabs>
      </v-layout>
      <!-- {{ referralTransactions }} -->
      <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0">
        <v-tab-item>
          <CelebrityPaymentHistoryTable
            :referralTransactions="referralTransactions"
            :loading="transactionListloading"
          />
        </v-tab-item>
        <v-tab-item>
          <CelebrityPlanTakenTable
            :referralTransactions="referralTransactions"
            :loading="transactionListloading"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import CelebrityPaymentHistoryTable from "../../celebrity/components/CelebrityPaymentHistoryTable.vue";
import CelebrityPlanTakenTable from "../../celebrity/components/CelebrityPlanTakenTable.vue";
import ManagerPayment from "../components/ManagerPayment.vue";
export default {
  components: {
    CelebrityPaymentHistoryTable,
    CelebrityPlanTakenTable,
    ManagerPayment
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.CELEBRITY_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getManagerById();
  },
  mounted() {
    this.$root.$on("updateGetManagerById", () => {
      this.getManagerById();
    });
  },
  watch: {
    "$route.query.managerId": {
      handler() {
        this.getManagerById();
      }
    }
  },
  methods: {
    getAllReferralTransactionsByUser() {
      this.transactionListloading = true;
      this.$apollo
        .watchQuery({
          query: require("../../celebrity/api/getAllReferralTransactionsByUser.graphql"),
          variables: { id: this.manager.user._id }
        })
        .refetch({})
        .then(data => {
          this.referralTransactions = [];
          this.transactionListloading = false;
          if (data.data.getAllReferralTransactionsByUser) {
            this.referralTransactions =
              data.data.getAllReferralTransactionsByUser.userReferrals;
            this.referralTransactions = this.referralTransactions.map(
              (items, index) => ({
                ...items,
                index: index + 1
              })
            );
          }
        })
        .catch(error => {
          this.transactionListloading = false;
          console.log(error);
        });
    },
    getManagerById() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getManagerById.graphql"),
          variables: {
            managerId: this.$route.query.managerId
          }
        })
        .refetch({
          variables: {
            managerId: this.$route.query.managerId
          }
        })
        .then(data => {
          this.loading = false;

          if (data.data.getManagerById) {
            this.manager = data.data.getManagerById;
            this.getAllReferralTransactionsByUser();
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      isDisable: true,
      imageUrl: process.env.VUE_APP_BASE_URL,
      manager: "",
      snackbar: false,
      text: "",
      tabs: null,
      btnloading: false,
      loading: true,
      transactionListloading: true,
      referralTransactions: []
    };
  }
};
</script>
