<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row class="mt-3 mt-sm-0">
        <h2 class="black--text text-h5 font-weight-bold ms-3">
          Contact Method
        </h2>
        <!-- <v-spacer></v-spacer> -->
        <v-layout justify-end class="me-3">
          <ContactMethodForm
            v-if="hasRole(AdminUserPermissions.CONTACT_ADD)"
          ></ContactMethodForm>
        </v-layout>
      </v-row>

      <!-- <v-system-bar height="1" class="mt-2"></v-system-bar> -->
      <!-- {{ menuList }} -->
      <ContactMethodTable
        :methodList="methodList"
        :totalItemsCount="totalItemsCount"
        :loading="loading"
      ></ContactMethodTable>
    </v-container>
  </div>
</template>

<script>
import ContactMethodForm from "../components/ContactMethodForm";
import ContactMethodTable from "../components/ContactMethodTable";
export default {
  components: {
    ContactMethodForm,
    ContactMethodTable
  },
  mounted() {
    this.$root.$on("updateMethods", () => {
      this.loading = true;
      this.$apollo.queries.getAllContactMethods.refetch({
        variables: this.variables
      });
    });
  },
  data() {
    return {
      loading: false,
      totalItemsCount: 25,
      methodList: [],
      variables: {
        limit: 25,
        skip: 0
      }
    };
  },
  apollo: {
    getAllContactMethods() {
      return {
        query: require("../api/getAllContactMethods.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.methodList = data.getAllContactMethods.methods;
          this.loading = loading;
          this.totalItemsCount = data.getAllContactMethods.totalCount;
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.CONTACT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
