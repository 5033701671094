<template>
  <v-container class="pt-8 px-0" fluid>
    <h3 class="black--text text-body-1 text-sm-h6 font-weight-bold">
      Adjust Stock Details
    </h3>
    <v-form @submit="adjustStock()" v-model="valid" ref="form" class="mt-5">
      <!-- {{ ingredient }} -->
      <v-layout wrap class="mt-4 mt-sm-0">
        <v-flex xs12 sm4 md2>
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Reference No.
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-text-field
            label="Reference Number"
            v-model="refNo"
            solo
            dense
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4 md2 class="ms-md-16">
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Item ID
          </h4>
        </v-flex>
        <!-- {{ ingredient }} -->
        <v-flex xs12 sm6 md3>
          <!-- <v-text-field label="Item ID" outlined dense></v-text-field> -->
          <v-autocomplete
            label="Search Item Id"
            v-model="ingredient"
            solo
            dense
            attach
            :items="allIngredients"
            :rules="[requiredValidator('item id')]"
            @change="getIngredientCurrentStock"
            item-text="itemId"
            item-value="_id"
            clearable
            deletable-chips
            :search-input.sync="ingredientsIdSearchKey"
            :loading="itemsLoading"
            return-object
          >
            <template slot="no-data">
              <v-flex xs12 md12 class="mt-2 mb-2">
                <h5 class="font-weight-medium ml-4">Search ingredient id...</h5>
              </v-flex>
            </template>
            <template slot="item" slot-scope="data">{{
              data.item.itemId
            }}</template>
            <!-- <template v-slot:append>
              <v-icon
                v-if="search.length > 0"
                large
                class="mt-n1 me-n2"
                @click="clearSearch()"
                >mdi-close</v-icon
              >
              <v-icon
                v-if="search.length == 0"
                large
                class="mt-n1 me-n2"
                @click="runSearch()"
                >mdi-magnify</v-icon
              >
            </template> -->
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm4 md2>
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Date
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate"
                placeholder="Date"
                solo
                readonly
                append-icon="fa-calendar-alt black--text"
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              color="secondary"
              v-model="adjustedDate"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12 sm4 md2 class="ms-md-16">
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Item Name
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3 lg4>
          <!-- <v-text-field label="Item Name" outlined dense></v-text-field> -->
          <v-autocomplete
            v-model="ingredient"
            :items="allIngredients"
            item-text="nameEn"
            item-value="_id"
            solo
            chips
            dense
            attach
            small-chips
            label="Search Item Name"
            :rules="[requiredValidator('item name')]"
            @change="getIngredientCurrentStock"
            clearable
            deletable-chips
            :search-input.sync="ingredientsNameSearchKey"
            :loading="itemsLoading"
            return-object
          >
            <template slot="no-data">
              <v-flex xs12 md12 class="mt-2 mb-2">
                <h5 class="font-weight-medium ml-4">
                  Search ingredient name...
                </h5>
              </v-flex>
            </template>
            <template slot="item" slot-scope="data">{{
              data.item.nameEn
            }}</template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm4 md2>
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Available Quantity
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-text-field
            v-model="currentStock"
            label="Qty"
            solo
            dense
            disabled
            type="number"
          >
            <template v-slot:append>
              <h4 class="mt-1 black--text">{{ priceUnit() }}</h4>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm4 md2 class="ms-md-16">
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Item Description
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3 lg4>
          <v-textarea
            v-model="description"
            solo
            name=""
            label="Description"
            value=""
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm4 md2>
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            New Quantity
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-text-field
            label="Qty"
            type="number"
            :rules="[requiredValidator('new quantity')]"
            @change="getAdjustedQuantity"
            v-model="updatedQuantity"
            @keypress="onlyForCurrency"
            min="0"
            solo
            dense
          >
            <template v-slot:append>
              <h4 class="mt-1 black--text">{{ priceUnit() }}</h4>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm4 md2 class="ms-md-16">
          <h4 class="text-start text-body-2 text-sm-body-1 black--text">
            Quantity Adjusted
          </h4>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-text-field
            label="Qty"
            solo
            dense
            v-model="adjustedQuantityModel"
            disabled
            type="number"
          >
            <template v-slot:append>
              <h4 class="mt-1 grey--text">{{ priceUnit() }}</h4> 
            </template>
          </v-text-field>
        </v-flex>
      </v-layout>
      <v-layout justify-center class="mt-sm-6">
        <v-btn
          @click="adjustStock"
          color="secondary"
          class="black--text text-capitalize"
          :width="$vuetify.breakpoint.smAndUp ? 150 : 120"
          :loading="btnloading"
          :disabled="!valid"
          v-if="hasRole(AdminUserPermissions.ADJUST_STOCK)"
          >Adjust</v-btn
        >
      </v-layout>
    </v-form>
    <!-- <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar> -->
    <!-- <v-system-bar height="1"></v-system-bar> -->
    <h3 class="black--text text-body-1 text-sm-h6 font-weight-bold mt-8 mb-5">
      Adjusted Stock
    </h3>
    <app-adjust-stock-table
      :adjustStockList="stockList"
      :unitList="unitList"
    ></app-adjust-stock-table>
    <!-- {{ stockList }} -->
  </v-container>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import AdjustStockTable from "../components/AdjustStockTable.vue";
import { requiredValidator } from "../../core/methods/validations";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: {
    "app-adjust-stock-table": AdjustStockTable
  },
  data: () => ({
    requiredValidator: requiredValidator,
    adjustedDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    menu: false,
    loading: true,
    stockList: [],
    totalItemsCount: 25,
    variables: {
      limit: 25,
      skip: 0
    },
    awaitingSearchId: false,
    awaitingSearchName: false,
    btnloading: false,
    ingredientsNameSearchKey: "",
    ingredientsIdSearchKey: "",
    ingredient: "",
    snackColor: "success",
    snackbar: false,
    text: "",
    itemsLoading: false,
    allIngredients: [],
    showDialog: false,
    search: "",
    searchKey: "",
    refNo: "",
    description: "",
    created_at: "",
    created_by: "",
    availableQuantity: "",
    updatedQuantity: "",
    adjustedQuantity: "",
    adjustedQuantityModel: "",
    valid: true,
    snackbarColor: "",
    currentStock: "",
    stockData: "",
    unitList: []
  }),
  watch: {
    // ingredient: {
    //   handler() {

    //   },
    // },
    ingredientsIdSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length < 1) return;
      if (!this.awaitingSearchId) {
        setTimeout(() => {
          this.awaitingSearchId = false;
          this.itemsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val
              }
            })
            .then(data => {
              this.allIngredients = data.data.getAllIngredients.ingredients;
              this.itemsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchId = true;
    },
    ingredientsNameSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length <= 0) return;
      if (!this.awaitingSearchName) {
        setTimeout(() => {
          this.awaitingSearchName = false;
          this.itemsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val
              }
            })
            .then(data => {
              this.allIngredients = data.data.getAllIngredients.ingredients;
              this.itemsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchName = true;
    }
  },
  apollo: {
    getAllIngredients() {
      return {
        query: require("../api/getAllIngredients.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.stockList = data.getAllIngredients.ingredients;
          this.totalItemsCount = data.getAllIngredients.totalCount;
          // console.log(data.getAllIngredients);
        }
      };
    }
  },
  computed: {
    formatDate() {
      return this.adjustedDate
        ? moment(this.adjustedDate).format(DATE_FORMAT)
        : "";
    }
  },
  methods: {
    onlyForCurrency($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.updatedQuantity != null &&
        this.updatedQuantity.indexOf(".") > -1 &&
        this.updatedQuantity.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    clearSearch() {
      this.search = "";
      this.searchKey = "";
    },
    runSearch() {
      this.searchKey = this.search;
    },

    adjustStock() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/adjustStock.graphql"),
          variables: {
            ingredient: this.ingredient._id,
            refNo: this.refNo,
            adjustedDate: this.adjustedDate,
            description: this.description,
            availableQuantity: parseFloat(this.ingredient.availableQuantity),
            updatedQuantity: parseFloat(this.updatedQuantity),
            adjustedQuantity: this.adjustedQuantity
          },
          update: (store, { data: { adjustStock } }) => {
            try {
              const query = {
                query: require("../api/getAllStockAdjust.graphql"),
                variables: {
                  limit: 25,
                  skip: 0
                }
              };
              let allDate = store.readQuery(query);
              allDate.getAllStockAdjust.StockAdjust.unshift(adjustStock); //push to top
              store.writeQuery({
                ...query,
                data: allDate
              });
              this.text = "Stock Adjusted";
              this.snackbarColor = "success";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
          }
        })
        .then(() => {
          this.dialog = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            this.btnloading = false;
            this.text = "Some error";
            this.snackbarColor = "error";
            this.snackbar = true;
          }

          this.btnloading = false;
          console.log(error);
        });
    },

    getAdjustedQuantity() {
      this.adjustedQuantity = this.currentStock - this.updatedQuantity;
      this.adjustedQuantityModel = this.updatedQuantity - this.currentStock;
    },
    getIngredientCurrentStock(item) {
      if (item) {
        this.$apollo
          .watchQuery({
            query: require("../api/getIngredientCurrentStock.graphql"),
            variables: {
              id: item._id
            }
          })
          .refetch({
            variables: {
              id: item._id
            }
          })
          .then(data => {
            this.stockData = data.data.getIngredientCurrentStock.stock;
            if (this.stockData) {
              this.currentStock = this.stockData.currentStock / 1000;
              if (data.data.getIngredientCurrentStock.quantityUnit == "PIECE") {
                this.currentStock = this.stockData.currentStock;
              }
            } else {
              this.currentStock = 0;
            }
          });
      } else {
        this.currentStock = 0;
      }
    },
    getAllUnits() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllUnits.graphql"),
          variables: this.variables
        })
        .refetch({})
        .then(data => {
          if (data.data) {
            this.unitList = data.data.getAllUnits.units;
          }
        });
    },
    priceUnit() {
      let id=null
      if(this.ingredient){
        id=this.ingredient.quantityUnit 
      }
      let unit = "";
      if (id) {
        let data = this.unitList.find(x => x._id == id);
        if (data.conversionUnit == "ML") unit = "L";
        else if (data.conversionUnit == "GRAMS") unit = "KG";
        else if (data.conversionUnit == "PIECE") unit = "PCS";
      }
      return unit;
    },
    itemQuantity() {
      let quantity = "";
      if (this.ingredient) {
        if (this.ingredient.quantityUnit === "GRAMS") quantity = "KG";
        else if (this.ingredient.quantityUnit === "ML") quantity = "L";
      }
      return quantity;
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.ADJUST_STOCK_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllUnits();
  }
};
</script>
