var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[(!_vm.totalItemsCount)?_c('div',[_vm._v("No sales Order...")]):_c('div',[_c('h2',{staticClass:"black--text text-h6 font-weight-bold"},[_vm._v(_vm._s(_vm.pageHeading))]),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.salesOrderList,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.package.titleEn)+" ")]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.payment_status == 'SUCCESS'
              ? 'green--text'
              : item.payment_status == 'PENDING'
              ? 'orange--text'
              : item.payment_status == 'PAY_LATER'
              ? 'black--text'
              : item.payment_status == 'UPCOMING'
              ? 'blue--text'
              : item.payment_status == 'EXPIRED'
              ? 'red--text'
              : ''},[_vm._v(" "+_vm._s(item.payment_status))])]}},{key:"item.customer",fn:function(ref){
              var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item.customer },
            query: { customerId: item.customer }
          }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.user.lastnameEn))+" ")])]}},{key:"item.endDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.end_date)),"MMM DD YYYY")))]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.created_At)),"MMM DD YYYY")))]}},{key:"item.startDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.start_date)),"MMM DD YYYY")))]}},{key:"item.modified",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:_vm.getDietitianName(item.calorie_range.modified_by) == 'Auto Update'
              ? 'black--text'
              : 'green--text'},[_vm._v(" "+_vm._s(_vm.getDietitianName(item.calorie_range.modified_by)))])]}},{key:"item.availableNumOfDays",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(item.package.availableChoices.length > 0 ? item.package.availableChoices[0].availableNumOfDays : ""))]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('SetCalorieValue',{attrs:{"salesOrder":item,"forUpdation":false},on:{"updateSalesOrderStatus":_vm.updateSalesOrderStatus}})]}},{key:"item.update",fn:function(ref){
              var item = ref.item;
return [_c('SetCalorieValue',{attrs:{"salesOrder":item,"forUpdation":true},on:{"updateSalesOrderStatus":_vm.updateSalesOrderStatus}})]}},{key:"item.paymentId",fn:function(ref){
              var item = ref.item;
return [_c('RegenerateSalesOrderPayment',{attrs:{"salesOrder":item}})]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":"3000","right":"","top":"","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.text))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }