<template>
  <div>
    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="availableChoices" :items-per-page="5">
      <template v-slot:[`item.actions`]="props">
        <v-row>
          <EditPackageChoice :choiceInfo="props.item" :allCategories="allCategories" />
          <v-icon color="red" @click="deleteItem(props.item)">
            mdi-delete
          </v-icon>
        </v-row>
      </template>

      <template v-slot:item.varients="{ item }">
        <div v-for="(varient, index) in item.mealChoice" :key="index">
          <PackageChoiceTableDetails :varient="varient" />
        </div>
      </template>
      <template v-slot:item.availableDays="{ item }">
        <span v-for="(day, idx) in item.availableDays" :key="idx">
          <span v-if="day.day">
            {{ day.day }}
          </span>
          <span v-else>
            {{ day }}
          </span>
        </span>
      </template>
      <template v-slot:item.order="{ item }">
        <v-row>
          <v-col class="mt-4" cols="10">
            <v-text-field v-model="item.showOrder" min="0" @change="onOrderChange" outline solo type="number" dense
              label="order  " id="id">
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
    </v-snackbar>
  </div>
</template>
<script>
import PackageChoiceTableDetails from "./PackageChoiceTableDetails.vue";
import EditPackageChoice from "./EditPackageChoice.vue";
import { requiredValidator, positive } from "../../core/methods/validations";
export default {
  components: { EditPackageChoice, PackageChoiceTableDetails },
  props: {
    allCategories: {
      default: []
    },
    availableChoices: {
      required: true
    }
  },
  data() {
    return {
      snack: false,
      requiredValidator,
      positive,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [
        { text: "Order", value: "order", width: 150 },
        { text: "Name English", value: "choiceNameEn", width: 150 },
        { text: "Name Arabic", value: "choiceNameAr", width: 150 },
        { text: "Days", value: "availableNumOfDays", width: 90 },
        // { text: "Price", value: "price" },
        { text: "Days", value: "availableDays", width: 160 },
        { text: "Variants", value: "varients", width: 160 },
        { text: "Actions", value: "actions", sortable: false, width: 90 }
      ]
    };
  },
  methods: {
    onOrderChange() {
      if (this.availableChoices) {
        this.availableChoices.forEach(item => {
          if (!item.showOrder) {
            item.showOrder = 0;
          }
        });
        this.availableChoices.sort((a, b) => a.showOrder - b.showOrder);
      }
    },
    deleteItem(choice) {
      // console.log(item);
      const index = this.availableChoices.findIndex(
        item => item.choiceNameEn === choice.choiceNameEn
      );
      this.availableChoices.splice(index, 1);
      // console.log(index);
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    }
  }
};
</script>
