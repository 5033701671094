<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" class="white--text text-capitalize" color="primary">
          + Add Package
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg" min-height="450">
        <v-card-title>
          <span class="text-h6 black--text">Assign Package</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" v-model="valid" ref="form">
          <v-card-text>
            <v-container>
              <div>
                <v-layout wrap>
                  <!-- {{ selectPackage }} -->
                  <v-flex xs12 sm3 md2>
                    <h4 class="text-body-2 black--text">Plan</h4>
                  </v-flex>
                  <v-flex xs12 sm7 md8>
                    <v-select @change="choiceList = []" v-model="selectPackage" :items="packagesList" solo dense
                      item-value="_id" :rules="[requiredValidator('package')]" item-text="titleEn" chips label="Plan"
                      return-object></v-select>
                  </v-flex>
                </v-layout>

                <v-layout v-if="selectPackage && selectPackage.availableChoices" wrap align-center>
                  <v-flex xs12 sm3 md2>
                    <h4 class="black--text text-body-2">Is Active?</h4>
                  </v-flex>
                  <v-flex xs12 sm7 md8>
                    <v-checkbox v-model="status"></v-checkbox>
                  </v-flex>
                </v-layout>

                <v-form class=" my-10" v-model="frmchoice" ref="frmchoice">

                  <v-layout v-if="selectPackage && selectPackage.availableChoices" wrap align-center>
                    <v-flex xs12 sm3 md2>
                      <h4 class="black--text text-body-2">Plan Choices</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md6 class="mr-4">
                      <v-select v-model="packageChoice.choice" :items="getAvailableChoices()" item-value="_id"
                        hide-details dense solo item-text="choiceNameEn" chips deletable-chips return-object
                        label="Choices">
                        <template slot="item" slot-scope="data"> {{ data.item.choiceNameEn }} -
                          ({{ data.item.mealChoice[0].price }}KD)
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs8 md2 class="mr-14">
                      <v-btn class="black--text text-capitalize" color="secondary" width="120" @click="addChoice"
                        :disabled="!frmchoice">
                        Add
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout>
                  <v-flex xs12 sm3 md2>
                    <h4 class="black--text text-body-2">Is Active?</h4>
                  </v-flex>
                  <v-flex xs12 sm7 md8>
                    <v-checkbox v-model="packageChoice.choiceStatus"></v-checkbox>
                  </v-flex>
                </v-layout> -->
                  <v-spacer></v-spacer>

                </v-form>
                <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                  {{ snackText }}
                </v-snackbar>

                <v-layout>
                  <v-flex>
                    <v-data-table v-if="choiceList.length > 0" :mobile-breakpoint="0" hide-default-footer
                      :items-per-page="-1" :items="choiceList" :headers="headers" class="elevation-1 mt-6 mb-5">
                      <template v-slot:item.choices="{ item }">
                        {{ item.choice.choiceNameEn }}
                      </template>
                      <template v-slot:item.choiceStatus="{ item }">

                        <v-icon v-show="item.choiceStatus" color="primary">mdi-checkbox-marked</v-icon>
                        <v-icon v-show="!item.choiceStatus" color="grey">mdi-checkbox-blank-outline</v-icon>

                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n6">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="black--text text-capitalize" @click="addNewPackage" :disabled="!valid">Add
              package</v-btn>
          </v-card-actions>
          <!-- {{ isPercentage }} -->
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  data() {
    return {
      requiredValidator,
      dialog: false,
      choices: [],
      valid: false,
      selectPackage: "",
      packagesList: [],
      status: false,
      // choiceStatus:false,
      packageChoice: {
        choice: "",
        choiceStatus: ""
      },
      choiceList: [],
      snack: false,
      snackColor: "",
      snackText: "",
      frmchoice: false,
      headers: [
        {
          text: "Choice",
          value: "choices"
        },
        {
          text: "Status",
          value: "choiceStatus"
        },

      ]
    };
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/menu-packages/api/getAllPackagesAllInfo.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.packagesList = data.getAllPackages.packages.filter(
            x => x.active
          );
        }
      };
    }
  },
  methods: {
    addNewPackage() {
      debugger;
      let packageAssign = {
        status: this.status,
        plan: this.selectPackage,
        choices: this.choiceList
      };
      this.$emit("addNewPackage", packageAssign);
      this.choiceList = []
      this.$refs.form.reset();
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
    getAvailableChoices() {
      let choices = this.selectPackage.availableChoices;
      let availableChoices = new Array();
      if (choices) {
        console.log(this.choiceList)
        choices.forEach(element => {
          if (this.choiceList.filter(x => x.choice._id == element._id).length == 0) {
            availableChoices.push(element)
          }
        });
      }

      return availableChoices;
    },
    addChoice() {
      debugger;
      let isValid = true;
      if (this.packageChoice.choice) {

        let choiceAssign = {
          choice: this.packageChoice.choice,
          choiceStatus: true
        };
        const index = this.choiceList.findIndex(
          cho => cho.choice._id === choiceAssign.choice._id
        );
        if (index === -1) {
          this.choiceList.push(choiceAssign);
        } else {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Choice already assigned.";
          isValid = false;
        }
        if (isValid) {
          if (this.$refs.frmchoice) {
            this.$refs.frmchoice.reset();
          }
        }
      }
      else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please select choice.";
        isValid = false;
      }
    }
  }
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
