<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h2 class="black--text text-h5 font-weight-bold">Sales</h2>
    <v-row>
      <v-col cols="5" sm="3" md="2" class="mt-3">
        <v-select :items="monthList" :label="today" v-model="monthFilter" @change="getSalesReport()" item-value="items"
          dense attach solo></v-select>
      </v-col>
      <v-col cols="5" sm="3" md="2" class="mt-3">
        <v-select :items="yearList" label="year" v-model="yearFilter" @change="getSalesReport()" dense attach solo>
        </v-select>
      </v-col>
      <v-col class="text-end mt-3">
        <ExportSalesReport :salesReport="salesReport" :headers="headers" :monthFilter="monthFilter" />
      </v-col>
    </v-row>
    <v-data-table :mobile-breakpoint="0" v-model="selected" id="myTable" :headers="headers" @click:row="rowClick"
      single-select :items="salesReport" :loading="loading" item-key="slNo" :items-per-page="25"
      class="elevation-1 mt-5 mt-sm-6 black--text" :search="search">
      <template v-slot:body="props">
        <tbody>
          <tr v-for="item in props.items" :key="item.id">
            <td v-for="(header, index) in headers" :key="index">
              <div v-if="index == 0">{{ item.slNo }}</div>
              <div v-else-if="header.value == 'customerName'">
                {{
                  item.user.firstnameEn[0].toUpperCase() +
                  item.user.firstnameEn.substring(1)
                }}
                {{
                  item.user.lastnameEn[0].toUpperCase() +
                  item.user.lastnameEn.substring(1)
                }}
              </div>
              <div v-else-if="header.value == 'total'">
                {{ getTotalFare(item) }}
              </div>
              <div v-else>
                {{ getCellValue(header.value, item) }}
              </div>
            </td>
          </tr>
          <tr style="background-color: #ffe500">
            <td v-for="(header, index) in headers" :key="index">
              <div v-if="index == 0"></div>
              <div v-else-if="header.value == 'customerName'">Total</div>
              <div v-else-if="header.value == 'total'">
                {{ getSumofTotalFare() }}
              </div>
              <div v-else>{{ getFooterFare(header.value) }}</div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import ExportSalesReport from "../components/ExportSalesReport.vue";
export default {
  components: {
    ExportSalesReport
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SALES_REPORT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }

    this.monthFilter = moment().format("MMMM");
    this.yearFilter = moment().year();
    this.monthList = moment.months();
    this.yearList = this.findYearList();
    this.getSalesReport();
  },
  methods: {
    findYearList() {
      let yearList = [];
      let startingYear = 2021;
      let currentYear = moment().year();
      while (currentYear >= startingYear) {
        yearList.push(startingYear);
        startingYear = startingYear + 1;
      }
      yearList.push(startingYear);
      return yearList;
    },
    rowClick: function (item, row) {
      row.select(true);
      this.selectedId = item.name;
    },
    getCellValue(day, item) {
      if (item.salesOrder) {
        let month = moment()
          .month(this.monthFilter)
          .format("M");
        let date = moment().set({ date: day, month: parseInt(month) - 1, year: parseInt(this.yearFilter) });

        let weekDay = date.format("dddd");
        //console.log(new Date(parseInt(item.salesOrder[0].start_date)));

        let currentPackage = item.salesOrder.find(
          x =>
            moment(new Date(parseInt(x.start_date))).startOf("day") <= date &&
            moment(new Date(parseInt(x.end_date))).endOf("day") >= date
        );
        if (currentPackage) {
          if (currentPackage.package.availableChoices) {
            let choice = currentPackage.package.availableChoices[0];
            if (choice.availableDays.includes(weekDay)) {
              if (currentPackage.selected_menu) {
                let selectedMenu = currentPackage.selected_menu.find(
                  x =>
                    moment(new Date(parseInt(x.date))).format("YYYY-MM-DD") ==
                    moment(date).format("YYYY-MM-DD")
                );
                if (selectedMenu && selectedMenu.is_paused) {
                  return "0";
                } else {
                  let package_price = currentPackage.net_price;
                  let availableNumOfDays = choice.availableNumOfDays;
                  let dailyPrice = package_price / availableNumOfDays;
                  return dailyPrice.toFixed(3);
                }
              }
            }
          }
        }
      }
      return "0";
    },
    getSalesReport() {
      this.variables.monthFilter = this.monthFilter;
      this.variables.yearFilter = this.yearFilter;

      this.loading = true;
      this.$apollo
        .query({
          query: require("../api/getSalesReport.graphql"),
          variables: this.variables
        })
        .then(data => {
          this.loading = false;
          this.btnloading = false;
          this.salesReport = data.data.getSalesReport;

          this.salesReport = this.salesReport.map((d, index) => ({
            ...d,
            slNo: index + 1
          }));

          // let days = data.data.getSalesReport.map((x) => x.numOfDays);
          // let maxVal = Math.max.apply(Math, days);

          this.getHeaders();
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },

    getFooterFare(day) {
      let total = 0;
      this.salesReport.forEach(item => {
        let amount = this.getCellValue(day, item);
        total = total + parseFloat(amount);
      });
      return total.toFixed(3);
    },
    getTotalFare(item) {
      let month = moment()
        .month(this.monthFilter)
        .format("M");
      let date = moment().set({ date: 10, month: parseInt(month) - 1, year: parseInt(this.yearFilter) });
      let numberOfDaysInMonth = date.daysInMonth();
      let total = 0;
      for (let index = 1; index <= numberOfDaysInMonth; index++) {
        let amount = this.getCellValue(index, item);
        total = total + parseFloat(amount);
      }
      return total.toFixed(3);
    },
    getSumofTotalFare() {
      let total = 0;

      this.salesReport.forEach(item => {
        let month = moment()
          .month(this.monthFilter)
          .format("M");
        let date = moment().set({ date: 10, month: parseInt(month) - 1, year: parseInt(this.yearFilter) });
        let numberOfDaysInMonth = date.daysInMonth();
        for (let index = 1; index <= numberOfDaysInMonth; index++) {
          let amount = this.getCellValue(index, item);
          total = total + parseFloat(amount);
        }
      });
      return total.toFixed(3);
    },
    getHeaders() {
      //let today = moment();
      let month = moment()
        .month(this.monthFilter)
        .format("M");
      let date = moment().set({ date: 10, month: parseInt(month) - 1, year: parseInt(this.yearFilter) });
      let numberOfDaysInMonth = date.daysInMonth();

      let headers = [
        { text: "Sl.No", value: "slNo", width: 50, sortable: false },
        {
          text: "Customer Name",
          value: "customerName",
          width: 200,
          sortable: false
        }
      ];
      for (let index = 1; index <= numberOfDaysInMonth; index++) {
        let header = { text: index, value: index, width: 50, sortable: false };
        headers.push(header);
      }
      let total = { text: "Total", value: "total", width: 50, sortable: false };
      headers.push(total);
      this.headers = headers;
    }
  },
  data() {
    return {
      today: "",
      tabs: "",
      salesReport: [],
      monthFilter: "",
      yearFilter: "",
      selectedId: -1,
      loading: true,
      search: "",
      btnloading: true,
      selected: [],
      headers: [],
      monthList: [],
      yearList: "",
      variables: {
        monthFilter: "",
        yearFilter: ""
      }
    };
  }
};
</script>
<style scoped>
tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
