<template>
  <div>
    <v-btn
      :disabled="salesReport.length == 0"
      @click="exportExcel"
      width="150"
      color="primary"
      class="white--text text-capitalize"
    >
      <v-icon size="17" class="me-1">mdi-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="printMe"
        hide-default-footer
        :headers="headers"
        :items-per-page="-1"
        :items="salesReport"
        class="elevation-1 mt-6"
      >
        <template v-slot:body="props">
          <tbody>
            <tr v-for="item in props.items" :key="item.id">
              <td v-for="(header, index) in headers" :key="index">
                <div v-if="index == 0">{{ item.slNo }}</div>
                <div v-else-if="header.value == 'customerName'">
                  {{ item.user.firstnameEn }} {{ item.user.lastnameEn }}
                </div>
                <div v-else-if="header.value == 'total'">
                  {{ getTotalFare(item) }}
                </div>
                <div v-else>
                  {{ getCellValue(header.value, item) }}
                </div>
              </td>
            </tr>
            <tr style="background-color: yellow">
              <td v-for="(header, index) in headers" :key="index">
                <div v-if="index == 0"></div>
                <div v-else-if="header.value == 'customerName'">Total</div>
                <div v-else-if="header.value == 'total'"></div>
                <div v-else>{{ getFooterFare(header.value) }}</div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { Utils } from "../../utils/utils";
import moment from "moment";
export default {
  components: {},
  props: {
    salesReport: {
      required: true,
    },
    headers: {
      required: true,
    },
  },
  methods: {
    exportExcel() {
      Utils.htmlToExcel("printMe", "SalesReport", false);
    },
    getFooterFare(day) {
      let total = 0;
      this.salesReport.forEach((item) => {
        let amount = this.getCellValue(day, item);
        total = total + parseFloat(amount);
      });
      return total.toFixed(3);
    },
    getTotalFare(item) {
      let numberOfDaysInMonth = moment().daysInMonth();
      let total = 0;
      for (let index = 1; index <= numberOfDaysInMonth; index++) {
        let amount = this.getCellValue(index, item);
        total = total + parseFloat(amount);
      }
      return total.toFixed(3);
    },
    getCellValue(day, item) {
      if (item.salesOrder) {
        let date = moment().set("date", day);
        let weekDay = date.format("dddd");
        //console.log(new Date(parseInt(item.salesOrder[0].start_date)));
        let currentPackage = item.salesOrder.find(
          (x) =>
            moment(new Date(parseInt(x.start_date))).startOf("day") <= date &&
            moment(new Date(parseInt(x.end_date))).endOf("day") >= date
        );
        if (currentPackage) {
          if (currentPackage.package.availableChoices) {
            let choice = currentPackage.package.availableChoices[0];
            if (choice.availableDays.includes(weekDay)) {
              if (currentPackage.selected_menu) {
                let selectedMenu = currentPackage.selected_menu.find(
                  (x) =>
                    moment(new Date(parseInt(x.date))).format("YYYY-MM-DD") ==
                    moment(date).format("YYYY-MM-DD")
                );
                if (selectedMenu && selectedMenu.is_paused) {
                  return "0";
                } else {
                  let package_price = currentPackage.net_price;
                  let availableNumOfDays = choice.availableNumOfDays;
                  let dailyPrice = package_price / availableNumOfDays;
                  return dailyPrice.toFixed(3);
                }
              }
            }
          }
        }
      }
      return "0";
    },
  },
  computed: {
    itemsWithSno() {
      if (this.pendingList == null) return [];
      return this.pendingList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
};
</script>
