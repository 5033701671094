<template>
  <v-container fluid class="px-0">
    <div v-if="!totalItemsCount">No sales Order...</div>
    <div v-else>
      <h2 class="black--text text-h6 font-weight-bold">{{ pageHeading }}</h2>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="salesOrderList"
        class="elevation-1 mt-4"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItemsCount"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
      >
        <template v-slot:[`item.plan`]="{ item }">
          {{ item.package.titleEn }}
        </template>
        <template v-slot:[`item.paymentStatus`]="{ item }">
          <span
            :class="
              item.payment_status == 'SUCCESS'
                ? 'green--text'
                : item.payment_status == 'PENDING'
                ? 'orange--text'
                : item.payment_status == 'PAY_LATER'
                ? 'black--text'
                : item.payment_status == 'UPCOMING'
                ? 'blue--text'
                : item.payment_status == 'EXPIRED'
                ? 'red--text'
                : ''
            "
          >
            {{ item.payment_status }}</span
          >
        </template>

        <template v-slot:[`item.customer`]="{ item }">
          <router-link
            v-if="!item.is_deleted"
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item.customer },
              query: { customerId: item.customer }
            }"
            class="mx-auto"
          >
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
            <!-- <div class="mx-1" /> -->
          </router-link>

          <span v-else>
            <!-- <div class="mx-1" /> -->
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
          </span>
          <!-- </v-row> -->
        </template>
        <template v-slot:[`item.endDate`]="{ item }">{{
          new Date(parseInt(item.end_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.date`]="{ item }">{{
          new Date(parseInt(item.created_At)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.startDate`]="{ item }">{{
          new Date(parseInt(item.start_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.modified`]="{ item }">
          <span
            :class="
              getDietitianName(item.calorie_range.modified_by) == 'Auto Update'
                ? 'black--text'
                : 'green--text'
            "
          >
            {{ getDietitianName(item.calorie_range.modified_by) }}</span
          ></template
        >
        <template v-slot:[`item.availableNumOfDays`]="{ item }">{{
         item.package.availableChoices.length > 0
                    ? item.package.availableChoices[0].availableNumOfDays
                    : "", }}</template>
        <template v-slot:[`item.action`]="{ item }">
          <SetCalorieValue
            :salesOrder="item"
            :forUpdation="false"
            @updateSalesOrderStatus="updateSalesOrderStatus"
          />
        </template>
        <template v-slot:[`item.update`]="{ item }">
          <SetCalorieValue
            :salesOrder="item"
            :forUpdation="true"
            @updateSalesOrderStatus="updateSalesOrderStatus"
          />
        </template>
        <template v-slot:[`item.paymentId`]="{ item }">
          <RegenerateSalesOrderPayment :salesOrder="item" />
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        timeout="3000"
        right
        top
        :color="snackbarColor"
        >{{ text }}</v-snackbar
      >
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import SetCalorieValue from "@/orders/components/SetCalorieValue.vue";
import RegenerateSalesOrderPayment from "@/customer/components/salesOrder/RegenerateSalesOrderPayment.vue";
export default {
  components: { SetCalorieValue, RegenerateSalesOrderPayment },
  props: {
    tab: {
      required: true
    },
    redTab: {},
    filter: {
      required: false,
      type: Object
    },
    updated: {
      required: true
    }
  },
  data: () => ({
    fDate: format(
      parseISO(
        moment()
          .subtract(7, "days")
          .toDate()
          .toISOString()
      ),
      "yyyy-MM-dd"
    ),
    tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    moment: moment,
    options: {
      itemsPerPage: 25,
      page: 1
    },
    loading: false,
    totalItemsCount: 1,
    variables: {
      limit: 25,
      skip: 0
    },
    packageStatuses: [
      { text: "All", value: "" },
      { text: "Active", value: "ACTIVE" },
      { text: "Upcoming", value: "UPCOMING" },
      { text: "Pending", value: "PENDING" },
      { text: "Expired", value: "EXPIRED" }
    ],
    packageStatus: "",
    search: "",
    headers: [
      {
        text: "Order ID",
        value: "order_number",
        width: 98
      },
      {
        text: "Created Date",
        value: "date",
        align: "center",
        width: 120,
        sortable: false
      },

      {
        text: "Customer Name",
        value: "customer",
        sortable: false,
        align: "center",
        width: 140
      },
      {
        text: "Start Date",
        value: "startDate",
        width: 120,
        align: "center",
        sortable: false
      },

      {
        text: "End Date",
        value: "endDate",
        width: 120,
        align: "center",
        sortable: false
      },
      {
        text: "Plan Name",
        value: "plan",
        width: 120,
        sortable: false,
        align: "center"
      },
      {
        text: "Days",
        value: "availableNumOfDays",
        align: "center",
        width: 75,
        sortable: false
      },
      {
        text: "Payment Status",
        value: "paymentStatus",
        sortable: false,
        align: "center",
        width: 120
      },
      {
        text: "Discount",
        value: "discount",
        align: "center",
        width: 100,
        sortable: false
      },

      {
        text: "Total",
        value: "net_price",
        align: "center",
        width: 100,
        sortable: false
      }
    ],
    salesOrderList: [],
    menuList: [],
    pageHeading: "",
    routeName: "",
    snackbarColor: "",
    snackbar: false,
    text: ""
  }),
  watch: {
    tab: {
      handler() {
        if (this.tab !== 0) {
          let data1 = {
            text: "Modified By",
            value: "modified",
            sortable: false,
            align: "center",
            width: 140
          };
          this.headers.push(data1);
        } else {
          this.headers = this.headers.filter(x => x.value !== "modified");
        }
        this.getAllSubscribedPackages();
      },
      deep: true
    },
    redTab: {
      handler() {
        this.headers = this.headers.filter(x => x.value !== "paymentId");
        if (this.redTab === 0 || this.redTab === 1) {
          let data1 = {
            text: "Generate payment link",
            value: "paymentId",
            sortable: false,
            align: "center",
            width: 140
          };
          this.headers.push(data1);
        }
        this.getAllSubscribedPackages();
      },
      deep: true
    },
    options: {
      handler() {
        this.variables.limit = this.options.page * this.options.itemsPerPage;
        this.variables.skip =
          (this.options.page - 1) * this.options.itemsPerPage;
        this.getAllSubscribedPackages();
      },
      deep: true
    },
    updated: {
      handler() {
        this.getAllSubscribedPackages();
      },
      deep: true
    }
  },
  mounted() {
    this.$root.$on("updateGetCustomerById", () => {
      this.getAllSubscribedPackages();
    });
  },
  created() {
    this.routeName = this.$route.name;
    if (this.routeName == "SetCalorieValue") {
      // let data1 = {
      //   text: "Action",
      //   value: "action",
      //   sortable: false,
      //   align: "center",
      //   width: 80
      // };
      // this.headers.push(data1);
      // ----------------------------
      if (this.tab == 0) {
        let data1 = {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: 80
        };
        this.headers.push(data1);
      } else if (this.tab == 1) {
        let data1 = [
          {
            text: "Modified By",
            value: "modified",
            sortable: false,
            align: "center",
            width: 140
          },
          {
            text: "Update",
            value: "update",
            sortable: false,
            align: "center",
            width: 80
          }
        ];
        this.headers.push(...data1);
      }

      // -------------------------
    } else if (this.tab !== 0) {
      let data1 = {
        text: "Modified By",
        value: "modified",
        sortable: false,
        align: "center",
        width: 140
      };
      this.headers.push(data1);
    }
    if (this.redTab === 0 || this.redTab === 1) {
      let data1 = {
        text: "Generate payment link",
        value: "paymentId",
        sortable: false,
        align: "center",
        width: 140
      };
      this.headers.push(data1);
    }
    this.titleOfPage();
    this.getAllSubscribedPackages();
  },
  methods: {
    titleOfPage() {
      if (this.tab === 1) {
        this.pageHeading = "Active Orders";
      } else if (this.tab === 2) {
        if (this.redTab === 0) {
          this.pageHeading = "Pay Later Orders";
        } else if (this.redTab === 1) {
          this.pageHeading = "Failed Orders";
        } else {
          this.pageHeading = "Payment Pending Orders";
        }
      } else {
        this.pageHeading = "Pending Orders";
      }
    },
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    updateSalesOrderStatus() {
      this.snackbarColor = "success";
      this.text = "calorie value is set";
      this.snackbar = true;
      this.getAllSubscribedPackages();
    },
    getAllSubscribedPackages() {
      let variables = {
        input: this.variables
      };
      if (this.tab === 0) {
        variables.orderStatus = "YELLOW";
      } else if (this.tab === 1) {
        variables.orderStatus = "GREEN";
      } else if (this.tab === 2) {
        if (this.redTab === 0) {
          variables.orderStatus = "RED_PAY_LATER";
        } else if (this.redTab === 1) {
          variables.orderStatus = "RED_FAILED";
        } else {
          variables.orderStatus = "RED_PENDING";
        }
      } else {
        variables.orderStatus = "YELLOW";
      }
      if (this.filter) {
        if (this.filter.search) variables.searchKey = this.filter.search;
        if (this.filter.fDate) variables.startDate = this.filter.fDate;
        if (this.filter.tDate) variables.endDate = this.filter.tDate;
      }
      this.$apollo
        // .query({
        .watchQuery({
          query: require("../api/getAllSubscribedPackagesByOrderStatus.graphql"),
          variables: variables
        })
        .refetch({ variables: variables })
        .then(data => {
          // console.log(data);
          if (data.data) {
            this.salesOrderList = [];
            this.loading = false;
            this.totalItemsCount =
              data.data.getAllSubscribedPackagesWithOrderStatus.total_count;
            this.salesOrderList =
              data.data.getAllSubscribedPackagesWithOrderStatus.sales_orders;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getDietitianName(data) {
      if (data) {
        let firstName = "";
        let lastName = "";
        if (data.firstnameEn) {
          firstName = this.toTitleCase(data.firstnameEn);
        }
        if (data.lastnameEn) {
          lastName = this.toTitleCase(data.lastnameEn);
        }
        let fullname = `${firstName} ${lastName}`;
        return fullname;
      } else {
        return "Auto Update";
      }
    }
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format("DD/MM/YYYY") : "";
    }
  }
};
</script>
