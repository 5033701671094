<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout justify-center class="mt-lg-n14">
      <v-flex offset-sm-7 offset-md-7 offset-lg-6>
        <v-btn
          class="ms-lg-16 mt-sm-n2 black--text text-capitalize text-end"
          @click="getAllStocks()"
          :loading="loading"
          :width="
            $vuetify.breakpoint.lgAndUp
              ? 180
              : $vuetify.breakpoint.md
              ? 140
              : 120
          "
          color="secondary"
          >search</v-btn
        >
      </v-flex>

      <v-flex class="mt-sm-n2 text-end">
        <Invard
          :fDate="fDate"
          :tDate="tDate"
          :stockList="stockList"
          :headers="headers"
        />
      </v-flex>
    </v-layout>
    <!-- {{ fDate }} -->
    <!-- {{ stockList }} -->
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="stockList"
      class="elevation-1 mt-6"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.quantity="{ item }">
        <div v-if="item.ingredient">
          <span v-if="item.ingredient.quantityUnit == 'PIECE'">
            {{ parseFloat(item.inward).toFixed(0) }}&nbsp;{{
              itemQuantityUnit(item.ingredient)
            }}
          </span>
          <span v-else>
            {{ parseFloat(item.inward / 1000).toFixed(3) }}&nbsp;{{
              itemQuantityUnit(item.ingredient)
            }}
          </span>
        </div>
      </template>
      <template v-slot:item.totalPrice="{ item }">
        {{ parseFloat(item.totalPrice).toFixed(3) }}
      </template>
      <!-- <template v-slot:item.created="{ item }">
        {{ new Date(parseInt(item.created_at)) | moment("YYYY-MM-DD") }}
      </template> -->
    </v-data-table>
  </v-container>
</template>
<script>
import moment from "moment";
import Invard from "./export/Invard.vue";
export default {
  components: { Invard },
  props: {
    fDate: {
      require: true
    },
    tDate: {
      require: true
    }
  },
  data() {
    return {
      stockList: [],
      loading: true,
      totalItemsCount: 25,
      options: {
        itemsPerPage: 25,
        page: 1
      },
      variables: {
        limit: 25,
        skip: 0
      },
      headers: [
        { text: "Item ID", value: "itemId", sortable: false },
        { text: "Item Name", value: "nameEn", sortable: false },
        {
          text: "Reference",
          value: "referenceType",
          sortable: false,
          width: 125
        },
        { text: "Quantity", value: "quantity", sortable: false },

        { text: "Total Price", value: "totalPrice", sortable: false }
        // { text: "Date", value: "created" },
      ]
    };
  },
  watch: {
    // fDate: {
    //   handler() {
    //     this.getAllStocks();
    //   },
    //   deep: true,
    // },
    // tDate: {
    //   handler() {
    //     this.getAllStocks();
    //   },
    //   deep: true,
    // },
    options: {
      handler() {
        this.getAllStocks();
      },
      deep: true
    }
  },
  methods: {
    getAllStocks() {
      const fDateTime = moment(`${this.fDate}`, "YYYY-MM-DD").format();
      let modifiedFromDate = new Date(fDateTime);
      const tDateTime = moment(`${this.tDate}`, "YYYY-MM-DD").format();
      let modifiedToDate = new Date(tDateTime);
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllStocks.graphql"),
          variables: {
            limit: 25,
            skip: 0,
            stockType: "inward",
            fromDate: moment(modifiedFromDate).format("MM-DD-YYYY"),
            toDate: moment(modifiedToDate).format("MM-DD-YYYY")
          }
        })
        .refetch({
          stockType: "inward",
          fromDate: moment(modifiedFromDate).format("MM-DD-YYYY"),
          toDate: moment(modifiedToDate).format("MM-DD-YYYY"),
          limit: this.options.itemsPerPage,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage
        })
        .then(data => {
          this.loading = false;

          if (data.data.getAllStocks) {
            this.stockList = data.data.getAllStocks.stock;
            this.totalItemsCount = data.data.getAllStocks.totalCount;
          }
        })
        .catch(error => {
          this.loading = false;

          console.log(error);
        });
    },
    itemQuantityUnit(item) {
      let unit = "";
      if (item.quantityUnit === "GRAMS") unit = "KG";
      else if (item.quantityUnit === "ML") unit = "L";
      else if (item.quantityUnit === "PIECE") unit = "PCS";
      return unit;
    }
  }
};
</script>
