<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="black--text text-h6 font-weight-bold ">Tutorials</h2>
    <v-form @submit="updateTutorial()" v-model="valid" ref="form" class="mt-5">
      <v-row>
        <v-col cols="12" sm="8" md="7" class="pe-16">
          <v-text-field label="Video Url" v-model="youtube_url" solo dense>
            <template v-slot:append>
              <v-btn
                color="primary rounded-r"
                height="40"
                class="me-n16 white--text text-capitalize font-weight-bold"
                :width="$vuetify.breakpoint.smAndUp ? 150 : 90"
                tile
              >
                Embed
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-n5 mt-sm-0">
        <v-col cols="12" sm="8" md="7">
          <v-textarea
            solo
            v-model="text_en"
            label="Transcript"
            :rules="[requiredValidator('transcript (english)')]"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="mt-n5 mt-sm-0">
        <v-col cols="12" sm="8" md="7">
          <v-textarea
            solo
            v-model="text_ar"
            label="Transcript (Arabic)"
            :rules="[requiredValidator('transcript (arabic)')]"
          ></v-textarea>

          <v-layout justify-center class="mt-6">
            <v-btn
              color="secondary"
              class="black--text font-weight-bold text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 200 : 150"
              @click="updateTutorial"
              :loading="btnloading"
              :disabled="!valid"
              >Save</v-btn
            >
          </v-layout>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </v-container>
</template>

<script>
//import UserImage from "../components/UserImage";
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {
    //   "app-user-image": UserImage
  },

  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      dialog: false,
      showDialog: false,
      title_en: "",
      title_ar: "",
      text_en: "",
      text_ar: "",
      youtube_url: "",
      snackbar: false,
      text: "",
      valid: true,
      isEdit: false,
      tutorialId: ""
    };
  },
  apollo: {
    getTutorial() {
      return {
        query: require("../api/getTutorial.graphql"),
        result({ data, loading }) {
          this.text_en = data.getTutorial.text_en;
          this.text_ar = data.getTutorial.text_ar;
          this.tutorialId = data.getTutorial._id;
          this.youtube_url = data.getTutorial.youtube_url;
          this.loading = loading;
        }
      };
    }
  },
  methods: {
    updateTutorial() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateTutorial.graphql"),
          variables: {
            id: this.tutorialId,
            text_en: this.text_en,
            text_ar: this.text_ar,
            youtube_url: this.youtube_url
          }
        })
        .then(() => {
          this.text = "Tutorial updated";
          this.snackbar = true;
          this.btnloading = false;
          this.dialog = false;
        })
        .catch(error => {
          this.btnloading = false;
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
