<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn small class="primary" v-on="on">Settings</v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title class="ps-6 ps-sm-10 pe-1">
          <span class="text-h6 black--text">Dietitian Settings</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form @submit="updateDietitian()" v-model="valid" ref="form">
          <v-card-text>
            <v-container>
              <v-layout v-if="showDays" wrap class="mb-8 ps-4 mt-4">
                <v-flex xs12 md2>
                  <h4 class="text-body-2 black--text">Available Days</h4>
                </v-flex>
                <v-flex class="ml-4" xs12 md8>
                  <v-row>
                    <v-flex xs6 sm4 md3 lg3>
                      <v-checkbox
                        label="All"
                        :disabled="showVariantSection"
                        value="All"
                        v-model="all"
                        @click="toggleDays"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      xs6
                      sm4
                      md3
                      lg3
                      v-for="(day, index) in listOfDays"
                      :key="index"
                    >
                      <v-checkbox
                        :label="day.day"
                        :disabled="showVariantSection"
                        v-model="availableDays"
                        :value="day"
                      ></v-checkbox>
                    </v-flex>
                  </v-row>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-2 ps-4">
                <v-flex xs12 sm2 class="mt-2 mb-2 mb-sm-0">
                  <span>Slot Duration</span>
                </v-flex>
                <v-flex xs4 sm4>
                  <v-text-field
                    type="number"
                    placeholder="Slot duration"
                    :rules="[integer('Slot duration')]"
                    solo
                    dense
                    v-model="slotDuration"
                    @change="slotDurationChange"
                    suffix="min"
                    :readonly="!showDays"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end>
                <v-btn small class="primary" @click="onNext">{{
                  nextText
                }}</v-btn>
              </v-layout>
              <div v-if="!showDays">
                <v-layout wrap>
                  <v-tabs
                    class="mt-2"
                    background-color="bg"
                    v-model="tab"
                    center-active
                  >
                    <v-tab
                      v-for="day in availableDays"
                      :key="day.day"
                      :value="day.day"
                      ><label
                        class="
                          text-caption text-sm-body-2
                          black--text
                          text-capitalize
                        "
                        >{{ day.day }}</label
                      ></v-tab
                    >
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(day, index) in availableDays"
                      :key="index"
                    >
                      <v-card color="pop_bg  pt-2 " width="700">
                        <span v-show="false">{{ index }}</span>
                        <v-flex
                          class="text-body-2 black--text ms-sm-5"
                          v-for="(item, dayIndex) in day.shifts"
                          :key="dayIndex"
                        >
                          <v-layout class="mt-5">
                            <v-flex>
                              <span class="font-weight-bold">{{
                                item.name
                              }}</span>
                            </v-flex>
                          </v-layout>
                          <v-layout class="mt-3">
                            <v-flex sm2 class=" mt-2">
                              <span>No of Slots</span>
                            </v-flex>
                            <v-flex xs8 sm3 class=" ">
                              <v-text-field
                                outlined
                                dense
                                label="no of slots"
                                type="number"
                                v-model="item.noOfSlots"
                                @change="getEndTime(item)"
                                :rules="[integer('No of slots')]"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout class="my-2" wrap>
                            <v-flex xs4 class="mt-2 " sm2>
                              Start Time
                            </v-flex>
                            <v-flex xs8 sm3 class=" ">
                              <v-text-field
                                outlined
                                dense
                                label="Start Time"
                                type="time"
                                v-model="item.startTime"
                                @change="getEndTime(item)"
                              ></v-text-field>
                              <!--  :rules="[
                                  endTimeValidator(day.shifts),
                                ]" -->
                            </v-flex>
                            <v-flex xs4 sm2 class="mt-2 ml-sm-6 ">
                              End Time
                            </v-flex>
                            <v-flex xs8 sm3 class=" ">
                              <v-text-field
                                outlined
                                dense
                                label="End Time"
                                type="time"
                                v-model="item.endTime"
                                readonly
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <hr v-if="dayIndex == 0" class="mr-4" />
                          <v-row class="mt-3">
                            <!-- <v-text-field
                              :label="item.nameEn + '(No.)'"
                              solo
                              type="number"
                              @keypress="isNumber"
                              v-model="item.count"
                              min="0"
                              dense
                            ></v-text-field> -->

                            <!-- <v-btn
                            class="white--text text-capitalize ml-5"
                            color="primary"
                            >Apply to All</v-btn
                          > -->
                          </v-row>
                        </v-flex>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-layout>
                <v-layout class="mt-5" justify-space-between>
                  <span class="font-weight-bold">
                    Off Days
                  </span>
                  <DietitianOffForm
                    v-if="hasRole(AdminUserPermissions.DIETITIAN_OFF_DAY)"
                    :dietitian="dietitian"
                    :OffDayList="offDayList"
                    @addOffDay="addOffDay"
                  />
                </v-layout>
                <DietitianOffDaysTable :OffDayList="offDayList" />
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              v-if="!showDays"
              small
              color="secondary"
              class="black--text text-capitalize"
              :loading="btnloading"
              @click="updateDietitian"
              :disabled="!valid"
              >Update Settings</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import moment from "moment";
// import { DATE_FORMAT } from "../../utils/utils";
import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator,
  integer
} from "../../core/methods/validations";
import DietitianOffForm from "./DietitianOffDaySelectionForm.vue";
import DietitianOffDaysTable from "./DietitianOffDaysTable.vue";
export default {
  components: {
    DietitianOffForm,
    DietitianOffDaysTable
  },
  data() {
    return {
      show1: false,
      requiredValidator: requiredValidator,
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      integer,
      btnloading: false,
      dialog: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      phone: "",
      password: "",
      myCroppa: null,
      image: null,
      imageUrl: process.env.VUE_APP_BASE_URL,
      snackbar: false,
      snackbarColor: "",
      text: "",
      doesPhoneExist: false,
      valid: true,
      //    shifts: [
      //         { name: "Morning Shift", startTime: "", endTime: "" },
      //         { name: "Evening Shift", startTime: "", endTime: "" }
      //       ],
      listOfDays: [
        {
          day: "Sunday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        },
        {
          day: "Monday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        },
        {
          day: "Tuesday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        },
        {
          day: "Wednesday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        },
        {
          day: "Thursday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        },
        {
          day: "Friday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        },
        {
          day: "Saturday",
          shifts: [
            {
              name: "Morning Shift",
              startTime: "",
              endTime: "",
              noOfSlots: ""
            },
            { name: "Evening Shift", startTime: "", endTime: "", noOfSlots: "" }
          ]
        }
      ],
      availableDays: [],
      showVariantSection: false,
      all: "",
      showDays: true,
      nextText: "Next",
      tab: 0,
      slotDuration: "",
      oldSlotDuration: "",
      offDayList: []
    };
  },
  created() {
    this.dietitianInfo = JSON.parse(JSON.stringify(this.dietitian));
    if (this.dietitianInfo.slotTiming.length > 0) {
      let slotTiming = this.dietitianInfo.slotTiming;
      slotTiming.forEach(x => {
        delete x.__typename;
      });
      this.slotDuration = this.dietitianInfo.slotDuration;
      this.showDays = !this.showDays;
      this.nextText = "Change selected days";
      this.availableDays = slotTiming;
      slotTiming.forEach(slot => {
        this.listOfDays.forEach(list => {
          if (list.day === slot.day) {
            list.shifts = slot.shifts;
          }
        });
      });
    }
    if (this.dietitianInfo.offDays.length) {
      let offDays = this.dietitianInfo.offDays;
      offDays.forEach(x => {
        delete x.__typename;
        if (x.date) {
          x.date = moment(new Date(parseInt(x.date))).format("YYYY-MM-DD");
        }
      });
      this.offDayList = offDays;
    }
  },
  props: {
    dietitian: {
      required: true
    }
  },

  methods: {
    addOffDay(data) {
      this.offDayList.push(data);
    },
    endTimeValidator(data) {
      let resOne = data[0].endTime;
      let resTwo = data[1].startTime;

      if (resOne != "" && resTwo != "") {
        let hourOne = resOne.split(":")[0];
        let minOne = resOne.split(":")[1];
        let hourTwo = resTwo.split(":")[0];
        let minTwo = resTwo.split(":")[1];
        if (parseFloat(hourTwo) >= parseFloat(hourOne)) {
          if (parseFloat(hourTwo) == parseFloat(hourOne)) {
            if (parseFloat(minTwo) < parseFloat(minOne)) {
              return false;
            } else {
              return true;
            }
          }
          return true;
        } else {
          return false;
        }
      }

      return false;
    },
    // endTimeValidator(data) {
    //   let resOne = data[0].endTime;
    //   let resTwo = data[1].startTime;

    //   if (resOne != "" && resTwo != "") {
    //     let hourOne = resOne.split(":")[0];
    //     let minOne = resOne.split(":")[1];
    //     let hourTwo = resTwo.split(":")[0];
    //     let minTwo = resTwo.split(":")[1];
    //     if (parseFloat(hourTwo) >= parseFloat(hourOne)) {
    //       if (parseFloat(hourTwo) == parseFloat(hourOne)) {
    //         if (parseFloat(minTwo) < parseFloat(minOne)) {
    //           return "starting time of evening shift should be greater than the morning shift end time";
    //         } else {
    //           return true;
    //         }
    //       }
    //       return true;
    //     } else {
    //       return "starting time of evening shift should be greater than the morning shift end time";
    //     }
    //   }

    //   return true;
    // },
    minuteValidator() {
      return v => {
        if (v == "") {
          return "starting time is required";
        }
        let min = v.split(":");
        let minutes = min[1];
        if (minutes) {
          if (minutes % 15 != 0) {
            return "Starting time should be gap of 15 min";
          } else {
            return true;
          }
        }
        return true;
      };
    },
    slotDurationChange() {
      let difference = this.slotDuration - this.oldSlotDuration;
      if (difference) {
        let data = this.timeConvert(difference);
        console.log(data);
        for (let availableDay of this.availableDays) {
          for (let shift of availableDay.shifts) {
            if (shift.endTime != "") this.getEndTime(shift);
          }
        }
      }
      this.oldSlotDuration = this.slotDuration;
    },
    getEndTime(data) {
      if (
        data.startTime == "" ||
        data.noOfSlots == "" ||
        this.slotDuration == ""
      ) {
        return;
      }
      let results = data.startTime.split(":");
      console.log(results);
      let hours = results[0];
      let minutes = results[1];
      let totalMinutes = parseFloat(hours * 60) + parseFloat(minutes);
      let num = data.noOfSlots * this.slotDuration + totalMinutes;
      data.endTime = this.timeConvert(num);
    },
    timeConvert(num) {
      {
        var h = Math.floor(num / 60);
        var m = num % 60;
        h >= 24 ? (h = parseFloat(h) - 24) : h;
        let hour = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
        return hour + ":" + m;
      }
    },
    toggleDays() {
      this.all == "All"
        ? (this.availableDays = this.listOfDays)
        : this.availableDays;
    },
    onNext() {
      if (this.availableDays.length == 0) {
        this.snackbar = true;
        this.snackbarColor = "red";
        this.text = "Atleast select one day";
        return;
      }
      if (this.slotDuration == "") {
        this.snackbar = true;
        this.snackbarColor = "red";
        this.text = "Enter Slot Duration";
        return;
      }
      this.showDays = !this.showDays;
      this.showDays
        ? (this.nextText = "Next")
        : (this.nextText = "Change selected days");
    },

    updateDietitian() {
      for (let x of this.availableDays) {
        let res = x.shifts.find(shift => shift.startTime === "");
        if (res) {
          this.snackbar = true;
          this.snackbarColor = "red";
          this.text = `Enter start date on ${x.day}`;
          return;
        }
        let endTimeValidation = this.endTimeValidator(x.shifts);
        if (!endTimeValidation) {
          this.snackbar = true;
          this.snackbarColor = "red";
          this.text = `check evening shift - starting time & morning shift - end time  on ${x.day}`;
          // this.text = `starting time of evening shift should be greater than the morning shift end time  on ${x.day}`;
          return;
        }
      }

      this.btnloading = true;
      const user = {
        // firstnameEn: this.dietitianInfo.user.firstnameEn,
        // lastnameEn: this.dietitianInfo.user.lastnameEn,
        // password: this.password,
        // phone: this.dietitianInfo.user.phone
      };

      let slotTiming = new Array();
      this.availableDays.forEach(x => {
        x.shifts.forEach(y => {
          delete y.__typename;
        });
        let data = new Object();
        data.day = x.day;
        data.shifts = x.shifts;
        slotTiming.push(data);
      });
      let input = {
        isActive: this.dietitianInfo.isActive,
        slotDuration: parseInt(this.slotDuration),
        slotTiming,
        offDays: this.offDayList
      };
      this.$apollo
        .mutate({
          mutation: require("../api/updateDietitian.graphql"),
          variables: {
            dietitianId: this.dietitianInfo._id,
            userId: this.dietitianInfo.user._id,
            userInput: user,
            input: input
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
            // this.$root.$emit("updateGetDietitianById");
          }
        })
        .then(() => {
          this.text = "Dietitian settings updated";
          this.snackbar = true;
          this.snackbarColor = "success";
          // this.$root.$emit("updateDietitian");
          this.btnloading = false;

          this.dialog = false;
        })
        .catch(error => {
          this.btnloading = false;
          if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
            // this.doesPhoneExist = true;
          }
          this.text = error.graphQLErrors[0].message;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
