<template>
  <v-container>
    <a href="javascriptt:void(0)" @click="showDialogBox">{{
      dateMenu.date | moment("MMM DD YYYY")
    }}</a>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="pa-3 pa-md-12">
        <v-row justify="end">
          <v-btn color="primary" text @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-card-title class="headline primary--text"
            >Menu - {{ dateMenu.date | moment("MMM DD YYYY") }}</v-card-title
          >
        </v-row>

        <v-row
          v-for="category in dateMenu.allMeals"
          :key="category._id"
          justify="space-between"
          class="mx-2"
        >
          <h5 class="primary--text">
            {{ category.category.nameEn }}
          </h5>
          <h5 v-for="item in category.menu_item" :key="item._id">
            {{ item.nameEn }}
          </h5>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    dateMenu: {
      require: true
    }
  },
  methods: {
    showDialogBox() {
      this.showDialog = true;
    }
  },
  created() {},
  data() {
    return {
      showDialog: false
    };
  }
};
</script>
