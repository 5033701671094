<template>
  <div>
    <v-layout justify-end>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!isUpdated"
            v-on="on"
            class="black--text text-capitalize font-weight-bold"
            color="secondary"
          >
            <v-icon class="me-1" small>mdi-plus</v-icon> Add
          </v-btn>
          <!-- <v-btn v-else
            v-on="on"
            class="black--text text-capitalize font-weight-bold"
            color="secondary"
          > -->
          <v-icon v-on="on" v-else class="me-1 green--text">mdi-pencil </v-icon>
          <!-- </v-btn> -->
        </template>
        <v-card class="" color="pop_bg">
          <v-card-title class="ps-5 pe-1">
            <span class="text-h6 black--text">{{ heading }}</span>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-form @submit="action()" v-model="valid" ref="form">
            <v-card-text class="pb-0 px-1 px-sm-3">
              <v-container class="pb-0">
                <v-layout class="" wrap>
                  <v-flex
                    xs12
                    sm4
                    md4
                    class="mb-2 mb-sm-0 text-body-2 black--text"
                  >
                    Name En
                  </v-flex>
                  <v-flex xs12 sm8 md8>
                    <v-text-field
                      v-model="name"
                      :rules="[requiredValidator('nameEn')]"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm4
                    md4
                    class="mb-2 mb-sm-0 text-body-2 black--text"
                  >
                    Name Ar
                  </v-flex>
                  <v-flex xs12 sm8 md8>
                    <v-text-field
                      v-model="nameAr"
                      :rules="[requiredValidator('nameAr')]"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions class="pb-5 pe-6">
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                class="black--text text-capitalize"
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                :loading="btnloading"
                @click="action"
                :disabled="!valid"
                >{{ btnAction }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      timeout="3000"
      right
      top
      :color="snackColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    isUpdated: {
      required: true,
      type: Boolean
    },
    content: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      snackColor: "",
      btnloading: false,
      name: "",
      nameAr: "",
      snackbar: false,
      text: "",
      valid: true,
      heading: "",
      btnAction: ""
    };
  },
  created() {
    if (!this.isUpdated) {
      this.btnAction = "Add";
    } else {
      this.btnAction = "Update";
    }
    if (this.content.name == "ACTIVITY" && !this.isUpdated) {
      this.heading = "Add Activity";
    }
    if (this.content.name == "HEALTHISSUE" && !this.isUpdated) {
      this.heading = "Add Health Issue";
    }
    if (this.content.name == "ACTIVITY" && this.isUpdated) {
      this.heading = "Update Activity";
      this.name = this.content.data.name;
      this.nameAr = this.content.data.nameAr;
    }
    if (this.content.name == "HEALTHISSUE" && this.isUpdated) {
      this.heading = "Update Health Issue";
      this.name = this.content.data.name;
      this.nameAr = this.content.data.nameAr;
    }
  },

  methods: {
    action() {
      if (this.content.name == "ACTIVITY" && !this.isUpdated) {
        this.createActivity();
      }
      if (this.content.name == "HEALTHISSUE" && !this.isUpdated) {
        this.createHealthIssue();
      }
      if (this.content.name == "ACTIVITY" && this.isUpdated) {
        let data = new Object();
        data.id = this.content.data._id;
        data.name = this.name;
        data.nameAr = this.nameAr;
        this.updateActivity(data);
      }
      if (this.content.name == "HEALTHISSUE" && this.isUpdated) {
        let data = new Object();
        data.id = this.content.data._id;
        data.name = this.name;
        data.nameAr = this.nameAr;
        this.updateHealthIssue(data);
      }
    },
    updateActivity(data) {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateActivity.graphql"),
          variables: {
            input: {
              name: data.name,
              nameAr: data.nameAr
            },
            id: data.id 
          },
          update: () => {
            try {
              this.text = "Activity Updated";
              this.snackbarError = true;
              this.$emit("updateActivity");
              this.snackColor = "green";
            } catch (e) {
              console.log(e);
              this.text = "Something bad happend";
              this.snackColor = "red";
              this.snackbarError = true;
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = "Error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    updateHealthIssue(data) {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateHealthIssue.graphql"),
          variables: {
            input: {
              name: data.name,
              nameAr: data.nameAr
            },
            id: data.id 
          },
          update: () => {
            try {
              this.text = "HealthIssue Updated";
              this.snackbarError = true;
              this.$emit("updateActivity");
              this.snackColor = "green";
            } catch (e) {
              console.log(e);
              this.text = "Something bad happend";
              this.snackColor = "red";
              this.snackbarError = true;
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = "Error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    createActivity() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/createActivity.graphql"),
          variables: {
            input: {
              name: this.name,
              nameAr: this.nameAr
            }
          },
          update: () => {
            try {
              this.text = "Activity Added";
              this.snackbarError = true;
              this.$emit("updateActivity");
              this.snackColor = "green";
            } catch (e) {
              console.log(e);
              this.text = "Something bad happend";
              this.snackColor = "red";
              this.snackbarError = true;
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = "Error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    createHealthIssue() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/createHealthIssue.graphql"),
          variables: {
            input: {
              name: this.name,
              nameAr: this.nameAr
            }
          },
          update: () => {
            try {
              this.text = "HealthIssue Added";
              this.snackbarError = true;
              this.$emit("updateActivity"); 
              this.snackColor = "green";
            } catch (e) {
              console.log(e);
              this.text = "Something bad happend";
              this.snackColor = "red";
              this.snackbarError = true;
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = "Error";
          this.snackbarError = true;

          console.log(error);
        });
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset() 
    }
  }
};
</script>
