var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.unqKey,attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.availableChoices,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"col":"1"}},[_c('EditVariant',{attrs:{"choice":item},on:{"updateVariant":_vm.updateVariant}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"col":"1"}},[_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)],1)],1)]}},{key:"item.sunday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Sunday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Sunday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}},{key:"item.monday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Monday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Monday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}},{key:"item.tuesday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Tuesday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Tuesday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}},{key:"item.wednesday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Wednesday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Wednesday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}},{key:"item.thursday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Thursday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Thursday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}},{key:"item.friday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Friday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Friday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}},{key:"item.saturday",fn:function(ref){
var item = ref.item;
return [_c('ul',[(_vm.getMeals(item, 'Saturday').length == 0)?_c('span',[_vm._v("--")]):_vm._e(),_vm._l((_vm.getMeals(item, 'Saturday')),function(mealItem){return _c('li',{key:mealItem},[_vm._v(" "+_vm._s(mealItem.nameEn)+" : "+_vm._s(mealItem.count)+" ")])})],2)]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }