<template>
  <v-container>
    <v-btn
      class="mt-n3 white--text text-capitalize me-1"
      @click="exportExcel"
      :loading="loading"
      width="150"
      color="primary"
    >
      <v-icon color="white">mdi-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <table id="printAllIngredient">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.value"
              :style="`width:${header.width};vertical-align: middle;`"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ingredientsList" :key="index">
            <!-- <tr> -->
            <td style="text-align: center; vertical-align: middle">
              {{ item.itemId }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.nameEn }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.nameAr }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.brand }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ getQuantityUnit(item.quantityUnit) }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.meanInfo.protein }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.meanInfo.fat }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.meanInfo.carbs }}
            </td>
            <td style="text-align: center; vertical-align: middle">
              {{ item.meanInfo.calories }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <v-data-table
        id="printAllIngredient"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="ingredientsList"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.nameEn`]="{ item }">{{ item.nameEn }}</template>
        <template v-slot:[`item.nameAr`]="{ item }">{{ item.nameAr }}</template>
        <template v-slot:[`item.id`]="{ item }">{{ item.itemId }}</template>
        <template #[`item.brandName`]="{ item }">{{ item.brand }}</template>
        <template #[`item.unit`]="{ item }">{{ item.quantityUnit }}</template>
      </v-data-table> -->
    </div>
  </v-container>
</template>
<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    unitList: {
      required: true
    }
  },
  // apollo: {
  //   getAllIngredients() {
  //     return {
  //       query: require("../../api/getAllIngredients.graphql"),
  //       variables: this.variables,
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.ingredientsList = data.getAllIngredients.ingredients;
  //         this.totalItemsCount = data.getAllIngredients.totalCount;
  //         // console.log(data.getAllIngredients);
  //       }
  //     };
  //   }
  // },

  methods: {
    exportExcel() {
      this.getAllIngredients();
      // Utils.htmlToExcel("printAllIngredient", "Ingredient", false);
    },
    getQuantityUnit(id) {
      let name;
      let data = this.unitList.find(x => x._id == id);
      data ? (name = data.name) : (name = "--");
      return name;
    },
    getAllIngredients() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("../../api/getAllIngredients.graphql"),
          variables: this.variables
        })
        .then(data => {
          this.loading = false;
          if (data.data) {
            this.ingredientsList = data.data.getAllIngredients.ingredients;
            setTimeout(() => {
              Utils.htmlToExcelOld("printAllIngredient", "Ingredient", false);
            }, 100);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Item ID", value: "id", width: 100 },
        { text: "Item Name (English)", value: "nameEn" },
        { text: "Item Name (Arabic)", value: "nameAr" },
        { text: "Brand Name", value: "brandName" },
        { text: "Unit", value: "unit" },
        { text: "Protein" },
        { text: "Fat" },
        { text: "Carbs" },
        { text: "Calories" }
      ],
      ingredientsList: [],
      totalItemsCount: "",
      variables: {
        limit: 0,
        skip: 0
      }
    };
  }
};
</script>
