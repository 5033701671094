var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"black--text mt-6 ms-3 mb-4"},[_c('h3',[_vm._v("Units")]),_c('v-spacer'),_c('UnitForm',{on:{"updatedUnit":_vm.updatedUnit}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-1 ms-2",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.unitList,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.unitList.indexOf(item) + 1))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name.charAt(0).toUpperCase() + item.name.substring(1)))]}},{key:"item.conversionUnit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.conversionUnit == "GRAMS" ? "Grams" : item.conversionUnit == "ML" ? "mL" : "Nos"))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.isFixed)?_c('v-row',[_c('v-col',[_c('UnitUpdate',{attrs:{"unit":item},on:{"updatedUnit":_vm.updatedUnit}})],1),_c('v-col',[_c('UnitDelete',{attrs:{"unit":item},on:{"updatedUnit":_vm.updatedUnit}})],1)],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }