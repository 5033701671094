<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row>
      <v-col>
        <h3 class="black--text text-h5 font-weight-bold">Health Issues</h3>
      </v-col>
      <v-col>
        <ActivityForm
          @updateActivity="updateActivity"
          :content="{ name: 'HEALTHISSUE' }"
          :isUpdated="false"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success"
      >Health issue successfully updated.</v-snackbar
    >

    <v-form v-model="valid" ref="form">
      <v-layout wrap class="mt-10">
        <v-flex v-for="(textField, i) in allHealthIssue" :key="i" xs12 sm6 md4>
          <v-card class="ma-4" color="primary ">
            <div class="pa-2 capitalize">
              <ul>
                <li>{{ textField.name }}</li>
                <li>{{ textField.nameAr ? textField.nameAr : "--" }}</li>
              </ul>
            </div>
            <v-card-actions>
              <ActivityForm
                @updateActivity="updateActivity"
                :content="{ name: 'HEALTHISSUE', data: textField }"
                :isUpdated="true"
              />

              <v-divider></v-divider>

              <deleteActivity
                :healthIssueId="textField._id"
                @updateActivity="updateActivity"
              />
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import ActivityForm from "../components/ActivityForm.vue";
import deleteActivity from "../components/DeleteActivityOrHealthIssue.vue";
export default {
  components: {
    ActivityForm,
    deleteActivity
  },
  data() {
    return {
      save: false,
      textDisabled: true,
      allHealthIssue: [],
      btnloading: false,
      snackbar: false,
      valid: false,
      requiredValidator: requiredValidator,
      name: ""
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.HEALTH_ISSUE_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllActivities();
  },
  methods: {
    updateActivity() {
      this.getAllActivities();
    },
    getAllActivities() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/e-clinic/api/getAllHealthIssue.graphql")
        })
        .refetch({})
        .then(data => {
          console.log(data.data);
          if (data.data)
            this.allHealthIssue = data.data.getAllHealthIssue.healthIssues;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    add() {
      this.textFields.push({
        placeholder: "Add Health Issue",
        value: "",
        _id: ""
      });
      this.textDisabled = false;
      this.save = true;
    },
    remove(index) {
      this.textFields.splice(index, 1);
      this.save = true;
    },
    onClick() {
      // this.save = true;
      // this.textDisabled=false
    },
    onUpdate() {
      this.save = true;
      this.textDisabled = false;
    }
  }
};
</script>
<style>
.capitalize {
  text-transform: capitalize;
}
</style>
