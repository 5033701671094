<template>
  <v-container>
    <v-btn
      class="mt-n3 white--text text-capitalize me-1"
      :disabled="stockList.length == 0"
      @click="exportExcel"
      width="150"
      color="primary"
    >
      <v-icon color="white">mdi-export</v-icon>Export
    </v-btn>

    <div v-show="false">
      <v-data-table
        id="printCurrentStock"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="stockListAll"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.quantity="{ item }">
          <span v-if="item.quantityUnit == 'PIECE'">
            {{ parseFloat(item.stock.currentStock).toFixed(0) }}&nbsp;{{
              itemQuantityUnit(item)
            }}
          </span>
          <span v-else>
            {{ parseFloat(item.stock.currentStock / 1000).toFixed(3) }}&nbsp;{{
              itemQuantityUnit(item)
            }}
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    stockList: {
      require: true
    },
    headers: {
      require: true
    }
  },
  watch: {
    stockList: {
      handler() {
        this.getAllStocks();
      },
      deep: true
    }
  },
  created() {
    this.getAllStocks();
  },
  methods: {
    exportExcel() {
      Utils.htmlToExcel("printCurrentStock", "Ingredient", false);
    },
    itemQuantityUnit(item) {
      let unit = "";
      if (item.quantityUnit === "GRAMS") unit = "KG";
      else if (item.quantityUnit === "ML") unit = "L";
      else if (item.quantityUnit === "PIECE") unit = "PCS";
      return unit;
    },
    getAllStocks() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllCurrentStocks.graphql"),
          variables: {
            limit: 0,
            skip: 0
          }
        })
        .refetch({
          limit: 0,
          skip: 0
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllCurrentStocks) {
            this.stockListAll = data.data.getAllCurrentStocks.ingredients;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      loading: true,
      stockListAll: []
    };
  }
};
</script>
