var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.selectedMealsList,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('ShowAllMenuItems',{attrs:{"dateMenu":item}})]}},{key:"item.last_modified_date",fn:function(ref){
var item = ref.item;
return [(item.last_modified_date != 'Invalid Date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.last_modified_date,"MMM DD YYYY"))+" ")]):_vm._e()]}},{key:"item.isPaused",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaused ? "Paused" : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.hasActiveFreeze)?_c('span',[(_vm.checkActivePackage())?_c('CustomerMealSelectionPopup',{attrs:{"selectedDay":item,"activePackage":_vm.activePackage}}):_vm._e()],1):_c('span',[_c('strong',{staticClass:"red--text"},[_vm._v(" The Plan is Freezed ")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }