<template>
  <v-container fluid class="pa-4 pa-sm-8 ">
    <v-form @submit="createDefaultCalorie()" v-model="valid" ref="form">
      <h2 class="black--text text-h6 font-weight-bold ">
        Calorie Settings
      </h2>
      <v-card width="500" min-height="250" elevation="0">
        <v-layout v-if="isThresholdTime" class="mt-2 " wrap>
          <v-flex class="mt-2" xs12 sm3 md3>
            <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Threshold Time</h4>
          </v-flex>
          <v-flex xs12 sm7 md7>
            <v-text-field
              solo
              dense
              type="number"
              v-model="thresholdTime"
              suffix="Minutes" 
              :rules="[requiredValidator('Time'), integer('Time')]"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout class="mt-2 " wrap>
          <v-flex class="mt-2" xs12 sm3 md3>
            <h4 class="mb-2 mb-sm-0 text-body-2 black--text">
              Require Dietitian to Set
            </h4>
          </v-flex>
          <v-flex xs12 sm7 md7 class="ml-5">
            <v-switch v-model="isThresholdTime" inset></v-switch>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-btn
            color="primary"
            class="mb-2 mb-sm-4 text-capitalize"
            @click="createDefaultCalorie"
            :loading="btnloading"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-layout>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        timeout="3000"
        right
        top
        :color="snackbarColor"
        >{{ text }}</v-snackbar
      >
    </v-form>
  </v-container>
</template>

<script>
import {
  requiredValidator,
  positive,
  integer
} from "../../core/methods/validations";
export default {
  data: () => ({
    tab: null,
    items: [
      { gender: "Male", min: null, max: null },
      { gender: "Female", min: null, max: null }
    ],
    requiredValidator,
    positive,
    integer,
    valid: true,
    btnloading: false,
    thresholdTime: "",
    snackbar: false,
    snackbarColor: "",
    text: "",
    isThresholdTime: false,
  }),
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data }) {
          if (data.getAppConfigurations.calorieSettings) {
            console.log(
              data.getAppConfigurations.calorieSettings.isThresholdTime
            );
            this.thresholdTime =
              data.getAppConfigurations.calorieSettings.thresholdTime;
            if (
              data.getAppConfigurations.calorieSettings.isThresholdTime !== null
            )
              this.isThresholdTime =
                data.getAppConfigurations.calorieSettings.isThresholdTime;
          }
        }
      };
    }
  },
  methods: {
    // greater(gender) {
    //   for (let item of this.items) {
    //     if (item.min !== "" && item.max !== "" && item.gender == gender) {
    //       if (parseFloat(item.max) <= parseFloat(item.min)) {
    //         return `should be greater than ${item.min} (min calorie value)`;
    //       }
    //     }
    //   }
    //   return true;
    // },
    createDefaultCalorie() {
      // for (let item of this.items) {
      //   if (item.min == null) {
      //     this.snackbarColor = "red";
      //     this.text = `${item.gender} min calorie value is null`;
      //     this.snackbar = true;
      //     return;
      //   }
      //   if (item.max == null) {
      //     this.snackbarColor = "red";
      //     this.text = `${item.gender} max calorie value is null`;
      //     this.snackbar = true;
      //     return;
      //   }
      // }
      this.btnloading = true;
      // for (let item of this.items) {
      //   item.min = parseFloat(item.min);
      //   item.max = parseFloat(item.max);
      // }
      let calorieSettings = {
        // defaultCalorieRange: this.items,
        thresholdTime: parseFloat(this.thresholdTime),
        isThresholdTime: this.isThresholdTime
      };
      console.log(calorieSettings);
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            calorieSettings
          }
        })
        .then(() => {
          this.snackbarColor = "success";
          this.text = `Calorie threshold time is updated`;
          this.snackbar = true;
          this.btnloading = false;
        })
        .catch(error => {
          this.btnloading = false;
          console.log(error);
        });
    }
  }
};
</script>
<style></style>
