<template>
  <v-container class="py-8 px-4 pa-sm-8" fluid>
    <h2 class="black--text text-h5 font-weight-bold mb-4">
      Paused Meals Report
    </h2>
    <v-layout class="" wrap>
      <v-flex xs7 sm4 md3 class="">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              label="Date"
              readonly
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker v-model="date" @change="menu = false"></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm4 md2 lg2 class="mt-md-0 ms-sm-5 ms-md-0 mb-8 mb-sm-0">
        <v-btn
          class="ms-sm-5 px-6 black--text text-capitalize"
          color="secondary"
          @click="searchMeals"
          >Search
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- <v-layout>
      <v-flex md9>
        <v-layout justify-end>
          <v-flex md3>
            <ExportPromocodeReport
              :promocodeReport="promocodeReport"
              :headers="headers"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> -->
    <!-- {{ options }} -->
    <v-data-table
      :mobile-breakpoint="0"
      id="myTable"
      :headers="headers"
      :items="customerList"
      :loading="loading"
      item-key="slNo"
      class="elevation-1 mt-10"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.slNo="{ item }">{{ item.slNo }} </template>
      <template v-slot:item.customerName="{ item }">
        <router-link
          :to="{
            name: 'CustomerDetails',
            params: { customerId: '' },
            query: { customerId: '' }
          }"
          class="mx-auto"
        >
          {{
            item.user.firstnameEn[0].toUpperCase() +
              item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
              item.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:item.startDate="{ item }">
        {{
          new Date(parseInt(item.subscribed_packages.start_date))
            | moment("DD MMM YYYY")
        }}</template
      >
      <template v-slot:item.shift="{ item }">
        <span
          v-if="!item.addressDetails.shift || item.addressDetails.shift == ''"
          >MORNING</span
        >
        <span v-else>{{ item.addressDetails.shift }}</span>
      </template>
      <template v-slot:item.endDate="{ item }">
        {{
          new Date(parseInt(item.subscribed_packages.end_date))
            | moment("DD MMM YYYY")
        }}</template
      >
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../utils/utils";
// import ExportPromocodeReport from "@/reports/components/ExportPromocodeReport.vue";
export default {
  components: {
    // ExportPromocodeReport,
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PAUSED_MEALS_REPORT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getPausedMealsDetailsByDate();
  },
  watch: {
    options: {
      handler() {
        this.getPausedMealsDetailsByDate();
        this.tableIndex =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
      }
    }
  },
  methods: {
    searchMeals() {
      this.getPausedMealsDetailsByDate();
    },
    getPausedMealsDetailsByDate() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getPausedMealsDetailsByDate.graphql"),
          variables: {
            date: this.date,
            limit: 25,
            skip: 0
          }
        })
        .refetch({
          date: this.date,
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage
        })
        .then(data => {
          this.loading = false;
          if (data.data.getPausedMealsDetailsByDate) {
            this.customerList = data.data.getPausedMealsDetailsByDate.customers;
            this.totalCount = data.data.getPausedMealsDetailsByDate.totalCount;
            this.customerList = this.customerList.map((d, index) => ({
              ...d,
              slNo: index + this.tableIndex + 1
            }));
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  },
  data() {
    return {
      menu: false,
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      customerList: [],
      loading: true,
      totalCount: 25,
      btnloading: true,
      options: {
        itemsPerPage: 25,
        page: 1
      },
      tableIndex: 0,
      headers: [
        { text: "Sl.No", value: "slNo", width: 50, sortable: false },
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false
        },
        {
          text: "Package",
          value: "subscribed_packages.package.titleEn",
          width: 150,
          sortable: false
        },
        {
          text: "Start Date",
          value: "startDate",
          width: 110,
          sortable: false
        },
        {
          text: "End Date",
          value: "endDate",
          width: 110,
          sortable: false
        },
        { text: "Shift", value: "shift", width: 100, sortable: false },
        {
          text: "Area",
          value: "addressDetails.area.nameEn",
          width: 150,
          sortable: false
        },
        {
          text: "Block",
          value: "addressDetails.block.block",
          width: 100,
          sortable: false
        }

        // { text: "Date & Time", value: "date", width: 150, sortable: false },
      ]
    };
  }
};
</script>
