var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"px-md-7 px-lg-6",attrs:{"col-12":"","xs12":"","md6":"","lg4":""}},[_c('v-card',{staticClass:"pt-8 mx-auto rounded-lg",attrs:{"height":"440","tile":"","max-width":_vm.$vuetify.breakpoint.xl
        ? 400
        : _vm.$vuetify.breakpoint.lg
        ? 350
        : _vm.$vuetify.breakpoint.md
        ? 390
        : _vm.$vuetify.breakpoint.sm
        ? 420
        : 340}},[_c('v-layout',[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('label',{staticClass:"text-h6 ms-4"},[_vm._v(" Birthday (Active) ")])])],1),_c('v-layout',[_c('v-flex',{staticClass:"ml-5",attrs:{"xs8":"","sm3":"","md6":"","lg8":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.fromDate,"label":"From","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.fDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.changeDate,"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fDate),callback:function ($$v) {_vm.fDate=$$v},expression:"fDate"}})],1)],1)],1),(_vm.loading)?_c('div',[_c('v-card-text',{staticClass:"text-xs-center text-md-center"},[_c('v-progress-circular',{staticClass:"primary--text",attrs:{"size":70,"indeterminate":""}})],1)],1):(_vm.customers.length > 0)?_c('div',[_c('v-virtual-scroll',{staticClass:"mt-4",attrs:{"height":"300","item-height":"64","bench":_vm.benched,"items":_vm.customers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{key:item._i},[_c('v-list-item-action',[_c('v-avatar',{attrs:{"width":80,"height":80}},[_c('v-img',{attrs:{"src":require('../../assets/user.jpg'),"contain":"","max-width":"50","max-height":"50","position":"left"}})],1)],1),_c('v-row',[_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
                      name: 'CustomerDetails',
                      params: { customerId: item._id },
                      query: { customerId: item._id },
                    }}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.user.firstnameEn))+" "+_vm._s(_vm.capitalizeFirstLetter(item.user.lastnameEn))+" ")])])],1),_c('v-list-item-title',[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.user.dob)),"DD-MMM-YYYY"))+" ")])])],1)],1)],1)]}}])})],1):_c('div',[_c('v-list-item-title',{staticClass:"text-center mb-10 mt-16"},[_c('v-layout',{staticClass:"pt-16",attrs:{"justify-center":""}},[_vm._v("******No Birthdays******")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }