import { jsPDF } from "jspdf";
import moment from "moment";
import { TIME_ZONE } from "../../utils/utils";

const defaultFontSize = 8;
const padding = 2;
const lineHeight = Math.floor(defaultFontSize / 2);
let currentY = lineHeight * 2;
const maxCharPerLine = 40;
const pageWidth = 100;
const pageHeight = 80;

let doc = new jsPDF("l", "mm", [pageWidth, pageHeight], true);

const print_delivery_sticker = (orders, date) => {
    initialiseDoc();
    if (orders != null) {
        orders.forEach(order => {
            printOrderHeader(order, date);
            printCustomerDetails(order);
            printSelectedMenu(order.menu);
            printCommand(order);
            printCalorie(order);
            printFooter();
            gotoNextPage()
        });
    }
    saveDoc(date);
}
const initialiseDoc = () => {
    doc = new jsPDF("l", "mm", [pageWidth, pageHeight], true);
    doc.setFontSize(defaultFontSize);
};
const printOrderHeader = (order, date) => {

    const startXCol2 = Math.floor(pageWidth / 4);
    const startXCol3 = Math.floor(pageWidth * 2 / 3);

    //boxes
    doc.rect(padding, padding, pageWidth - 2 * padding, pageHeight - 4 * padding);
    doc.rect(padding, padding, pageWidth - 2 * padding, currentY + 2);
    doc.rect(startXCol2 - 8, padding, startXCol3 - startXCol2 - 8, currentY + 2);

    // first column 
    doc.setFont("times", "bold");
    doc.setFontSize(defaultFontSize);
    doc.text("Q#" + padLeft(order.order_id.toString(), 4), padding + .7, currentY + 2);

    if (order.customer.tags) {
        let tags = order.customer.tags.map(x => x.name);
        tags = tags.join(",")
        doc.text(tags, padding + .7, currentY - 3);
    }

    doc.setFont("times", "bold");
    doc.setFontSize(defaultFontSize - 1);
    //  doc.text(date, padding + 2, currentY + 1);
    doc.setFontSize(defaultFontSize);

    // second column 

    doc.setFontSize(defaultFontSize + 1);
    doc.setFont("times", "bold");
    const splitMenuItems = doc.splitTextToSize(order.package_code, 30);
    doc.text(splitMenuItems, startXCol2 - 7.3, currentY - 3.1);
    // doc.text("START ", startXCol2 - 7, currentY - 1.5);
    doc.setFontSize(defaultFontSize + 1);
    let packageRange = `${getDate(order.subscribedPackage.start_date)} - ${getDate(order.subscribedPackage.end_date)}`
    doc.text(packageRange, startXCol2 - 7.5, currentY + 3.5);

    // doc.setFont("times", "bold");
    // doc.text(getDate(order.subscribedPackage.start_date), startXCol2 + 1, currentY - 1.5);
    // doc.text(getDate(order.subscribedPackage.end_date), startXCol2 + 1, currentY + 1);
    doc.setFontSize(defaultFontSize);

    // third column 
    //doc.rect(startXCol2 - 2, padding, startXCol3 - startXCol2, currentY);
    let zoneName = order.zone ? order.zone.zoneName : ''
    doc.text(date + " (" + order.shift + ") - " + zoneName, startXCol3 - 15, currentY - 2.4);
    doc.setFont("times", "bold");
    // doc.text("Zone :", startXCol3 - 14, currentY + 1);
    let driver = "";
    if (order.driver && order.driver.user) {
        driver = ` ${order.driver.user.firstnameEn} ${order.driver.user.lastnameEn}`
    }
    //${order.zone.zoneName ?? ''}, 
    doc.text(`Driver : ${driver}`, startXCol3 - 15, currentY + 3);
    doc.setFont("times", "bold");
    doc.setFontSize(defaultFontSize);
    gotoNextLine();
}
const printCommand = (order) => {

    doc.rect(padding, padding, pageWidth - 2 * padding, pageHeight - 8 * padding);
    if (order.address) {
        if (order.address.comments) {
            let comment = order.address.comments;
            if (comment.length > 110) {
                comment = order.address.comments.replace(/\r?\n|\r/g, " ").slice(0, 110).concat(' ...');
            }
            const splitMenuItems = doc.splitTextToSize(`Comments: ${comment}`, maxCharPerLine + 50);
            //  const splitMenuItems = doc.splitTextToSize(aMenuItem.nameEn ?? "", maxCharPerLine);
            //   const requiredNumberOfLines = splitMenuItems.length;
            doc.text(splitMenuItems, padding + 2, pageHeight - 11);
        }
    }
    gotoNextLine();
}
const printCalorie = (order) => {

    //doc.rect(padding, padding, pageWidth - 2 * padding, pageHeight - 4 * padding);
    if (order.calorie) {
        doc.text("Total Calorie : " + order.calorie.toFixed(0), padding + 2, pageHeight - 15);
    }
    gotoNextLine();
}

const printCustomerDetails = (order) => {
    const startXcol2 = Math.floor((pageWidth + padding) / 2);
    //boxes
    doc.rect(padding, padding + 2 + currentY - lineHeight, pageWidth - 2 * padding, 3 * lineHeight - 2);
    doc.rect(padding, padding + 2 + currentY - lineHeight, startXcol2 - 3, 3 * lineHeight - 2);

    // col 1
    let thisCustomer = order.customer;
    let userName = "";
    if (thisCustomer.user != null) {
        const fName = thisCustomer.user.firstnameEn ?? "";
        const lName = thisCustomer.user.lastnameEn ?? "";
        userName = fName + " " + lName;
    }
    doc.setFont("times", "bold");
    doc.text(capitalizeFirstLetter(userName), padding + .7, currentY + 3);
    gotoNextLine();
    doc.setFont("times", "bold");
    //  doc.text("ID : " + thisCustomer.customerId ?? "", padding + 2, currentY + 1);
    //  gotoNextLine();
    let cutlery = thisCustomer.isCutleryRequired ? "Yes" : "No";
    let mobile = thisCustomer.user.phone ?? ""
    doc.text("Mobile : " + mobile + ", Cutlery : " + cutlery, padding + .7, currentY + 4);
    gotoNextLine();

    //  doc.text(, padding + .7, currentY + 1);
    // gotoNextLine();
    gotoPreviousLine(2);

    // col 2
    doc.setFont("times", "bold");
    const address = order.address;
    let addressline1 = address.area.nameEn ?? "";
    if (address.block.block != null) {
        addressline1 = addressline1 + "(" + address.block.block + ")";
    }
    //doc.text(addressline1, startXcol2, currentY + 2);
    // gotoNextLine();
    //let addressline2 = "";
    if (address.jedha != null) {
        if (address.jedha.length > 0) {
            addressline1 += ", Jd: " + address.jedha + " ";
        }
    }
    if (address.street != null) {
        if (address.street.length > 0) {
            addressline1 += ", St:" + address.street + " ";
        }
    }
    //doc.text(addressline2, startXcol2, currentY + 1);
    //gotoNextLine();
    // let addressline3 = "";
    if (address.house_number != null) {
        if (address.house_number.length > 0) {
            addressline1 += ", H:" + address.house_number + " ";
        }
    }
    if (address.building_number != null) {
        if (address.building_number.length > 0) {
            addressline1 += ", Fl:" + address.building_number + " ";
        }
    }
    if (address.apartment_number != null) {
        if (address.apartment_number.length > 0) {
            addressline1 += ", Ap:" + address.apartment_number + " ";
        }
    }

    const addressline = doc.splitTextToSize(addressline1, 40);
    doc.text(addressline, startXcol2, currentY + 3);
    // doc.text(addressline3, startXcol2, currentY);
    doc.setFont("times", "bold");
    gotoNextLine();
    gotoNextLine();
    gotoNextLine();
}

const getDate = (date) => {
    let converted = moment(date).tz(TIME_ZONE);
    return converted.format("MMM DD YY");
}
const printSelectedMenu = (menu) => {
    if (menu == null) return;
    incrementYByPixels(2);
    let lastPrintedYinCol1 = currentY;
    let lastPrintedYinCol2 = currentY;

    const filteredMenu = getFilteredMenu(menu);
    let itemNum = 1;
    doc.setFontSize(defaultFontSize - .3);
    for (var i = 0; i < filteredMenu.length; i++) {
        let xStart = padding + 6;

        if (i % 2 == 0) {
            // column1StartYRef = maxPrintedYinBothColumns; //currentY;
            currentY = lastPrintedYinCol1;
            //column2
        } else {
            currentY = lastPrintedYinCol2; //column1StartYRef;
            xStart = Math.floor(pageWidth / 2) + padding + 3;
        }
        doc.setFont("times", "bold");
        doc.text(filteredMenu[i].category?.nameEn ?? "-", xStart - 5, currentY);
        doc.setFont("times", "bold");


        const menuItem = filteredMenu[i];
        if (menuItem != null) {
            doc.setFontSize(defaultFontSize - .5);
            menuItem.menu.forEach(aMenuItem => {

                const menuNameWithDislikes = getNameWithDislikes(aMenuItem);

                const splitMenuItems = doc.splitTextToSize(`${itemNum}.${menuNameWithDislikes}`, maxCharPerLine);
                //  const splitMenuItems = doc.splitTextToSize(aMenuItem.nameEn ?? "", maxCharPerLine);
                const requiredNumberOfLines = splitMenuItems.length;
                gotoNextLine();

                //   const isAutoSelected = aMenuItem.isAutoSelected;
                //  doc.addImage(isAutoSelected ? "img/forefinger-pointing-right.png" : "img/forefinger-pointing-up.png", "png", xStart - 4, currentY - 2, defaultFontSize / 3, defaultFontSize / 3)
                // doc.text(itemNum + "." + splitMenuItems, xStart, currentY);
                doc.text(splitMenuItems, xStart, currentY);
                gotoNextLine(requiredNumberOfLines - 1);
                itemNum = itemNum + 1;
            });
            doc.setFontSize(defaultFontSize);
        }
        //column1
        if (i % 2 == 0) {
            lastPrintedYinCol1 = currentY + 1 * lineHeight;
            //column2
        } else {
            lastPrintedYinCol2 = currentY + 1 * lineHeight;
        }
    }

}
const printFooter = () => {

    doc.text("www.basickw.com", padding + 4, pageHeight - padding - 1);
    const xValue = Math.floor(pageWidth / 2) - 10;
    doc.addImage("img/globe.png", "png", padding, pageHeight - 5, 3, 3)
    doc.addImage("img/call.png", "png", xValue, pageHeight - 5, 3, 3)
    doc.text("+965 94422333", xValue + 4, pageHeight - padding - 1);

    doc.addImage("img/logo.png", "png", pageWidth - 18, pageHeight - 5, 16, 3)

}
const getFilteredMenu = (menu) => {
    let filteredMenu = [];
    menu.forEach(m => {
        if (m.menu != null) {
            if (m.menu.length > 0) filteredMenu.push(m);
        }
    });
    return filteredMenu;
}

const getNameWithDislikes = (item) => {
    let dislikedIngrediants = item.dislikedIngrediants.map(
        d => "No " + d.nameEn
    );
    let nameWithDislike = item.nameEn.trim();
    if (dislikedIngrediants.length > 0)
        nameWithDislike =
            nameWithDislike + " (" + dislikedIngrediants.join(", ") + ")";
    return nameWithDislike;
}

const padLeft = (number, length) => {
    var stringValue = "" + number;
    while (stringValue.length < length) {
        stringValue = "0" + stringValue;
    }
    return stringValue;
}
const gotoNextLine = (numberOfLines) => {
    if (numberOfLines == null) numberOfLines = 1;
    currentY += numberOfLines * lineHeight;
}
const gotoPreviousLine = (numberOfLines) => {
    if (numberOfLines == null) numberOfLines = 1;
    currentY -= numberOfLines * lineHeight;
}
const incrementYByPixels = (pixels) => {
    currentY += pixels;
    if (currentY > pageHeight) gotoNextPage();
}
const gotoNextPage = () => {
    doc.addPage();
    currentY = lineHeight * 2;
}
const saveDoc = (date) => {
    doc.save(date + " Delivery Sticker.pdf");
}
const capitalizeFirstLetter = (string) => {
    // return string.charAt(0).toUpperCase() + string.slice(1);
    return string.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
export { print_delivery_sticker }