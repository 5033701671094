<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <!-- <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <h2 class="black--text text-h5 font-weight-bold">Promo Code</h2>
    <v-layout wrap>
      <v-flex xs12 sm4 md3 class="mt-4">
        <v-select
          :items="allPromoCodes"
          item-value="_id"
          item-text="promoCode"
          label="PromoCode"
          v-model="variables.promoCodeId"
          @change="getPromocodeReport()"
          dense
          attach
          solo
        ></v-select>
      </v-flex>
      <v-flex  md9 class="d-flex justify-end  px-0 mx-0">

          <ExportPromocodeReport
            :promocodeReport="promocodeReport"
            :headers="headers"
            class="text-end "
          />
      </v-flex>
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      id="myTable"
      :headers="headers"
      :items="promocodeReport"
      :loading="loading"
      item-key="slNo"
      class="elevation-1 mt-4 mt-sm-6"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.slNo="{ item }">{{ item.slNo }}</template>
      <template v-slot:item.customerName="{ item }">
        {{
          item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
        }}
        {{
          item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
        }}
      </template>
      <template v-slot:item.discount="{ item }">
        <span>{{ item.discountedAmount }} KD</span>
      </template>
      <template v-slot:item.date="{ item }">
        {{ new Date(item.dateTime) | moment("DD MMM YYYY") }}</template
      >
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { tz } from "moment-timezone";
import { TIME_ZONE } from "../../utils/utils";
import ExportPromocodeReport from "@/reports/components/ExportPromocodeReport.vue";
export default {
  components: {
    ExportPromocodeReport
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PROMO_CODE_REPORT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllPromoCodes();
    this.getHeaders();
    this.getPromocodeReport();
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.getPromocodeReport()) {
          this.variables.limit =
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;
          this.getPromocodeReport();
        }
      }
    }
  },
  methods: {
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },
    getPromocodeReport() {
      if (this.variables.promoCodeId == null) this.variables.promoCodeId = "";
      // console.log(this.variables);
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/reports/api/getPromocodeReport.graphql"),
          variables: this.variables
        })
        .then(data => {
          this.loading = false;
          this.btnloading = false;
          this.promocodeReport =
            data.data.getPromocodeReport.promoCodeCustomers;
          this.totalItemsCount = data.data.getPromocodeReport.totalCount;
          this.promocodeReport = this.promocodeReport.map((d, index) => ({
            ...d,
            slNo: index + 1
          }));
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAllPromoCodes() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/promotion/api/getAllPromoCodes.graphql"),
          variables: { searchKey: "" }
        })
        .then(data => {
          this.loading = false;
          //   this.btnloading = false;
          this.allPromoCodes = data.data.getAllPromoCodes;
          this.allPromoCodes.unshift({ _id: "", promoCode: "ALL" });
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getHeaders() {
      let headers = [
        { text: "Sl.No", value: "slNo", width: 50, sortable: false },
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false
        },
        { text: "Promo Code", value: "promoCode", width: 150, sortable: false },
        { text: "Discount", value: "discount", width: 100, sortable: false },

        { text: "Date & Time", value: "date", width: 150, sortable: false }
      ];
      this.headers = headers;
    }
  },
  data() {
    return {
      tz: tz,
      promocodeReport: [],
      loading: true,
      totalItemsCount: 25,
      btnloading: true,
      headers: [],
      allPromoCodes: [],
      options: {
        itemsPerPage: 25,
        page: 1
      },
      variables: {
        skip: 0,
        limit: 25,
        promoCodeId: ""
      }
    };
  }
};
</script>
