<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-icon @click="openDialog" v-on="on" color="black"
          >mdi-square-edit-outline</v-icon
        >
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Update Appointment</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
      <!-- {{appointment}}  --> 
          <v-form @submit="updateAppointment()" v-model="valid" ref="form">
            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Date</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate"
                      readonly
                      label="Date"
                      :rules="[requiredValidator('date'), dietitianOff()]"
                      solo
                      append-icon="mdi-calendar-month-outline"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                      dense
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    color="secondary"
                    v-model="date"
                    @change="onChangeDietitian"
                    :min="currentDate"
                  ></v-date-picker>
                  <!-- @change="menu = false" -->
                </v-menu>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Dietitian</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-autocomplete
                  readonly
                  v-model="dietitian"
                  :items="dietitianList"
                  item-text="user.firstnameEn"
                  item-value="_id"
                  solo
                  dense
                  label="Dietitians"
                >
                  <template slot="item" slot-scope="data"
                    >{{ data.item.user.firstnameEn }}
                    {{ data.item.user.lastnameEn }}
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>

            <v-layout wrap v-if="dietitian">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Fee</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-text-field
                  :value="appointment.fee"
                  readonly
                  dense
                  attach
                  solo
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="date && dietitian">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Shift</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-select
                  :items="shiftList"
                  :rules="[requiredValidator('shift')]"
                  v-model="shift"
                  dense
                  attach
                  solo
                  @change="onShiftChange"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Slot</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-combobox
                  :items="shiftSlotList"
                  :rules="[requiredValidator('slot')]"
                  v-model="shiftSlot"
                  dense
                  solo
                  item-text="startTime"
                ></v-combobox>
              </v-flex>
            </v-layout>

            <v-layout row class="mt-4" justify-center>
              <v-btn
                width="120"
                color="secondary"
                class="text-capitalize black--text"
                @click="updateAppointment()"
                :loading="btnloading"
                :disabled="!valid"
                >Update</v-btn
              >
            </v-layout>
          </v-form>
        </v-container>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  requiredValidator
  // requiredValidatorSelect,
} from "../../../core/methods/validations";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  components: {},
  props: {
    appointment: {
      required: true
    }
  },
  apollo: {
    getAllDietitians() {
      return {
        query: require("@/dietitian/api/getAllDietitians.graphql"),
        variables: {
          skip: 0,
          limit: 1000,
          searchKey: ""
        },
        result({ data, loading }) {
          this.loading = loading;

          this.dietitianList = data.getAllDietitians.dietitians;
        }
      };
    }
  },

  created() {
    this.dietitian = this.appointment.dietitian._id;
    this.shiftSlot = this.appointment.appointmentTime;

    if (this.appointment.appointmentDate) {
      // debugger;
      const date = new Date(parseInt(this.appointment.appointmentDate));

      this.date = moment(
        new Date(parseInt(this.appointment.appointmentDate))
      ).format("YYYY-MM-DD");

      //this.date = format(parseISO(date.toISOString()), "dd-MM-yyyy");
      const time = `${date.getHours()}:${date.getMinutes()}`;
      this.time = time;
      this.getDietitianSlots();
    }
  },

  data() {
    return {
      date: "",
      time: "",
      menu: "",
      menu1: "",
      dietitianLoading: false,
      dietitiansSearchKey: "",
      dietitianList: [],
      dietitian: "",
      awaitingSearch: false,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      variables: {
        limit: 0,
        skip: 0
      },
      shiftList: ["Morning Shift", "Evening Shift"],
      shift: "",
      shiftSlotList: [],
      shiftSlot: "",
      slotList: null,
      slot: "",
      isDietitianOff: false,
      dietitianLeaveSlot: null
      // avalilablePaymentMethod: [
      //   { text: "Online", value: "ONLINE" },
      //   { text: "Offline", value: "OFFLINE" },
      //   { text: "Free", value: "FREE" }
      // ],
      // paymentMethod: ""
    };
  },

  methods: {
    openDialog() {
      this.getDietitianSlots();
    },
    onShiftChange() {
      this.shiftSlot = "";
      this.shiftChange();
    },
    dietitianOff() {
      if (this.isDietitianOff) {
        return "Dietitian is Off on this day";
      }
      if (this.dietitianLeaveSlot == "FULL_DAY") {
        return "Dietitian is leave on this day";
      }
      return true;
    },
    onChangeDietitian() {
      this.menu = false;
      if (this.date && this.dietitian) {
        this.shiftSlot = "";
        this.getDietitianSlots();
      }
    },
    getDietitianSlots() {
      this.dietitianLeaveSlot = null;
      this.$apollo
        .watchQuery({
          query: require("@/dietitian/api/getDietitanSlots.graphql"),
          variables: {
            dietitianId: JSON.parse(JSON.stringify(this.dietitian)),
            date: this.date
          }
        })
        .refetch({
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
          }
        })
        .then(data => {
          if (data.data) {
            this.slotList = data.data.getAvailableSlot;
            let res = this.slotList.slots.find(
              x => x.startTime == this.appointment.appointmentTime
            );
            if (res) {
              this.shift = res.shift;
            }
            this.isDietitianOff = false;
            this.shiftChange();
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            this.shiftChange();
            if (error.graphQLErrors[0].message === "DIETITIAN_IS_OFF") {
              this.isDietitianOff = true;
              this.slotList = null;
              this.shiftChange();
            } else if (error.graphQLErrors[0].message === "FULL_DAY") {
              this.dietitianLeaveSlot = "FULL_DAY";
            } else {
              this.text = "Something bad is happend";
              this.snackColor = "error";
              this.snackbar = true;
            }
          }
          this.btnloading = false;
        });
    },
    shiftChange() {
      if (this.slotList !== null) {
        this.shiftSlotList = this.slotList.slots.filter(
          x => x.shift == this.shift && x.customer == null
        );
      } else {
        this.shiftSlotList = [];
      }
    },
    close() {
      this.show_dialog_box = false;
    },
    updateAppointment() {
      this.btnloading = true;
      let shiftSlotTime = "";
      this.shiftSlot.startTime
        ? (shiftSlotTime = this.shiftSlot.startTime)
        : (shiftSlotTime = this.shiftSlot);
      this.$apollo
        .mutate({
          mutation: require("@/appointments/api/updateAppointment.graphql"),
          variables: {
            id: this.appointment._id,
            appointmentTime: shiftSlotTime,
            appointmentDate: this.date
          }
        })
        .then(() => {
          this.snackbar = true;
          this.text = "Dietitian appoinment updated";
          this.$root.$emit("updateDietitianAppointments");
          this.$emit("updateDietitianAppt");
          this.btnloading = false;
          this.close();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.close();
        });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
    currentDate() {
      let data = new Date();
      return moment(data).format("YYYY-MM-DD");
    }
  }
};
</script>
