var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"mt-lg-n14",attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"offset-sm-7":"","offset-md-7":"","offset-lg-6":""}},[_c('v-btn',{staticClass:"ms-lg-16 mt-sm-n2 black--text text-capitalize text-end",attrs:{"loading":_vm.loading,"width":_vm.$vuetify.breakpoint.lgAndUp
            ? 180
            : _vm.$vuetify.breakpoint.md
            ? 140
            : 120,"color":"secondary"},on:{"click":function($event){return _vm.getAllStocks()}}},[_vm._v("search")])],1),_c('v-flex',{staticClass:"mt-sm-n2 text-end"},[_c('Invard',{attrs:{"fDate":_vm.fDate,"tDate":_vm.tDate,"stockList":_vm.stockList,"headers":_vm.headers}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.stockList,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [(item.ingredient)?_c('div',[(item.ingredient.quantityUnit == 'PIECE')?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.inward).toFixed(0))+" "+_vm._s(_vm.itemQuantityUnit(item.ingredient))+" ")]):_c('span',[_vm._v(" "+_vm._s(parseFloat(item.inward / 1000).toFixed(3))+" "+_vm._s(_vm.itemQuantityUnit(item.ingredient))+" ")])]):_vm._e()]}},{key:"item.totalPrice",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.totalPrice).toFixed(3))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }