<template>
  <v-container fluid class="px-0 mx-0">
    <v-layout class="mb-4" wrap justify-end>
      <DislikeIngredientForm @dislikeIngredient="dislikeIngredient" />
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="dislikeIngredientsList"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }"
    >
      <template v-slot:[`item.index`]="{ item }">{{ dislikeIngredientsList.indexOf(item)+1 }}</template> 
      <template v-slot:[`item.id`]="{ item }">{{ item.itemId }}</template>
      <template v-slot:[`item.category`]="{ item }">{{
        item.ingredientCategory.nameEn
      }}</template>
      <template v-slot:[`item.name`]="{ item }">{{ item.nameEn }}</template>
      <template v-slot:[`item.delete`]="{ item }"
        ><v-icon
          color="red"
          @click="deleteFromTable(dislikeIngredientsList.indexOf(item))"
          class="gray1--text"
          >mdi-delete-outline</v-icon
        ></template
      >
    </v-data-table>
  </v-container>
</template>
<script>
import DislikeIngredientForm from "../components/DislikeIngredientForm.vue";
export default {
  components: {
    DislikeIngredientForm
  },
  props: {
    dislikeIngredientsData: {
      type: Array
    }
  },

  data: () => ({
    dialog: false,
    loading: false,
    options: {
      itemsPerPage: 25,
      page: 1
    },
    variables: {
      limit: 25,
      skip: 0
    },
    headers: [
      { text: "Si No", value: "index" },
      { text: "Item ID", value: "id" },
      { text: "Category", value: "category" },
      { text: "Ingredient", value: "name" },
      { text: "", value: "delete" }
    ],
    stockList: [],
    totalItemsCount: 25,
    dislikeIngredientsList: []
  }),
  created() {
    if(this.dislikeIngredientsData){
      this.dislikeIngredientsList=this.dislikeIngredientsData 
    }
  },
  methods: {
    dislikeIngredient(data) {
      this.dislikeIngredientsList.unshift(data);
      let value=this.dislikeIngredientsList.map(x=>x._id)
      this.$emit("dislikeIngredients",value)
    },
    deleteFromTable(item) {
      console.log(item)
      this.dislikeIngredientsList.splice(item, 1);
      let data=this.dislikeIngredientsList.map(x=>x._id)
      this.$emit("dislikeIngredients",data)
    }
  }
};
</script>
