<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          small
          @click="onShowDialog"
          class="background--text text-capitalize font-weight-bold"
          color="primary"
          >+ Add</v-btn
        >
      </template>
      <v-card class="pa-3 py-8" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2"
            >Add Dislike Ingredients</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="px-0  ">
          <v-container class="mt-2">
            <h3 class=" black--text text-body-1 font-weight-bold">
              Enter Dislike Ingredients Details
            </h3>
            <v-form
              class=""
              @submit="createDislikeIngredients()"
              v-model="valid"
              ref="form"
            >
              <v-layout class="mt-5 mt-md-10" wrap>
                <v-flex xs12 sm4 md4>
                  <h5 class="mt-1 text-body-2 black--text mb-2 mb-sm-0">
                    Ingredient Category
                  </h5>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-autocomplete
                    label="Ingredient Category"
                    v-model="newIngredientCategory"
                    solo
                    dense
                    attach
                    :items="ingredientCategoryList"
                    item-text="nameEn"
                    item-value="_id"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsCategorySearchKey"
                    :loading="ingredientsCategoryLoading"
                    return-object
                    @change="onChangeIngredientCategory"
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient category by name...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data">{{
                      data.item.nameEn
                    }}</template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <h5 class="text-body-2 black--text mb-2 mb-sm-0">
                    Ingredient
                  </h5>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-autocomplete
                    v-model="newIngredient"
                    :items="ingredientSearcList"
                    item-text="nameEn"
                    :rules="[requiredValidator('ingredient')]"
                    dense
                    attach
                    :error-messages="errors"
                    solo
                    chips
                    small-chips
                    label="Ingredient Name"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsSearchKey"
                    :loading="ingredientsLoading"
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient by name...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data"
                      >{{ data.item.nameEn }}, {{ data.item.nameAr }}</template
                    >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <h5 class="text-body-2 black--text mb-2 mb-sm-0">
                    Ingredient ID
                  </h5>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-autocomplete
                    label="Ingredient ID"
                    v-model="newIngredient"
                    solo
                    dense
                    attach
                    :rules="[requiredValidator('ingredient id')]"
                    :items="ingredientSearcList"
                    item-text="itemId"
                    item-value="_id"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsIdSearchKey"
                    :loading="ingredientsLoading"
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient by id...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data">{{
                      data.item.itemId
                    }}</template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout row justify-end class="mt-5 mb-sm-n10">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  small
                  class="black--text text-capitalize"
                  @click="createDislikeIngredients()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Add</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,

      showOrder: "",
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      newIngredient: "",
      newIngredientCategory: "",
      ingredientsLoading: false,
      ingredientsCategoryLoading: false,
      ingredientsSearchKey: "",
      ingredientsIdSearchKey: "",
      ingredientsCategorySearchKey: "",
      ingredientSearcList: [],
      ingredientCategoryList: []
    };
  },
  methods: {
    onChangeIngredientCategory() {
      this.getAllIngredients("");
    },
    getAllIngredients(val) {
      this.ingredientsLoading = true;
      let ingredientCategoryId = "";
      if (this.newIngredientCategory) {
        ingredientCategoryId = this.newIngredientCategory._id;
      }
      this.$apollo
        .query({
          query: require("@/stock/api/getAllMealIngredients.graphql"),
          variables: {
            limit: 25,
            skip: 0,
            searchKey: val,
            ingredientCategoryId
          }
        })
        .then(data => {
          console.log(data);
          this.ingredientSearcList =
            data.data.getAllMealIngredients.ingredients;
          this.ingredientsLoading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
    },
    onShowDialog() {
      this.$refs.form.reset();
    },
    createDislikeIngredients() {
      console.log(this.newIngredient);
      let data = this.newIngredient;
      this.$emit("dislikeIngredient", data);
      this.show_dialog_box = false;
    }
  },
  watch: {
    newIngredient: {
      handler(value) {
        if (value) {
          console.log(value.ingredientCategory);
          if (value.ingredientCategory) {
            this.newIngredientCategory = value.ingredientCategory;
          }
        }
      },
      immediate: true
    },
    ingredientsCategorySearchKey: {
      handler(value) {
        if (this.ingredientsCategoryLoading == true) return;
        this.ingredientsCategoryLoading = true;
        this.$apollo
          .watchQuery({
            query: require("@/stock/api/getAllStockCategory.graphql"),
            variables: { searchKey: value }
          })
          .refetch({})
          .then(data => {
            if (data.data) {
              this.ingredientsCategoryLoading = false;
              this.ingredientCategoryList =
                data.data.getAllStockCategory.stockCategory;
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      immediate: true
    },
    ingredientsIdSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length < 1) return;
      this.getAllIngredients(val);
    },
    ingredientsSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length <= 0) {
        return;
      }
      this.getAllIngredients(val);
    }
  }
};
</script>
