<template>
  <div>
    <!-- {{ referralTransactions[0] }} -->
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="referralTransactions"
      class="elevation-1"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.customer="{ item }">
        <v-row>
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
          }}
        </v-row>
      </template>
      <template v-slot:item.credit="{ item }">
        <span v-if="item.credit">
          {{ parseFloat(item.credit).toFixed(3) }} KD
        </span>
        <span v-else> -- </span>
      </template>
      <template v-slot:item.debit="{ item }">
        <span v-if="item.debit">
          {{ parseFloat(item.debit).toFixed(3) }} KD
        </span>
        <span v-else> -- </span>
      </template>
      <template v-slot:item.date="{ item }">
        <span v-if="item.createdAt">
          {{
            new Date(parseInt(item.createdAt)) | moment("MMM DD YYYY hh:mmA")
          }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.status" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.status" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    referralTransactions: {
      require: true,
    },
    loading: {
      default: true,
    },
  },

  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        width: 70,
      },
      {
        text: "Amount Received",
        value: "credit",
        sortable: false,
        width: 120,
      },
      {
        text: "Amount Paid",
        value: "debit",
        sortable: false,
        width: 120,
      },
      { text: "Date", value: "date", sortable: false, width: 190 },
      // { text: "Time", value: "time", sortable: false, width: 120 },
      // { text: "Payment Status", value: "", sortable: false, width: 70 },
    ],
  }),

  methods: {},
};
</script>

