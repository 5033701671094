<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h3 class="black--text text-h5 font-weight-bold ms-3 mb-6">Staff</h3>
    </v-row>

    <v-row align="center" class="mb-5">
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          placeholder="Name or Phone.."
          v-model="search"
          solo
          dense
          hide-details
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" class="d-flex justify-start justify-md-end">
        <span class="me-6">
          <ManagerCreateForm v-if="hasRole(AdminUserPermissions.MANAGER_ADD)" />
        </span>
        <AuditTrialLog class="me-6" auditType="MANAGER_LOG" />
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'Permission',
          }"
          ><v-btn
            color="secondary"
            v-if="hasRole(AdminUserPermissions.PERMISSION_VIEW)"
            class="black--text text-capitalize"
          >
            Permission
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <ManagersTable
      class=""
      :options="options"
      :managerList="managerList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
      @optionsEmit="optionsUpdate"
    ></ManagersTable>
  </v-container>
</template>

<script>
import ManagersTable from "../components/ManagersTable.vue";
import ManagerCreateForm from "../components/ManagerCreateForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  components: {
    ManagersTable,
    ManagerCreateForm,
    AuditTrialLog,
  },
  mounted() {
    this.$root.$on("updateManager", () => {
      this.getAllManagers();
    });
  },
  data() {
    return {
      totalItemsCount: 0,
      search: "",
      managerList: [],
      loading: true,
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      variables: {
        limit: 25,
        skip: 0,
      },
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MANAGER_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getAllManagers();
  },

  methods: {
    optionsUpdate(val) {
      if (!this.loading) {
        this.variables.limit = val.itemsPerPage;
        this.variables.skip = val.page * val.itemsPerPage - val.itemsPerPage;
        this.getAllManagers();
      }
    },
    runSearch() {
      this.getAllManagers();
    },
    clearSearch() {
      this.search = "";
      this.getAllManagers();
    },
    getAllManagers() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllManagers.graphql"),
          variables: {
            skip: this.variables.skip,
            limit: this.variables.limit,
            searchKey: this.search,
          },
        })
        .refetch({})
        .then((data) => {
          this.loading = false;
          let managerList = data.data.getAllManagers.managers;
          this.totalItemsCount = data.data.getAllManagers.totalCount;
          this.managerList = managerList.map((items, index) => ({
            ...items,
            index: index + 1,
          }));
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
