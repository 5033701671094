<template>
  <v-container>
    <v-card
      rounded="lg"
      outlined
      max-height="400px"
      max-width="500px"
      class="mx-auto my-16 pa-10 pa-sm-14"
      color="secondary"
      elevation="4"
    >
      <v-layout justify-center>
        Downloading Invoice
      </v-layout>
    </v-card>
  </v-container>
</template>
<script>
import { printInvoice } from "../methods/a4_invoice";
export default {
  layout: "empty",
  methods: {},

  data: () => ({
    loading: true,
    invoice: ""
  }),

  created() {
    let id = this.$route.params.id;

    let variables = {
      id
    };
    this.$apollo
      .query({
        query: require("../api/getInvoiceById.graphql"),
        variables
      })
      .then(data => {
        if (data.data) {
          this.invoice = data.data.getInvoiceTransactionById;
          this.loading = false;
          printInvoice(this.invoice, "invoice");
        }
      });
  }
};
</script>
