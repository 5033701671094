<template>
  <div v-if="!loading" style="background-color: #e5e5e5">
    <FreezeCustomerPopup :freezeDays="freezeDays" />
    <v-data-table
      :headers="headers"
      :items="freezeDays"
      :items-per-page="10"
      :mobile-breakpoint="0"
    >
      <template v-slot:[`item.last_modified_date`]="{ item }">
        <span v-if="item.last_modified_date != 'Invalid Date'">
          {{ item.last_modified_date | moment("MMM DD YYYY") }}
        </span>
      </template>
      <template v-slot:[`item.isPaused`]="{ item }">
        {{ item.isPaused ? "Paused" : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <UnFreeze :freeze="item" v-if="hasUnfreezeOption(item)" />
      </template>
      <template v-slot:[`item.fromDate`]="{ item }">
        {{ new Date(parseInt(item.fromDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <span v-if="item.endDate">
          {{ new Date(parseInt(item.endDate)) | moment("MMM DD YYYY") }}
        </span>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <v-checkbox
          :disabled="true"
          color="primary"
          disabled-color="primary"
          v-model="item.isActive"
        ></v-checkbox>
      </template>
      <template v-slot:item.updatedBy="{ item }">
        <span v-if="item.updatedBy"
          >{{ item.updatedBy.firstnameEn }} {{ item.updatedBy.lastnameEn }}</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FreezeCustomerPopup from "./FreezeCustomerPopup.vue";
import UnFreeze from "./UnFreeze.vue";
// import moment from "moment";
import { tz } from "moment-timezone";

export default {
  components: { FreezeCustomerPopup, UnFreeze },
  mounted() {
    this.$root.$on("getCustomerFreezeDays", () => {
      this.loading = true;
      this.getCustomerFreezeDays();
    });
  },
  created() {
    this.getCustomerFreezeDays();
  },
  methods: {
    getCustomerFreezeDays() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("./api/getCustomerFreezeDays.graphql"),
          variables: {
            customer: this.$route.query.customerId,
          },
        })
        .refetch({
          customer: this.$route.query.customerId,
        })
        .then((data) => {
          this.loading = false;
          if (data.data.getCustomerFreezeDays) {
            this.freezeDays = data.data.getCustomerFreezeDays;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    hasUnfreezeOption(item) {
      if (item.isActive) {
        return true;
      }
    },
  },

  data() {
    return {
      tz: tz,
      freezeDays: [],
      loading: true,
      headers: [
        { text: "From Date", value: "fromDate", width: 150 },
        { text: "To Date", value: "endDate" },
        { text: "Active", value: "isActive" },
        { text: "Updated By", value: "updatedBy" },
        { text: "Remarks", value: "remark", sortable: false },
        { text: "Auto Expired", value: "isAutoExpired" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
};
</script>
