var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"bg",attrs:{"wrap":""}},[_c('v-flex',{staticClass:" mt-n3",attrs:{"xs3":"","md1":""}},[_c('CustomerAddPauseDate',{attrs:{"pausedDates":_vm.pausedDates,"subscribedPackage":_vm.activePackage},on:{"updatePausedDates":_vm.updatePausedDates}})],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.pausedDates,"items-per-page":10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"MMM DD YYYY"))+" ")]}},{key:"item.universalPause",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isUniversalPause ? "Universal" : "Individual")+" ")]}},{key:"item.last_modified_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.last_modified_date)),"MMM DD YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.checkActivePackage()  && !item.isUniversalPause)?_c('Unpause',{attrs:{"pausedDay":item,"activePackage":_vm.activePackage}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }