<template>
  <v-container fluid class="pa-4 pa-sm-8">
    <v-layout wrap class="d-flex">
      <v-flex>
        <h2 class="black--text text-h5 font-weight-bold">
          <!-- <v-icon size="32" class="primary--text me-2 pb-1">mdi-account</v-icon
          > -->
          Customers
        </h2>
      </v-flex>
      <v-spacer></v-spacer>

      <div class="d-flex mt-4 mt-sm-0">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'UserOTPDetails'
          }"
        >
          <v-btn
            color="primary"
            class="text-capitalize white--text me-3"
            height="40px"
          >
            <v-icon small class="me-1" color="white"
              >mdi-cellphone-message</v-icon
            >Reg. OTP
          </v-btn>
        </router-link>
        <app-customer-form v-if="hasRole(AdminUserPermissions.CUSTOMER_ADD)" />
        <!-- <v-flex class=""> -->

        <!-- </v-flex> -->
      </div>
      <!-- <v-flex xs12 sm3 lg2 class="mb-5 mb-sm-0 ms-sm-n8 ms-md-n14 ms-lg-0"> -->

      <!-- </v-flex> -->

      <!-- <v-spacer></v-spacer> -->
      <!-- <v-flex xs12 sm3 lg2 class="mt-5 mt-sm-0">
        <v-btn outlined color="primary" @click="add" class>+ Add Customer</v-btn>
      </v-flex>-->
      <!-- <v-flex xs12 sm3 lg2 class="mt-5 mt-sm-0 ms-sm-n4">
        <v-btn class="ms-sm-5" outlined color="primary" width="170">
          <v-icon>mdi-arrow-down</v-icon>Import CSV
        </v-btn>
      </v-flex>
      <v-flex xs12 sm3 lg2 class="mt-5 mt-sm-0">
        <v-btn class="ms-sm-5" outlined color="primary" width="170">
          <v-icon>mdi-arrow-up</v-icon>Export CSV
        </v-btn>
      </v-flex>-->
    </v-layout>
    <v-layout justify-end>
      <!-- <v-flex> -->
      <ImportCustomers class="mt-4" />
      <!-- </v-flex>  -->
      <!-- <v-flex> -->
      <ExportCustomerPopup class="mt-4" />
      <!-- </v-flex> -->
    </v-layout>

    <v-layout class="mt-6" wrap>
      <v-flex xs12 sm5 lg3>
        <v-text-field
          placeholder="Search.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:prepend-inner>
            <v-icon
              color="black"
              v-if="search.length > 0"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon v-if="search.length == 0" color="black" @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-flex>
      <v-flex xs12 sm5 lg3 class="ms-sm-5">
        <v-select
          :items="fieldsToSearch"
          v-model="fieldToSearch"
          item-text="key"
          item-value="value"
          label="Field To Search"
          dense
          attach
          solo
        ></v-select>
      </v-flex>

      <v-flex xs12 sm5 lg3 class="ms-sm-5">
        <v-select
          :items="customerStatuses"
          v-model="filter.customerStatus"
          item-text="key"
          item-value="value"
          label="Customer Status"
          dense
          attach
          solo
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs8 sm3 lg3>
        <v-select
          :items="tags"
          v-model="filter.tag"
          item-text="name"
          item-value="_id"
          label="Tags"
          dense
          attach
          solo
        ></v-select>
      </v-flex>
      <v-flex xs8 sm3 lg3 class="ms-sm-5">
        <v-autocomplete
          :items="areas"
          v-model="filter.area"
          item-text="nameEn"
          item-value="_id"
          label="Area"
          dense
          :loading="areasLoading"
          attach
          solo
        ></v-autocomplete>
      </v-flex>

      <v-flex xs8 sm3 lg3 class="ms-sm-5">
        <v-autocomplete
          :items="areaBlocks"
          v-model="filter.block"
          item-text="block"
          item-value="_id"
          label="Block"
          dense
          :loading="areasLoading"
          attach
          solo
        ></v-autocomplete>
      </v-flex>
      <v-flex xs2 sm1 lg1 class="ms-5 mb-4 mb-sm-0 ">
        <v-btn
          class="white--text"
          color="primary"
          height="40px"
          dense
          @click="clearFilter()"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- <v-layout class="mt-3">
    </v-layout> -->

    <v-data-table
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :mobile-breakpoint="0"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.customerId="{ item }">
        {{ padLeft(item.customerId, 4) }}
      </template>
      <template v-slot:item.name="{ item }">
        <template v-if="item.is_deleted">
          <span v-if="item.user" class="mx-auto">
            {{
              item.user.firstnameEn[0].toUpperCase() +
                item.user.firstnameEn.substring(1)
            }}
            <!-- <div class="mx-1" /> -->
            {{
              item.user.lastnameEn[0].toUpperCase() +
                item.user.lastnameEn.substring(1)
            }}
          </span>
        </template>
        <template v-else>
          <router-link
            v-if="item.user"
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item._id },
              query: { customerId: item._id }
            }"
            class="mx-auto"
          >
            {{
              item.user.firstnameEn[0].toUpperCase() +
                item.user.firstnameEn.substring(1)
            }}
            <!-- <div class="mx-1" /> -->
            {{
              item.user.lastnameEn[0].toUpperCase() +
                item.user.lastnameEn.substring(1)
            }}
          </router-link>
        </template>

        <!-- </v-row> -->
      </template>
      <template #item.tag="{ item }">
        <span v-for="tag in item.tags" :key="tag._id">
          {{ tag.name }}<br />
        </span>
      </template>

      <template v-slot:item.area="{ item }">
        <CustomerAddressColumn
          :address="defaultAddress(item.addressDetails)"
          type="area"
        />
      </template>

      <template v-slot:item.block="{ item }">
        <CustomerAddressColumn
          :address="defaultAddress(item.addressDetails)"
          type="block"
        />
      </template>

      <template v-slot:item.building="{ item }">
        <CustomerAddressColumn
          :address="defaultAddress(item.addressDetails)"
          type="building"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row v-if="item.is_deleted">
          <CustomerRestore
            v-if="hasRole(AdminUserPermissions.CUSTOMER_RESTORE)"
            :customer="item"
            :limit="variables.limit"
            :skip="variables.skip"
          />
        </v-row>
        <v-row v-else>
          <CustomerDelete
            v-if="hasRole(AdminUserPermissions.CUSTOMER_DELETE)"
            :customer="item"
            :limit="variables.limit"
            :skip="variables.skip"
          />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CustomerForm from "../components/CustomerForm.vue";
import CustomerDelete from "../components/CustomerDelete.vue";
import CustomerRestore from "../components/CustomerRestore.vue";
import CustomerAddressColumn from "../components/CustomerAddressColumn.vue";
import ExportCustomerPopup from "../components/ExportCustomerPopup.vue";
import ImportCustomers from "../components/ImportCustomers.vue";

export default {
  components: {
    "app-customer-form": CustomerForm,
    CustomerDelete,
    CustomerAddressColumn,
    ExportCustomerPopup,
    ImportCustomers,
    CustomerRestore
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",

      headers: [
        {
          text: "#",
          value: "customerId",
          width: 100
        },
        // { text: "Avatar", value: "photo" },
        { text: "Customer Name", value: "name", width: 150 },
        { text: "Tag", value: "tag", width: 100, sortable: false },
        {
          text: "Contact Number",
          value: "user.phone",
          width: 170,
          sortable: false
        },

        { text: "Area", value: "area", width: 150, sortable: false },
        {
          text: "Block, Street, Jedha",
          value: "block",
          width: 220,
          sortable: false
        },
        { text: "Building", value: "building", width: 200, sortable: false },

        { text: "Action", value: "actions", width: 40, sortable: false }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 25,
        page: 1,
        sortDesc: [false],
        sortBy: []
      },
      variables: {
        limit: 25,
        skip: 0,
        sortDesc: false,
        sortBy: "",
        fieldToSearch: "CUSTOMER_ID"
      },
      loading: true,
      filter: { tag: "", area: "", block: "", customerStatus: "ALL" },
      areas: {},
      areasLoading: false,
      blocks: [],
      areaBlocks: [],
      fieldToSearch: "PHONE",
      fieldsToSearch: [
        { key: "Customer Id", value: "CUSTOMER_ID" },
        { key: "Customer Name", value: "CUSTOMER_NAME" },
        { key: "Contact Number", value: "PHONE" }
      ],
      customerStatuses: [
        { key: "All", value: "ALL" },
        { key: "Disabled", value: "DISABLED" }
      ]
    };
  },
  mounted() {
    this.$root.$on("updateCustomers", () => {
      this.loading = true;
      this.$apollo.queries.customers.refetch({
        variables: this.variables
      });
    });
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllAreas();
    this.getAllBlocks();
  },
  apollo: {
    customerTags() {
      return {
        query: require("../api/customerTags.graphql"),
        result({ data }) {
          this.tags = data.customerTags;
        }
      };
    },
    customers() {
      this.loading = true;
      return {
        query: require("../api/customers.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.customerList = data.customers.customers;
          this.customerList.forEach(element => {
            if (element.addressDetails) {
              element.addressDetails.forEach(address => {
                if (address.area) {
                  let area = this.areas.find(x => x._id == address.area._id);
                  address.area = area;
                }
                if (address.block) {
                  let block = this.blocks.find(x => x._id == address.block._id);
                  address.block = block;
                }
              });
            }
          });
          this.totalItemsCount = data.customers.totalCount;
          // console.log(data);
        }
      };
    }
  },
  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  },
  methods: {
    getAllAreas() {
      this.$apollo
        .query({
          query: require("@/customer/api/getAllAreas.graphql"),
          variables: {
            searchKey: ""
          }
        })
        .then(data => {
          let areas = data.data.getAllAreas.areas;
          areas = areas.sort((a, b) =>
            a.nameEn > b.nameEn ? 1 : b.nameEn > a.nameEn ? -1 : 0
          );

          this.areas = areas;
          this.areasLoading = false;
        });
    },
    getAllBlocks() {
      this.$apollo
        .query({
          query: require("@/customer/api/getAllBlocks.graphql"),
          variables: {
            searchKey: "",
            skip: 0,
            limit: 0
          }
        })
        .then(data => {
          let blocks = data.data.getAllBlocks.blocks;
          blocks = blocks.sort((a, b) =>
            a.block > b.block ? 1 : b.block > a.block ? -1 : 0
          );

          this.blocks = blocks;
        });
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    defaultAddress(addresses) {
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          return defaultAddress;
        } else {
          return addresses[0];
        }
      }
    },
    clearFilter() {
      this.search = "";
      this.areaBlocks = [];
      this.filter.area = "";
      this.filter.tag = "";
      this.filter.block = "";
      this.filter.customerStatus = "ALL";
      this.$apollo.queries.customers.refetch({
        limit: 25,
        skip: 0,
        searchKey: "",
        fieldToSearch: this.fieldToSearch,
        customerStatus: this.filter.customerStatus
      });
    },
    runSearch() {
      this.$apollo.queries.customers.refetch({
        limit: 25,
        skip: 0,
        searchKey: this.search,
        fieldToSearch: this.fieldToSearch,
        customerStatus: this.filter.customerStatus
      });
    },
    clearSearch() {
      this.search = "";
      this.filter.customerStatus = "ALL";
      this.$apollo.queries.customers.refetch({
        limit: 25,
        skip: 0,
        searchKey: "",
        fieldToSearch: this.fieldToSearch,
        customerStatus: this.filter.customerStatus
      });
    }
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        if (!this.loading) {
          this.variables.limit = this.options.itemsPerPage;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;

          this.$apollo.queries.customers.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 500,
            skip: this.variables.skip,
            sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : "",
            sortBy: this.options.sortBy ? this.options.sortBy[0] : "",
            fieldToSearch: this.fieldToSearch,
            customerStatus: this.filter.customerStatus
          });
        }
      },
      deep: true
    },
    filter: {
      handler() {
        if (this.filter.area) {
          this.areaBlocks = this.blocks
            .filter(p => p.area != null)
            .filter(x => x.area._id == this.filter.area);
        }
        if (this.filter.block) {
          let areaBlock = this.blocks.find(x => x._id == this.filter.block);
          if (areaBlock) {
            if (areaBlock.area._id != this.filter.area) {
              this.filter.block = "";
            }
          }
        }
        this.variables.limit = this.options.itemsPerPage;
        this.variables.skip = 0;
        this.$apollo.queries.customers.refetch({
          limit: this.options.itemsPerPage,
          skip: this.variables.skip,
          tag: this.filter.tag,
          area: this.filter.area,
          block: this.filter.block,
          fieldToSearch: this.fieldToSearch,
          customerStatus: this.filter.customerStatus
        });
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  }
};
</script>

<style scoped></style>
