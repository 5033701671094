<template>
  <div v-if="shiftList != null">
    <v-data-table
      :headers="headers"
      :items="shiftList"
      class="elevation-1 mt-10"
      :mobile-breakpoint="0"
      :loading="loading"
    >
      <template v-slot:item.zone="{ item }">
        <span v-if="item.zone"> {{ item.zone.zoneName }}</span>
      </template>
      <template v-slot:item.mngDriver="{ item }">
        <span v-if="item.mngDriver">
          {{ item.mngDriver.user.firstnameEn }}
          {{ item.mngDriver.user.lastnameEn }}
        </span>
      </template>
      <template v-slot:item.evngDriver="{ item }">
        <span v-if="item.evngDriver">
          {{ item.evngDriver.user.firstnameEn }}
          {{ item.evngDriver.user.lastnameEn }}
        </span>
      </template>
      <template v-slot:item.active="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.active" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row justify="end" class="pe-2">
          <ShiftUpdateForm v-if="hasRole(AdminUserPermissions.LOCATION_EDIT)"  :shiftInfo="item" />
          <ShiftDelete v-if="hasRole(AdminUserPermissions.LOCATION_DELETE)"  :shift="item" />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ShiftUpdateForm from "../components/ShiftUpdateForm.vue";
import ShiftDelete from "../components/ShiftDelete";
export default {
  components: {
    ShiftUpdateForm,
    ShiftDelete,
  },
  mounted() {
    this.$root.$on("updateShift", () => {
      this.getAllShifts();
    });
  },

  data: () => ({
    dialog: false,
    shiftList: [],
    loading: true,
    headers: [
      {
        text: "Zone",
        value: "zone",
        width: 230,
        sortable: false,
      },
      { text: "Morning Driver", value: "mngDriver", width: 120 },
      { text: "Evening Driver", value: "evngDriver", width: 120 },
      { text: "Action", value: "actions", sortable: false, width: 70 ,align:"right"},

    ],
  }),
  created() {
    this.getAllShifts();
  },
  methods: {
    getAllShifts() {
      this.loading = true;

      this.$apollo
        .watchQuery({
          query: require("../api/getAllDriverShifts.graphql"),
        })
        .refetch({})
        .then((data) => {
          this.loading = false;
          if (data.data.getAllDriverShifts) {
            this.shiftList = data.data.getAllDriverShifts;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

