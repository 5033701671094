<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="black--text">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-body-1 text-sm-h6 primary--text"
            >Update Appointment</span
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-6"
          @submit="updateAppointment()"
          ref="form"
          v-model="valid"
        >
          <v-card-text>
            <v-container class="mt-sm-n6">
              <v-layout wrap>
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Customer Name</h5>
                </v-flex>
                <v-flex
                  xs8
                  md3
                  class="black--text text-body-2 font-weight-bold"
                >
                  <span v-if="appointment.customer != null">{{
                    appointment.customer.user.firstnameEn
                  }}</span>
                </v-flex>
                <v-flex xs12 sm4 md3 class="mt-sm-3 mt-md-0">
                  <h5 class="black--text text-body-2 mt-3 mt-sm-0">Phone</h5>
                </v-flex>
                <v-flex
                  xs8
                  md3
                  class="
                    text-body-2
                    black--text
                    font-weight-bold
                    mt-sm-3 mt-md-0
                  "
                >
                  <span v-if="appointment.customer != null">
                    {{ appointment.customer.user.phone }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-3 mt-sm-4 mt-md-3">
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Height</h5>
                </v-flex>
                <v-flex xs8 sm5 md2 class="mt-2 mt-sm-0">
                  <v-text-field
                    v-model="appointment.height"
                    :rules="[requiredValidator('height')]"
                    type="number"
                    min="0"
                    @keypress="formatDecimalHeight"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 offset-md-1 offset-lg-1>
                  <h5 class="black--text text-body-2">Weight</h5>
                </v-flex>
                <v-flex xs8 sm5 md2 class="mt-2 mt-sm-0 text-body-2">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="appointment.weight"
                    :rules="[requiredValidator('weight')]"
                    @keypress="formatDecimalWeight"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n3">
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Fat</h5>
                </v-flex>
                <v-flex xs8 sm5 md2 class="mt-2 mt-sm-0 text-body-2">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="appointment.fat"
                    solo
                    @keypress="formatDecimalFat"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 offset-md-1 offset-lg-1>
                  <h5 class="black--text text-body-2">Muscle</h5>
                </v-flex>
                <v-flex xs8 sm5 md2>
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="appointment.muscle"
                    solo
                    @keypress="formatDecimalMuscle"
                    dense
                    class="mt-2 mt-sm-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n3">
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Workout Time</h5>
                </v-flex>
                <v-flex xs8 sm5 md6 class="mt-2 mt-sm-0">
                  <v-text-field
                    v-model="appointment.workoutTime"
                    append-icon="mdi-clock-time-four-outline"
                    dense
                    solo
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n3">
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Sleeping Time</h5>
                </v-flex>
                <v-flex xs8 sm5 md6 class="mt-2 mt-sm-0">
                  <v-text-field
                    v-model="appointment.sleepingTime"
                    append-icon="mdi-clock-time-four-outline"
                    dense
                    solo
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-n3">
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Target Calories</h5>
                </v-flex>
                <v-flex xs8 sm5 md6 class="mt-2 mt-sm-0">
                  <v-text-field
                    v-model="appointment.targetCalories"
                    solo
                    type="number"
                    @keypress="formatDecimalTargetedCalories"
                    min="0"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n3">
                <v-flex xs12 sm4 md3>
                  <h5 class="black--text text-body-2">Appointment Status</h5>
                </v-flex>
                <v-flex xs8 sm5 md6 class="mt-n4">
                  <v-switch
                    v-model="appointment.isAppointmentClosed"
                  ></v-switch>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-n3">
                <v-row>
                  <v-col cols="12" sm="4" md="3">
                    <h4 class="black--text text-body-2">Remark</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="8" class="mt-2 mt-sm-0">
                    <v-textarea
                      solo
                      name="input-4-8"
                      placeholder="Remark"
                      value=""
                      height="75"
                      v-model="appointment.remark"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary black--text"
              class="text-capitalize"
              width="120"
              :loading="btnloading"
              :disabled="!valid"
              @click="updateAppointment"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "@/core/methods/validations";

export default {
  components: {
    // "app-customer-form": CustomerForm,
  },

  data() {
    return {
      workoutTime: "",
      valid: false,
      sleepingTime: "",
      isAppointmentClosed: false,
      date: "",
      requiredValidator: requiredValidator,
      dialog: false,
      time: "",
      btnloading: false,
      menu: "",
      menu1: "",
      menu2: "",
      menu3: "",
      snackbar: false,
      text: "",
      remark: "",
    };
  },
  created() {
    this.appointment.height =
      this.appointment.height == 0 ? "" : this.appointment.height;

    this.appointment.weight =
      this.appointment.weight == 0 ? "" : this.appointment.weight;

    this.appointment.fat =
      this.appointment.fat == 0 ? "" : this.appointment.fat;

    this.appointment.muscle =
      this.appointment.muscle == 0 ? "" : this.appointment.muscle;

    this.appointment.targetCalories =
      this.appointment.targetCalories == 0
        ? ""
        : this.appointment.targetCalories;
  },

  props: {
    appointment: {
      required: true,
    },
  },
  methods: {
    formatDecimalHeight($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.appointment.height != null &&
        this.appointment.height.indexOf(".") > -1 &&
        this.appointment.height.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    formatDecimalWeight($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.appointment.weight != null &&
        this.appointment.weight.indexOf(".") > -1 &&
        this.appointment.weight.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    formatDecimalFat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.appointment.fat != null &&
        this.appointment.fat.indexOf(".") > -1 &&
        this.appointment.fat.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    formatDecimalMuscle($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.appointment.muscle != null &&
        this.appointment.muscle.indexOf(".") > -1 &&
        this.appointment.muscle.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    formatDecimalTargetedCalories($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
      if (
        this.appointment.targetCalories != null &&
        this.appointment.targetCalories.indexOf(".") > -1 &&
        this.appointment.targetCalories.split(".")[1].length > 2
      ) {
        $event.preventDefault();
      }
    },
    updateAppointment() {
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("../api/updateAppointment.graphql"),
          variables: {
            id: this.appointment._id,
            height: this.appointment.height,
            weight: this.appointment.weight,
            fat: this.appointment.fat,
            muscle: this.appointment.muscle,
            workoutTime: this.appointment.workoutTime,
            sleepingTime: this.appointment.sleepingTime,
            targetCalories: this.appointment.targetCalories,
            remark: this.appointment.remark,
            isAppointmentClosed: this.appointment.isAppointmentClosed,
          },
        })
        .then(() => {
          this.$root.$emit("updateAppointment");
          this.close();
          this.btnloading = false;
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>
