<template>
  <div>
    <v-btn @click="dialog = true" v-if="errorLog.length > 0" color="secondary"
      class="ma-2 pa-3 primary--text text-capitalize">
      <v-icon small class="me-1"> mdi-file-document-multiple</v-icon>Error Log
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text">Error Log</span>
          <v-spacer></v-spacer>


          <!-- <v-btn class="ma-2 pa-3 secondary--text text-capitalize" color="secondary_one" :loading="btnloading"
            @click="generateReport">
            Generate
          </v-btn> -->

          <v-btn color="primary" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-card-title>
        <v-divider></v-divider>



        <v-card-text>
          <v-container>
            <v-layout>
              <ul class="mt-5 black--text" v-if="errorLog.length > 0">

                <li v-for="item in errorLog" :key="item"> <a href="#" @click="onErrorCustomerClick(item)">
                    {{ item }}</a>
                </li>

              </ul>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { apolloClient } from "../../vue-apollo-report.js";
export default {
  data() {
    return {
      dialog: false,
      snackbar: false,
      text: "",
      valid: true,
      btnloading: false,
      loading: false,
      showErrorDialog: false
    };
  },
  props: {
    errorLog: {
      required: true,
    },
    date: {
      required: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    generateReport() {
      if (this.btnloading == false) {
        this.btnloading = true;
        apolloClient
          .mutate({
            mutation: require("../api/generateDeliveryReport.graphql"),
            variables: {
              date: this.date,
              isInstantView: false,
              errorFix: true
            }
          })
          .then(data => {
            this.btnloading = false;
            this.$root.$emit("generateReport");
            if (data.data.generateDeliveryReport.errors) {
              this.errorLog = data.data.generateDeliveryReport.errors;
              this.showErrorDialog = true;
            }

          })
          .catch(error => {

            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },
    onErrorCustomerClick(error) {
      var customerId = error.substring(error.indexOf(":") + 1, error.lastIndexOf("-"));
      if (customerId && !Number.isNaN(customerId)) {
        this.loading = true;
        this.$apollo
          .watchQuery({
            query: require("../api/getCustomerByCustomerUniqueId.graphql"),
            customerId: customerId
          })
          .refetch({
            customerId: customerId,
          })
          .then((data) => {
            this.loading = false;
            if (data.data.getCustomerByCustomerUniqueId) {
              this.$router.push({
                name: "CustomerDetails",
                query: { customerId: data.data.getCustomerByCustomerUniqueId._id }
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }

    },
  }

};
</script>