import { jsPDF } from "jspdf";

const defaultFontSize = 10;
const lineHeight = Math.floor(defaultFontSize / 2);
let currentY = 1 * lineHeight;
let pageNumber = 1;
const maxCharPerLine = 185;
const leftPadding = 8;
const pageWidth = 192;
const pageHeight = 280;

let doc = new jsPDF({
    compressPdf: true,
    format: "a4"
});

const print_a4_report = (orders, date) => {
    currentY = 1 * lineHeight;
    doc = new jsPDF({
        compressPdf: true,
        format: "a4"
    });
    doc.setFont("times", "normal");
    printTitle(date)
    if (orders != null) {
        orders.forEach(order => {
            printOrderHeader(order);
           // printCustomerGoal(order)
            printCustomerCalorieRange(order)
            printCustomerComment(order)
            printDislikes(order);
            printAllergies(order);
            printSelectedMenu(order.menu);
            incrementYByPixels(1); // this adds correct overlaping between orders
        });
    }
    saveDoc(date);
}
const printTitle = (date) => {
    doc.setFontSize(defaultFontSize);
    doc.text("Delivery Report : " + date, leftPadding, currentY);
    // doc.text("# means Selected by user", pageWidth - 80, currentY);
    doc.text("Page: " + pageNumber, pageWidth - 14, currentY);
    gotoNextLine(2);
}
const printOrderHeader = (order) => {
    let thisCustomer = order.customer;
    let userName = "";
    if (thisCustomer.user != null) {
        const fName = thisCustomer.user.firstnameEn ?? "";
        const lName = thisCustomer.user.lastnameEn ?? "";
        userName = fName + " " + lName;
    }
    //black rect
    doc.setFillColor("#000000");
    doc.rect(leftPadding, currentY - 5, pageWidth - 64, 6, "F");
    //grey rect
    doc.setFillColor("#AAAAAA");
    doc.rect(pageWidth - 64, currentY - 5, 72, 6, "F");
    doc.rect(leftPadding, currentY - 5, pageWidth, 6);
    doc.setTextColor("#FFFFFF");
    doc.text("Q#" + order.order_id.toString(), 12, currentY - 1);
    doc.text(userName, 30, currentY - 1);
    doc.setTextColor("#000000");
    doc.text(order.package_code, pageWidth - 60, currentY - 1);
}

const printDislikes = (order) => {
    const dislikes = "Dislikes: " + getCustomerDislikes(order);
    const splitTitle = doc.splitTextToSize(dislikes, maxCharPerLine);
    const requiredNumberOfLines = splitTitle.length + 1;
    jumpToNextPageIfNotEnoughSapceInCurrentPage(requiredNumberOfLines);
    doc.rect(leftPadding, currentY + 1, pageWidth, requiredNumberOfLines * lineHeight);
    doc.text(splitTitle, leftPadding + 2, currentY + 5);
    gotoNextLine(requiredNumberOfLines);

}

const printAllergies = (order) => {
    const allergies = "Allergies: " + getCustomerAllergies(order);
    const splitTitle = doc.splitTextToSize(allergies, maxCharPerLine);
    const requiredNumberOfLines = splitTitle.length + 1;
    jumpToNextPageIfNotEnoughSapceInCurrentPage(requiredNumberOfLines);
    doc.rect(leftPadding, currentY + 1, pageWidth, requiredNumberOfLines * lineHeight);
    doc.text(splitTitle, leftPadding + 2, currentY + 5);
    gotoNextLine(requiredNumberOfLines);

}

// const printCustomerGoal = (order) => {
//     const goal = "Customer Goal: " + getCustomerGoal(order.customer.customerGoal);
//     const splitTitle = doc.splitTextToSize(goal, maxCharPerLine);
//     const requiredNumberOfLines = 1;
//     jumpToNextPageIfNotEnoughSapceInCurrentPage(requiredNumberOfLines);
//     doc.rect(leftPadding, currentY + 1, pageWidth, requiredNumberOfLines * lineHeight);
//     doc.text(splitTitle, leftPadding + 2, currentY + 5);
//     gotoNextLine(requiredNumberOfLines);

// }
const printCustomerCalorieRange = (order) => {

    let fromRange = "-"
    if (order.subscribedPackage)
        if (order.subscribedPackage.calorieRange)
            fromRange = order.subscribedPackage.calorieRange.min

    let toRange = "-"
    if (order.subscribedPackage)
        if (order.subscribedPackage.calorieRange)
            toRange = order.subscribedPackage.calorieRange.max

    const range = "Calorie Range : " + fromRange + ' - ' + toRange + "   "
        + `Cutlery Required :${order.isCutleryRequired ? "YES" : "NO"}`
    const requiredNumberOfLines = 1;
    jumpToNextPageIfNotEnoughSapceInCurrentPage(requiredNumberOfLines);
    doc.rect(leftPadding, currentY + 1, pageWidth, requiredNumberOfLines * lineHeight);
    doc.text(range, leftPadding + 2, currentY + 5);

    gotoNextLine(requiredNumberOfLines);

}
//isCutleryRequired
const printCustomerComment = (order) => {
    if (order.customer.comment) {
        const comment = "Comment: " + order.customer.comment;
        const splitTitle = doc.splitTextToSize(comment, maxCharPerLine);
        const requiredNumberOfLines = splitTitle.length + 1;
        jumpToNextPageIfNotEnoughSapceInCurrentPage(requiredNumberOfLines);
        doc.rect(leftPadding, currentY + 1, pageWidth, requiredNumberOfLines * lineHeight);
        doc.text(splitTitle, leftPadding + 2, currentY + 5);
        gotoNextLine(requiredNumberOfLines);
    }

}


// const getCustomerGoal = (goal) => {
//     if (goal == "MAINTAIN_WEIGHT") return "Maintain Weight";
//     if (goal == "LOSE_WEIGHT") return "Lose Weight";
//     if (goal == "GAIN_WEIGHT") return "Gain Weight";
//     else return "-";
// }

const printSelectedMenu = (menu) => {
    if (menu == null) return;
    const filteredMenu = getFilteredMenu(menu);
    for (var i = 0; i < filteredMenu.length; i++) {
        const menuItem = filteredMenu[i];
        if (menuItem != null) {
            doc.setFillColor("#DDDDDD");
            doc.rect(leftPadding, currentY - lineHeight + 1, pageWidth, lineHeight, "DF");
            doc.setFillColor("#FFFFFF");
            doc.text(filteredMenu[i].category?.nameEn ?? "-", leftPadding + 2, currentY);

            gotoNextLine();
            menuItem.menu.forEach(aMenuItem => {
                let nameWithDislikes = getNameWithDislikes(aMenuItem);
                const splitMenuItems = doc.splitTextToSize(nameWithDislikes, maxCharPerLine);
                const requiredNumberOfLines = splitMenuItems.length;

                const isAutoSelected = menuItem.isAutoSelected;

                let allergies = getAllergies(aMenuItem);
                const splitAllergies = doc.splitTextToSize(allergies, maxCharPerLine * 0.85); //0.85 is added since text is bold
                let requiredNumberOfLinesForAllergies = 0;
                if (allergies != "") {
                    requiredNumberOfLinesForAllergies = splitAllergies.length;
                }

                doc.rect(leftPadding, currentY - lineHeight + 1, pageWidth, (requiredNumberOfLines + requiredNumberOfLinesForAllergies) * lineHeight);
                // console.log("menuItem", menuItem);
                doc.addImage(isAutoSelected ? "img/forefinger-pointing-right.png" : "img/forefinger-pointing-up.png", "png", leftPadding + 4, currentY - 3, defaultFontSize / 3, defaultFontSize / 3)

                doc.text(splitMenuItems, 16, currentY);
                gotoNextLine(requiredNumberOfLines);

                //print allergies in highlite
                if (allergies != "") {
                    doc.setFillColor("#FFFF00");
                    doc.rect(leftPadding, currentY - lineHeight + 1, pageWidth, lineHeight * requiredNumberOfLinesForAllergies, "DF");
                    doc.rect(leftPadding, currentY - lineHeight + 1, pageWidth, requiredNumberOfLinesForAllergies * lineHeight);

                    doc.setFont("times", "bold");
                    doc.text(splitAllergies, 16, currentY);
                    doc.setFont("times", "normal");
                    gotoNextLine(requiredNumberOfLinesForAllergies);
                }
            });
        }
    }

}
const getFilteredMenu = (menu) => {
    let filteredMenu = [];
    menu.forEach(m => {
        if (m.menu != null) {
            if (m.menu.length > 0) filteredMenu.push(m);
        }
    });
    return filteredMenu;
}
const getNameWithDislikes = (item) => {
    let dislikedIngrediants = item.dislikedIngrediants.map(
        d => "No " + d.nameEn
    );
    //name
    let nameWithDislike = item.nameEn.trim();
    //add dislike
    if (dislikedIngrediants.length > 0) {
        nameWithDislike =
            nameWithDislike + "(" + dislikedIngrediants.join(", ") + ")";
    }
    return nameWithDislike;
}
const getAllergies = (item) => {
    let allergies = "";
    if (item.allergyIngredients) {
        let allergyIngrediants = item.allergyIngredients.map(
            d => "No " + d.nameEn
        );


        if (allergyIngrediants.length > 0) {
            allergies = allergies + `Allergy: ${allergyIngrediants.join(", ")}`
        }
    }
    return allergies;
}
const getCustomerDislikes = (item) => {
    let allDislikes = "";
    if (item.customer.dislikes != null) {
        item.customer.dislikes = item.customer.dislikes.filter(x => x.nameEn != null);
        allDislikes = item.customer.dislikes.map(x => "No " + x.nameEn);
        return allDislikes.join(", ");
    } else return "-";
}

const getCustomerAllergies = (item) => {
    let allAllegies = "";
    if (item.customer.allergies != null) {
        item.customer.allergies = item.customer.allergies.filter(x => x.nameEn != null);
        allAllegies = item.customer.allergies.map(x => "No " + x.nameEn);
        return allAllegies.join(", ");
    } else return "-";
}
const gotoNextLine = (numberOfLines) => {
    if (numberOfLines == null) numberOfLines = 1;
    currentY += numberOfLines * lineHeight;
    if (currentY > pageHeight) gotoNextPage();
}
const jumpToNextPageIfNotEnoughSapceInCurrentPage = (numberOfLines) => {
    if (numberOfLines == null) numberOfLines = 1;
    if (numberOfLines * lineHeight > pageHeight) gotoNextPage();
}
const incrementYByPixels = (pixels) => {
    currentY += pixels;
    if (currentY > pageHeight) gotoNextPage();
}
const gotoNextPage = () => {
    doc.addPage();
    pageNumber++;
    currentY = 2 * lineHeight;
    doc.text("Page: " + pageNumber, pageWidth - 14, currentY);
    currentY = 3 * lineHeight;
}
const saveDoc = (date) => {
    doc.save(date + " A4 Report.pdf");
}
export { print_a4_report }