<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout class="mt-3 mt-sm-0">
      <h2 class="black--text text-h5 font-weight-bold">Areas and Shifts</h2>
      <v-spacer></v-spacer>
      <ShiftCreateForm
        v-if="hasRole(AdminUserPermissions.LOCATION_ADD)"
        :shift="shift"
      />
    </v-layout>
    <app-shift-table :loading="loading"></app-shift-table>
    <!-- <v-tabs center-active v-model="tabs" class="mt-10 mb-10">
      <v-tab
        class="font-weight-medium text-caption text-sm-body-1 primary--text"
        :key="0"
        >Area</v-tab
      >
      <v-tab
        class="font-weight-medium text-caption text-sm-body-1 primary--text"
        :key="1"
        >Block</v-tab
      >
      <v-tab
        class="font-weight-medium text-caption text-sm-body-1 primary--text"
        :key="2"
        >Zone</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item :key="0">
        <CustomerArea />
      </v-tab-item>
      <v-tab-item :key="1">
        <CustomerBlock />
      </v-tab-item>
      <v-tab-item :key="2">
        <CustomerZone />
      </v-tab-item>
    </v-tabs-items> -->
    <v-btn-toggle
      tile
      color="white"
      v-model="tabs"
      mandatory
      class="mt-10 mb-5"
      dense
      background-color="background"
    >
      <v-btn
        color="white"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleArea"
        elevation="1"
        class="font-weight-bold text-body-2 text-capitalize rounded-lg "
        >Area</v-btn
      >
      <v-btn
        color="white"
        elevation="1"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleBlock"
        class="font-weight-bold text-body-2 text-capitalize ms-6 rounded-lg"
        >Block</v-btn
      >
      <v-btn
        elevation="1"
        color="white"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleZone"
        class="font-weight-bold text-body-2 text-capitalize ms-6 rounded-lg"
        >Zone</v-btn
      >
    </v-btn-toggle>
    <CustomerArea v-show="showArea" />
    <CustomerBlock v-show="showBlock" />
    <CustomerZone v-show="showZone" />
  </v-container>
</template>

<script>
import ShiftTable from "../components/ShiftTable.vue";
import ShiftCreateForm from "../components/ShiftCreateForm.vue";
import CustomerArea from "./CustomerArea";
import CustomerBlock from "./CustomerBlock";
import CustomerZone from "./CustomerZone";
export default {
  components: {
    ShiftCreateForm,
    "app-shift-table": ShiftTable,
    CustomerArea,
    CustomerZone,
    CustomerBlock
  },
  data() {
    return {
      showArea: true,
      showBlock: false,
      showZone: false,
      tabs: "",
      textFields: [],
      tags: [],
      loading: true,
      areaList: [],
      blockList: [],
      totalItemsCount: 0,
      area: {
        NameEn: "",
        NameAr: ""
      },
      shift: {
        NameEn: "",
        NameAr: ""
      },
      block: "",
      zone: "",
      search: ""
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.LOCATION_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
          this.totalItemsCount = data.getAllAreas.totalCount;
        }
      };
    }
  },
  methods: {
    toggleArea() {
      this.showArea = true;
      this.showBlock = false;
      this.showZone = false;
    },
    toggleBlock() {
      this.showArea = false;
      this.showBlock = true;
      this.showZone = false;
    },
    toggleZone() {
      this.showArea = false;
      this.showBlock = false;
      this.showZone = true;
    },
    runSearch() {
      this.$apollo.queries.getAllAreas.refetch({
        searchKey: this.search
      });
    },
    clearSearch() {
      this.search = "";
      this.$apollo.queries.getAllAreas.refetch({
        searchKey: ""
      });
    }
  }
};
</script>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  color: #839e87 !important;
  margin-top: 0px;
  height: 105%;
}
</style>
