<template>
  <v-container class="pa-0 ma-0 ">
    <v-layout justify-end>
      <v-btn
        class="black--text text-capitalize  "
        color="secondary"
        @click="showIngrediantDialog()"
        ><v-icon small class="me-1">mdi-plus</v-icon> Add</v-btn
      >
    </v-layout>

    <v-dialog v-model="show_dialog_box" max-width="600px">
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Unit</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createUnit()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <h4 class="black--text text-body-2 mt-2">Unit Name</h4>
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    solo
                    dense
                    label="Name of unit"
                    :rules="[requiredValidator('unit name')]"
                    v-model="unitName"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <!-- <h4 class="black--text text-body-2">Unit Name</h4> -->
                </v-flex>

                <v-flex xs12 sm8>
                  <v-radio-group
                    v-model="conversionUnit"
                    :mandatory="false"
                    row
                    class="mt-n1"
                  >
                    <v-radio label="Grams" value="GRAMS"></v-radio>
                    <v-radio label="Nos" value="PIECE"></v-radio>
                    <v-radio label="mL" value="ML"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <h4 class="black--text text-body-2 mt-2">Conversion Value</h4>
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    solo
                    dense
                    label="conversion value"
                    :rules="[positive('conversion value')]"
                    type="number"
                    min="0"
                    v-model="conversionValue"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n16">
          <v-spacer></v-spacer>
          <v-btn
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            color="secondary "
            @click="createUnit()"
            :loading="btnloading"
            :disabled="!valid"
            class="mt-8 black--text text-capitalize"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator, positive } from "../../core/methods/validations";

export default {
  data() {
    return {
      btnloading: false,
      isIngredient: true,
      isCommonDislikable: true,
      loading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      requiredValidator: requiredValidator,
      positive,
      unitName: "",
      conversionUnit: "GRAMS",
      conversionValue: ""
    };
  },

  methods: {
    createUnit() {
      console.log(this.nameEn + this.nameAr);
      this.$apollo
        .mutate({
          mutation: require("@/stock/api/createUnit.graphql"),
          variables: {
            name: this.unitName,
            conversionUnit: this.conversionUnit,
            conversionValue: parseFloat(this.conversionValue)
          }
        })
        .then(data => {
          console.log(data);
          this.$refs.form.reset() 
          this.$emit("updatedUnit");
          this.show_dialog_box = false;
        });
    },
    showIngrediantDialog() {
      this.show_dialog_box = true;
    }
  }
};
</script>
