<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h2 class="black--text text-h5 font-weight-bold ms-2 mb-6">
        Celebrities
      </h2>
    </v-row>
    <v-row align="center" class="mt-n4 mt-md-0">
      <v-col cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="Search.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="1" class="mb-4 mb-sm-0">
        <CelebrityCreateForm
          v-if="hasRole(AdminUserPermissions.CELEBRITY_ADD)"
          :Celebrity="Celebrity"
        />
      </v-col>
      <v-col cols="1" class="mb-4 mb-sm-0">
        <AuditTrialLog class="ms-16 ms-md-4 mt-n6" auditType="CELEBRITY_LOG" />
      </v-col>
    </v-row>

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="celebrityList"
      class="elevation-1 mt-10"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.customer="{ item }">
        <router-link
          v-if="item.customer.user"
          :to="{
            name: 'CelebrityInfo',
            params: { celebrityId: item._id },
            query: { celebrityId: item._id },
          }"
          class="mx-auto"
        >
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>

      <template v-slot:item.referralCode="{ item }">
        <span v-if="item.customer.user">
          {{ parseInt(item.customer.user.userId).toString(16).toUpperCase() }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon v-show="item.status" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.status" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <CelebrityDeleteForm
            v-if="hasRole(AdminUserPermissions.CELEBRITY_DELETE)"
            :celebrity="item"
          />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import CelebrityCreateForm from "../components/CelebrityCreateForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import CelebrityDeleteForm from "../components/CelebrityDeleteForm.vue";

export default {
  components: {
    CelebrityCreateForm,
    AuditTrialLog,
    CelebrityDeleteForm,
  },
  mounted() {
    this.$root.$on("updateCelebrity", () => {
      this.getAllCelebrities();
    });
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.CELEBRITY_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getAllCelebrities();
  },
  data() {
    return {
      loading: true,
      totalItemsCount: 25,
      celebrityList: [],
      Celebrity: {
        firstnameEn: "",
        lastnameEn: "",
        password: "",
        phone: "",
      },
      search: "",
      variables: {
        limit: 25,
        skip: 0,
      },
      headers: [
        {
          text: "Sl No",
          value: "index",
          sortable: false,
          width: 70,
        },
        { text: "Name", value: "customer", sortable: false, width: 120 },
        {
          text: "Phone",
          value: "customer.user.phone",
          sortable: false,
          width: 120,
        },
        { text: "Status", value: "status", sortable: false, width: 70 },
        {
          text: "Referral Code",
          value: "referralCode",
          sortable: false,
          width: 20,
        },
        { text: "Action", value: "actions", sortable: false, width: 30 },
      ],
    };
  },

  methods: {
    runSearch() {
      this.getAllCelebrities();
    },
    clearSearch() {
      this.search = "";
      this.getAllCelebrities();
    },
    getAllCelebrities() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllCelebrity.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey: this.search,
          },
        })
        .refetch({})
        .then((data) => {
          this.loading = false;
          this.celebrityList = [];
          if (data.data.getAllCelebrities) {
            this.celebrityList = data.data.getAllCelebrities.celebrities;
            this.celebrityList = this.celebrityList.map((items, index) => ({
              ...items,
              index: index + 1,
            }));
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>