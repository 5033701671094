<template>
  <div>
    <h2 class="black--text text-h5 font-weight-bold mt-6 ">
      Refund
    </h2>
    <v-data-table
      :headers="headers"
      :items="accountList"
      :server-items-length="totalItemsCount"
      class="elevation-1 mt-4"
      :mobile-breakpoint="0"
      :loading="loading"
      :items-per-page="25"
      :options.sync="options"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.siNo`]="{ item }">
        {{
          accountList
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:[`item.customerName`]="{ item }">
        <router-link
          v-if="item.user"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer },
            query: { customerId: item.customer }
          }"
          class="mx-auto"
        >
          {{ capitalize(`${item.user.firstnameEn} ${item.user.lastnameEn}`) }}
        </router-link>
      </template>
      <template v-slot:[`item.contact`]="{ item }">
        {{ item.user.phone }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt))) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount }}
      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{ item.total }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    accountList: [],
    loading: true,

    headers: [
      {
        text: "Si No",
        value: "siNo",
        width: 90
      },
      { text: "Customer Name", value: "customerName", width: 150 },
      { text: "Contact", value: "contact", width: 130 },
      { text: "Date and Time", value: "date", width: 140 },
      { text: "Amount", value: "amount", width: 100 },
      { text: "Total ", value: "total", width: 100 }
    ],
    variables: {
      limit: 25,
      skip: 0
    },
    options: {
      itemsPerPage: 25,
      page: 1
    },
    totalItemsCount: 0
  }),
  watch: {
    options: {
      handler() {
        if (!this.loading) {
          this.variables.limit = this.options.itemsPerPage;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;
          this.getAllCreditNotes();
        }
      },
      deep: true
    }
  },
  created() {
    this.getAllCreditNotes();
  },
  methods: {
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss a");
    },
    getAllCreditNotes() {
      this.$apollo
        .query({
          query: require("../api/getAllCreditNoteTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data.getAllCreditNoteTransactions) {
            this.accountList =
              data.data.getAllCreditNoteTransactions.transactionList;
          }
          this.totalItemsCount =
            data.data.getAllCreditNoteTransactions.totalCount;
          this.loading = false;
        });
    },
    capitalize(data) {
      return data
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
    }
  }
};
</script>
