<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" @click="onEditPromoCode" class="black--text">mdi-square-edit-outline</v-icon>
      </template>

      <v-card class="" color="pop_bg">
        <v-card-title class="py-5 pe-2">
          <span class="text-h6 black--text">Update Promo Code</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form @submit="updatePromoCode()" v-model="valid" class="px-0 px-sm-3" ref="form">
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Promo Code</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="promoCode.promoCode" @keyup="onPromoChange" @click="doesPromoCodeExist = false"
                    :rules="[
                      requiredValidator('promo code'),
                      this.doesPromoCodeExist != true ||
                      'promo code already exists.'
                    ]" solo dense></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mt-2 ms-md-16 pe-md-4">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Currently Active</h4>
                </v-flex>
                <v-flex xs1 sm2 md1 class="mt-n3">
                  <v-checkbox v-model="promoCode.isActive"></v-checkbox>
                </v-flex>
                <v-flex xs6 sm4 md2 class="mt-2 ms-2 ms-sm-n16 ms-md-n6">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Active</h4>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-4">
                <v-flex xs12 sm4 md2 class="mt-n2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Discount</h4>
                </v-flex>
                <v-flex xs12 sm8 md3 class="mt-sm-n4">
                  <v-text-field solo dense v-model="promoCode.discount"
                    :append-icon="inPercentage == true ? 'mdi-percent' : ''" type="number" min="0"
                    :rules="[requiredValidator('discount')]"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mt-md-n2 ms-md-16">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Discount Type</h4>
                </v-flex>
                <v-flex xs12 sm5 md3 class="mt-md-n6">
                  <v-radio-group v-model="inPercentage" :mandatory="true" column>
                    <v-radio label="Fixed Amount" :value="false"></v-radio>

                    <v-radio label="Percentage" :value="true"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-1">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Total Usage Count</h4>
                </v-flex>
                <v-flex xs12 sm8 md3 class="mt-1">
                  <v-text-field solo dense v-model="promoCode.usageCount" type="number" min="0"
                    :rules="[requiredValidator('total usage count')]"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mb-2 mb-sm-0 mt-1 ms-md-16" v-if="inPercentage">
                  <h4 class="text-body-2 black--text">
                    Maximum Discount Amount
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md3 class="mt-1" v-if="inPercentage">
                  <v-text-field solo dense v-model="promoCode.maxDiscountAmount" type="number" class="ms-n1" min="0"
                    :rules="[requiredValidator('discount')]"></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Start Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field solo dense :value="formatStartDate" append-icon="fa-calendar-alt black--text"
                        readonly :rules="[requiredValidator('start date')]" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="startDate" @input="menuStartDate = false"></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs12 sm4 md2 class="ms-md-16 mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">End Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field solo dense :value="formatEndDate" append-icon="fa-calendar-alt black--text" readonly
                        :rules="[requiredValidator('end date')]" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="endDate" @input="menuEndDate = false"></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Title (English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="promoCode.titleEn" :rules="[requiredValidator('title (english)')]" solo dense>
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md2 class="ms-md-16 mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Title (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="promoCode.titleAr" :rules="[requiredValidator('title (arabic)')]" solo dense>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout justify-end class="mt-8 mb-5 text-end">
                <AssignPackages @addNewPackage="addNewPackage" />
              </v-layout>

              <div :key="rand">
                <v-data-table v-if="planChoices.length > 0" :mobile-breakpoint="0" :headers="headers"
                  :items-per-page="-1" :items="planChoices" hide-default-footer class="elevation-1 mt-6 mb-5">
                  <template v-slot:item.choices="{ item }">
                    <div v-if="item.choices">


                      <div v-for="choice in item.choices" :key="choice._id">

                        <v-checkbox v-model="choice.choiceStatus" :label="`${choice.choiceNameEn ||
                        choice.choice.choiceNameEn}`"></v-checkbox>


                      </div>
                    </div>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-checkbox v-model="item.status"></v-checkbox>

                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon color="red" @click="removePackage(item)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pe-6">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="black--text text-capitalize" :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading" @click="updatePromoCode" :disabled="!valid">Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
      </v-snackbar>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
        text
    }}</v-snackbar>
  </div>
</template>

<script>
import {
  requiredValidator,
  percentageValidator
} from "../../core/methods/validations";

import { DATE_FORMAT } from "../../utils/utils";
import moment from "moment";
import AssignPackages from "./AssignPackages.vue";
// import UpdateAssignPackages from "./UpdateAssignPackages.vue";
export default {
  components: {
    AssignPackages,
  //  UpdateAssignPackages
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/menu-packages/api/getAllPackagesAllInfo.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.packagesList = data.getAllPackages.packages;
          this.fetchPackages();
        }
      };
    }
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      percentageValidator,
      btnloading: false,
      dialog: false,
      firstnameEn: "",
      rand: 0,
      lastnameEn: "",
      phone: "",
      password: "",
      inPercentage: false,
      snackbar: false,
      doesPromoCodeExist: false,
      text: "",
      valid: true,
      menuStartDate: false,
      menuEndDate: false,
      startDate: new Date(),
      endDate: new Date(),
      selectPackage: "",
      packagesList: [],
      variables: {
        limit: 25,
        skip: 0
      },
      choices: [],
      isNewPlan: false,
      planChoices: [],
      snack: false,
      snackColor: "",
      snackText: "",
      enableAddBtn: true,
      headers: [

        {
          text: "Package",
          value: "plan.titleEn"
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Choice",
          value: "choices"
        },
        {
          text: "Actions",
          value: "action"
        }
      ]
    };
  },
  props: {
    promoCodeInfo: {
      required: true
    }
  },

  created() {
    this.promoCode = JSON.parse(JSON.stringify(this.promoCodeInfo));
    this.inPercentage = this.promoCode.inPercentage;
    this.startDate = moment(
      new Date(parseInt(this.promoCode.startDate))
    ).format("YYYY-MM-DD");
    this.endDate = moment(new Date(parseInt(this.promoCode.endDate))).format(
      "YYYY-MM-DD"
    );
    // let assignedPackages = new Array();
    // if (this.promoCode.planChoices) {
    //   debugger
    //   console.log("PackageList ",this.packagesList)
    //   this.promoCode.planChoices.forEach(plan => {
    //     console.log("Plan ",plan)
    //     let packageInfo = this.packagesList.find(x => x._id == plan.plan);
    //     console.log("PackageInfo ",packageInfo);

    //     let assignedChoices = new Array();
    //     if (plan.choices) {
    //       plan.choices.forEach(choice => {
    //         console.log("choice ",choice)
    //         let availableChoice = packageInfo.availableChoices.find(
    //           x => x._id == choice.choice._id
    //         );
    //         assignedChoices.push(availableChoice);
    //       });
    //     }
    //     let packageAssign = {
    //       plan: plan,
    //       choices: assignedChoices
    //     };
    //     assignedPackages.push(packageAssign);
    //     console.log("assignedPackages ",this.assignedPackages)
    //   });
    // }
    // this.planChoices = assignedPackages;
    // console.log("planChoice ",this.planChoices)
  },
  methods: {
    updateSelectedPackage(updatedPackage) {
      this.planChoices.map(item => {
        if (item.plan._id === updatedPackage.plan) {
          item.status = updatedPackage.status
          item.choices = updatedPackage.choices
        }
      })
    },
    fetchPackages() {
      let assignedPackages = new Array();
      if (this.promoCode.planChoices) {
        if (this.promoCode.planChoices.length > 0) {
          this.promoCode.planChoices.forEach(plan => {
            // console.log("Plan ",plan)

            let packageInfo = this.packagesList.find(x => x._id == plan.plan._id);

            let assignedChoices = new Array();
            if (plan.choices) {
              if (plan.choices.length > 0) {
                plan.choices.forEach(choice => {
                  let availableChoice = packageInfo.availableChoices.find(
                    x => x._id === choice.choice
                  );
                  if (availableChoice) {
                    availableChoice.choiceStatus = choice.choiceStatus;
                    assignedChoices.push(availableChoice);
                  }
                });
              }
            }

            let packageAssign = {
              status: plan.status,
              plan: plan.plan,
              choices: assignedChoices
            };
            assignedPackages.push(packageAssign);
          });
        }
      }
      this.planChoices = assignedPackages;
    },


    addNewPackage(packageAssign) {
      debugger
      if (this.planChoices.find(x => x.plan._id == packageAssign.plan._id)) {

        this.planChoices.forEach(planChoices => {
          packageAssign.choices.forEach(choice => {
            if (planChoices.choices.find(x => x.choice._id == choice.choice._id)) {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = "Choice already assigned.";
              return false
            }
          });
        });

      }

      this.planChoices.push(packageAssign);



    },
    removePackage(packageToRemove) {
      //  debugger;
      this.planChoices = this.planChoices.filter(
        x => x.plan._id != packageToRemove.plan._id
      );
    },

    onEditPromoCode() {
      // debugger;
      this.rand = this.rand + 1;
      this.promoCode = JSON.parse(JSON.stringify(this.promoCodeInfo));
    },
    onPromoChange() {
      this.promoCode.promoCode = this.promoCode.promoCode.toUpperCase();
    },
    updatePromoCode() {
      let minimumChoiceAmount = Number.MAX_SAFE_INTEGER;
      let assignedPackages = new Array();
      if (this.planChoices && this.planChoices.length > 0) {
        this.planChoices.forEach(packageInfo => {

          debugger
          let packageAssign = {
            status: packageInfo.status,
            plan: packageInfo.plan._id,
            choices: []
          };
          let assignedChoices = new Array();
          if (packageInfo.choices && packageInfo.choices.length > 0) {
            packageInfo.choices.forEach(choice => {
              debugger
              assignedChoices.push({
                choice: choice._id ? choice._id : choice.choice._id,
                choiceStatus: choice.choiceStatus
              });

              if (parseFloat(choice.price) < minimumChoiceAmount)
                minimumChoiceAmount = choice.price;
            });
            packageAssign.choices = assignedChoices;


          } else {
            packageInfo.plan.availableChoices.forEach(choice => {
              if (parseFloat(choice.price) < minimumChoiceAmount)
                minimumChoiceAmount = choice.price;
            });
          }

          assignedPackages.push(packageAssign);
        });

        // if (!this.inPercentage) {
        //   if (parseFloat(this.promoCode.discount) >= minimumChoiceAmount) {
        //     this.snack = true;
        //     this.snackColor = "error";
        //     this.snackText =
        //       "Discount amount should be less than the package choice amount.";
        //     return false;
        //   }
        // }
        this.btnloading = true;
      }
      let input = {
        promoCode: this.promoCode.promoCode,
        startDate: this.startDate,
        endDate: this.endDate,
        titleEn: this.promoCode.titleEn,
        titleAr: this.promoCode.titleAr,
        isActive: this.promoCode.isActive,
        discount: parseFloat(this.promoCode.discount),
        inPercentage: this.inPercentage,
        maxDiscountAmount: parseFloat(this.promoCode.maxDiscountAmount),
        planChoices: assignedPackages,
        usageCount: parseFloat(this.promoCode.usageCount)
      };
      this.$apollo
        .mutate({
          mutation: require("../api/updatePromoCode.graphql"),
          variables: {
            id: this.promoCode._id,
            input: input
          }
        })
        .then(() => {
          this.$root.$emit("updatePromoCode");
          this.text = "Promocode details updated";
          this.dialog = false;
          this.snackbar = true;
          this.btnloading = false;
          this.isNewPlan = false;
        })
        .catch(error => {
          this.btnloading = false;
          if (error.message === "GraphQL error: ALREADY_EXIST") {
            this.doesPromoCodeExist = true;
          }
        });
    },
    close() {
      this.dialog = false;
    }
  },
  computed: {
    formatStartDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    },
    formatEndDate() {
      return this.endDate ? moment(this.endDate).format(DATE_FORMAT) : "";
    },
    packagesWithSno() {
      return this.planChoices.map((d, index) => ({ ...d, sno: index + 1 }));
    }
  }
};
</script>
