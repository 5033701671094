<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="gray1--text">mdi-eye</v-icon>
      </template>
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text">Change History</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-5">
            <code>
              {{ auditLog.additionalDetail }}
            </code>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    auditLog: {
      default: true,
    },
  },
  data() {
    return {
      show_dialog_box: false,
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
  },
};
</script>














