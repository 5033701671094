<template>
    <v-container class="pa-4 pa-sm-8" fluid>

        <v-dialog v-model="dialog" max-width="600px" persistent>

            <v-card class="pt-1 pb-5" color="pop_bg">
                <v-form v-model="valid">
                    <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text">Verification
                    </v-card-text>
                    <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text">
                        <v-flex md6>
                            <v-text-field solo dense label="Password" type="password" v-model="password"
                                :rules="[requiredValidator('password')]"></v-text-field>
                        </v-flex>
                    </v-card-text>


                    <v-card-actions justify="center">
                        <v-row justify="center">
                            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
                            <v-btn color=" mx-2 success" depressed :disabled="!valid" @click="confirmPassword();
                            ">Yes</v-btn>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
            {{ text }}
        </v-snackbar>
        <!-- <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
        <h2 class="black--text text-h5 font-weight-bold">Users</h2>

        <v-layout wrap>
            <v-flex xs6 sm4 md3 class="mt-4">
                <v-select :items="userRoles" item-value="value" item-text="item" label="" v-model="userType"
                    @change="getAllUsers()" dense solo></v-select>
            </v-flex>
            <v-flex xs6 sm4 md3 class="mt-4 ml-3">
                <v-text-field label="Search" v-model="searchKey" @keydown.enter="runSearch()" dense solo>
                </v-text-field>
            </v-flex>

        </v-layout>
        <!-- {{ usersList }} -->
        <v-data-table :mobile-breakpoint="0" id="myTable" :headers="headers" :items="usersList" :loading="loading"
            item-key="slNo" class="elevation-1 mt-4 mt-sm-6" :options.sync="options"
            :server-items-length="totalItemsCount" :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
            <template v-slot:item.slNo="{ item }">{{ item.slNo }}</template>
            <template v-slot:item.name="{ item }">
                <!-- {{ item }} -->
                {{ item.firstnameEn[0].toUpperCase() + item.firstnameEn.substring(1) }}
                {{ item.lastnameEn[0].toUpperCase() + item.lastnameEn.substring(1) }}
            </template>
            <template v-slot:item.referralCode="{ item }">
                {{
                        parseInt(item.userId)
                            .toString(16)
                            .toUpperCase()
                }}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-row>
                    <DeleteUser :user="item" />
                </v-row>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import DeleteUser from "../components/DeleteUser.vue";
import { requiredValidator } from "@/core/methods/validations";
export default {
    components: {
        DeleteUser
    },
    mounted() {
        this.$root.$on("updateUsers", () => {
            this.loading = true;
            this.getAllUsers();
        });
    },
    created() {
        this.dialog = true;
        let isAdmin = localStorage.getItem("DONE_USER_ROLE") == "ADMIN";
        if (!isAdmin) {
            this.$router.push({
                name: "Error404"
            });
        }
        this.getAllUsers();
    },
    watch: {
        options: {
            handler() {
                this.getAllUsers();
                this.tableIndex =
                    this.options.page * this.options.itemsPerPage -
                    this.options.itemsPerPage;
            }
        },
        userType: {
            handler() {
                this.tableIndex = 0;
                this.options.itemsPerPage = 25;
                this.options.page = 1;
            }
        }
    },
    methods: {
        confirmPassword() {
            if (this.password == "B@s1c@313#") {
                this.dialog = false;
            }
        },
        close() {
            this.$router.push({
                name: "Dashboard"
            });
        },
        runSearch() {
            this.getAllUsers();
        },
        getAllUsers() {
            this.loading = true;
            this.$apollo
                .watchQuery({
                    query: require("@/customer/api/getAllUsers.graphql"),
                    variables: {
                        // searchKey: "",
                        conditions: JSON.stringify(this.userType),
                        searchKey: this.searchKey,
                        limit: 25,
                        skip: 0
                    }
                })
                .refetch({
                    conditions: JSON.stringify(this.userType),
                    limit:
                        this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
                    skip:
                        this.options.page * this.options.itemsPerPage -
                        this.options.itemsPerPage
                })
                .then(data => {
                    this.loading = false;
                    if (data.data.getAllUsers) {
                        this.usersList = data.data.getAllUsers.users;
                        this.totalItemsCount = data.data.getAllUsers.totalCount;
                        this.usersList = this.usersList.map((d, index) => ({
                            ...d,
                            slNo: index + this.tableIndex + 1
                        }));
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
        }
    },
    data() {
        return {
            usersList: [],
            loading: true,
            valid: false,
            totalItemsCount: 25,
            btnloading: true,
            tableIndex: 0,
            dialog: true,
            userType: {},
            password: "",
            searchKey: "",
            requiredValidator,
            headers: [
                { text: "Sl.No", value: "slNo", width: 50, sortable: false },
                {
                    text: "Name",
                    value: "name",
                    width: 150,
                    sortable: false
                },
                { text: "Type", value: "role", width: 150, sortable: false },
                { text: "Phone", value: "phone", width: 150, sortable: false },

                { text: "Action", value: "actions", sortable: false, width: 60 }
            ],
            userRoles: [
                {
                    item: "All",
                    value: {}
                },
                {
                    item: "Customer",
                    value: { role: "CUSTOMER" }
                },
                // {
                //   item: "Dietitian",
                //   value: { role: "DIETITIAN" },
                // },
                // {
                //   item: "Driver",
                //   value: { role: "DRIVER" },
                // },
                {
                    item: "Manager",
                    value: { role: "MANAGER" }
                }
                // {
                //   item: "Celebrity",
                //   value: { role: "CELEBRITY" },
                // },
            ],
            options: {
                itemsPerPage: 25,
                page: 1
            },
            variables: {
                skip: 0,
                limit: 25,
                userRole: null
            }
        };
    }
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
    opacity: 0.2 !important;
}
</style>
