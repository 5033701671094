<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
    </template>
    <v-card class="pa-6">
      <v-card-text class="subtitle-1 mb-0 mt-6 text-center font-weight-medium "
        >Do you really want to cancel this appointment ?</v-card-text
      >
      <v-form @submit="deleteDietitianAppointment()" v-model="valid" ref="form">
        <v-layout class="mt-2" wrap justify-center>
          <v-flex class="mt-1" xs12 sm4 md3>
            <h5 class="subtitle-1 ">Reason</h5>
          </v-flex>
          <v-flex xs12 sm7 md7>
            <v-text-field
              v-model="reason"
              label="Reason to cancel this appointment"
              dense
              attach
              :rules="[requiredValidator('reason')]"
              solo
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                deleteDietitianAppointment();
              "
              :disabled="!valid"
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { requiredValidator } from "../../../core/methods/validations";
export default {
  props: {
    dietitianappointment: {
      required: true
    }
  },
  data() {
    return {
      requiredValidator,
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      alertData: {
        value: true,
        msg: " Dietitian Appointment deleted !",
        msg_ar: "تم حذف العضو!",
        type: "success"
      },
      reason: ""
    };
  },
  methods: {
    deleteDietitianAppointment() {
      let id = this.dietitianappointment._id;
      // console.log(id);
      this.$apollo
        .mutate({
          mutation: require("../../api/deleteDietitianAppointment.graphql"),
          variables: {
            id,
            reasonToCancel: this.reason
          }
        })
        .then(() => {
          this.$root.$emit("updateDietitianAppointments");
          this.$emit("updateDietitianAppt"); 
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
          this.$refs.form.reset();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
