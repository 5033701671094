var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"mb-4",attrs:{"wrap":"","justify-end":""}},[_c('DislikeIngredientForm',{on:{"dislikeIngredient":_vm.dislikeIngredient}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.dislikeIngredientsList,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40] }},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dislikeIngredientsList.indexOf(item)+1))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.itemId))]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ingredientCategory.nameEn))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nameEn))]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"gray1--text",attrs:{"color":"red"},on:{"click":function($event){_vm.deleteFromTable(_vm.dislikeIngredientsList.indexOf(item))}}},[_vm._v("mdi-delete-outline")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }