import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import i18n from "./i18n";
import { createProvider } from "./vue-apollo";
import * as VeeValidate from "vee-validate";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { UserRoleSettings, AdminUserPermissions } from "./utils/user-role";
import moment from "vue-moment";
import VueHtmlToPaper from "vue-html-to-paper";
import "./scss/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.filter("truncate", function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
});
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.min.js",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.css",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.min.js",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.js",
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/json/attributes.json",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/json/tags.json",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/json/web-types.json",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.css.map",
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.4.5/vuetify.js.map",
    "../styles/pdf.css",
    "../styles/meal_sticker.css"
  ]
};

Vue.use(VueHtmlToPaper, options);
Vue.config.productionTip = false;
Vue.use(VeeValidate);
//Vue.use(require('vue-moment'));
Vue.use(moment);
Vue.use(Croppa);
Vue.mixin({
  data: function () {
    return {
      AdminUserPermissions: AdminUserPermissions
    };
  },
  methods: {
    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
    formatCurrency(amount) {
      return `${parseFloat(amount).toFixed(2)} KD`

    },
    hasRole(role) {
      return new UserRoleSettings().hasRole(role);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    numberWithCommas(x) {
      if (!x) {
        return 0;
      }
      return parseFloat(x)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
});
extend("required", {
  ...required,
  message: "{_field_}  is required"
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
