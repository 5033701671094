var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.OffDayList,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [ 5, 10, 15, 20] }},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.OffDayList.indexOf(item) + 1)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatShift(item.shift))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.activeOrNot(item.date) == 'Upcoming' ? 'green--text' : 'red--text'},[_vm._v(_vm._s(_vm.activeOrNot(item.date)))])]}},(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_OFF_DAY))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.activeOrNot(item.date) == 'Upcoming')?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.deleteList(_vm.OffDayList.indexOf(item))}}},[_vm._v("mdi-delete")]):_vm._e()]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }