<template>
  <div>
    <span v-for="(item, index) in permissionMenuItems" :key="index">
    <p class="d-block pa-2 primary background--text">{{ item.title }}</p>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        v-for="(data, i) in item.data"
        :key="i"
      >
     
        <v-checkbox v-model="data.value" :label="data.name"></v-checkbox>
        <!-- <v-btn @click="onClick">click</v-btn> -->
      </v-col>
    </v-row>
    </span>
  </div>
</template>

<script>
export default {
  name: "PermissionTable",
  props: {
    permissionMenuItems: {
      required: true
    }
  },
  components: {},
  data() {
    return {
      
         };
  },
  
};
</script>

<style scoped></style>
