<template>
  <v-flex col-12 xs12 md6 lg4 class="px-md-7 px-lg-6">
    <v-card
      height="440"
      tile
      class="pt-8 mx-auto rounded-lg"
      :max-width="
        $vuetify.breakpoint.xl
          ? 400
          : $vuetify.breakpoint.lg
          ? 350
          : $vuetify.breakpoint.md
          ? 390
          : $vuetify.breakpoint.sm
          ? 420
          : 340
      "
    >
      <v-layout>
        <v-flex xs12 sm12 md12 lg12>
          <label class="text-h6 ms-4"> Birthday (Active) </label>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs8 sm3 md6 lg8 class="ml-5">
          <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="fromDate"
                label="From"
                solo
                class="ms-sm-2"
                append-icon="fa-calendar-alt black--text"
                v-bind="attrs"
                v-on="on"
                @click:clear="fDate = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              color="secondary"
              @change="changeDate"
              @input="menu1 = false"
              v-model="fDate"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      <div v-if="loading">
        <v-card-text class="text-xs-center text-md-center">
          <v-progress-circular :size="70" indeterminate class="primary--text" />
        </v-card-text>
      </div>
      <div v-else-if="customers.length > 0">
        <v-virtual-scroll
          height="300"
          item-height="64"
          :bench="benched"
          :items="customers"
          class="mt-4"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item._i">
              <v-list-item-action>
                <v-avatar :width="80" :height="80">
                  <!-- <v-img
                    v-if=""
                    :src="imageUrl + item.customer.user.photo"
                    contain
                    max-width="50"
                    max-height="50"
                    position="left"
                  /> -->
                  <v-img
                    :src="require('../../assets/user.jpg')"
                    contain
                    max-width="50"
                    max-height="50"
                    position="left"
                  />
                </v-avatar>
              </v-list-item-action>

              <v-row>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      :to="{
                        name: 'CustomerDetails',
                        params: { customerId: item._id },
                        query: { customerId: item._id },
                      }"
                      class="mx-auto"
                    >
                      <span class="font-weight-medium">
                        {{ capitalizeFirstLetter(item.user.firstnameEn) }}
                        {{ capitalizeFirstLetter(item.user.lastnameEn) }}
                      </span>
                    </router-link>
                  </v-list-item-title>
                  <v-list-item-title>
                    <span class="font-weight-light"
                      >{{ new Date(parseInt(item.user.dob)) | moment("DD-MMM-YYYY") }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-row>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </div>
      <div v-else>
        <v-list-item-title class="text-center mb-10 mt-16"
          ><v-layout justify-center class="pt-16"
            >******No Birthdays******</v-layout
          ></v-list-item-title
        >
      </div>
    </v-card>
  </v-flex>
</template>
<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  apollo: {
    getBirthdayReport() {
      return {
        query: require("../api/getBirthdayReport.graphql"),
        variables: {
          date: this.fDate,
        },
        result({ data, loading }) {
          this.loading = loading;
          this.customers = data.getBirthdayReport;
        },
      };
    },
  },
  methods: {
    changeDate() {
      this.$apollo.queries.getBirthdayReport.refetch({
        date: this.fDate,
      });
    },
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    },
  },
  data() {
    return {
      menu1: false,
      fDate: format(
        parseISO(moment().add(2, "days").toDate().toISOString()),
        "yyyy-MM-dd"
      ),
      loading: true,
      customers: [],
      benched: 0,
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px",
      },
    };
  },
};
</script>
