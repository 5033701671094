var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"width":_vm.$vuetify.breakpoint.xs ? 100 : 150,"color":"primary","small":_vm.$vuetify.breakpoint.xs,"loading":_vm.loading},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":_vm.$vuetify.breakpoint.xs,"size":_vm.$vuetify.breakpoint.smAndUp ? 17 : ''}},[_vm._v("fa-file-export")]),_vm._v("Export ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"id":"printQuickView","hide-default-footer":"","headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(_vm.padLeft(item.order_id, 4))+" ")]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('span',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn)+" "+_vm._s(item.customer.user.lastnameEn)+" ")]):_vm._e()]}},{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(_vm.padLeft(item.customer.customerId, 4))+" ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.customer.tags)?_c('span',_vm._l((item.customer.tags),function(tag){return _c('v-row',{key:tag._id},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1):_vm._e()]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"addresses":item.address,"type":"area"}})]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"addresses":item.address,"type":"block"}})]}},{key:"item.building",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"addresses":item.address,"type":"building"}})]}},{key:"item.deliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(parseFloat(item.date))),"MMM DD YYYY"))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(item.subscribedPackage.start_date)),"MMM DD YYYY"))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(item.subscribedPackage.end_date)),"MMM DD YYYY"))+" ")]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shift)+" ")]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [(item.driver)?_c('span',[_vm._v(" "+_vm._s(item.driver.user.firstnameEn)+" "+_vm._s(item.driver.user.lastnameEn)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDeliveryStatus(item.delivery_status))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }