<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h2 class="black--text text-h5 font-weight-bold ms-2 mb-6">
        Promo Code
      </h2>
    </v-row>
    <v-row align="center" class="mt-n4 mt-md-0">
      <v-col cols="8" sm="6" lg="4">
        <v-text-field placeholder="Promo Code" v-model="searchKey" solo dense @keydown.enter="runSearch()">
          <template v-slot:append>
            <v-icon v-if="searchKey.length > 0" large color="black" class="me-n2" @click="clearSearch()">mdi-close
            </v-icon>
            <v-icon v-if="searchKey.length == 0" color="black" class="me-n2" @click="runSearch()">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="1" sm="2" lg="1">
        <CreatePromoCode v-if="hasRole(AdminUserPermissions.PROMO_CODE_ADD)" />
      </v-col>
      <v-col cols="12" sm="2" lg="1" class="mb-4 mb-sm-0 text-end text-sm-start mt-n7 mt-sm-0">
        <AuditTrialLog class="mt-sm-n6" auditType="PROMOTIONS_LOG" />
      </v-col>
    </v-row>

    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="promoCodes" class="elevation-1 mt-6"
      :loading="loading" :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
      <template v-slot:item.promoCode="{ item }">
        <router-link v-if="item" :to="{
          name: 'PromoCodeDetails',
          params: { promocodeId: item._id },
          query: { promocodeId: item._id }
        }" class="mx-auto">
          {{ item.promoCode }}
        </router-link>
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-icon v-show="item.isActive" color="primary">mdi-checkbox-marked</v-icon>
        <v-icon v-show="!item.isActive" color="grey">mdi-checkbox-blank-outline</v-icon>
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ new Date(parseInt(item.startDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ new Date(parseInt(item.endDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <UpdatePromoCode :promoCodeInfo="item" :key="rand" v-if="hasRole(AdminUserPermissions.PROMO_CODE_EDIT)" />
          <DeletePromoCode v-if="hasRole(AdminUserPermissions.PROMO_CODE_DELETE)" :promoCode="item" />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import DeletePromoCode from "../components/DeletePromoCode.vue";
import CreatePromoCode from "../components/CreatePromoCode.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import UpdatePromoCode from "../components/UpdatePromoCode.vue";
export default {
  components: {
    CreatePromoCode,
    DeletePromoCode,
    UpdatePromoCode,
    AuditTrialLog
  },
  data() {
    return {
      loading: true,
      promoCodes: [],
      search: "",
      searchKey: "",
      rand: 0,
      headers: [
        { text: "Promo Code", value: "promoCode" },
        { text: "Title (English)", value: "titleEn" },
        { text: "Title (Arabic)", value: "titleAr" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Is Active", value: "isActive" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  mounted() {
    this.$root.$on("updatePromoCode", () => {
      this.loading = true;
      this.$apollo.queries.getAllPromoCodes.refetch({
        variables: { searchKey: "" }
      });
    });
  },
  apollo: {
    getAllPromoCodes() {
      return {
        query: require("../api/getAllPromoCodes.graphql"),
        variables: { searchKey: "" },
        result({ data, loading }) {
          this.loading = loading;
          this.promoCodes = data.getAllPromoCodes;
          this.rand = this.rand + 1;
          console.log(this.promoCodes);
        }
      };
    }
  },
  methods: {
    runSearch() {
      this.$apollo.queries.getAllPromoCodes.refetch({
        searchKey: this.searchKey
      });
    },
    clearSearch() {
      this.searchKey = "";
      this.$apollo.queries.getAllPromoCodes.refetch({
        searchKey: ""
      });
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PROMO_CODE_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
