<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1100px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" v-if="isUserTable == 'true'" color="black"
          >mdi-wallet</v-icon
        >
        <v-btn
          v-on="on"
          color="primary"
          v-if="isUserTable == 'false'"
          class="white--text ma-2 pa-3 text-capitalize"
        >
          Referrals
          <v-icon small class="white--text ms-1">mdi-wallet</v-icon>
        </v-btn>
      </template>
      <v-card color="pop_bg">
        <v-card-title class="ps-4 ps-md-8 pe-1">
          <span class="text-h6 black--text">Referral Transactions</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-2 px-md-6">
          <v-container class="mt-5">
            <!-- {{ user }} -->
            <div>
              <v-row class="mb-n6">
                <v-col cols="6" sm="4" md="3" lg="2" class="black--text"
                  ><h3 class="text-body-2">Refferal Code</h3></v-col
                >
                <v-col md="3" class="black--text" v-if="user.userId">
                  <h4 class="text-body-2">
                    {{
                      parseInt(user.userId)
                        .toString(16)
                        .toUpperCase()
                    }}
                  </h4>
                </v-col>
              </v-row>

              <v-row class="mb-n6">
                <v-col cols="6" sm="4" md="3" lg="2" class="black--text"
                  ><h3 class="text-body-2">Total Received</h3></v-col
                >
                <v-col md="3" class="black--text" v-if="user.userId">
                  <h4 class="text-body-2">
                    {{ parseFloat(totalCredit).toFixed(3) }} KD
                  </h4>
                </v-col>
              </v-row>
              <v-row class="mb-n6">
                <v-col cols="6" sm="4" md="3" lg="2" class="black--text"
                  ><h3 class="text-body-2">Total Paid</h3></v-col
                >
                <v-col md="3" class="black--text" v-if="user.userId">
                  <h4 class="text-body-2">
                    {{ parseFloat(totalDebit).toFixed(3) }} KD
                  </h4>
                </v-col>
              </v-row>
              <v-row class="mb-n6">
                <v-col cols="6" sm="4" md="3" lg="2" class="black--text"
                  ><h3 class="text-body-2">Balance Amount</h3></v-col
                >
                <v-col md="3" class="black--text" v-if="user.userId">
                  <h4 class="text-body-2">
                    {{ parseFloat(pendingAmount).toFixed(3) }} KD
                  </h4>
                </v-col>
              </v-row>
            </div>
            <!-- <v-divider class="mb-6"></v-divider> -->
            <h2 class="black--text text-h6 mt-10 mb-2">Earnings</h2>
            <ReferralTransactionEarningsTable
              :referralTransactions="referralTransactions"
              :loading="transactionListloading"
            />
            <h2 class="black--text text-h6 mt-10 mb-2">Plans Taken</h2>
            <ReferralTransactionPlanTable
              :referralTransactions="referralTransactions"
              :loading="transactionListloading"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReferralTransactionPlanTable from "../components/ReferralTransactionPlanTable.vue";
import ReferralTransactionEarningsTable from "../components/ReferralTransactionEarningsTable.vue";

export default {
  components: {
    ReferralTransactionEarningsTable,
    ReferralTransactionPlanTable
  },
  props: {
    user: {
      require: true
    },
    isUserTable: {
      require: true
    }
  },
  created() {
    this.getAllReferralTransactionsByUser();
    this.totalReferralTransactionAmountByUser();
  },
  data() {
    return {
      btnloading: false,
      show_dialog_box: false,
      transactionListloading: true,
      referralTransactions: [],
      totalCredit: 0,
      totalDebit: 0,
      pendingAmount: 0
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    totalReferralTransactionAmountByUser() {
      this.$apollo
        .watchQuery({
          query: require("@/celebrity/api/totalReferralTransactionAmountByUser.graphql"),
          variables: { id: this.user._id }
        })
        .refetch({})
        .then(data => {
          // console.log(data.data);
          if (data.data.totalReferralTransactionAmountByUser) {
            this.totalCredit =
              data.data.totalReferralTransactionAmountByUser.totalCredit;
            this.totalDebit =
              data.data.totalReferralTransactionAmountByUser.totalDebit;
            this.pendingAmount = this.totalCredit - this.totalDebit;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAllReferralTransactionsByUser() {
      this.transactionListloading = true;
      this.$apollo
        .watchQuery({
          query: require("@/celebrity/api/getAllReferralTransactionsByUser.graphql"),
          variables: { id: this.user._id }
        })
        .refetch({})
        .then(data => {
          this.referralTransactions = [];
          this.transactionListloading = false;
          if (data.data.getAllReferralTransactionsByUser) {
            this.referralTransactions =
              data.data.getAllReferralTransactionsByUser.userReferrals;
            this.referralTransactions = this.referralTransactions.map(
              (items, index) => ({
                ...items,
                index: index + 1
              })
            );
          }
        })
        .catch(error => {
          this.transactionListloading = false;
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
