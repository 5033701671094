<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout justify-end wrap class="mt-3">
      <v-flex xs12 sm3 lg2>
        <h2 class="primary--text">Requests</h2>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="fieldsToSearch"
          v-model="fieldToSearch"
          item-text="key"
          item-value="value"
          label="Field To Search"
          dense
          attach
          solo
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          placeholder="Name.."
          v-model="search"
          solo
          dense
          hide-details
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-4"
      :items-per-page="25"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.name="{ item }">
        <span v-if="item.customer != null">
          <router-link
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item.customer._id },
              query: { customerId: item.customer._id },
            }"
            class="mx-auto"
          >
            {{
              item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
            }}
            {{
              item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
            }}
          </router-link>
        </span>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.appointmentTime="{ item }">
        <span v-if="item.appointmentTime">
          {{ new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY") }}
          {{ item.appointmentTime }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <updateAppointment
            :appointment="item"
            :limit="variables.limit"
            :skip="variables.skip"
          />
        </v-row>
      </template>
    </v-data-table>

    <v-layout class="mt-10">
      <h2 class="primary--text text-h5 font-weight-bold">Calendar View</h2>
    </v-layout>
    <v-row class="mt-6 ms-1">
      <BookingCalender v-if="loading == false" :appoinments="customerList" />
    </v-row>

    <app-closedAppointments-table
      :customerList="customerList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
    ></app-closedAppointments-table>
  </v-container>
</template>

<script>
import closedAppointments from "../components/closedAppointments.vue";
import updateAppointment from "../components/updateAppointment.vue";
import BookingCalender from "../components/BookingCalender.vue";
export default {
  components: {
    "app-closedAppointments-table": closedAppointments,
    updateAppointment,
    BookingCalender,
  },
  mounted() {
    this.$root.$on("updateAppointment", () => {
      this.loading = true;
      this.getAllActiveAppoinmentsByUserID();
    });
  },
  created() {
    this.getAllActiveAppoinmentsByUserID();
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      fieldToSearch: "PHONE",
      fieldsToSearch: [
        { key: "Customer Id", value: "CUSTOMER_ID" },
        { key: "Customer Name", value: "CUSTOMER_NAME" },
        { key: "Contact Number", value: "PHONE" },
      ],
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
        },
        { text: "Customer Name", value: "name", sortable: false },
        {
          text: "Contact Number",
          value: "customer.user.phone",
          sortable: false,
        },
        { text: "Date", value: "createdDate", sortable: false },
        {
          text: "Appointment Date and Time",
          value: "appointmentTime",
          sortable: false,
        },
        { text: "Payment Status", value: "payment_status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 0,
        page: 1,
      },
      variables: {
        limit: 0,
        skip: 0,
      },
      loading: true,
    };
  },

  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.fieldToSearch = "PHONE";
      this.getAllActiveAppoinmentsByUserID();
    },
    runSearch() {
      this.getAllActiveAppoinmentsByUserID();
    },
    getAllActiveAppoinmentsByUserID() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllActiveAppoinmentsByUserID.graphql"),
          variables: {
            skip: 0,
            limit: 0,
            searchField: this.fieldToSearch,
            searchKey: this.search,
          },
        })
        .refetch({
          variables: {
            skip: 0,
            limit: 0,
            searchField: this.fieldToSearch,
            searchKey: this.search,
          },
        })
        .then((data) => {
          this.loading = false;

          this.customerList =
            data.data.getAllActiveAppoinmentsByUserID.appointments;
          this.totalItemsCount =
            data.data.getAllActiveAppoinmentsByUserID.totalCount;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>


<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
