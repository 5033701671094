<template>
  <v-flex col-12 xs12 md6 lg4 class="px-md-7 px-lg-6">
    <v-card
      height="440"
      tile
      class="pt-8 mx-auto rounded-lg"
      :max-width="
        $vuetify.breakpoint.xl
          ? 400
          : $vuetify.breakpoint.lg
          ? 350
          : $vuetify.breakpoint.md
          ? 390
          : $vuetify.breakpoint.sm
          ? 420
          : 340
      "
    >
      <label class="text-h6 ms-4"> Birthday </label>

      <div v-if="customers.length > 0">
        <v-virtual-scroll
          height="300"
          item-height="64"
          :bench="benched"
          :items="customers"
          class="mt-4"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item._i">
              <v-list-item-action>
                <v-avatar :width="80" :height="80">
                  <!-- <v-img
                  v-if=""
                  :src="imageUrl + item.customer.user.photo"
                  contain
                  max-width="50"
                  max-height="50"
                  position="left"
                /> -->
                  <v-img
                    :src="require('../../assets/user.jpg')"
                    contain
                    max-width="50"
                    max-height="50"
                    position="left"
                  />
                </v-avatar>
              </v-list-item-action>

              <v-row>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      :to="{
                        name: 'CustomerDetails',
                        params: { customerId: item._id },
                        query: { customerId: item._id },
                      }"
                      class="mx-auto"
                    >
                      <span class="font-weight-medium">
                        {{ capitalizeFirstLetter(item.user.firstnameEn) }}
                        {{ capitalizeFirstLetter(item.user.lastnameEn) }}
                      </span>
                    </router-link>
                  </v-list-item-title>
                  <v-list-item-title>
                    <span class="font-weight-light"
                      >{{ new Date(parseInt(item.user.dob)) | moment("DD-MMM-YYYY") }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-row>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </div>
      <div v-else>
        <v-list-item-title class="text-center mb-10 mt-16"
          ><v-layout justify-center class="pt-16"
            >******No Birthdays******</v-layout
          ></v-list-item-title
        >
      </div>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  apollo: {
    getTodayCustomerBirthdays() {
      return {
        query: require("../api/getTodayCustomerBirthdays.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.customers = data.getTodayCustomerBirthdays;
        },
      };
    },
  },

  data() {
    return {
      loading: true,
      customers: [],
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px",
      },
    };
  },
};
</script>
