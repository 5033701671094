<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="white--text ma-2 text-capitalize"
          color="primary"
          @click="onEditClick"
        >
          <v-icon small class="white--text">mdi-square-edit-outline</v-icon>
          Edit
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg" v-if="celebrity">
        <v-card-title>
          <span class="text-h6 black--text">Update Celebrity</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8"
          @submit="updateCelebrity()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-layout wrap>
                    <v-flex xs12 sm4 md3>
                      <h4 class="text-body-2 black--text">Celebrity Name</h4>
                    </v-flex>
                    <v-flex xs12 sm8 md9>
                      <v-text-field
                        :value="
                          celebrity.customer.user.firstnameEn +
                            ' ' +
                            celebrity.customer.user.lastnameEn +
                            ' - ' +
                            celebrity.customer.customerId
                        "
                        solo
                        disabled
                        dense
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm3 md3>
                      <h5 class="text-body-2 black--text">Image</h5>
                    </v-flex>

                    <v-flex xs12 sm5 md9>
                      <croppa
                        class="ml-2 mt-2"
                        v-model="myCroppa"
                        :width="170"
                        :height="100"
                        canvas-color="black"
                        :placeholder="'Update image'"
                        :placeholder-font-size="16"
                        placeholder-color="yellow"
                        :accept="'image/*'"
                        :file-size-limit="0"
                        :quality="5"
                        :zoom-speed="3"
                        :disabled="false"
                        :disable-drag-and-drop="false"
                        :disable-click-to-choose="false"
                        :disable-drag-to-move="false"
                        :disable-scroll-to-zoom="false"
                        :disable-rotation="false"
                        :prevent-white-space="false"
                        :reverse-scroll-to-zoom="false"
                        :show-remove-button="true"
                        :remove-button-color="'red'"
                        :remove-button-size="20"
                      >
                        /></croppa
                      >
                      <v-layout>
                        <span style="color: red" class="text-center">
                          ****Use mouse scroll wheel to crop image****
                        </span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap class="mt-4">
                    <v-flex xs12 sm4 md3>
                      <h4 class="text-body-2 black--text">Status</h4>
                    </v-flex>
                    <v-flex xs12 sm8 md9 class="mt-n1">
                      <v-checkbox
                        label="Active"
                        v-model="celebrity.status"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm4 md3>
                      <h4 class="text-body-2 black--text">
                        Description in English
                      </h4>
                    </v-flex>
                    <v-flex xs12 sm8 md9>
                      <v-textarea
                        rows="4"
                        label="About Celebrity"
                        v-model="celebrity.descriptionEn"
                        :rules="[requiredValidator('description')]"
                        solo
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm4 md3>
                      <h4 class="text-body-2 black--text">
                        Description in Arabic
                      </h4>
                    </v-flex>
                    <v-flex xs12 sm8 md9>
                      <v-textarea
                        rows="4"
                        label="About Celebrity "
                        v-model="celebrity.descriptionAr"
                        :rules="[requiredValidator('description in AR')]"
                        solo
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm4 md3>
                      <h4 class="text-body-2 black--text">
                        Commission Percentage
                      </h4>
                    </v-flex>
                    <v-flex xs12 sm8 md9>
                      <v-text-field
                        placeholder="Commission Percentage"
                        v-model="celebrity.commissionPercentage"
                        @keypress="isFloatNumber"
                        :rules="[
                          requiredValidator('commission percentage'),
                          percentageValidator('commission prcentage')
                        ]"
                        name="input-10-1"
                        type="number"
                        append-icon="mdi-percent"
                        min="0"
                        solo
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col cols="4">
                  <v-layout>
                    <v-flex xs12 sm3 md12 class="">
                      <v-img :width="150" class="ms-md-8" :src="getImage()" />
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n6 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="updateCelebrity"
              :disabled="!valid"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import {
  requiredValidator,
  percentageValidator
} from "../../core/methods/validations";
export default {
  data() {
    return {
      image: null,
      imageUrl: process.env.VUE_APP_BASE_URL,
      myCroppa: null,
      requiredValidator,
      percentageValidator,
      btnloading: false,
      dialog: false,
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      celebrity: null
    };
  },
  props: {
    celebrityInfo: {
      required: true
    }
  },
  methods: {
    onEditClick() {
      this.celebrity = JSON.parse(JSON.stringify(this.celebrityInfo));
      let date = new Date();
      this.celebrity.image = this.celebrity.image + "?" + date.getTime();
    },
    updateCelebrity() {
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob(blob => {
          this.image = blob;
          this.updateCelebrityAPI();
        });
      } else {
        this.updateCelebrityAPI();
      }
    },
    updateCelebrityAPI() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateCelebrity.graphql"),
          variables: {
            celebrityId: this.celebrity._id,
            status: this.celebrity.status,
            descriptionEn: this.celebrity.descriptionEn,
            descriptionAr: this.celebrity.descriptionAr,
            image: this.image,
            commissionPercentage: parseFloat(
              this.celebrity.commissionPercentage
            )
          },
          update: () => {
            this.$root.$emit("updategetCelebrityById");
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .then(() => {
          this.text = "Celebrity details updated";
          this.snackbar = true;
          this.snackbarColor = "success";
          this.btnloading = false;
          this.dialog = false;
          this.myCroppa.remove();
        })
        .catch(error => {
          console.log(error);
          this.btnloading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },
    getImage() {
      if (this.celebrity.image) return this.imageUrl + this.celebrity.image;
      else return require("../../assets/no-image.png");
    },
    close() {
      this.dialog = false;
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
