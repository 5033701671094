<template>
  <!-- <v-tooltip bottom> -->
  <!-- <template v-slot:activator="{ on, attrs }"> -->
  <div>
    <span @click="signout" style="cursor: pointer">
      <!-- <v-icon class="mx-4 black--text font-weight-bold" outlined color="dark"
        >mdi-logout</v-icon
      > -->
      <font-awesome-icon
        class="mx-4 white--text font-weight-bold mt-1"
        :icon="['fas', 'sign-out-alt']"
      ></font-awesome-icon>
      <!-- v-on="on" v-bind="attrs" -->
      <label
        class="text-body-1 white--text font-weight-bold"
        style="cursor: pointer"
        >Logout</label
      >
    </span>
  </div>
  <!-- </template> -->
  <!-- <span>{{ $t("user.signOut") }}</span> -->
  <!-- </v-tooltip> -->
</template>

<script>
import { onLogout } from "@/vue-apollo.js";
import { getCurrentUser } from "../methods/helper.js";
export default {
  data() {
    return {
      user: null
    };
  },

  created() {
    this.user = getCurrentUser.user;
  },
  methods: {
    signout() {
      onLogout(this.$apollo.provider.defaultClient);
      localStorage.clear() 
      localStorage.removeItem("DONE_USER");
      localStorage.removeItem("DONE_USER_ROLE");
      localStorage.removeItem("DONE_USER_PERMISSIONS");
      this.$router.replace({ path: "/" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
