<template>
  <v-container>
    <!-- <PendingSalesOrder :tab="3" />  -->
    <SalesOrderPendingApproved /> 
  </v-container>
</template>

<script>
import { isDietitian } from "@/user/methods/helper.js";
// import PendingSalesOrder from "@/orders/components/PendingSalesOrder.vue";
import SalesOrderPendingApproved from "@/orders/components/SalesOrderPendingApproved.vue";
export default {
  components: {
    // PendingSalesOrder,
    SalesOrderPendingApproved
  },
  created() {
    if (!isDietitian()) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>

<style scoped></style>
