<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <div class="text-end">
          <v-btn
            color="secondary"
            v-on="on"
            class="primary--text text-capitalize"
            @click="OpenSynchronize()"
            >Sync</v-btn
          >
        </div>
      </template>
      <v-card class="pt-3 pb-5" color="pop_bg">
        <v-card-text
          class="subtitle-1 text-center black--text"
          v-if="selectedItemsCount > 0"
          >Do you really want to sync <strong>Selected meals ?</strong> (count:
          {{ selectedItemsCount }})
        </v-card-text>
        <v-card-text class="subtitle-1 text-center black--text" v-else
          >Do you really want to sync <strong> All meals?</strong></v-card-text
        >

        <v-card-text v-if="error" class="error--text font-weight-bold">{{
          error
        }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                syncMeals();
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success"
      >Meals synced PC values.</v-snackbar
    >
  </v-container>
</template>
<script>
export default {
  props: {
    menuList: {
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      selectedItemsCount: 0,
      button_loading: false,
      show_alert: false,
      error: null,
      snackbar: false,
    };
  },
  methods: {
    OpenSynchronize() {
      let selectedItems = this.menuList.filter((x) => x.isSelect == true);
      this.selectedItemsCount = selectedItems.length;
    },
    syncMeals() {
      let selectedItems = this.menuList.filter((x) => x.isSelect == true);
      let ids = null;
      if (selectedItems.length) {
        ids = selectedItems.map((x) => x._id);
      }

      this.$apollo
        .mutate({
          mutation: require("../api/onSynchronizeMealPCValues.graphql"),
          variables: {
            ids: ids,
          },
        })
        .then(() => {
          this.snackbar = true;
          this.$root.$emit("updateMenuitems");
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
        })
        .catch((error) => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>
