<template>
  <v-container>
    <v-dialog v-model="showDialog" max-width="500">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red" class="ms-2">mdi-play</v-icon>
      </template>
      <v-card class="pa-8" color="pop_bg">
        <v-form @submit="saveDate()" v-model="valid" ref="form">
          <v-row>
            <v-card-title class="text-h6 black--text mt-n4"
              >Restart Plan From
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-4">
            <v-col cols="12" md="10" class="mx-auto">
              <v-row justify="center">
                <vc-date-picker
                  :key="number"
                  :min-date="minDate"
                  v-model="startDate"
                  :disabled-dates="disabledDates"
                />
              </v-row>
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-6">
            <v-flex xs12 sm7 md8>
              <v-text-field
                solo
                dense
                name="input-7-4"
                label="Remark"
                v-model="remark"
                :rules="[requiredValidator('remark')]"
              ></v-text-field>
            </v-flex>
          </v-row>

          <v-row justify="end" class="mt-6">
            <v-btn
              :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
              class="black--text me-3 text-capitalize"
              color="secondary"
              @click="saveDate()"
              :disabled="!valid"
              :loading="btnloading"
            >
              <v-icon left>mdi-play</v-icon>
              Start
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import VCalendar from "v-calendar";
import { tz } from "moment-timezone";
import moment from "moment";
import { TIME_ZONE } from "../../../utils/utils";
import { requiredValidator } from "@/core/methods/validations";

Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
export default Vue.extend({
  components: {},
  watch: {
    "$route.query.customerId": {
      handler() {
        this.getCustomerDetails();
      },
    },
  },
  props: {
    freeze: {
      required: true,
    },
  },
  apollo: {
    getMealSelectionDate() {
      return {
        query: require("@/customer/components/salesOrder/api/getMealSelectionDate.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;

          let packageSelectionDate = moment(
            new Date(parseInt(data.getMealSelectionDate.packageSelectionDate))
          );

          this.minDate = packageSelectionDate.format("YYYY-MM-DD");

          let cond = moment(new Date(this.freeze.fromDate)).isAfter(packageSelectionDate);
          if (cond) {
            this.minDate = moment(new Date(this.freeze.fromDate)).format("YYYY-MM-DD");
          }
        },
      };
    },
  },
  created() {
    this.getCustomerFreezeDays();
  },
  methods: {
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },

    checkActivePackage() {
      if (!this.subscribedPackage) {
        return true;
      } else if (this.subscribedPackage.payment_status === "PENDING") {
        return true;
      }
      const today = new Date();
      const currentDate = moment(today);
      const endDate = moment(new Date(parseInt(this.subscribedPackage.end_date))).tz(
        TIME_ZONE
      );
      let dateDiff = endDate.diff(currentDate, "days");
      if (dateDiff < 0) {
        return true;
      }
      return false;
    },
    onAddClick() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },

    getCustomerFreezeDays() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/customer/components/freeze/api/getCustomerFreezeDays.graphql"),
          variables: {
            customer: this.$route.query.customerId,
          },
        })
        .refetch({
          customer: this.$route.query.customerId,
        })
        .then((data) => {
          this.loading = false;
          debugger;
          if (data.data.getCustomerFreezeDays) {
            this.freezeDays = data.data.getCustomerFreezeDays;
            this.setUnavailableDates();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    setUnavailableDates() {
      var dateArray = new Array();
      debugger
      if (this.freezeDays.length > 0) {
        this.freezeDays.forEach((freeze) => {
          if (moment(this.minDate) < moment(new Date(parseInt(freeze.fromDate)))) {
            this.minDate = new Date(
              moment(new Date(parseInt(freeze.fromDate)))
                .add(1, "day")
                .add(3, "h")
                .toDate()
            );
          }
          for (
            var m = moment(new Date(parseInt(freeze.fromDate)));
            m.isBefore(moment(new Date(parseInt(freeze.endDate))));
            m.add(1, "days")
          ) {
            console.log(m.format("YYYY-MM-DD"));
            dateArray.push(m.toDate());
          }
        });
      }

      this.disabledDates = [];

      dateArray.forEach((element) => {
        let paused = {
          start: new Date(moment(element).add(3, "h").toDate()),
          end: new Date(moment(element).add(3, "h").toDate()),
        };
        this.disabledDates.push(paused);
      });
    },
    saveDate() {
      if (!this.startDate) {
        return false;
      }
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("./api/removePackageFreeze.graphql"),
          variables: {
            input: {
              date: moment(this.startDate).format("MM-DD-YYYY"),
              customer: this.$route.query.customerId,
              remark: this.remark,
            },
          },
          update: () => {
            this.btnloading = false;
            this.showDialog = false;
          },
        })
        .then((data) => {
          console.log(data);
          this.startDate = "";
          this.$root.$emit("updateGetCustomerById");
          this.$root.$emit("getCustomerFreezeDays");
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
  },

  data() {
    return {
      tab: null,
      loading: true,
      requiredValidator,
      valid: false,
      tz: tz,
      btnloading: false,
      startDate: null,
      disabledDates: [],
      days: [],
      number: 0,
      showDialog: false,
      minDate: null,
      maxDate: null,
      weekDays: "",
      remark: "",
      freezeDays: [],
    };
  },
});
</script>
