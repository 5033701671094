var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"color":"primary","small":_vm.$vuetify.breakpoint.xs,"width":_vm.$vuetify.breakpoint.xs ? 100 : ''},on:{"click":_vm.showDialog}},[_vm._v(" Quick View ")]),_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2 pa-sm-3",attrs:{"color":"bg"}},[_c('v-overlay',{attrs:{"value":_vm.btnloading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{staticClass:"my-3"},[_c('v-card-title',{staticClass:"black--text text-h6 py-0  ms-7"},[_vm._v("Instant View")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-layout',{staticClass:"mt-2 ms-n5 ps-5 ps-sm-0",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mt-5 mt-sm-0 ms-sm-5",attrs:{"xs8":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate,"readonly":"","label":"Date","solo":"","append-icon":"fa-calendar-alt black--text","dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-flex',{staticClass:"mt-md-0 ms-sm-5",attrs:{"xs5":"","sm2":"","md2":"","lg1":""}},[_c('v-btn',{staticClass:"black--text text-capitalize",attrs:{"color":"secondary","small":_vm.$vuetify.breakpoint.xs,"width":_vm.$vuetify.breakpoint.xs ? 100 : ''},on:{"click":_vm.searchDelivery}},[_vm._v("Search")])],1),_c('v-flex',{staticClass:"mt-md-0 ms-sm-5",attrs:{"xs5":"","sm2":"","md2":"","lg2":""}},[(_vm.hasRole(this.AdminUserPermissions.QUICK_VIEW_GENERATE))?_c('v-btn',{staticClass:"black--text text-capitalize",attrs:{"color":"secondary","small":_vm.$vuetify.breakpoint.xs,"width":_vm.$vuetify.breakpoint.xs ? 100 : ''},on:{"click":_vm.generateReport}},[_vm._v(" Generate")]):_vm._e()],1),_c('v-flex',{staticClass:"mt-md-0 ms-sm-5",attrs:{"xs12":"","sm2":"","md2":"","lg2":""}},[_c('ErrorLog',{staticClass:"mt-4 mt-sm-n2 ms-n2",attrs:{"errorLog":_vm.errorLog}})],1),_c('v-flex',{staticClass:"mt-md-0 ms-sm-5",attrs:{"xs12":"","sm2":"","md2":"","lg2":""}},[_c('ExportInstatView',{attrs:{"headers":_vm.headers,"date":_vm.date}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.itemsWithSno,"loading":_vm.loading,"item-key":"slNo","search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(_vm.padLeft(item.order_id, 4))+" ")]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('span',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(_vm.padLeft(item.customer.customerId, 4))+" ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.customer.tags)?_c('span',_vm._l((item.customer.tags),function(tag){return _c('v-row',{key:tag._id},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1):_vm._e()]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"addresses":item.address,"type":"area"}})]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"addresses":item.address,"type":"block"}})]}},{key:"item.building",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"addresses":item.address,"type":"building"}})]}},{key:"item.deliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(parseFloat(item.date))),"MMM DD YYYY"))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(item.subscribedPackage.start_date)),"MMM DD YYYY"))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getDate(new Date(item.subscribedPackage.end_date)),"MMM DD YYYY"))+" ")]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shift)+" ")]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [(item.driver)?_c('span',[_vm._v(" "+_vm._s(item.driver.user.firstnameEn)+" "+_vm._s(item.driver.user.lastnameEn)+" ")]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-row',[_c('v-card-title',{staticClass:"text-h6 black--text mt-n4"},[_vm._v("Meals Log")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.showErrorDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-spacer'),(_vm.errorLog.length > 0)?_c('ul',{staticClass:"mt-5"},_vm._l((_vm.errorLog),function(item){return _c('li',{key:item},[_vm._v(_vm._s(item))])}),0):_c('div',[_vm._v("All meals are generated successfully!")])],1)],1),_c('v-snackbar',{attrs:{"timeout":"3000","right":"","top":"","color":"error"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.text))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }