<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1100px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" class="ms-2">mdi-content-copy</v-icon>
      </template>
      <v-card class="ma-0 pa-3 pa-sm-6" color="pop_bg">
        <v-card-text>
          <v-row>
            <h3 class="black--text text-h5 text-sm-h6 ms-2 mb-4">
              Duplicate Meal
            </h3>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider class="mt-2"></v-divider>
          <v-form
            class="pa-sm-8"
            @submit="duplicateMenuItem()"
            v-model="valid"
            ref="form"
          >
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text">Meal Name</h5>
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field
                  label="Meal Name"
                  v-model="nameEn"
                  :rules="[requiredValidator('meal name')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                <v-text-field
                  v-model="nameAr"
                  label="Meal Name Arabic"
                  :rules="[requiredValidator('meal name arabic')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row justify-end class="mt-8 mb-n5">
              <v-btn
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                color="secondary"
                class="black--text text-capitalize"
                @click="duplicateMenuItem()"
                :loading="btnloading"
                :disabled="!valid"
                >Duplicate Meal</v-btn
              >
            </v-layout>
          </v-form>

          <v-snackbar
            v-model="snack"
            timeout="3000"
            right
            top
            color="success"
            >{{ snackText }}</v-snackbar
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import AddImage from "../../core/components/AddImage";

import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    menuItemInfo: {
      type: Object
    }
  },

  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,

      //form data
      nameEn: this.menuItemInfo.nameEn,
      nameAr: this.menuItemInfo.nameAr
    };
  },

  methods: {
    //utility methods
    close() {
      this.show_dialog_box = false;
    },
    //api methods
     duplicateMenuItem() {
      this.btnloading = true;
       this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/duplicateMenuItem.graphql"),
          variables: {
            id: this.menuItemInfo._id,
            nameEn: this.nameEn,
            nameAr: this.nameAr
          }
        })
        .then(() => {
          this.active = true;
          try {
            this.text = "Menu Item Duplicated";
            this.snackbar = true;
          } catch (e) {
            console.log(e);
            console.log("Something bad happend- MenuForm.vue");
          }

          this.btnloading = false;
          this.show_dialog_box = false;
          this.$root.$emit("updateMenuitems");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors, "graphql errors");
          }
          this.btnloading = false;
        });
        

    }
  }
};
</script>
