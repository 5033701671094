<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-5" color="pop_bg">
        <v-row>
          <v-card-title>
            <p class="black--text text-h6 mt-n2 ms-2">Set Calorie Value</p>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <!-- {{customer}} -->
            <!-- <h3 class="ms-sm-8 black--text text-body-1 font-weight-bold">
              Enter Meal Category Details
            </h3> -->
            <p
              v-if="salesOrder.package.calorieRange.to"
              class="black--text text-h6 mt-n2 ms-2"
            >
              {{
                `The Package Calorie Range is from ${salesOrder.package.calorieRange.from} to ${salesOrder.package.calorieRange.to}`
              }}
            </p>
            <v-form
              class="pa-sm-8"
              @submit="updateCalorieValue()"
              v-model="valid"
              ref="form"
            >
              <v-layout wrap>
                <v-flex class="mt-2 " xs12 sm3 md3>
                  <h5 class="text-body-2 black--text mt-6   mt-sm-0">
                    Customer Name
                  </h5>
                </v-flex>
                <v-flex xs10 sm6 md5>
                  <v-text-field
                    label="Customer Name"
                    :value="getFullName()"
                    solo
                    readonly
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md3 class="mt-2">
                  <h5 class="text-body-2 black--text">
                    Gender
                  </h5>
                </v-flex>
                <v-flex xs10 sm6 md5>
                  <v-text-field
                    :value="gender(salesOrder.user.gender)"
                    label="gender"
                    solo
                    dense
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs6 sm3 md3 class="mt-2">
                  <h5 class="text-body-2 black--text">Calorie Range</h5>
                </v-flex>
                <v-flex xs10 sm3 md2 class="mr-md-14   mr-sm-1 ">
                  <v-text-field
                    solo
                    dense
                    label="min"
                    v-model="minCalorie"
                    type="number"
                    :rules="[positive('calorie value')]"
                  ></v-text-field>
                  <!-- , lesser -->
                </v-flex>
                <v-flex xs10 sm3 md2>
                  <v-text-field
                    solo
                    dense
                    label="max"
                    v-model="maxCalorie"
                    type="number"
                    :rules="[positive('calorie value')]"
                  ></v-text-field>
                  <!-- , greater -->
                </v-flex>
              </v-layout>

              <v-layout row justify-end class="mt-4 mb-n6 mb-sm-n12">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="black--text text-capitalize"
                  @click="updateCalorieValue()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >{{ forUpdation ? "Update" : "Save" }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackColor"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator, positive } from "../../core/methods/validations";
export default {
  props: {
    salesOrder: {
      required: true
    },
    forUpdation: {
      required: true
    }
  },
  //   created() {
  //     this.customerSalesOrder = JSON.parse(JSON.stringify(this.salesOrder));
  //   },
  data() {
    return {
      requiredValidator: requiredValidator,
      positive,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      snackColor: "success",
      customerSalesOrder: "",
      show_dialog_box: false,
      customer: "",
      minCalorie: "",
      maxCalorie: ""
    };
  },
  methods: {
    // lesser() {
    //   let max = parseFloat(this.maxCalorie);
    //   let min = parseFloat(this.minCalorie);
    //   if (max < min) {
    //     return `min value should be less than max calorie value`;
    //   }
    //   return true;
    // },
    gender(data) {
      return data
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
    },

    // greater() {
    //   let max = parseFloat(this.maxCalorie);
    //   let min = parseFloat(this.minCalorie);
    //   if (max < min) {
    //     return `should be greater than ${min} (min calorie value)`;
    //   }

    //   return true;
    // },
    onShowDialog() {
      this.customerSalesOrder = JSON.parse(JSON.stringify(this.salesOrder));
      if (this.forUpdation) {
        if (this.customerSalesOrder.calorie_range) {
          this.maxCalorie = parseFloat(
            this.customerSalesOrder.calorie_range.max
          );
          this.minCalorie = parseFloat(
            this.customerSalesOrder.calorie_range.min
          );
        }
      }
      // this.getCustomerDetails()
    },
    close() {
      this.show_dialog_box = false;
      // this.$refs.form.reset();
    },
    getCustomerDetails() {
      let variables = {
        id: this.customerSalesOrder.customer
      };
      this.$apollo
        .query({
          query: require("@/customer/api/getCustomerById.graphql"),
          variables: variables
        })
        .then(data => {
          console.log(data);
          if (data.data) {
            this.salesOrderList = [];
            this.loading = false;
            this.customer = data.data.getCustomerById;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getFullName(gender) {
      if (gender) {
        return this.getName(gender);
      } else {
        let data = "";
        let firstName = "";
        let lastName = "";
        if (this.salesOrder.user) {
          if (this.salesOrder.user.firstnameEn) {
            firstName = this.getName(this.salesOrder.user.firstnameEn);
          }
          if (this.salesOrder.user.lastnameEn) {
            lastName = this.getName(this.salesOrder.user.lastnameEn);
          }
        }
        data = `${firstName} ${lastName}`;
        return data;
      }
    },
    getName(name) {
      return name.replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
    },
    updateCalorieValue() {
      if (parseFloat(this.minCalorie) > parseFloat(this.maxCalorie)) {
        this.text =
          "Max calorie value should be greater than Min calorie value";
        this.snackColor = "red";
        this.snackbar = true;
        return;
      }
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/orders/api/updateSalesOrderStatus.graphql"),
          variables: {
            input: {
              customerId: this.customerSalesOrder.customer,
              subscribedPackageId: this.customerSalesOrder._id,
              calorieRange: {
                min: parseFloat(this.minCalorie),
                max: parseFloat(this.maxCalorie)
              }
            }
          },
          update: () => {
            this.$refs.form.reset();
            this.$emit("updateSalesOrderStatus"); 
            this.btnloading = false;
            this.show_dialog_box = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    }
  }
};
</script>
