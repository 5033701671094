<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="selectedMealsList"
      :items-per-page="10"
      :mobile-breakpoint="0"
    >
      <template v-slot:[`item.date`]="{ item }">
        <ShowAllMenuItems :dateMenu="item" />
      </template>

      <!-- <template v-slot:[`item.date`]="{ item }">
        {{ item.date | moment("MMM DD YYYY") }}
      </template> -->
      <template v-slot:[`item.last_modified_date`]="{ item }">
        <span v-if="item.last_modified_date != 'Invalid Date'">
          {{ item.last_modified_date | moment("MMM DD YYYY") }}
        </span>
      </template>
      <template v-slot:[`item.isPaused`]="{ item }">
        {{ item.isPaused ? "Paused" : "" }}
      </template>
      <template v-slot:item.actions="{ item }">
     
        <span v-if="!hasActiveFreeze">
          <CustomerMealSelectionPopup
            :selectedDay="item"
            v-if="checkActivePackage()"
            :activePackage="activePackage"
          />
        </span>
        <span v-else>
          <strong class="red--text"> The Plan is Freezed </strong>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ShowAllMenuItems from "./ShowAllMenuItems.vue";
import CustomerMealSelectionPopup from "./CustomerMealSelectionPopup.vue";
import moment from "moment";
import { tz } from "moment-timezone";
import { TIME_ZONE, Utils } from "../../../utils/utils";
export default {
  components: { ShowAllMenuItems, CustomerMealSelectionPopup },

  props: {
    selectedPackage: {
      require: true,
    },
  },

  watch: {
    selectedPackage: {
      handler(newVal) {
        this.activePackage = newVal;
        this.getAllMenu();
      },
      deep: true,
    },
  },
  methods: {
    async getCustomerFreezeDays() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.$apollo
          .watchQuery({
            query: require("@/customer/components/freeze/api/getCustomerFreezeDays.graphql"),
            variables: {
              customer: this.$route.query.customerId,
            },
          })
          .refetch({
            customer: this.$route.query.customerId,
          })
          .then((data) => {
            this.loading = false;
            if (data.data.getCustomerFreezeDays) {
              this.freezeDays = data.data.getCustomerFreezeDays;
            }
            if (this.freezeDays.find((x) => x.isActive)) {
              this.hasActiveFreeze = false; //temp removed
            }
            resolve(this.freezeDays);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            reject(error);
          });
      });
    },
    isPackageNotAvailableOnThisDay(date, availabelWeekDaysInNumbers) {
      let day = date.day();
      let available = availabelWeekDaysInNumbers.filter((x) => x == day);
      return available.length > 0 ? false : true;
    },
    checkActivePackage() {
      return Utils.checkIsActiveOrUpcomingPackage(this.activePackage);
    },

    getAllDates(activePackage, freezeDays) {
      let fromDate = new Date(parseInt(activePackage.start_date));
      const toDate = new Date(parseInt(activePackage.end_date));
      let momentFromDate = moment(fromDate).add(3, "h").startOf("day");
      let momentToDate = moment(toDate).add(3, "h").endOf("day");
      var dateArray = new Array();

      let availableWeekDays = activePackage.package.availableChoices[0].availableDays;

      let availabelWeekDays = this.getAvailabelWeekDaysInNumbers(availableWeekDays);

      while (momentFromDate <= momentToDate) {
        let isFreezedDay = false;
        if (freezeDays.length > 0) {
          freezeDays.forEach((freeze) => {
            let fromDate = moment(parseInt(freeze.fromDate));
            let toDate = moment(parseInt(freeze.endDate));

            let isThisFreezedDay = momentFromDate.isBetween(
              fromDate.startOf("day"),
              toDate.endOf("day"),
              undefined,
              "[]"
            );
            console.log(isThisFreezedDay);
            if (isThisFreezedDay) {
              isFreezedDay = true;
            }
          });
        }

        if (activePackage.selected_menu) {
          let dateData = activePackage.selected_menu.find(
            (x) =>
              moment(new Date(parseInt(x.date)))
                .tz(TIME_ZONE)
                .format("YYYY-MM-DD") == momentFromDate.format("YYYY-MM-DD")
          );
          if (dateData) {
            if (!this.isPackageNotAvailableOnThisDay(momentFromDate, availabelWeekDays)) {
              if (!isFreezedDay) dateArray.push(momentFromDate.toDate());
            }
          } else {
            if (!this.isPackageNotAvailableOnThisDay(momentFromDate, availabelWeekDays)) {
              if (!isFreezedDay) dateArray.push(momentFromDate.toDate());
            }
          }
        } else {
          if (!this.isPackageNotAvailableOnThisDay(momentFromDate, availabelWeekDays)) {
            if (!isFreezedDay) dateArray.push(momentFromDate.toDate());
          }
        }
        momentFromDate.add(1, "days");
      }
      return dateArray;
    },

    async getAllMenu() {
      let freeze = await this.getCustomerFreezeDays();
      const allDates = this.getAllDates(this.activePackage, freeze);

      let dateWiseMenu = new Array();
      let selectedMenu = this.activePackage.selected_menu;
      let index = 0;
      allDates.forEach((element) => {
        let seletedItem = {};
        seletedItem.date = element;
        seletedItem.index = index;

        if (selectedMenu) {
          let formattedDate = moment(Date.parse(element)).format("MM-DD-YYYY");

          let menu = selectedMenu.find(
            (x) =>
              moment(new Date(parseInt(x.date))).format("MM-DD-YYYY") == formattedDate
          );

          if (menu) {
            seletedItem = {
              date: element,
              allMeals: menu.menu_category,
              last_modified_by: menu.last_modified_by,
              last_modified_date: new Date(parseInt(menu.last_modified_date)),
              index: index,
              isPaused: menu.is_paused,
            };
          }
        } else {
          seletedItem = {
            date: element,
            allMeals: [],
            last_modified_by: "",
            last_modified_date: "",
            index: index,
            isPaused: false,
          };
        }

        dateWiseMenu.push(seletedItem);
        index = index + 1;
      });

      dateWiseMenu.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      this.selectedMealsList = dateWiseMenu;
    },
    getAvailabelWeekDaysInNumbers(availabelWeekDays) {
      let availabelWeekDaysInNumbers = new Array(0);
      if (availabelWeekDays == null) return [];
      availabelWeekDays.forEach((element) => {
        switch (element) {
          case "Sunday":
            availabelWeekDaysInNumbers.push(0);
            break;
          case "Monday":
            availabelWeekDaysInNumbers.push(1);
            break;
          case "Tuesday":
            availabelWeekDaysInNumbers.push(2);
            break;
          case "Wednesday":
            availabelWeekDaysInNumbers.push(3);
            break;
          case "Thursday":
            availabelWeekDaysInNumbers.push(4);
            break;
          case "Friday":
            availabelWeekDaysInNumbers.push(5);
            break;
          case "Saturday":
            availabelWeekDaysInNumbers.push(6);
            break;
          default:
        }
      });
      return availabelWeekDaysInNumbers;
    },
    onPackageChange() {
      this.getAllMenu();
    },
  },

  created() {
    this.activePackage = this.selectedPackage;
    this.getAllMenu();
  },
  data() {
    return {
      activePackage: "",
      tz: tz,
      runningPackages: [],
      selectedMealsList: [],
      hasActiveFreeze: false,
      headers: [
        { text: "Date", value: "date", width: 150 },
        { text: "Updated By", value: "last_modified_by" },
        { text: "Updated Date", value: "last_modified_date" },
        { text: "Remarks", value: "isPaused", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
};
</script>
