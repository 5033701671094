var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-layout',[_c('h2',{staticClass:"black--text text-h5 font-weight-bold mt-3"},[_vm._v("Requests")])]),_c('v-data-table',{staticClass:"elevation-1 mt-8",attrs:{"headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":25,"options":_vm.options,"loading":_vm.loading,"mobile-breakpoint":0,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.slNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.itemsWithSno .map(function(x) { return x._id; }) .indexOf(item._id) + 1 + _vm.options.itemsPerPage * (_vm.options.page - 1))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('span',[(item.customer.user)?_c('span',[_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
              name: 'CustomerDetails',
              params: { customerId: item.customer._id },
              query: { customerId: item.customer._id }
            }}},[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")])],1):_vm._e()]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"MMM DD YYYY"))+" ")]}},{key:"item.appointmentTime",fn:function(ref){
            var item = ref.item;
return [(item.appointmentDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"MMM DD YYYY"))+" "+_vm._s(item.appointmentTime)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_APPOINTMENT_EDIT))?_c('UpdateCustomerDietitianAppoinment',{attrs:{"appointment":item},on:{"updateDietitianAppt":_vm.updateDietitianAppt}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.DIETITIAN_APPOINTMENT_DELETE))?_c('DeleteCustomerDietitianAppt',{attrs:{"dietitianappointment":item},on:{"updateDietitianAppt":_vm.updateDietitianAppt}}):_vm._e()],1)]}}],null,true)}),_c('v-layout',{staticClass:"mt-10"},[_c('h2',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v("Calendar View")])]),_c('v-row',{staticClass:"mt-2"},[(_vm.loading == false)?_c('BookingCalender',{attrs:{"appoinments":_vm.customerList}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }