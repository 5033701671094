<template>
  <v-container class="pa-3" fluid v-if="hasRole(AdminUserPermissions.DASHBOARD_VIEW)">
    <!-- {{ dashboardData }} -->
    <v-layout justify-space-around class="mt-5 px-xl-8" wrap>
      <dashboard-main-card
        v-for="(item, index) in items"
        :key="index"
        :card-item="item"
        :index="index"
      ></dashboard-main-card>
    </v-layout>
    <v-layout justify-start class="ms-3">
      <v-flex xs12 md6 lg2>
        <h3 class="mt-5 ms-md-4 ms-lg-5 ms-xl-16 text-h5 text-xl-h4 font-weight-bold">
          Statistics
        </h3>
      </v-flex>
      <!-- <v-flex xs12 md6 lg4>
        <v-select
          class="ma-0 pa-0 mr-3"
          :menu-props="{ top: false, offsetY: true }"
          :items="reportTypes"
          item-text="text"
          item-value="value"
          dense
          solo
          attach
          label="Month"
          v-model="reportType"
        ></v-select>
      </v-flex> -->
    </v-layout>
    <v-layout> </v-layout>

    <v-layout class="mt-5 px-0" wrap justify-md-space-around justify-lg-start>
      <v-flex xs12 md6 lg6 class="px-8">
        <v-card
          :max-width="
            $vuetify.breakpoint.xl
              ? 400
              : $vuetify.breakpoint.lg
              ? 600
              : $vuetify.breakpoint.md
              ? 390
              : $vuetify.breakpoint.sm
              ? 420
              : 340
          "
          :height="$vuetify.breakpoint.smAndUp ? 550 : 550"
          class="pa-6 pa-sm-8 pa-md-10 pa-lg-6 mx-auto rounded-lg"
          tile
        >
          <v-row>
            <v-col cols="12" lg="4">
              <label class="text-h6 mt-5">Customer</label>
            </v-col>

            <v-col cols="6" lg="4">
              <v-select
                class="ma-0 pa-0 mr-3"
                background-color="#839E87"
                :menu-props="{ top: false, offsetY: true }"
                :items="['Year', 'Month']"
                dense
                solo
                label="Type"
                rounded
                v-model="customerReportType"
              ></v-select>
            </v-col>

            <v-col cols="6" lg="4" v-if="customerReportType == 'Year'">
              <v-select
                class="ma-0 pa-0 mr-3"
                background-color="#839E87"
                :menu-props="{ top: false, offsetY: true }"
                :items="yearList"
                item-text="text"
                item-value="value"
                dense
                solo
                rounded
                label="Year"
                v-model="customerYear"
              ></v-select>
            </v-col>
            <v-col cols="6" lg="4" v-else>
              <v-select
                background-color="#839E87"
                rounded
                class="ma-0 pa-0 mr-3"
                :menu-props="{ top: false, offsetY: true }"
                :items="months"
                item-text="text"
                item-value="value"
                dense
                solo
                label="Month"
                v-model="customerMonth"
              ></v-select>
            </v-col>
          </v-row>
          <CustomerGraph
            class="mt-lg-8 mt-n8"
            :customerReportType="customerReportType"
            :customerYear="customerYear"
            :customerMonth="customerMonth"
          />
        </v-card>
      </v-flex>

      <v-flex xs12 md6 lg6 class="px-8">
        <v-card
          :max-width="
            $vuetify.breakpoint.xl
              ? 400
              : $vuetify.breakpoint.lg
              ? 750
              : $vuetify.breakpoint.md
              ? 390
              : $vuetify.breakpoint.sm
              ? 420
              : 340
          "
          :height="$vuetify.breakpoint.smAndUp ? 550 : 550"
          class="pa-6 pa-sm-8 pa-md-10 pa-lg-6 mx-auto mt-8 mt-md-0 rounded-lg"
          tile
        >
          <v-row>
            <v-col cols="12" lg="4">
              <label class="text-h6 mt-5">Order Report</label>
            </v-col>

            <v-col cols="6" lg="4">
              <v-select
                background-color="#839E87"
                rounded
                class="ma-0 pa-0 mr-3"
                :menu-props="{ top: false, offsetY: true }"
                :items="['Year', 'Month']"
                dense
                solo
                label="Type"
                v-model="orderReportType"
              ></v-select>
            </v-col>

            <v-col cols="6" lg="4" v-if="orderReportType == 'Year'">
              <v-select
                background-color="#839E87"
                rounded
                class="ma-0 pa-0 mr-3"
                :menu-props="{ top: false, offsetY: true }"
                :items="yearList"
                item-text="text"
                item-value="value"
                dense
                solo
                label="Month"
                v-model="orderYear"
              ></v-select>
            </v-col>
            <v-col cols="6" lg="4" v-else>
              <v-select
                background-color="#839E87"
                rounded
                class="ma-0 pa-0 mr-3"
                :menu-props="{ top: false, offsetY: true }"
                :items="months"
                item-text="text"
                item-value="value"
                dense
                solo
                label="Month"
                v-model="orderMonth"
              ></v-select>
            </v-col>
          </v-row>
          <OrderReport
            class="mt-lg-8 mt-n8"
            :orderReportType="orderReportType"
            :orderYear="orderYear"
            :orderMonth="orderMonth"
          />
        </v-card>
      </v-flex>

      <v-flex xs12 md6 lg4 class="mt-8 mt-sm-10 mt-lg-8">
        <v-card
          :max-width="
            $vuetify.breakpoint.xl
              ? 400
              : $vuetify.breakpoint.lg
              ? 350
              : $vuetify.breakpoint.md
              ? 390
              : $vuetify.breakpoint.sm
              ? 420
              : 340
          "
          :height="$vuetify.breakpoint.mdAndUp ? 460 : ''"
          class="mx-auto pt-6 rounded-lg"
          tile
        >
          <v-row>
            <v-col cols="5">
              <label class="text-h6 ms-6">Packages</label>
            </v-col>
            <v-col cols="6">
              <v-menu
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    background-color="#839E87"
                    dense
                    rounded
                    :value="packageDate"
                    append-icon="fa-calendar-alt black--text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="packageDateModel"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div class="pa-6 pa-sm-12 pa-md-10 pa-lg-8">
            <PackageReport
              :date="packageDate"
              class="mt-n5 mt-sm-n10 mt-md-n12 mt-lg-n15 mb-n10 mb-md-0"
            />
          </div>
        </v-card>
      </v-flex>

      <v-flex xs12 md6 lg4 class="mt-8 mt-sm-10 mt-lg-8">
        <v-card
          :max-width="
            $vuetify.breakpoint.xl
              ? 400
              : $vuetify.breakpoint.lg
              ? 350
              : $vuetify.breakpoint.md
              ? 390
              : $vuetify.breakpoint.sm
              ? 420
              : 340
          "
          :height="$vuetify.breakpoint.smAndUp ? 460 : 360"
          class="pa-6 pa-sm-8 pa-md-10 pa-lg-6 mx-auto rounded-lg"
          tile
        >
          <label class="text-h6">Delivery Status</label>
          <DeliveryStatus /> </v-card
      ></v-flex>
      <v-flex xs12 md6 lg4 class="mt-8 mt-sm-10 mt-lg-8">
        <v-card
          :max-width="
            $vuetify.breakpoint.xl
              ? 400
              : $vuetify.breakpoint.lg
              ? 350
              : $vuetify.breakpoint.md
              ? 390
              : $vuetify.breakpoint.sm
              ? 420
              : 340
          "
          :height="$vuetify.breakpoint.lgAndUp ? 460 : ''"
          class="mx-auto pt-6 rounded-lg"
          tile
        >
          <v-row>
            <v-col cols="7">
              <label class="text-h6 ms-6 mt-5">Trending Meal</label>
            </v-col>

            <v-col cols="5">
              <v-select
                background-color="#839E87"
                rounded
                solo
                dense
                class="ma-0 pa-0 mr-3"
                :menu-props="{ top: false, offsetY: true }"
                :items="months"
                item-text="text"
                item-value="value"
                label="Month"
                v-model="monthTrendingMeal"
              ></v-select>
            </v-col>
          </v-row>
          <div class="pa-6 pa-sm-12 pa-md-10 pa-lg-8">
            <TrendingMealGraph
              class="mt-n5 mt-sm-n10 mt-md-n12 mt-lg-n15 mb-n10 mb-md-0"
              :month="monthTrendingMeal"
            />
          </div> </v-card
      ></v-flex>

      <TodaysCustomerBirthday class="mt-5 mt-md-7 mt-lg-5" />

      <ActiveCustomerBirthdayByDate class="mt-5 mt-md-7 mt-lg-5" />

      <DietitianCard class="mt-5 mt-md-7 mt-lg-5" />
    </v-layout>
    <div class="mt-9 ps-md-4 px-2 px-sm-9 px-md-0 px-lg-7 px-xl-16">
      <v-card class="pt-5" color="blue lighten-5 rounded-lg">
        <label class="text-h6 ms-6">Sales Trend</label>
        <SalesTrendGraph class="mt-md-3 px-4" />
      </v-card>
    </div>
    <!-- <h3 class="mt-16 text-body-1 text-sm-h6 font-weight-bold">
      TODAY ( {{ new Date() | moment("DD.MM.YYYY") }} )
    </h3> -->
    <v-layout class="mt-8 ps-md-4 px-sm-6 px-md-0 px-lg-4 px-xl-16" wrap>
      <MealRatingCard />
      <StockAlert />
    </v-layout>
    <v-layout row class="mt-3 mt-sm-8 mb-5 px-3 px-sm-6 px-md-0 px-lg-7 px-xl-16" wrap>
    </v-layout>
  </v-container>
</template>
<script>
import DashboardMainCard from "../../core/components/DashboardMainCard.vue";
import CustomerGraph from "../../core/components/CustomerGraph.vue";
import DeliveryStatus from "../../core/components/DeliveryStatus.vue";
import OrderReport from "../../core/components/OrderReport.vue";
import TrendingMealGraph from "../../core/components/TrendingMealGraph.vue";
import PackageReport from "../../core/components/PackageReport.vue";
import TodaysCustomerBirthday from "../components/TodaysCustomerBirthday.vue";
import ActiveCustomerBirthdayByDate from "../components/ActiveCustomerBirthdayByDate.vue";
import StockAlert from "../../core/components/StockAlert.vue";
import DietitianCard from "../../core/components/DietitianCard";
import MealRatingCard from "../../core/components/MealRatingCard";
import SalesTrendGraph from "../../core/components/SalesTrendGraph";
import moment from "moment";
import { format, parseISO } from "date-fns";
// import { AdminUserPermissions } from "../../utils/user-role";
export default {
  components: {
    "dashboard-main-card": DashboardMainCard,
    ActiveCustomerBirthdayByDate,
    TodaysCustomerBirthday,
    CustomerGraph,
    DietitianCard,
    MealRatingCard,
    SalesTrendGraph,
    TrendingMealGraph,
    OrderReport,
    DeliveryStatus,
    PackageReport,
    StockAlert,
  },
  apollo: {
    getDashboardData() {
      return {
        query: require("../api/getDashboardData.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          // console.log(data);
          this.dashboardData = data.getDashboardData;
          this.setDashboardData(this.dashboardData);
        },
      };
    },
  },

  methods: {
    findYearList() {
      let yearList = [];
      let startingYear = 2021;
      let currentYear = moment().year();
      while (currentYear >= startingYear) {
        yearList.push(startingYear);
        startingYear = startingYear + 1;
      }
      yearList.push(startingYear)
      return yearList;
    },
    setDashboardData(dashboardData) {
      this.items = [];
      let totalCustomer = {
        title: "Active Customers",
        image: "mdi-account-group",
        count: dashboardData.totalCustomers,
      };
      let customersToday = {
        title: "Customers Today",
        image: "mdi-account-group",
        count: dashboardData.customersToday,
      };
      let salesOrderToday = {
        title: "Sales Order Today",
        image: "mdi-file-document",

        count: dashboardData.salesOrderToday,
      };
      let deliveriesToday = {
        title: "Deliveries Today",
        image: "mdi-truck",
        count: dashboardData.deliveriesToday,
      };
      let totalSales = {
        title: "Total Sales",
        image: "mdi-file-document",
        count: dashboardData.totalSalesOrder,
      };
      this.items.push(totalCustomer);
      this.items.push(customersToday);
      this.items.push(salesOrderToday);
      this.items.push(deliveriesToday);
      this.items.push(totalSales);
    },
  },
  computed: {
    packageDate() {
      return this.packageDateModel
        ? moment(this.packageDateModel).format("DD/MM/YYYY")
        : "";
    },
  },
  data() {
    return {
      packageDateModel: format(parseISO(moment().toDate().toISOString()), "yyyy-MM-dd"),
      dashboardData: [],
      yearList: this.findYearList(),
      customerReportType: "Year",
      customerYear: moment().year(),
      customerMonth: moment().month() + 1,

      orderReportType: "Year",
      orderYear: moment().year(),
      orderMonth: moment().month() + 1,

      items: [],
      monthTrendingMeal: "",
      reportTypes: [
        { text: "Month", value: 1 },
        { text: "Year", value: 1 },
      ],
      reportType: "",
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
    };
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
