<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout>
      <h2 class="black--text text-h5 font-weight-bold mt-3">Requests</h2>
    </v-layout>

    <v-data-table
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-8"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :mobile-breakpoint="0"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.slNo`]="{ item }">
        {{
          itemsWithSno
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:item.name="{ item }">
        <span v-if="item.customer">
          <span v-if="item.customer.user">
            <router-link
              :to="{
                name: 'CustomerDetails',
                params: { customerId: item.customer._id },
                query: { customerId: item.customer._id }
              }"
              class="mx-auto"
            >
              {{
                item.customer.user.firstnameEn[0].toUpperCase() +
                  item.customer.user.firstnameEn.substring(1)
              }}
              {{
                item.customer.user.lastnameEn[0].toUpperCase() +
                  item.customer.user.lastnameEn.substring(1)
              }}
            </router-link>
          </span>
        </span>
      </template>

      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.appointmentTime="{ item }">
        <span v-if="item.appointmentDate">
          {{ new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY") }}
          {{ item.appointmentTime }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <!-- <UpdateDietitianAppointment
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_EDIT)"
            :dietitianappointment="item"
            :limit="variables.limit"
            :skip="variables.skip"
          /> -->
          <UpdateCustomerDietitianAppoinment
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_EDIT)"
            :appointment="item"
            @updateDietitianAppt="updateDietitianAppt"
          />
          <!-- <deleteDietitianAppointment
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_DELETE)"
            :dietitianappointment="item"
            :limit="variables.limit"
            :skip="variables.skip"
            @updateDietitianAppt="updateDietitianAppt" 
          /> -->
          <DeleteCustomerDietitianAppt
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_DELETE)"
            :dietitianappointment="item"
            @updateDietitianAppt="updateDietitianAppt"
          />
        </v-row>
      </template>
    </v-data-table>

    <v-layout class="mt-10">
      <h2 class="black--text text-h5 font-weight-bold">Calendar View</h2>
    </v-layout>
    <v-row class="mt-2">
      <BookingCalender v-if="loading == false" :appoinments="customerList" />
    </v-row>
  </v-container>
</template>

<script>
import UpdateCustomerDietitianAppoinment from "../customerDietitian/UpdateCustomerDietitianAppoinment.vue";
import DeleteCustomerDietitianAppt from "../customerDietitian/DeleteCustomerDietitianAppt.vue";
// import deleteDietitianAppointment from "./deleteDietitianAppointment.vue";
// import UpdateDietitianAppointment from "./updateDietitianAppointment.vue";
import BookingCalender from "./BookingCalender.vue";
export default {
  components: {
    UpdateCustomerDietitianAppoinment,
    DeleteCustomerDietitianAppt,
    // deleteDietitianAppointment,
    // UpdateDietitianAppointment,
    BookingCalender
  },
  mounted() {
    // this.$root.$on("updateDietitianAppointment", () => {
    //   this.loading = true;
    //   this.$apollo.queries.getAllDietitianAppts.refetch({
    //     variables: this.variables
    //   });
    // });
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        // if (this.$apollo.queries.getAllDietitianAppts)
        // this.$apollo.queries.getAllDietitianAppts.refetch({
        (this.variables.limit =
          this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        this.getAllDietitianAppts();
        // });
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
          width: 50
        },
        { text: "Customer Name", value: "name", width: 160, sortable: false },
        {
          text: "Contact Number",
          value: "customer.user.phone",
          width: 140,
          sortable: false
        },

        { text: "Date", value: "createdDate", width: 120, sortable: false },
        {
          text: "Appointment Date and Time",
          value: "appointmentTime",
          sortable: false,
          width: 195
        },

        { text: "Action", value: "actions", width: 70, sortable: false }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 25,
        page: 1
      },
      variables: {
        limit: 25,
        skip: 0
      },
      loading: true
    };
  },
  // apollo: {
  //   getAllDietitianAppts() {
  //     return {
  //       query: require("../../api/getAllActiveDietitianAppoinments.graphql"),
  //       variables: this.variables,
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.customerList = [];
  //         this.totalItemsCount = 0;
  //         if (data.getAllActiveDietitianAppoinments) {
  //           this.customerList =
  //             data.getAllActiveDietitianAppoinments.dietitianAppointments;
  //           this.totalItemsCount =
  //             data.getAllActiveDietitianAppoinments.totalCount;
  //         }
  //       }
  //     };
  //   }
  // },
  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  },
  methods: {
    getAllDietitianAppts() {
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllActiveDietitianAppoinments.graphql"),
          variables: this.variables
        })
        .refetch({})
        .then(data => {
          if (data.data.getAllActiveDietitianAppoinments) {
            this.loading = false;
            this.customerList =
              data.data.getAllActiveDietitianAppoinments.dietitianAppointments;
            this.totalItemsCount =
              data.data.getAllActiveDietitianAppoinments.totalCount;
            console.log(this.customerList.length);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateDietitianAppt() {
      this.getAllDietitianAppts();
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllDietitianAppts();
  }
};
</script>

<style scoped></style>
