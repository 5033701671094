<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="OffDayList"
      class="elevation-1 mt-10"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [ 5, 10, 15, 20] }"  
    >
      <template v-slot:[`item.index`]="{ item }">
        {{ OffDayList.indexOf(item) + 1 }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:[`item.shift`]="{ item }">
        {{ formatShift(item.shift) }}
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <span
          :class="
            activeOrNot(item.date) == 'Upcoming' ? 'green--text' : 'red--text'
          "
          >{{ activeOrNot(item.date) }}</span
        >
      </template>
      <template v-if="hasRole(AdminUserPermissions.DIETITIAN_OFF_DAY)" v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="activeOrNot(item.date) == 'Upcoming'"  small @click="deleteList(OffDayList.indexOf(item))" color="red">mdi-delete</v-icon>
      
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  props: {
    OffDayList: {
      require: true
    },
    loading: { require: true }
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        align: "center"
      },
      {
        text: "Date",
        value: "date",
        sortable: false,
        align: "center"
      },
      {
        text: "Shift",
        value: "shift",
        sortable: false,
        align: "center"
      },
      {
        text: "Upcoming or Not",
        value: "active",
        sortable: false,
        align: "center"
      },
      { text: "Action", value: "actions", sortable: false } 
    ],
    redColor: true,
    greenColor: true
  }),

  methods: {
    formatDate(date) {
      return date ? moment(date).format(DATE_FORMAT) : "";
    },
    formatShift(shift) {
      return shift == "FULL_DAY"
        ? "Full Day"
        : shift == "MORNING_SHIFT"
        ? "Morning Shift"
        : shift == "EVENING_SHIFT"
        ? "Evening Shift"
        : "--";
    },
    activeOrNot(data) {
      let today = moment();
      let date = moment(data);
      let diff = date.diff(today, "days");
      if (diff < 0) {
        return "Recent";
      } else {
        return "Upcoming";
      }
    },
    deleteList(index){
        this.OffDayList.splice(index,1)
    }
  }
};
</script>
