<template>
  <v-flex col-12 md7>
    <v-card tile class="pt-8 mx-auto overflow-y: scroll mx-auto rounded-lg"> 
      <label class="text-h6 ms-4"> Stock Alert </label>
      <div v-if="ingrediants.length > 0">
        <v-virtual-scroll
          height="300"
          item-height="64"
          :bench="benched"
          :items="itemsWithSno"
          class="mt-4"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item._id">
              <v-row>
                <v-col cols="2">
                  <v-list-item-action>
                    <v-btn
                      :x-small="$vuetify.breakpoint.xs"
                      :small="$vuetify.breakpoint.smAndUp"
                      depressed
                      color="#F4E2CE"
                      class="black--text font-weight-bold mt-n3"
                    >
                      {{ item.sno }}
                    </v-btn>
                  </v-list-item-action>
                </v-col>
                <v-col cols="7">
                  <v-list-item-title
                    class="text-caption text-sm-body-1 mt-1 mt-sm-0"
                  >
                    <span> {{ item.nameEn }}</span>
                  </v-list-item-title>
                </v-col>
                <v-col cols="3" v-if="item.stock">
                  <span class="ms-n2 text-caption text-sm-body-1">
                    {{ (item.stock.currentStock / 1000).toFixed(2) }}
                    {{ item.quantityUnit }}
                  </span>
                </v-col>
                <v-col cols="3" v-else>
                  <span class="ms-n2 text-caption text-sm-body-1"
                    >0
                    {{ item.quantityUnit }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </div>
      <div v-else>
        <v-list-item-title class="text-center pb-5 mt-5"
          >******No Alerts******</v-list-item-title
        >
      </div>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  apollo: {
    getAlertQuantity() {
      return {
        query: require("../api/getAlertQuantity.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.ingrediants = data.getAlertQuantity.ingredients;
        }
      };
    }
  },
  computed: {
    itemsWithSno() {
      return this.ingrediants.map((d, index) => ({ ...d, sno: index + 1 }));
    }
  },

  data() {
    return {
      loading: true,
      ingrediants: [],
      benched: 0,
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px"
      }
    };
  }
};
</script>
