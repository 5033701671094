<template>
  <v-container fluid class="px-0">
    <v-layout class="black--text mt-6 ms-3 mb-4">
      <h3>Units</h3>
      <v-spacer></v-spacer>
      <UnitForm @updatedUnit="updatedUnit" />
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="unitList"
      class="elevation-1 mt-1 ms-2"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.id`]="{ item }">{{
        unitList.indexOf(item) + 1
      }}</template>
      <template v-slot:[`item.name`]="{ item }">{{
        item.name.charAt(0).toUpperCase() + item.name.substring(1)
      }}</template>
      <template v-slot:[`item.conversionUnit`]="{ item }">{{
        item.conversionUnit == "GRAMS"
          ? "Grams"
          : item.conversionUnit == "ML"
          ? "mL"
          : "Nos"
      }}</template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row v-if="!item.isFixed">
          <v-col>
            <!--  v-if="hasRole(AdminUserPermissions.MASTER_DATA_EDIT)" -->
            <UnitUpdate :unit="item" @updatedUnit="updatedUnit" />
          </v-col>
          <v-col>
            <UnitDelete :unit="item" @updatedUnit="updatedUnit" />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import UnitForm from "../components/UnitForm.vue";
import UnitDelete from "../components/UnitDelete.vue";
import UnitUpdate from "../components/UnitUpdate.vue";

export default {
  components: {
    UnitForm,
    UnitDelete,
    UnitUpdate
  },
  methods: {
    updatedUnit() {
      this.$apollo.queries.getAllUnits.refetch({});
    }
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "Si No", value: "id", width: 100, sortable: false },
      { text: "Unit Name", value: "name", sortable: false },
      { text: "Conversion Unit", value: "conversionUnit", sortable: false },
      { text: "Conversion Scale", value: "conversionValue", sortable: false },
      { text: "Actions", value: "actions", width: 110, sortable: false }
    ],
    totalItemsCount: 25,
    unitList: [],
    options: {
      itemsPerPage: 25,
      page: 1
    },
    variables: {
      limit: 0,
      skip: 0
    }
  }),
  apollo: {
    getAllUnits() {
      return {
        query: require("../api/getAllUnits.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          if (data.getAllUnits) {
            this.unitList = data.getAllUnits.units;
            this.totalItemsCount = data.getAllUnits.totalCount;
          }
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MASTER_DATA_VIEW)) {
      if (
        this.hasRole(this.AdminUserPermissions.STOCK_VIEW) ||
        this.AdminUserPermissions.PURCHASE_VIEW ||
        this.AdminUserPermissions.ADJUST_STOCK_VIEW
      ) {
        this.$router.push("/stock");
      } else
        this.$router.push({
          name: "Error404"
        });
    }
  }
};
</script>
