<template>
  <div>
    <v-layout class="bg" wrap>
      <v-flex xs3 md1 class="mb-4">

        <v-dialog max-width="700px" v-model="showDialog">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class=" text-capitalize ms-sm-4 mb-4" color="primary" @click="showDialog">
              <v-icon class="me-1" small>mdi-plus</v-icon> Edit
            </v-btn>
          </template>
          <v-card class="pa-8 pa-md-12">
            <v-row class="mt-n4 mt-md-n8">
              <v-card-title class="primary--text text-h6 mt-n2">Meal Selection</v-card-title>
              <v-spacer></v-spacer>
              <!-- 22 {{ selectedPackage.package.mealsCount }}22 -->
              <!-- {{ this.menuItems }} -->

              <v-btn color="primary" text @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
            <v-divider class="mb-4"></v-divider>

            <v-form ref="form" v-model="valid">
            
              <div v-for="(item, index) in customerMeals" :key="index">
                <v-card flat>
                  <v-flex xs12 md12>
                    <h4 class="font-weight-bolder">
                      {{ item.name }}

                      <!-- {{item}} -->
                      <!-- <div v-for="(allCategories, index) in menuItems.menuItem " :key="index"> -->
                        <v-layout wrap class="pop_bg">
                          <v-flex cols12>
                            <v-select :items="menuItems.menuItem" placeholder="Select" v-model="item.menuItemList"
                              item-text="nameEn" item-value="_id" attach dense multiple chips outlined></v-select>

                            <template slot="no-data">
                              <v-flex xs12 md6 class="mt-2 mb-2">
                                <h5 class="font-weight-medium ml-4"></h5>
                              </v-flex>
                            </template>
                          </v-flex>
                        </v-layout>

                      <!-- </div> -->

                      <!-- end of for -->




                    </h4>
                  </v-flex>
                </v-card>
                <v-spacer class="mt-5"></v-spacer>
              </div>
            </v-form>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :width="$vuetify.breakpoint.smAndUp ? 120 : 90" @click="saveMealSelection"
                :disabled="!valid" :loading="loading">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>


    <v-layout>
      <v-flex>
        <v-card width="500px" class="pa-8 pa-md-12" color="#ffdfeb">
          <v-list-item v-for="item in selectedPackage.customMeal" :key="item._id">
            <v-list-item-content>
              <v-list-item-title> <strong>
                  <v-list-item-icon>
                    <v-icon>mdi-checkbox-blank-circle</v-icon>
                  </v-list-item-icon> {{ item.category.nameEn }}
                </strong> </v-list-item-title>
              <div v-for="item in item.menu_item" :key="item._id">
                <v-list-item-subtitle>{{ item.nameEn }}</v-list-item-subtitle>
              </div>
            </v-list-item-content>
          </v-list-item>

        </v-card>
      </v-flex>
    </v-layout>

    <v-snackbar v-model="snackBar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../../core/methods/validations";
import VueLodash from "vue-lodash";
import Vue from "vue";
import lodash from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: lodash });
export default Vue.extend({
  components: {},
  props: {
    selectedPackage: {
      require: true
    }
  },
  created() {
    this.getAllMenuItems();
    
    this.customSelection();
    console.log("selectedPackage",this.selectedPackage)
    console.log("category list ",this.allCategories)
    console.log("MenuItems ", this.menuItems);

    this.customerMeals = new Array();


    // console.log("custommeals", this.customerMeals);
  },
  methods: {
    showDialogBox() {
      this.showDialog = true;
    },
    getAllMenuItems() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../../menu-packages/api/getAllMenuItems.graphql"),
          variables: {
            skip: this.skip,
            limit: 100
          }
        })
        .refetch({
          variables: {
            skip: this.skip,
            limit: 100
          }
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllMenuItems) {
            this.menuItems = data.data.getAllMenuItems;
            // let menuItemList = new Array()
            // this.menuItems.forEach((element)=>{
            //   menuItemList.push(element)
            // })
            // console.log("MenuItems ", this.menuItems.menuItem);
           
          }
        });
    },

    

    customSelection() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/menu-packages/api/getAllCategories.graphql"),
          variables: {skip:0,limit:0}
        })
        .refetch({
          variables: {
            skip: 0,
            limit: 0
          }
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllCategories) {
            let allCategories = data.data.getAllCategories.category;
            this.allCategories = JSON.parse(JSON.stringify(allCategories));
            // console.log("allCategories ",this.allCategories);

            this.customerMeals = new Array();
            this.selectedCategoryIds = new Array();
            if (this.selectedPackage.package){
              this.allCategories.forEach((category)=>{
                // console.log(category._id);
                this.selectedPackage.package.dayChoices.forEach(element => {
                  element.mealCount.forEach((element)=>{
                    // console.log(element.menuCategoryId)
                    if (element.menuCategoryId == category._id) {
                      if (element.count > 0) {
                        // console.log("count greater than 0",element.menuCategoryId)

                        let mealCategory = {
                          categoryId:category._id,
                          name: category.nameEn,
                          menuItemList: ""
                        };

                          if(!this.selectedCategoryIds.includes(element.menuCategoryId)){
                            this.selectedCategoryIds.push(element.menuCategoryId)
                            this.customerMeals.push(mealCategory);
                          }
                          console.log("this.selectedCategoryIds",this.selectedCategoryIds);
                          console.log("this.customerMealss",this.customerMeals)

                      }
                    }
                  })
                })

              })

            }

          }
        });
    },
    saveMealSelection() {
      

      this.btnloading = true;
      let selectedMeals = new Array(0);
      this.customerMeals.forEach((element) => {
        console.log("fff",element.categoryId)
        console.log("tttt",element.menuItemList)
        // let menuItems = new Array(0);
        let item = {
          categoryId: element.categoryId,
          mealId: element.menuItemList,
        };
        console.log("Item ",item);
        selectedMeals.push(item);


      })
      console.log("selectedMeals ",selectedMeals);

      let input = {
        customMeal: selectedMeals,
      };

      this.$apollo
        .mutate({
          mutation: require("../../api/createCustomMeal.graphql"),
          variables: {
            input: input,
            customerId: this.$route.query.customerId,
            subscriptionId: this.selectedPackage._id,
          },

        })
        .then(() => {
          this.showDialog = false;
          this.text = "Success!";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.btnloading = false;
          this.$root.$emit("updateGetCustomerById");
        })
        .catch(error => {
          this.snackbar = true;
          this.text = "Error";
          this.snackColor = "error";
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    }
  },

  data() {
    return {
      requiredValidator: requiredValidator,
      customerMeals: [],
      showDialog: false,
      dislikedIngredients: [],
      menu: [],
      valid: false,
      loading: false,
      snackBar: false,
      snackColor: "",
      text: "",
      skip: 0,
      limit: 0,
      menuItems: [],
      btnloading: false,
      snackbarColor: "",
      customerMealsList: [],
      categoryList:[],
      allCategories:[],
      mealIdList:[],
      menuItemList:[],
      selectedCategoryIds:[],

      headers: [
        { text: "Paused Date", value: "date" },
        { text: "Pause Type", value: "universalPause" },
        { text: "Modified Date", value: "last_modified_date" },
        { text: "Done By", value: "last_modified_by" },
        { text: "Action", value: "actions" }
      ]
    };
  }
});
</script>
