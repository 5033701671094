var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticStyle:{"background-color":"#e5e5e5"}},[_c('FreezeCustomerPopup',{attrs:{"freezeDays":_vm.freezeDays}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.freezeDays,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.last_modified_date",fn:function(ref){
var item = ref.item;
return [(item.last_modified_date != 'Invalid Date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.last_modified_date,"MMM DD YYYY"))+" ")]):_vm._e()]}},{key:"item.isPaused",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaused ? "Paused" : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasUnfreezeOption(item))?_c('UnFreeze',{attrs:{"freeze":item}}):_vm._e()]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.fromDate)),"MMM DD YYYY"))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(item.endDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.endDate)),"MMM DD YYYY"))+" ")]):_vm._e()]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":true,"color":"primary","disabled-color":"primary"},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.updatedBy",fn:function(ref){
var item = ref.item;
return [(item.updatedBy)?_c('span',[_vm._v(_vm._s(item.updatedBy.firstnameEn)+" "+_vm._s(item.updatedBy.lastnameEn))]):_vm._e()]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }